import { useEffect, useState } from 'react';
import headerImageMobile from '../../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0002_s-REAL-Sujets-2023-Webhero-16zu9-1.14.jpg';
import headerImage from '../../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.4.jpg';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useFetchStats } from '../../hooks/stats/useFetchStats';
import { useHandleUserRealtyActive } from '../../hooks/user/useHandleUserRealtyActive';
import Header from '../Header/Header';
import SimpleSlider from '../Sliders/SimpleSlider';

function DashboardHeaderFinalStats({ stats, cssClasses, children }) {
    const [activityPeriod, setActivityPeriod] = useState(null);

  const { getData, verschickteAngeboteData, besichtigungenData, inseratKlicksData, startDatumFormatiert, endDatumFormatiert, startDatum, endDatum } = useFetchStats();

  if (!cssClasses) cssClasses = 'header--slider header--slider--purplebg header--stats header--stats-final header--purplebg header--fitcontent';
  else cssClasses += ' header--slider header--slider--purplebg header--stats header--purplebg header--fitcontent';

  const { marketingActivities, marketingActivitiesLoaded } = useHandleUserRealtyActive();
  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (startDatum && endDatum) {
      const startDatumAktivitäten = new Date(startDatum);
      const endDatumAktivitäten = new Date(endDatum);
      const activityPeriodTime = endDatumAktivitäten.getTime() - startDatumAktivitäten.getTime();
      const activityPeriod = activityPeriodTime / (1000 * 3600 * 24);
      setActivityPeriod(activityPeriod);
    }
  }, [startDatum, endDatum]);

  const sliderSettings = {
    appendDots: (dots) => <ul>{dots}</ul>,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: true,
  };

  return (
    <Header cssClasses={cssClasses}>
      <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
      {isMobile ? (
        <>
          <div className="w-desktop--center position-relative z-index-1 text-white">
            <h1 className="mb-50rem mt-50rem font-150">Dashboard</h1>
            <p className="font-weight-600 font-90">
              Zusammenfassung Immobilienvermarktung:
              <br />
              {startDatumFormatiert && endDatumFormatiert ? `${startDatumFormatiert} - ${endDatumFormatiert}` : ''}
            </p>
          </div>
          <SimpleSlider settings={sliderSettings} isDashboard={true}>
            <div className="statsheader-container statsheader-container--slider">
              <div className="statsheader-container--final__left">
                <div className="statistic-card">
                  {activityPeriod ? <h1>{activityPeriod} Tage</h1> : <h1>0 Tage</h1>}
                  <p>in aktiver Vermarktung</p>
                </div>
                <div className="statistic-card">
                  {inseratKlicksData && inseratKlicksData.count && inseratKlicksData.count[2] ? <h1>{inseratKlicksData.count[2]}</h1> : <h1>0</h1>}
                  <p>Klicks auf Immobilieninserat</p>
                </div>
              </div>
            </div>
            <div className="statsheader-container statsheader-container--slider">
              <div className="statsheader-container--final__right">
                <div className="statistic-card">
                  <p>Besichtigungen</p>
                  {besichtigungenData && besichtigungenData.count && besichtigungenData.count[2] ? <h1>{besichtigungenData.count[2]}</h1> : <h1>0</h1>}
                </div>
                <div className="statistic-card">
                  <p>verschickte Angebote</p>
                  {verschickteAngeboteData && verschickteAngeboteData.count && verschickteAngeboteData.count[2] ? <h1>{verschickteAngeboteData.count[2]}</h1> : <h1>0</h1>}
                </div>
                <div className="statistic-card">
                  <p>Vermarktungsaktivitäten</p>
                  {marketingActivitiesLoaded && marketingActivities ? <h1>{marketingActivities}</h1> : <h1>0</h1>}
                </div>
              </div>
            </div>
          </SimpleSlider>
          <div className="w-desktop--center position-relative z-index-1 pt-0 text-center">{/*<button className="button button--white-outline">Details> einsehen</button>*/}</div>
        </>
      ) : (
        <>
          <div className="w-desktop--center position-relative z-index-1 pb-0 mt-50rem">
            <h1 className="mb-0">Dashboard</h1>
            <div className="d-flex justify-content-between">
              <h3 className="text-white mt-100rem">Zusammenfassung Immobilienvermarktung</h3>
              <h3 className="text-white mt-100rem">{startDatumFormatiert && endDatumFormatiert ? `${startDatumFormatiert} - ${endDatumFormatiert}` : ''}</h3>
            </div>
          </div>

          <div className="statsheader-container statsheader-container--final w-desktop--center">
            <div className="statsheader-container--final__left">
              <div className="statistic-card">
                {activityPeriod ? <h1>{activityPeriod} Tage</h1> : <h1>0 Tage</h1>}
                <p>in aktiver Vermarktung</p>
              </div>
              <div className="statistic-card">
                {inseratKlicksData && inseratKlicksData.count && inseratKlicksData.count[2] ? <h1>{inseratKlicksData.count[2]}</h1> : <h1>0</h1>}
                <p>Klicks auf Immobilieninserat</p>
              </div>
            </div>
            <div className="statsheader-container--final__right">
              <div className="statistic-card">
                <p>Besichtigungen</p>
                {besichtigungenData && besichtigungenData.count && besichtigungenData.count[2] ? <h1>{besichtigungenData.count[2]}</h1> : <h1>0</h1>}
              </div>
              <div className="statistic-card">
                <p>verschickte Angebote</p>
                {verschickteAngeboteData && verschickteAngeboteData.count && verschickteAngeboteData.count[2] ? <h1>{verschickteAngeboteData.count[2]}</h1> : <h1>0</h1>}
              </div>
              <div className="statistic-card">
                <p>Vermarktungsaktivitäten</p>
                {marketingActivitiesLoaded && marketingActivities ? <h1>{marketingActivities}</h1> : <h1>0</h1>}
              </div>
            </div>
          </div>
        </>
      )}
      {children}
    </Header>
  );
}

export default DashboardHeaderFinalStats;
