import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// ICONS
import IconBarchartWithArrow from '../Icons/IconBarchartWithArrow';
import IconLock from '../Icons/IconLock';

import InfoDialog from '../Dialogs/InfoDialog';
import PageDialogWohnwertLight from '../Dialogs/PageDialogWohnwertLight';
import WohnwertLightFormResultWrapper from '../Forms/WohnwertLightFormResultWrapper';
import Card from './Card';

// STORE
import { setPageDialogOpenContactForm, setReasonContactForm } from '../../reducers/contactInformation';
import { setWohnwertResultsActive } from '../../reducers/user';
import { setPageDialogOpenWohnwertForm, setResetFieldsWohnwertForm } from '../../reducers/wohnwertLight';

import { CONTACTFORM_ANLIEGEN_VERKAUF } from '../../constants';
import { bitteWaehlenOption } from '../FormFields/util/formOptions';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useHandleWohnwert } from '../../hooks/user/useHandleWohnwert';
import '../../styles/wohnwert.scss';
import { anliegenOptions } from '../FormFields/util/formOptions';

function WohnwertLightCard({ cssClasses }) {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user);

  const [infoDialogShown, setInfoDialogShown] = useState(false);

  const { isMobile } = useHandleScreenResize();

  const { handleSetImmoForContactForm } = useHandleWohnwert();

  if (!cssClasses) cssClasses = 'wohnwertlight-card';
  else cssClasses += ' wohnwertlight-card';

  const handleInfoModal = () => {
    setInfoDialogShown(!infoDialogShown);
  };

  const handleOpenContactForm = () => {
    dispatch(setPageDialogOpenWohnwertForm(false));
    dispatch(setResetFieldsWohnwertForm());

    handleSetImmoForContactForm();

    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_VERKAUF]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  const handleOpenWohnwertForm = () => {
    setShowForm(true);

    dispatch(setResetFieldsWohnwertForm());
    dispatch(setPageDialogOpenWohnwertForm(true));
    dispatch(setWohnwertResultsActive(bitteWaehlenOption));
  };

  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if (user.wohnwertResultsActive && user.wohnwertResultsActive.id !== -1) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  }, [user.wohnwertLastResults]);

  return (
    <>
      <Card cssClasses={cssClasses + ' background-apple text-white'} cardStyle={'customContent'} icon={<IconBarchartWithArrow color="#fff" />}>
        {showForm ? (
          <>
            <div className="wohnwertlight__content">
              <IconBarchartWithArrow color="#fff" cssClasses={'mobile-hidden mb-20'} />
              <h2 className="text-white">Wohnwert Light</h2>

              <p className="font-90 h-100">Der schnelle Immobilienrechner gibt Ihnen einen groben Richtwert zum Wert Ihrer Immobilie. Es ist ein erster Schritt.</p>

              <div className="d-flex d-flex--btnandicon">
                <button className="button button--white-outline mt-100rem" onClick={handleOpenWohnwertForm}>
                  Bewertung starten
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="wohnwertlight__content">
              <IconBarchartWithArrow color="#1E3163" cssClasses="mobile-hidden mb-20" />
              <h2 className="text-white">Wohnwert Light</h2>
              <h3 className="text-white headline--withinfomark font-130">Ergebnis Ihrer Schnell-Bewertung:</h3>

              <WohnwertLightFormResultWrapper>
                <div className="button-panel">
                  <button className="button button--white" onClick={handleOpenContactForm}>
                    Diese Immobilie verkaufen?
                  </button>

                  {user.wohnwertLastResults.length === 3 || (user.wohnwertLastResults.length === 1 && !user.isLoggedIn) ? (
                    <button className="button button--withicon button--white-outline" disabled={true}>
                      <IconLock color={'#fff'} />
                      Neue Berechnung
                    </button>
                  ) : (
                    <button className="button button--white-outline" onClick={handleOpenWohnwertForm}>
                      Neue Berechnung
                    </button>
                  )}
                </div>
              </WohnwertLightFormResultWrapper>
            </div>
          </>
        )}
      </Card>

      <InfoDialog open={infoDialogShown} handleClose={handleInfoModal} headline="Informationen zum Ergebnis der Schnell-Bewertung">
        <h3>Die Schnell-Bewertung ermittelt den Richtwert für Ihre Immobilie und gibt Ihn als Ergebnis aus.</h3>
        <p>Weitere Infos und Lorem ipsum, styling kommt noch abhängig vom Inhalt</p>
      </InfoDialog>

      <PageDialogWohnwertLight />
    </>
  );
}

export default WohnwertLightCard;
