import { useState, useEffect } from 'react';
import { getLocations } from '../../../api/Sreal';
import statesWithZips from '../../FormFields/util/statesWithZips';
import { autocomplete } from '../../../api/JustImmo';
import { useSelector } from 'react-redux';
const wait = 500;

export const useHandleAutocomplete = (formType) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [typing, setTyping] = useState(false);
  const currentSearchProfileForm = useSelector((state) => state.searchProfileForm);

  const handleAutocomplete = (filterValue) => {
    setFilterValue(filterValue);
    setTyping(true);
  };

  useEffect(() => {
    if (!typing && filterValue !== '') {
      switch (formType) {
        case 'searchForm':
          getLocationsAndUpdateList(filterValue);
          break;
        // Hier können andere Autocomplete Logiken für andere Formulare hinzugefügt werden (unten neue Methode hinzufügen und hier ausführen)
        case 'searchProfileForm':
          getAndSetSearchProfileFormLocations(filterValue, selectedOptions[0]);
          break;
        default:
          break;
      }
    }
  }, [typing]);

  useEffect(() => {
    let timeoutId;

    if (typing) {
      timeoutId = setTimeout(() => setTyping(false), wait);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [typing, wait]);

  const getAndSetSearchProfileFormLocations = async (val) => {

    try {
      let autoCompleteResults = await autocomplete(val);
      // autoCompleteResults = autoCompleteResults.filter((r) => r.type === 5);
      autoCompleteResults = autoCompleteResults.map((r) => {
        return {
          ...r,
          value: parseInt(r.value),
        };
      });

      setFilteredOptions(autoCompleteResults);
    } catch (e) {
      // handle autocomplete error
      console.error('e', e);
    }
  };

  const getLocationsAndUpdateList = async (val) => {
    try {
      let locations = await getLocations(val);

      // Filter out selected options
      locations = locations.filter((location) => {
        return !selectedOptions.some((selectedOption) => selectedOption.value === location.value);
      });

      if (locations.length === 0 || locations.every((location) => location.disabled)) {
        locations = [{ value: '', label: 'Keine Ergebnisse gefunden', disabled: true }];
      }

      setFilteredOptions(locations);
    } catch (e) {
      // Handle error
      console.error('e', e);
    }
  };

  // Hier können weitere Logiken für andere Formulare hinzugefügt werden

  return {
    handleAutocomplete,
    setFilteredOptions,
    filteredOptions,
    selectedOptions,
    setSelectedOptions,
  };
};
