function IconCheckmark({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="9.79" height="9.79" viewBox="0 0 9.79 9.79">
      <path
        data-name="Pfad 10325"
        d="M32.558,32.445l3.665-3.665a.716.716,0,0,0-1.013-1.013l-3.665,3.665L27.88,27.767a.716.716,0,1,0-1.013,1.013l3.665,3.665L26.867,36.11a.716.716,0,0,0,1.013,1.013l3.665-3.665,3.665,3.665a.691.691,0,0,0,.482.193.619.619,0,0,0,.482-.193.7.7,0,0,0,0-1.013Z"
        transform="translate(-26.65 -27.55)"
        fill={color}
      />
    </svg>
  );
}

export default IconCheckmark;
