import {
  CONTACTFORM_ANLIEGEN_ALLG,
  CONTACTFORM_ANLIEGEN_MARKTBERICHT,
  CONTACTFORM_ANLIEGEN_IMMOGUTACHTEN,
  CONTACTFORM_ANLIEGEN_VERKAUF,
  CONTACTFORM_ANLIEGEN_BERATUNG,
  CONTACTFORM_ANLIEGEN_SUCHE,
  CONTACTFORM_ANLIEGEN_PROFIL,
  CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN,
  CONTACTFORM_ANLIEGEN_SUPPORT,
  JUSTIMMO_IMMOBILIENART_WOHNUNG,
  JUSTIMMO_IMMOBILIENART_HAUS,
  JUSTIMMO_IMMOBILIENART_GRUNDSTUECK,
} from '../../../constants';

const genderOptions = [
  { value: 'm', label: 'Herr*' },
  { value: 'f', label: 'Frau*' },
];

const emailSettingsWantsToReceiveMailsOptions = [
  { value: 'yes', label: 'Ja' },
  { value: 'no', label: 'Nein' },
];

const emailSettingsFrequencyOptions = [
  { value: 1, label: 'Täglich' },
  { value: 2, label: 'Alle zwei Tage' },
  { value: 7, label: 'Wöchentlich' },
];

const emailSettingsTimeOfDayOptions = [
  { value: 6, label: 'Morgens 6 Uhr' },
  { value: 12, label: 'Mittags 12 Uhr' },
  { value: 18, label: 'Abends 18 Uhr' },
];

const anliegenOptions = [
  { id: CONTACTFORM_ANLIEGEN_ALLG, text: 'Allgemeine Anfrage' },
  { id: CONTACTFORM_ANLIEGEN_SUPPORT, text: 'Supportanfrage' },
  { id: CONTACTFORM_ANLIEGEN_IMMOGUTACHTEN, text: 'Gutachten anfragen' },
  // { id: CONTACTFORM_ANLIEGEN_MARKTBERICHT, text: 'Anfrage Marktbericht' },
  { id: CONTACTFORM_ANLIEGEN_BERATUNG, text: 'Beratungsgespräch' },
  { id: CONTACTFORM_ANLIEGEN_VERKAUF, text: 'Verkaufsanfrage' },
  { id: CONTACTFORM_ANLIEGEN_SUCHE, text: 'Suchanfrage' },
  { id: CONTACTFORM_ANLIEGEN_PROFIL, text: 'Profil ändern' },
  { id: CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN, text: 'Profil deaktivieren' },
];

const anliegenOptionsNotLoggedIn = anliegenOptions.filter(
  (o) => o.id !== CONTACTFORM_ANLIEGEN_MARKTBERICHT && o.id !== CONTACTFORM_ANLIEGEN_PROFIL && o.id !== CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN,
);

const furnishingOptions = [
  {
    id: 1,
    text: 'möbliert',
  },
  {
    id: 2,
    text: 'teilmöbliert',
  },
  {
    id: 3,
    text: 'nicht möbliert',
  },
  {
    id: 4,
    text: 'optional',
  },
  {
    id: 7,
    text: 'Küche',
  },
  {
    id: 8,
    text: 'Küche, Bad',
  },
  {
    id: 9,
    text: 'Kochnische',
  },
  {
    id: 10,
    text: 'Geschäftseinrichtung',
  },
  {
    id: 11,
    text: 'Bad',
  },
];

const immobilienartOptions = [
  {
    id: 2,
    text: 'Haus',
  },
  {
    id: 3,
    text: 'Wohnung',
  },
];

const immobilienartOptionsJustImmo = [
  {
    id: JUSTIMMO_IMMOBILIENART_HAUS,
    text: 'Haus',
  },
  {
    id: JUSTIMMO_IMMOBILIENART_WOHNUNG,
    text: 'Wohnung',
  },
  {
    id: JUSTIMMO_IMMOBILIENART_GRUNDSTUECK,
    text: 'Grundstück',
  },
];

const exteriorSurfacesOptionsJustImmo = [
  {
    id: 'covered_balcony',
    text: 'Loggia',
  },
  {
    id: 'balcony',
    text: 'Balkon',
  },
  {
    id: 'terrace',
    text: 'Terrasse',
  },
  {
    id: 'garden',
    text: 'Garten',
  },
];

const immobilienartOptionsSearchForm = [
  {
    id: 'house',
    text: 'Haus',
  },
  {
    id: 'flat',
    text: 'Wohnung',
  },
  {
    id: 'other',
    text: 'Andere Immobilien',
  },
  {
    id: 'investment',
    text: 'Anlageimmobilien',
  },
  {
    id: 'vacation',
    text: 'Ferienimmobilien',
  },
  {
    id: 'business',
    text: 'Gewerbeimmobilien',
  },
  {
    id: 'property',
    text: 'Grundstücke',
  },
  {
    id: 'agriculture',
    text: 'Land- und Forstwirtschaften',
  },
  {
    id: 'residential_projects',
    text: 'Neubau Projekte',
  },
];

let immobilienUnterartOptions = [
  {
    id: 2,
    text: 'Sonstige Immobilien',
    realtyTypes: ['other'],
  },
  {
    id: 3,
    text: 'Sonstige Anlageimmobilien',
    realtyTypes: ['investment'],
  },
  {
    id: 4,
    text: 'Vorsorgewohnung',
    realtyTypes: ['investment'],
  },
  {
    id: 5,
    text: 'Ferienhaus',
    realtyTypes: ['vacation'],
  },
  {
    id: 6,
    text: 'Ferienwohnung',
    realtyTypes: ['vacation'],
  },
  {
    id: 7,
    text: 'Betriebsobjekt/-grundstück',
    realtyTypes: ['business'],
  },
  {
    id: 8,
    text: 'Büro',
    realtyTypes: ['business'],
  },
  {
    id: 9,
    text: 'Geschäftslokal',
    realtyTypes: ['business'],
  },
  {
    id: 10,
    text: 'Hotel/Gastronomie',
    realtyTypes: ['business'],
  },
  {
    id: 11,
    text: 'Sonstige Gewerbeimmobilien',
    realtyTypes: ['business'],
  },
  {
    id: 12,
    text: 'Baugrundstück',
    realtyTypes: ['property'],
  },
  {
    id: 13,
    text: 'Sonstiges Grundstück',
    realtyTypes: ['property'],
  },
  {
    id: 14,
    text: 'Bauernhaus',
    realtyTypes: ['house'],
  },
  {
    id: 15,
    text: 'Doppelhaushälfte',
    realtyTypes: ['house'],
  },
  {
    id: 16,
    text: 'Einfamilienhaus',
    realtyTypes: ['house'],
  },
  {
    id: 17,
    text: 'Reihenhaus',
    realtyTypes: ['house'],
  },
  {
    id: 18,
    text: 'Sonstige Häuser',
    realtyTypes: ['house'],
  },
  {
    id: 19,
    text: 'Villa',
    realtyTypes: ['house'],
  },
  {
    id: 20,
    text: 'Forstwirtschaft',
    realtyTypes: ['agriculture'],
  },
  {
    id: 21,
    text: 'Landwirtschaft',
    realtyTypes: ['agriculture'],
  },
  {
    id: 22,
    text: 'Sonstige Land- und Forstwirtschaft',
    realtyTypes: ['agriculture'],
  },
  {
    id: 23,
    text: 'Neubau',
    realtyTypes: ['flat'],
  },
  {
    id: 24,
    text: 'Wohnung',
    realtyTypes: ['flat'],
  },
  {
    id: 25,
    text: 'Zinshaus',
    realtyTypes: ['investment'],
  },
];

const featuresOptions = [
  {
    id: 'balconyOrTerace',
    text: 'Balkon oder Terrasse',
  },
  {
    id: 'garage',
    text: 'Garage',
    realtyTypes: ['other'],
  },
  {
    id: 'garden',
    text: 'Garten',
  },
  {
    id: 'cellar',
    text: 'Keller',
  },
  {
    id: 'lift',
    text: 'Lift',
  },
  {
    id: 'parkingSpot',
    text: 'Parkplatz',
  },
  {
    id: 'renovated',
    text: 'renoviert',
  },
  {
    id: 'unrestricted',
    text: 'unbefristet',
  },
  {
    id: 'furnished',
    text: 'möbliert',
  },
];

const sortOptions = [
  {
    id: 'updated_desc',
    text: 'Aktualität',
  },
  {
    id: 'price_asc',
    text: 'Preis aufsteigend',
  },
  {
    id: 'price_desc',
    text: 'Preis absteigend',
  },
  {
    id: 'rooms_asc',
    text: 'Zimmer aufsteigend',
  },
  {
    id: 'rooms_desc',
    text: 'Zimmer absteigend',
  },
  {
    id: 'area_asc',
    text: 'Fläche aufsteigend',
  },
  {
    id: 'area_desc',
    text: 'Fläche absteigend',
  },
];

const bitteWaehlenOption = { id: -1, text: 'Bitte wählen' };

const phoneNumberPrefixOptions = [
  { id: 30, text: '+30 (Griechenland)' },
  { id: 31, text: '+31 (Niederlande)' },
  { id: 32, text: '+32 (Belgien)' },
  { id: 33, text: '+33 (Frankreich)' },
  { id: 34, text: '+34 (Spanien)' },
  { id: 351, text: '+351 (Portugal)' },
  { id: 352, text: '+352 (Luxemburg)' },
  { id: 353, text: '+353 (Irland)' },
  { id: 354, text: '+354 (Island)' },
  { id: 356, text: '+356 (Malta)' },
  { id: 357, text: '+357 (Zypern)' },
  { id: 358, text: '+358 (Finnland)' },
  { id: 359, text: '+359 (Bulgarien)' },
  { id: 36, text: '+36 (Ungarn)' },
  { id: 370, text: '+370 (Litauen)' },
  { id: 371, text: '+371 (Lettland)' },
  { id: 372, text: '+372 (Estland)' },
  { id: 385, text: '+385 (Kroatien)' },
  { id: 386, text: '+386 (Slowenien)' },
  { id: 39, text: '+39 (Italien)' },
  { id: 40, text: '+40 (Rumänien)' },
  { id: 420, text: '+420 (Tschechien)' },
  { id: 421, text: '+421 (Slowakei)' },
  { id: 423, text: '+423 (Liechtenstein)' },
  { id: 43, text: '+43 (Österreich)' },
  { id: 45, text: '+45 (Dänemark)' },
  { id: 46, text: '+46 (Schweden)' },
  { id: 47, text: '+47 (Norwegen)' },
  { id: 48, text: '+48 (Polen)' },
  { id: 49, text: '+49 (Deutschland)' },
].sort((a, b) => {
  const countryNameA = a.text.substring(a.text.indexOf(' '));
  const countryNameB = b.text.substring(b.text.indexOf(' '));

  return countryNameA.localeCompare(countryNameB);
});

export {
  bitteWaehlenOption,
  genderOptions,
  emailSettingsWantsToReceiveMailsOptions,
  emailSettingsFrequencyOptions,
  emailSettingsTimeOfDayOptions,
  anliegenOptions,
  anliegenOptionsNotLoggedIn,
  featuresOptions,
  immobilienartOptionsSearchForm,
  immobilienartOptionsJustImmo,
  immobilienartOptions,
  sortOptions,
  furnishingOptions,
  exteriorSurfacesOptionsJustImmo,
  phoneNumberPrefixOptions,
};
