import moment from 'moment';

const parseDate = (dateString, format = 'DD.MM.YYYY') => {
  if (dateString === '' || dateString === null) {
    return '';
  }

  const date = new Date(dateString);

  return moment(date).locale('de').format(format);
};

const parseTime = (dateString, format = 'HH:mm') => {
  if (dateString === '' || dateString === null) {
    return '';
  }

  const date = new Date(dateString);
  return moment(date).format(format);
};

export { parseDate, parseTime };
