import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FormSelect from "../FormSelect"

function FormSelectWohnwertLastResults({label = 'Bisherige Berechnungen'}) {
    let user = useSelector((state) => state.user);
    const [wohnwertCalcs, setWohnwertCalcs] = useState([]);

    useEffect(() => {
        if(user.wohnwertLastResults) {
            let selectOptions = user.wohnwertLastResults.map((calc, index) => {
                let angaben =
                    calc.federalState + ', ' +
                    calc.district + ', ' +
                    calc.estateType + ', ' +
                    calc.estateSize + 'm';

                return { id: index, text: angaben };
            });
            
            setWohnwertCalcs(selectOptions);
        }
    }, [user.wohnwertLastResults])

    return (
        <FormSelect
            label={label}
            placeholder="Bitte wählen"
            value={user.wohnwertResultsActive}
            id={'previous_calculations'}
            formType="wohnwertLight"
            options={wohnwertCalcs}
            disabled={!user.isLoggedIn || wohnwertCalcs.length === 0}
        />
    )
}

export default FormSelectWohnwertLastResults;