function IconLinkAbgelaufen({ color, cssClasses }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="147.687"
      height="95.038"
      viewBox="0 0 147.687 95.038"
      className={cssClasses}
    >
      <g data-name="Gruppe 13232" transform="translate(9230.187 -9680.1)">
        <path
          data-name="Differenzmenge 41"
          d="M138.945,72.8H71.734A36.52,36.52,0,0,0,72,68.4,36,36,0,0,0,38.145,32.463V11.2A11.179,11.179,0,0,1,39.78,5.455L92.415,47a2.808,2.808,0,0,0,3.466,0L148.51,5.455a11.178,11.178,0,0,1,1.634,5.746V61.6A11.212,11.212,0,0,1,138.945,72.8Zm-44.8-31.561h0L43.829,1.518A10.943,10.943,0,0,1,49.344,0h89.6a10.95,10.95,0,0,1,5.516,1.518L94.145,41.239Z"
          transform="translate(-9233.145 9680.6)"
          fill={color}
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <g data-name="Gruppe 13234" transform="translate(-9148.187 9738.019)">
          <g data-name="Gruppe 13235" transform="translate(-82 -16.88)">
            <path
              data-name="Pfad 8367"
              d="M61.836,26.13A27.067,27.067,0,0,1,35.727,54.123c-.32.01-.642.015-.962.015A27.015,27.015,0,0,1,10.193,38.4a2.578,2.578,0,0,1,4.681-2.16A21.917,21.917,0,1,0,34.02,5.174a22.234,22.234,0,0,0-16.058,7.739A21.855,21.855,0,0,0,12.98,23.926L17.4,18.894A2.578,2.578,0,0,1,21.268,22.3l-9.043,10.3a2.576,2.576,0,0,1-1.936.876c-.059,0-.116-.005-.175-.005a2.582,2.582,0,0,1-1.972-1.15L.431,20.7a2.577,2.577,0,1,1,4.295-2.851l3.212,4.834A26.931,26.931,0,0,1,14.05,9.559,27.407,27.407,0,0,1,33.84.018a27.072,27.072,0,0,1,28,26.111"
              transform="translate(0 -0.139)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconLinkAbgelaufen;
