import React, { useState } from 'react';

// Icons
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import IconArrowUp2 from '../Icons/IconArrowUp2';

function AktuellesLinkitems({ children, date }) {
  const [isActive, setActive] = useState(false);

  const { isMobile } = useHandleScreenResize();

  const showLessButton = [
    <span className="show-less" onClick={() => setActive(false)} key="showLessButton">
      Weniger <IconArrowUp2 color="#3679ec" cssClasses="ml-50rem" />
    </span>,
  ];

  const toggleMessages = () => {
    if (!isActive) {
      setActive(true);
    }
  };
  let childrenContainerCssClasses = 'aktuellescontainer-children';
  if (isActive) {
    childrenContainerCssClasses += ' is-open';
  } else {
    childrenContainerCssClasses += ' is-closed';
  }

  return (
    <>
      {isMobile ? (
        <div className={isActive ? 'aktuellescontainer-wrapper is-open' : 'aktuellescontainer-wrapper'}>
          <div className="aktuellescontainer" onClick={toggleMessages}>
            {' '}
          </div>
          {isActive && (
            <div className="aktuellescontainer__topline">
              <span className="font-weight-600 aktuellescontainer__topline__date">{date}</span>
              {showLessButton}
            </div>
          )}

          <div className={childrenContainerCssClasses}>{children}</div>

          {isActive && showLessButton}
        </div>
      ) : (
        <>{children[0]}</>
      )}
    </>
  );
}

export default AktuellesLinkitems;
