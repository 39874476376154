function IconImmoLive({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="36.565"
      height="11.16"
      viewBox="0 0 36.565 11.16"
    >
      <g data-name="Gruppe 10268" transform="translate(0 1)">
        <path
          data-name="Pfad 7607"
          d="M11.133,3.5h-.582V7.236h1.023v-2.8a1.218,1.218,0,0,1,.627-.194c.328,0,.553.112.553.478V7.236h1.023V4.667a1.132,1.132,0,0,0-.015-.224,1.431,1.431,0,0,1,.65-.2c.336,0,.545.112.545.478V7.236h1.03V4.652a1.048,1.048,0,0,0-1.135-1.187,1.916,1.916,0,0,0-1.217.5,1.061,1.061,0,0,0-1-.5,1.387,1.387,0,0,0-1.06.455v-.06c0-.179-.1-.358-.441-.358M9.616,8.9,6.564,6.543v3.811L7.6,9.459l.839,1.7.687-.328L8.3,9.131ZM5.354,4.435a1.218,1.218,0,0,1,.627-.194c.329,0,.553.112.553.478v.99l1.023.784V4.667a1.132,1.132,0,0,0-.015-.224,1.431,1.431,0,0,1,.65-.2c.336,0,.545.112.545.478V7.236h1.03V4.652A1.048,1.048,0,0,0,8.632,3.465a1.916,1.916,0,0,0-1.217.5,1.061,1.061,0,0,0-1-.5,1.387,1.387,0,0,0-1.06.455v-.06c0-.179-.1-.358-.441-.358H4.331V7.236H5.354ZM36.565,9.781V0H0V9.781H5.369V8.959H.822V.822H35.744V8.959H10.63l.018.822ZM3.539,3.905a.354.354,0,0,0-.4-.4H2.516V7.236H3.539ZM2.516,3.174H3.539V2.307H2.9c-.276,0-.388.142-.388.4Zm15.793.986c.538,0,.665.448.665,1.21s-.127,1.217-.665,1.217-.672-.455-.672-1.217.127-1.21.672-1.21m0-.694c-1.314,0-1.732.859-1.732,1.9s.418,1.9,1.732,1.9,1.725-.859,1.725-1.9-.411-1.9-1.725-1.9M21.042,5.01a.339.339,0,0,0-.388.381v.336h2.091V5.01Zm2.875-2.7c-.276,0-.388.142-.388.4V7.236h1.023V2.307Zm1.426.866h1.023V2.307h-.635c-.276,0-.388.142-.388.4Zm.627.329h-.627V7.236h1.023V3.905a.354.354,0,0,0-.4-.4m1.673,0h-.814a.6.6,0,0,0,.022.179l1.068,3.4c.052.172.149.172.336.172h.859c.187,0,.284,0,.336-.172l1.09-3.4a.58.58,0,0,0,.022-.179h-.821c-.187,0-.246,0-.291.164L28.7,6.19h-.03l-.739-2.524c-.045-.164-.1-.164-.291-.164M31.809,5c.03-.612.246-.844.687-.844s.567.306.567.612c0,.067,0,.164-.007.232Zm0,.657h2.233a5.3,5.3,0,0,0,.045-.732,1.4,1.4,0,0,0-1.6-1.463c-1.307,0-1.74.859-1.74,1.9s.426,1.9,1.725,1.9c1.038,0,1.441-.119,1.441-.478V6.526h-1.3c-.523,0-.806-.2-.806-.866"
          transform="translate(0 -1)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconImmoLive;
