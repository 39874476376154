function IconEye({ color, cssClasses }) {
  return (
    <svg className={cssClasses} id="Gruppe_15144" data-name="Gruppe 15144" xmlns="http://www.w3.org/2000/svg" width="25.921" height="18.523" viewBox="0 0 25.921 18.523">
      <g id="Gruppe_15140" data-name="Gruppe 15140">
        <path
          id="Pfad_8711"
          data-name="Pfad 8711"
          d="M25.219,7.236C22.951,4.536,18.415,0,12.961,0S2.97,4.536.7,7.29A3.173,3.173,0,0,0,0,9.234a3.116,3.116,0,0,0,.7,2c2.268,2.7,6.8,7.29,12.259,7.29s9.991-4.536,12.259-7.29a3.208,3.208,0,0,0,.7-2,3.454,3.454,0,0,0-.7-2m-1.458,2.808c-2.052,2.484-6.156,6.588-10.8,6.588-4.7,0-8.8-4.158-10.8-6.588a1.35,1.35,0,0,1,0-1.62c2.052-2.484,6.156-6.588,10.8-6.588s8.8,4.1,10.8,6.588a1.35,1.35,0,0,1,0,1.62"
          fill={color}
        />
        <path
          id="Pfad_8712"
          data-name="Pfad 8712"
          d="M20.044,8.5a4.644,4.644,0,1,0,4.644,4.644A4.644,4.644,0,0,0,20.044,8.5m0,7.4A2.754,2.754,0,1,1,22.8,13.144,2.766,2.766,0,0,1,20.044,15.9"
          transform="translate(-7.084 -3.91)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconEye;
