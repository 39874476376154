import React from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconArrowDown from '../Icons/IconArrowDown';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

import '../../styles/card.scss';

function Card({
  children,
  cardStyle,
  linkOnFullCard,
  linkTo,
  openLinkInNewTab,
  cssClasses,
  headline,
  cardMeta,
  text,
  btnText,
  linkOnImage,
  handleLink,
  icon,
  imagePath,
  title,
  dropdownHeadline,
  dropdownChildren,
  handleInfoModalDesktop,
}) {
  if (!cssClasses) cssClasses = '';

  switch (cardStyle) {
    case 'fullsizeImage':
      cssClasses += ' fullsizeImage';
      break;
    case 'topImage':
      cssClasses += ' topImage';
      break;
    case 'topImage2Cols':
    case 'topImage2ColsReversed':
      cssClasses += ' topImage topImage--2cols';
      break;
    case 'topImage2ColsRight':
    case 'topImage2ColsReversedRight':
      cssClasses += ' topImage topImage--2cols--right';
      break;
    case 'topImage2ColsLeft':
    case 'topImage2ColsReversedLeft':
      cssClasses += ' topImage topImage--2cols--left';
      break;
    case 'bottomImage':
      cssClasses += ' bottomImage';
      break;
    case 'teaserMini':
      cssClasses += ' teaserMini';
      break;
    case 'customContent':
      break;
    default:
      cssClasses += ' standard';
      break;
  }

  const { isMobile } = useHandleScreenResize();

  // only link without button
  if (!btnText && handleLink && !linkOnImage) cssClasses += ' cursor-pointer';
  if (linkOnFullCard) cssClasses += ' card--linkOnFullCard';

  let clonedIcon = '';
  if (icon) {
    clonedIcon = React.cloneElement(icon);
  }

  let dropdown;
  if (dropdownHeadline != null && dropdownChildren != null) {
    dropdown = (
      <div className={`card__dropdown ${!isMobile ? 'pl-150rem' : null}`}>
        {isMobile ? (
          <Accordion>
            <AccordionSummary expandIcon={<IconArrowDown color="#1E3163" />}>
              <h3>{dropdownHeadline}</h3>
            </AccordionSummary>
            <AccordionDetails className="card__dropdowntext">{dropdownChildren}</AccordionDetails>
          </Accordion>
        ) : (
          <div onClick={handleInfoModalDesktop}>
            <Accordion>
              <AccordionSummary expandIcon={<IconArrowDown color="#1E3163" />}>
                <h3>{dropdownHeadline}</h3>
              </AccordionSummary>
            </Accordion>
          </div>
        )}
      </div>
    );
  }

  let imageOrIcon;
  if (imagePath != null && imagePath !== false) {
    if (linkOnImage) {
      imageOrIcon = (
        <Link to={handleLink}>
          <img src={imagePath} alt={title} />
        </Link>
      );
    } else {
      imageOrIcon = <img src={imagePath} alt={title} />;
    }
  } else if (icon != null) {
    imageOrIcon = <div className="topImage_icon">{clonedIcon}</div>;
  }

  if (cardStyle === 'customContent') {
    return (
      <div className={'card ' + cssClasses}>
        {children}
        {dropdown}
      </div>
    );
  } else if (cardStyle === 'teaserMini') {
    return (
      <div className={'card ' + cssClasses}>
        <h3 className="teaserMini__headline">{headline}</h3>
        <span className="teaserMini__text">{text}</span>
        <div className="teaserMini__icon">{clonedIcon}</div>
        {children}
        <span className="textlink" onClick={handleLink}>
          {btnText}
        </span>
      </div>
    );
  } else if (cardStyle === 'topImage' || cardStyle === 'topImage2Cols' || cardStyle === 'topImage2ColsRight' || cardStyle === 'topImage2ColsLeft') {
    if (linkOnFullCard) {
      return (
        <a href={linkTo} className="card-link no--hover" target={openLinkInNewTab ? '_blank' : '_self'} rel="noreferrer">
          <div title={title} className={'card ' + cssClasses}>
            {imageOrIcon}
            <div className="card__content">
              {children}
              {dropdown}
            </div>
          </div>
        </a>
      );
    } else {
      return (
        <div title={title} className={'card ' + cssClasses}>
          {imageOrIcon}
          <div className="card__content">
            {children}
            {dropdown}
          </div>
        </div>
      );
    }
  } else if (cardStyle === 'topImage2ColsReversed' || cardStyle === 'topImage2ColsReversedRight' || cardStyle === 'topImage2ColsReversedLeft') {
    return (
      <div title={title} className={'card ' + cssClasses}>
        <div className="card__content">
          {children}
          {dropdown}
        </div>
        {imageOrIcon}
      </div>
    );
  } else {
    //fullsizeImage, bottomImage, standard

    return (
      <div title={title} style={{ backgroundImage: cssClasses.includes('fullsizeImage') && `url(${imagePath})` }} className={'card ' + cssClasses} onClick={!btnText && handleLink ? handleLink : null}>
        {!cssClasses.includes('fullsizeImage') && !!imagePath && <img src={imagePath} alt={title} />}

        <div className="card__content">
          {headline && <h2 className="font-150 lh-130">{headline}</h2>}
          {cardMeta && <p className="cardMeta">{cardMeta}</p>}
          {text && <p className="cardText">{text}</p>}

          {btnText && handleLink && (
            <button className="button button--orange" onClick={handleLink}>
              {btnText}
            </button>
          )}
        </div>
        {dropdown}
      </div>
    );
  }
}

export default Card;
