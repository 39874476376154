import { useEffect, useState } from 'react';
import { useHandleUserRealtyActive } from '../../hooks/user/useHandleUserRealtyActive';
import BesichtigungCard from '../Cards/BesichtigungCard';
import { useHandleVisibleElements } from '../../hooks/app/useHandleVisibleElements';
import { useSelector } from 'react-redux';
import { userReadActivities } from '../../api/User';

function BesichtigungenContent({ containerSelector }) {
  const user = useSelector((state) => state.user);

  const { besichtigungen, besichtigungenLoaded } = useHandleUserRealtyActive();
  const { getVisibleElements, visibleElements } = useHandleVisibleElements();

  const realtyActivitiesSeenArray = user.userObject.realtyActivitiesSeen ? user.userObject.realtyActivitiesSeen.split(';') : [];
  const [besichtigungenIdsRead, setBesichtigungenIdsRead] = useState(realtyActivitiesSeenArray);

  useEffect(() => {
    if (besichtigungenLoaded) {
      const elementSelector = '.besichtigung-card';
      getVisibleElements(containerSelector, elementSelector);
    }
  }, [besichtigungenLoaded]);

  useEffect(() => {
    // Convert the Set to an array
    const visibleElementsArray = Array.from(visibleElements);
    const ids = visibleElementsArray
      .map((el) => {
        let dataEl = el.querySelector('.js-dataid');
        if (!dataEl) return null;

        let dataId = 'b-' + dataEl.dataset.id;
        return dataId;
      })
      .filter((id) => id != 'b-undefined' && id !== null);

    const newIdsArray = [...besichtigungenIdsRead, ...ids];
    const uniqueIdsArray = [...new Set(newIdsArray)];

    setBesichtigungenIdsRead(uniqueIdsArray);

    // you need localStorage data for unmount function
    localStorage.setItem('activityIdsRead', uniqueIdsArray.join(';'));
  }, [visibleElements]);

  useEffect(() => {
    // This function will be called just before the component is unmounted
    return () => {
      // only call setReadActivities if there are more read activities in localStorage.
      const lsArray = localStorage.getItem('activityIdsRead') ? localStorage.getItem('activityIdsRead').split(';') : [];
      if (lsArray.length > realtyActivitiesSeenArray.length) {
        setReadActivities();
      }
    };
  }, []);

  const setReadActivities = async () => {
    try {
      await userReadActivities(localStorage.getItem('activityIdsRead'));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {besichtigungen.length > 0 ? (
        <div className="cardgrid" id="besichtigungen-cards">
          {besichtigungen.map((b) => {
            return (
              <BesichtigungCard
                dateFrom={b.dateFrom}
                dateTo={b.dateTo}
                comment={b.value.Comment}
                key={b.id + '-' + b.value.Created_at}
                immo={b.immo}
                id={b.key}
                unread={!besichtigungenIdsRead.find((itemId) => itemId.includes(String(b.key)))}
              />
            );
          })}
        </div>
      ) : (
        <p className="page__emptymessage">Es wurden noch keine Besichtigungen eingetragen.</p>
      )}
    </>
  );
}

export default BesichtigungenContent;
