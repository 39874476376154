function IconVerKaufen({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 13726" xmlns="http://www.w3.org/2000/svg" width="44.395" height="44.395" viewBox="0 0 44.395 44.395">
      <path data-name="Pfad 8575" d="M31.443,0A14.243,14.243,0,1,0,45.686,14.243,14.249,14.249,0,0,0,31.443,0m0,25.342a11.1,11.1,0,1,1,11.1-11.1,11.131,11.131,0,0,1-11.1,11.1" transform="translate(-1.292)" fill={color} />
      <path data-name="Pfad 8576" d="M30.151,10.3a1.621,1.621,0,0,0-1.572,1.572v6.382a1.572,1.572,0,0,0,3.145,0V11.872A1.621,1.621,0,0,0,30.151,10.3m1.017,23.77H20.532A4.32,4.32,0,0,0,20.81,32.5a5.054,5.054,0,0,0-1.48-3.515l-3.7-3.7a7.977,7.977,0,0,0-5.642-2.312H1.572a1.572,1.572,0,1,0,0,3.145h8.509a4.4,4.4,0,0,1,3.33,1.387l3.7,3.7a1.788,1.788,0,0,1,0,2.59,1.848,1.848,0,0,1-2.5,0l-2.4-2.4a1.57,1.57,0,1,0-2.22,2.22l2.4,2.4a5.054,5.054,0,0,0,3.515,1.48,6.245,6.245,0,0,0,1.295-.185H31.261a3.71,3.71,0,0,1,3.7,3.7,1.026,1.026,0,0,1-1.017,1.017H13.411a4.6,4.6,0,0,1-3.052-1.11L2.59,34.44a1.6,1.6,0,0,0-2.22.185,1.6,1.6,0,0,0,.185,2.22l7.769,6.474a7.824,7.824,0,0,0,5.087,1.85h20.44a4.3,4.3,0,0,0,4.254-4.254,6.968,6.968,0,0,0-6.937-6.844" transform="translate(0 -0.774)" fill="#fff"/>
    </svg>


  );
}

export default IconVerKaufen;
