function IconPhone({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="17.537" height="17.51" viewBox="0 0 17.537 17.51">
      <path
        data-name="Pfad 10341"
        d="M16.962,12.841,14.555,10.47a1.983,1.983,0,0,0-2.736,0,.777.777,0,0,1-1.021,0L7.04,6.712A.872.872,0,0,1,6.858,6.2a.72.72,0,0,1,.219-.511,2.029,2.029,0,0,0,0-2.772L4.705.547a2.029,2.029,0,0,0-2.772,0l-.584.584a4.686,4.686,0,0,0-.62,5.8,36.8,36.8,0,0,0,9.886,9.849,4.459,4.459,0,0,0,2.481.73,4.669,4.669,0,0,0,3.283-1.35l.584-.547a1.96,1.96,0,0,0,0-2.772m-.875,1.86-.584.547a3.415,3.415,0,0,1-4.232.474A35.682,35.682,0,0,1,1.75,6.238a3.384,3.384,0,0,1,.511-4.232l.511-.547a.777.777,0,0,1,1.021,0L6.164,3.83a.777.777,0,0,1,0,1.021,1.958,1.958,0,0,0-.547,1.386A1.993,1.993,0,0,0,6.2,7.624l3.757,3.757a2.029,2.029,0,0,0,2.772,0,.729.729,0,0,1,.985,0l2.371,2.371a.683.683,0,0,1,0,.948"
        transform="translate(0)"
        fill={color}
      />
    </svg>
  );
}

export default IconPhone;
