function IconHouseSearch({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="30.003" height="30.003" viewBox="0 0 30.003 30.003">
      <g id="Gruppe_16162" data-name="Gruppe 16162" transform="translate(1 1)">
        <g id="magnifying-glass--glass-search-magnifying">
          <path
            id="Vector"
            d="M12.172,23.844A11.672,11.672,0,1,0,.5,12.172,11.672,11.672,0,0,0,12.172,23.844Z"
            transform="translate(-0.5 -0.5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path id="Vector_2" d="M17.428,17.428,10,10" transform="translate(10.161 10.161)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
        <path
          id="Pfad_10427"
          data-name="Pfad 10427"
          d="M14.43,6.93l-2.1-2.1L7.659.159a.5.5,0,0,0-.729,0L2.254,4.866l-2.1,2.1a.5.5,0,0,0,0,.729.56.56,0,0,0,.364.152.56.56,0,0,0,.364-.152L2.072,6.505V12a.532.532,0,0,0,.516.516h9.382A.532.532,0,0,0,12.487,12V6.475l1.184,1.184a.5.5,0,0,0,.729,0,.474.474,0,0,0,.03-.729m-2.976,4.524H7.811V8.843a.516.516,0,1,0-1.032,0v2.611H3.135V5.443l4.16-4.16,4.16,4.16Z"
          transform="translate(4.596 4.666)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
}

export default IconHouseSearch;
