function IconSend({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="48.084" height="48.175" viewBox="0 0 48.084 48.175">
      <g data-name="Gruppe 4" transform="translate(0)">
        <g data-name="Gruppe 5">
          <path
            data-name="Pfad 12"
            d="M44.422.375a5,5,0,0,0-3.8,0l-36.8,12.3a5.8,5.8,0,0,0-3.7,4.2,5.458,5.458,0,0,0,1.5,5l8.9,8.9-.3,11.2a1.763,1.763,0,0,0,.8,1.5,2.03,2.03,0,0,0,1.7.1l6.9-3.6,6.6,6.5a5.383,5.383,0,0,0,3.9,1.7,5.853,5.853,0,0,0,1.3-.2,5.36,5.36,0,0,0,4-3.7l12.3-36.7a5.59,5.59,0,0,0-3.3-7.2m-40.3,19.1a2.214,2.214,0,0,1-.6-1.9,1.937,1.937,0,0,1,1.4-1.6l36.1-12.1-28.7,23.8Zm40.4-13.1-12.3,36.8a2.1,2.1,0,0,1-1.6,1.4,1.918,1.918,0,0,1-2-.6l-7.5-7.4a1.606,1.606,0,0,0-1.1-.5,2.353,2.353,0,0,0-.8.2l-5.5,2.8.2-8.2,30.7-25.5a3.4,3.4,0,0,1-.1,1"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconSend;
