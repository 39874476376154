import { useEffect, useState } from 'react';
import ProgressBarSingle from '../Charts/ProgressBarSingle';
import { parseDate } from '../../util/datetimeParser';
import '../../styles/kennzahlen.scss';
import { useSelector } from 'react-redux';

const labelText = (label) => {
  switch (label) {
    case '1_cooperative_flat':
      return 'Genossenschaftswohnung';
    case '2_non_profit_h_d_c':
      return 'Gemeinnützige Wohnbaugesellschaft';
    case '3_council_flat':
      return 'Gemeindewohnung';
    case '4_rented_flat':
    case '4_rented':
      return 'Sonstige Mietwohnung';
    case '9_flat':
      return 'Eigentumswohnung';
    case '7_detached_house':
      return 'Einfamilienhaus';
    case '8_two_family_house':
      return 'Zweifamilienhaus';
    default:
      break;
  }
};

function KennzahlBars({ kennzahl, category, hasButton, handleButtonClick }) {
  return (
    <>
      <h1 className="kennzahl__headline mobile-hidden">{category}</h1>
      <div className="kennzahl__bars">
        {kennzahl && kennzahl.length > 0 && (
          <>
            {kennzahl.map((k, index) => {
              let percent = (k.relativeValue * 100).toFixed(2);

              if (percent == 0.0) {
                return <></>;
              } else {
                return (
                  <div className="kennzahl__bars__item" key={k.subtype}>
                    <div className="d-flex flex-row justify-content-between">
                      <span>{labelText(k.subtype)}</span>
                      <span>{percent} %</span>
                    </div>

                    <ProgressBarSingle percent={percent} />
                  </div>
                );
              }
            })}
          </>
        )}
      </div>
      {hasButton && handleButtonClick && (
        <button className="button button--white-outline" onClick={handleButtonClick}>
          Details ansehen
        </button>
      )}
    </>
  );
}

export default KennzahlBars;
