import moment from 'moment';
import httpClient from './index';
import { CONDITION_RENOVATION_NEEDED, CONDITION_WELL_MAINTAINED, CONDITION_NEW_OR_RECENTLY_RENOVATED } from '../constants';

const END_POINT = '/Price';

const getValuationResult = async ({ city, postCode, street, houseNumber, propertyType, livingArea, condition, key }) => {
  let conditionVal = '';
  switch (condition) {
    case 0:
      conditionVal = CONDITION_RENOVATION_NEEDED;
      break;
    case 1:
      conditionVal = CONDITION_WELL_MAINTAINED;
      break;
    case 2:
    case 3:
      conditionVal = CONDITION_NEW_OR_RECENTLY_RENOVATED;
      break;
    default:
      conditionVal = CONDITION_NEW_OR_RECENTLY_RENOVATED;
  }

  if (propertyType === 0 || propertyType === '0') {
    propertyType = 'house';
  } else if (propertyType === 1 || propertyType === '1') {
    propertyType = 'apartment';
  } else {
    propertyType = 'apartment';
  }

  try {
    const resp = await httpClient.get(END_POINT + '/Valuation', {
      params:
        propertyType === 'house'
          ? {
              city: city,
              postCode: postCode,
              street: street,
              houseNumber: houseNumber,
              propertyType: 'house',
              livingArea: livingArea,
              landArea: 100,
              buildingYear: 2000,
              condition: conditionVal,
              countryCode: 'AT',
              dealType: 'sale',
              key: key,
            }
          : {
              city: city,
              postCode: postCode,
              street: street,
              houseNumber: houseNumber,
              propertyType: 'apartment',
              livingArea: livingArea,
              buildingYear: 2000,
              condition: conditionVal,
              countryCode: 'AT',
              dealType: 'sale',
              key: key,
            },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getValuationLight = async (divisionLevel4, postCode, propertyType, livingArea, condition) => {
  let conditionVal = '';
  switch (condition) {
    case 0:
      conditionVal = CONDITION_RENOVATION_NEEDED;
      break;
    case 1:
      conditionVal = CONDITION_WELL_MAINTAINED;
      break;
    case 2:
    case 3:
      conditionVal = CONDITION_NEW_OR_RECENTLY_RENOVATED;
      break;
    default:
      conditionVal = CONDITION_NEW_OR_RECENTLY_RENOVATED;
  }

  propertyType === 'Haus' ? (propertyType = 'house') : (propertyType = 'apartment');

  try {
    const resp = await httpClient.get(END_POINT + '/ValuationLight', {
      params:
        propertyType === 'house'
          ? {
              divisionLevel4: divisionLevel4,
              postCode: postCode,
              propertyType: 'house',
              livingArea: livingArea,
              landArea: livingArea,
              buildingYear: 2000,
              condition: conditionVal,
              countryCode: 'AT',
              dealType: 'sale',
            }
          : {
              divisionLevel4: divisionLevel4,
              postCode: postCode,
              propertyType: 'apartment',
              livingArea: livingArea,
              buildingYear: 2000,
              condition: conditionVal,
              countryCode: 'AT',
              dealType: 'sale',
            },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getLocationScores = async ({ city, postCode, street, houseNumber, key }) => {
  try {
    const resp = await httpClient.get(END_POINT + '/LocationScores', {
      params: {
        city: city,
        postCode: postCode,
        street: street,
        houseNumber: houseNumber,
        countryCode: 'AT',
        key: key,
      },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getPointsOfInterest = async ({ city, postCode, street, houseNumber, key }) => {
  try {
    const resp = await httpClient.get(END_POINT + '/PointsOfInterest', {
      params: {
        city: city,
        postCode: postCode,
        street: street,
        houseNumber: houseNumber,
        countryCode: 'AT',
        key: key,
      },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getSocioEconomics = async ({ postCode }) => {
  const key = moment(new Date()).format();

  try {
    const resp = await httpClient.get(END_POINT + '/SocioEconomics', {
      params: {
        postCode: postCode,
        countryCode: 'AT',
        key: key,
      },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getOfferStatistics = async ({ postCode, livingArea, propertyType, key }) => {
  if (propertyType === 0 || propertyType === '0') {
    propertyType = 'house';
  } else if (propertyType === 1 || propertyType === '1') {
    propertyType = 'apartment';
  } else {
    propertyType = 'apartment';
  }

  try {
    const resp = await httpClient.get(END_POINT + '/offerStatistics', {
      params: {
        divisionLevel100: postCode,
        livingArea: livingArea,
        propertyType: propertyType,
        dealType: 'rent',
        countryCode: 'AT',
        key: key,
      },
    });

    // TODO filter ergebnisse nicht nach dem ersten sondern nach dem, wo der living area wert innerhalb der boundaries liegt
    //? Wird erst in phase 2 benötigt, da die results noch nicht angezeigt werden
    /*console.log(resp.data);
    const t = resp.data.items.filter((item) => {
      return (
        livingArea >= item.groupLowerBound && livingArea <= item.groupUpperBound
      );
    });
    console.log(t);*/

    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getMarktinfoLight = async ({ city, streetAndHouseNumber, postCode }) => {
  const { street, houseNumber } = getStreetAndHouseNumber(streetAndHouseNumber);
  const key = moment(new Date()).format();

  try {
    const [pointsOfInterest, locationScores] = await Promise.all([
      getPointsOfInterest({
        city: city,
        postCode: postCode,
        street: street,
        houseNumber: houseNumber,
        key: key,
      }),
      getLocationScores({
        city: city,
        postCode: postCode,
        street: street,
        houseNumber: houseNumber,
        key: key,
      }),
    ]);

    let returnObject = {
      LocationScores: locationScores,
      PointsOfInterest: pointsOfInterest,
    };

    return returnObject;
  } catch (e) {
    console.log(e);
  }
};

// OBSOLETE
const getMarktinfoLightSeller = async ({ city, postCode, streetAndHouseNumber, propertyType, livingArea, condition }) => {
  const { street, houseNumber } = getStreetAndHouseNumber(streetAndHouseNumber);
  const key = moment(new Date()).format();

  try {
    const [valuation, pointsOfInterest, locationScores, offerStatistics] = await Promise.all([
      getValuationResult({
        city: city,
        postCode: postCode,
        street: street,
        houseNumber: houseNumber,
        propertyType: propertyType,
        livingArea: livingArea,
        condition: condition,
        key: key,
      }),
      getPointsOfInterest({
        city: city,
        postCode: postCode,
        street: street,
        houseNumber: houseNumber,
        key: key,
      }),
      getLocationScores({
        city: city,
        postCode: postCode,
        street: street,
        houseNumber: houseNumber,
        key: key,
      }),
      getOfferStatistics({
        postCode: postCode,
        livingArea: livingArea,
        propertyType: propertyType,
        key: key,
      }),
    ]);

    // TODO Wieder rückgängig machen und immer offer statistics erwarten
    let returnObject = {
      LocationScores: locationScores,
      PointsOfInterest: pointsOfInterest,
      Valuation: valuation,
      OfferStatistics: offerStatistics,
    };

    if (offerStatistics.length > 0) {
      returnObject = {
        ...returnObject,
        OfferStatistics: offerStatistics,
      };
    }
    return returnObject;
  } catch (e) {
    throw e;
  }
};

// OBSOLETE
const getMarktinfoLightBuyer = async (postCode) => {
  try {
    const socioEconomics = await getSocioEconomics({
      postCode: postCode,
    });

    if (socioEconomics && Object.keys(socioEconomics).length > 0) {
      return [
        {
          SocioEconomics: socioEconomics,
        },
      ];
    } else {
      return false;
    }
  } catch (e) {
    throw e;
  }
};

const getPostCodesFromDivisionLevel4 = async (divisionLevel4) => {
  try {
    const resp = await httpClient.get(END_POINT + '/postCodes', {
      params: { divisionLevel4: divisionLevel4 },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getCoordinates = async (city, postCode, street, houseNumber) => {
  try {
    const resp = await httpClient.get(END_POINT + '/Coordinates', {
      params: {
        street: street,
        houseNumber: houseNumber,
        postCode: postCode,
        city: city,
        countryCode: 'AT',
      },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const calculateDarlehen = async (finanzierungsBetrag, laufzeit) => {
  try {
    const resp = await httpClient.post(END_POINT + '/calcDarlehen', {
      amount: finanzierungsBetrag,
      duration: laufzeit * 12, // wert wird in monaten benötigt also input *  12
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

// Util method
const getStreetAndHouseNumber = (address) => {
  const regex = /([A-Za-zäöüßÄÖÜ.-\s]+)\s(\d+)/g;
  const match = regex.exec(address);

  if (!match) {
    return false;
  } else {
    return {
      street: match[1],
      houseNumber: match[2],
    };
  }
};

export {
  getValuationResult,
  getPostCodesFromDivisionLevel4,
  getValuationLight,
  getLocationScores,
  getPointsOfInterest,
  getMarktinfoLightSeller,
  getMarktinfoLightBuyer,
  getSocioEconomics,
  getCoordinates,
  calculateDarlehen,
  getMarktinfoLight,
};
