import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNews, getNewsDetail, getNewsNew } from '../../api/News';
import { setCachedNews } from '../../reducers/cachedNews';
import * as moment from 'moment';
import { useFetchNewsDetails } from './useFetchNewsDetails';

export const useFetchNews = () => {
  const dispatch = useDispatch();
  const cachedNews = useSelector((state) => state.cachedNews.cachedNews);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [news, setNews] = useState([]);
  const [newsError, setNewsError] = useState(null);
  const [shownEntries, setShownEntries] = useState(12);
  const [didNewsLoad, setDidNewsLoad] = useState(false);
	const [maxPages, setMaxPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    getAndSetNews();
  }, []);

  const getAndSetNews = async (page = 1) => {
    try {
      const res = await getNewsNew(page);
			let newsitems = [];
			if(res.items && res.items.length > 0) {
				if(page === 1) {
					newsitems = res.items;
				}
				else {
					// add to existing
					newsitems = [...news,...res.items];
				}

				res.items.forEach((newsElement) => {
					if (newsElement.tags) {
						const heroTag = newsElement.tags.find((tag) => tag.name.includes('hero') || tag.name.includes('Hero'));
						if (heroTag) {
							// bsp code: hero_1
							const rank = heroTag.code.split('_')[1];
							if (!isNaN(Number(rank))) {
								newsElement.hero_rank = Number(rank);
							}
						}
					}
				});

				setMaxPages(res.maxPages);
			}

			newsitems = sortbyPublishedOn(newsitems);
      setNews(newsitems);

      // schaut welche news schon im cache sind und überschreibt diese
      // Blocks (News Details) bleiben erhalten falls schon gecached und in news detail updated
      const updatedCache = news?.map((newsElement) => {
        const cachedNewsElement = cachedNews?.find((cachedNews) => cachedNews.slug === newsElement.slug);
        if (cachedNewsElement) {
          return { ...cachedNewsElement, ...newsElement };
        }
        return newsElement;
      });

      dispatch(setCachedNews(updatedCache));
      setNewsError(null);
    } catch (error) {
      setNewsError(error);
    } finally {
      setDidNewsLoad(true);
    }
  };

	const sortbyPublishedOn = (news) => {
		return news.sort((a, b) => {
			const dateA = new Date(a.published_on);
			const dateB = new Date(b.published_on);

			// Handle cases where date is missing or invalid
			if (!dateA || isNaN(dateA)) return 1;
			if (!dateB || isNaN(dateB)) return -1;
			
			return dateB - dateA;
		});
	}

  const showMoreEntries = (increasePage = false) => {
		if(increasePage) {
			setCurrentPage(currentPage + 1);
      getAndSetNews(currentPage + 1);
		}

    setShownEntries((shownEntries) => shownEntries + 12);
  };

  const handleSetActiveFilterIndex = (event) => {
    let index = event.target.dataset.index;
    setShownEntries(12);
    setSelectedFilter(parseInt(index));
  };

  return {
    selectedFilter,
    news,
    newsError,
    shownEntries,
    setShownEntries,
    didNewsLoad,
    handleSetActiveFilterIndex,
    showMoreEntries,
    getAndSetNews,
		currentPage,
		maxPages,
		sortbyPublishedOn
  };
};
