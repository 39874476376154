import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
import { setUnreadMessages } from '../../reducers/notifications';
import DotUnread from '../DotUnread';
import { PHASE1_VERKAUF } from '../../constants';

function MenuCount({ li }) {
  const notifications = useSelector((state) => state.notifications);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const { getLinkName, getImmoDriveCount, getNachrichtenCount } = useHandleMenu(true);

  //TODO Wenn das wieder eingebaut wird, wie bei nachrichtenCount nachmachen
  const [immodriveCount, setImmodriveCount] = useState(notifications.unseenImmodriveCount || 0);
  

  let linkname = getLinkName(li);
  useEffect(() => {
	if(app.menuId !== PHASE1_VERKAUF) {
    	// setImmodriveCount(getImmoDriveCount(li));
    	getAndSetMessageStats();
	}
  }, []);

  const getAndSetMessageStats = async () => {
    let msgCount = await getNachrichtenCount(li);
    if (msgCount) {
      dispatch(setUnreadMessages(msgCount));
    }
  };

	if(app.menuId === PHASE1_VERKAUF) {
		return null;
	}
	else {
		return (
			<>
				{linkname === 'immo-drive' && immodriveCount > 0 && (
					<DotUnread unreadAmount={immodriveCount} />
				)}

				{linkname === 'nachrichten' && notifications?.unreadMessages > 0 && (
					<DotUnread unreadAmount={notifications.unreadMessages} />
				)}

				{linkname === 'besichtigungen' && notifications?.unreadBesichtigungen > 0 && (
					<DotUnread unreadAmount={notifications.unreadBesichtigungen} />
				)}

				{linkname === 'aktivitaeten' && notifications?.unreadWeitere > 0 && (
					<DotUnread unreadAmount={notifications.unreadWeitere} />
				)}
			</>
		)
	}
}

export default MenuCount;
