import Card from './Card';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

function EnergieCard({ energy, category }) {
  const { isMobile } = useHandleScreenResize();

  if (!energy || !category) return null;

  let value = null;
  const hasVal = energy.find((item) => item.label === category);
  if (hasVal?.value) {
    value = hasVal.value;
  }

  let label = null;
  let headline = null;
  switch (category) {
    case 'fGEE Klasse':
      headline = 'fGEE';
      const fGEELabel = energy.find((item) => item.label === 'fGEE');
      if (fGEELabel?.value) {
        label = fGEELabel.value;
      }
      break;
    case 'Heizwärmeklasse':
      headline = 'Heizwärmeklasse';

      const heizwaermebedarfLabel = energy.find((item) => item.label === 'Heizwärmebedarf');
      if (heizwaermebedarfLabel?.value) {
        label = heizwaermebedarfLabel.value;
      }
      break;
    default:
      break;
  }

  if (!value || !label || !headline) return null;

  let backgroundColor = '';
  if (value.includes('A')) {
    backgroundColor = 'skala__a';
  } else if (value.includes('B')) {
    backgroundColor = 'skala__b';
  } else if (value.includes('C')) {
    backgroundColor = 'skala__c';
  } else if (value.includes('D')) {
    backgroundColor = 'skala__d';
  } else if (value.includes('E')) {
    backgroundColor = 'skala__e';
  } else if (value.includes('F')) {
    backgroundColor = 'skala__f';
  } else if (value.includes('G')) {
    backgroundColor = 'skala__g';
  }

  return (
    <Card cardStyle="customContent" cssClasses={'card--energy card--energy-desktop skala'}>
      <h4 className="text-center mobile-hidden">{headline}</h4>
      <div className={'immodetail__features-wrapper immodetail__features-wrapper--big text-white d-flex flex-col text-center ' + backgroundColor}>
        {isMobile ? <span className="immodetail__features--val">{headline}</span> : <span className="immodetail__features--val">{value}</span>}
        <span className="immodetail__features--label">{label}</span>
      </div>
    </Card>
  );
}

export default EnergieCard;
