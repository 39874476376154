import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import SimpleSlider from './SimpleSlider';
import FsLightbox from 'fslightbox-react';

import '../../styles/mediaslider.scss';

// STORE
import { toggleDragging } from '../../reducers/app';

function MediaSlider({ settings, cssClasses = '', media, label = '', labelIcon = '' }) {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const [lightboxSource, setLightboxSource] = useState([]);

  useEffect(() => {
    if (media.length > 0) {
      let mediaArray = [];
      media.map((m) => {
        mediaArray.push(m.url);
      });

      setLightboxSource(mediaArray);
    }
  }, [media]);

  let sliderSettings = {
    dots: false,
    arrows: true,
    swipe: true,
    infinite: false,
    swipeToSlide: true,
    className: 'carousel-slider',
    beforeChange: () => dispatch(toggleDragging()),
    afterChange: () => dispatch(toggleDragging()),
    variableWidth: true,
  };

  if (settings && settings.length > 0) {
    sliderSettings = settings;
  }

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const handleLightboxImg = (index) => {
    if (!app.dragging) {
      setLightboxController({
        toggler: !lightboxController.toggler,
        slide: index + 1,
      });
    }
  };

	let clonedIcon = '';
  if (labelIcon) {
    clonedIcon = React.cloneElement(labelIcon, { color: '#3679ec' });
  }

  return (
    <>
      <div className={`carousel ${cssClasses} media-slider`}>
				{ label && (
					<div className="media-slider__label">
					{clonedIcon && clonedIcon}
					{label}
				</div>
				)}

        {media.length > 1 ? (
          <SimpleSlider settings={sliderSettings}>
            {media.map((submedia, index) => {
              let url = submedia.url;

              if (submedia.thumbnails) {
                let th = submedia.thumbnails.find((th) => th.name === 'fullhd');
                if (th && th.url && th.url !== '') {
                  url = th.url;
                }
              }

              return <img src={url} alt={submedia.headline} key={submedia.url} onClick={() => handleLightboxImg(index)} />;
            })}
          </SimpleSlider>
        ) : (
          <div className="oneImage-container">
            <img src={media[0]?.url} alt={media[0]?.headline} key={media[0]?.url} onClick={() => handleLightboxImg(0)} />
          </div>
        )}
      </div>

      <FsLightbox toggler={lightboxController.toggler} sources={lightboxSource} slide={lightboxController.slide} />
    </>
  );
}

export default MediaSlider;
