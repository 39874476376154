import InfoDialog from './InfoDialog';

// ICONS
import IconSmiley from '../Icons/IconSmiley';

function InfoDialogZustandImmo({ handleClose, open }) {
  return (
    <InfoDialog handleClose={handleClose} headline="Zustand der Immobilie" open={open}>
      <div className="d-flex flex-col">
        <div className="d-flex flex-row">
          <div style={{ width: 45 }}>
            <IconSmiley color={'#3679ec'} level={3} />
          </div>
          <p className="ml-100rem mb-200rem">Neubau, Erstbezug</p>
        </div>
        <div className="d-flex flex-row">
          <div style={{ width: 45 }}>
            <IconSmiley color={'#3679ec'} level={2} />
          </div>
          <p className="ml-100rem mt-0 mb-200rem">Sehr gute Ausstattung, sehr gute Wohnlage, moderne Sanitäranlagen, keine Modernisierung-, oder Verbesserungstätigkeiten notwendig, ausgezeichnete Infrastruktur</p>
        </div>
        <div className="d-flex flex-row">
          <div style={{ width: 45 }}>
            <IconSmiley color={'#3679ec'} level={1} />
          </div>
          <p className="ml-100rem mt-0 mb-200rem">Gute Bausubstanz, mittlere Wohnlage, normale Anbindung an Verkehr und Infrastruktur, einfaches Bad und WC.</p>
        </div>
        <div className="d-flex flex-row">
          <div style={{ width: 45 }}>
            <IconSmiley color={'#3679ec'} level={0} />
          </div>
          <p className="ml-100rem mt-0 mb-200rem">Einfache Ausstattung, weniger beliebte Wohnlage, Verbesserungen notwendig</p>
        </div>
      </div>
    </InfoDialog>
  );
}

export default InfoDialogZustandImmo;
