function IconPrint({ color, cssClasses, handleClick }) {
  return (
    <svg className={cssClasses} onClick={handleClick} data-name="Gruppe 15098" xmlns="http://www.w3.org/2000/svg" width="23.972" height="24" viewBox="0 0 23.972 24">
      <path data-name="Pfad 8765" d="M23.3,7.616a2.543,2.543,0,0,0-1.8-.752h-1.7V.852A.878.878,0,0,0,18.939,0H5.161a.844.844,0,0,0-.852.852V6.864H2.555a2.382,2.382,0,0,0-1.8.752A2.543,2.543,0,0,0,0,9.42V14.58a2.382,2.382,0,0,0,.752,1.8,2.543,2.543,0,0,0,1.8.752h1.7v6.013A.878.878,0,0,0,5.111,24H18.839a.878.878,0,0,0,.852-.852V17.136h1.7a2.382,2.382,0,0,0,1.8-.752,2.543,2.543,0,0,0,.752-1.8V9.47A2.15,2.15,0,0,0,23.3,7.616M6.013,1.7H18.038V6.864H6.013ZM18.038,22.347H6.013V16.334a1.253,1.253,0,0,1,.2-.651.507.507,0,0,1,.351-.2H17.486a.434.434,0,0,1,.351.2.924.924,0,0,1,.2.651Zm4.309-7.766a.83.83,0,0,1-.251.6.924.924,0,0,1-.6.251H19.641a2.543,2.543,0,0,0-.451-.9,2.191,2.191,0,0,0-1.653-.8H6.564a2.114,2.114,0,0,0-1.653.8,2.543,2.543,0,0,0-.451.9h-1.9a.83.83,0,0,1-.6-.251.924.924,0,0,1-.251-.6V9.47a.83.83,0,0,1,.251-.6.924.924,0,0,1,.6-.251H21.395a.83.83,0,0,1,.6.251.924.924,0,0,1,.251.6V14.58Z" fill={color}/>
      <path data-name="Pfad 8766" d="M26.4,36h-9.8a1.207,1.207,0,0,0,0,2.413h9.724a1.244,1.244,0,0,0,1.207-1.207A1.183,1.183,0,0,0,26.4,36" transform="translate(-9.472 -18.537)" fill={color}/>
    </svg>
  );
}

export default IconPrint;
