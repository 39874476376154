import axios from 'axios';

const version = 1;
let baseURL = 'https://sreal.k25-dev.at/sreal-backend/api/v' + version;

if (window.location.host.indexOf('frontend.stable.srealportal.microf.cs.eb.lan.at') != -1) {
  baseURL = 'https://backend.stable.srealportal.microf.cs.eb.lan.at/api/v' + version;
} else if (window.location.host.indexOf('my-sreal.at') != -1) {
  baseURL = 'https://my-sreal.at/api/v' + version;
} else if (window.location.host.indexOf('my-srealtest.at') != -1) {
	baseURL = 'https://my-srealtest.at/api/v' + version;
}

// https://sreal.k25-dev.at/sreal-backend/swagger/index.html

const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true
  // timeout: 1000,
});

// Request interceptor
// Macht etwas für jede Request
httpClient.interceptors.request.use(
  async (config) => {
    // ? Hier können jegliche configs definiert werden, die bei jedem Request gesetzt werden sollen
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
// Macht etwas für jede Response, falls nötig (z.B. Fehlerhandling)
/*httpClient.interceptors.response.use(null, async (error) => {
  if (error.response && error.response.status === 401) {
    // z.B. wenn 401 error, dann mach was
  } else {
    // Handle every error other than 401
    return Promise.reject(error);
  }
});*/

export default httpClient;
