import axios from 'axios';
const baseURLSreal = 'https://www.sreal.at';

const sRealClient = axios.create({
  baseURL: 'https://www.sreal.at',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // withCredentials: true,
});

const getRealtySearch = async (searchData, page) => {
  let query = 'f[buyingType]=buy';
  Object.entries(searchData).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case 'addressObject':
          value?.map((v) => {
            query += `&f[location_or_id][]=${v.value.replace(' ', '+')}`;
          });
          break;
        case 'areaFrom':
          query += `&f[area][min]=${value}`;
          break;
        case 'areaTo':
          query += `&f[area][max]=${value}`;
          break;
        case 'priceFrom':
          query += `&f[price][min]=${value}`;
          break;
        case 'priceTo':
          query += `&f[price][max]=${value}`;
          break;
        case 'roomsFrom':
          query += `&f[rooms][min]=${value}`;
          break;
        case 'roomsTo':
          query += `&f[rooms][max]=${value}`;
          break;
        case 'surfaceAreaFrom':
          query += `&f[surface_area][min]=${value}`;
          break;
        case 'surfaceAreaTo':
          query += `&f[surface_area][max]=${value}`;
          break;
        case 'estateType':
          value?.map((v) => {
            query += `&f[objectType][]=${v.id}`;
          });
          break;
        case 'subEstateType':
          value?.map((v) => {
            query += `&f[objectSubType][]=${v.id}`;
          });
          break;
        case 'features':
          value?.map((v) => {
            query += `&f[features][]=${v.id}`;
          });
          break;
        case 'fullText':
          query += `&f[fulltext]=${value}`;
          break;
        case 'sorting':
          query += `&f[sorting]=${value.id}`;
          break;
				case 'projects_only':
					query += `&f[projects_only]=1`;
					break;
        default:
          break;
      }
    }
  });

  try {
    const resp = await sRealClient.get(`/api/realties?${query}&p=${page}`);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getRealtySubTypes = async (realtyTypes) => {
  if (!realtyTypes) return [];

  const realtyTypeData = realtyTypes
    .map((realtyType) => {
      return `realtyTypes%5B%5D=${realtyType}`;
    })
    .join('&');

  try {
    const resp = await axios.post(`${baseURLSreal}/search/sub-realty-types`, realtyTypeData, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    const subTypes = resp.data.map((row) => {
      return { id: row.value, text: row.label };
    });
    return subTypes;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getLocations = async (query) => {
  try {
    const resp = await sRealClient.get(`/search/locations?q=${query}`);
    return resp.data.slice(0, 20);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getRealtiesByObjectNumbers = async (objectNumbers) => {
  //TODO implement
};

export { getRealtySearch, getRealtySubTypes, getLocations, getRealtiesByObjectNumbers };
