import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import IconArrowDown from '../Icons/IconArrowDown';

import '../../styles/dialogs.scss';

/* DOCS
https://mui.com/material-ui/react-dialog/#transitions
*/

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

function InfoDialog({ handleClose, headline, children, open, disableESC = false }) {
  let fullScreen = true;
  if (window.innerWidth >= 949) {
    fullScreen = false;
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="infodialog"
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      scroll="paper"
      disableEscapeKeyDown={disableESC}
    >
      <DialogTitle>
        {headline}

        {!disableESC && (
          <IconArrowDown
            color="#1E3163"
            width={22}
            handleClick={handleClose}
            cssClasses="cursor-pointer"
          />
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default InfoDialog;
