function IconBarchart({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 16111" xmlns="http://www.w3.org/2000/svg" width="85.01" height="85.01" viewBox="0 0 85.01 85.01">
      <g data-name="Gruppe 16113">
        <path
          data-name="Pfad 10410"
          d="M82,78.989H78.989V21.253a3.1,3.1,0,0,0-3.011-3.011H51.537a3.1,3.1,0,0,0-3.011,3.011V36.484H30.285V3.011A3.1,3.1,0,0,0,27.274,0H3.011A3.1,3.1,0,0,0,0,3.011V82A3.1,3.1,0,0,0,3.011,85.01H82a3.011,3.011,0,0,0,0-6.022M54.725,24.263H72.967V78.989H54.725Zm-6.2,54.725H30.285V42.505H48.527Zm-24.263,0H6.022V6.022H24.263Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconBarchart;
