import Papa from 'papaparse';
import EBSPK_Institute from '../util/EBSPK_Institute_v1_0.csv';

const parseEBSPK_Institute = async (hasHeadline) => {
  return new Promise((resolve, reject) => {
    Papa.parse(EBSPK_Institute, {
      download: true,
      complete: (results) => {
        let result = {};
        const records = results.data;

        let startIndex = 0;
        if (hasHeadline === true) {
          startIndex = 1;
        }

        records.map((record, index) => {
          if (index > startIndex) {
            if (!result.hasOwnProperty(record[0])) {
              result[record[0]] = [];
            }
            Object.keys(result).forEach((key) => {
              if (record[0] === key) {
                result[key].push({
                  institute: record[1],
                  short: record[2],
                  url1: record[3],
                  url2: record[4],
                });
              }
            });
          }
        });
        Object.keys(result).forEach((key) => {
          if (key === '') {
            delete result[key];
          }
        });
        return resolve(result);
      },
      error: (error) => {
        return reject(error);
      },
    });
  });
};

export { parseEBSPK_Institute };
