import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import mySaga from './sagas';
import persistConfig from './storage';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

// import dummySlice from "./reducer/dummy";
import appSlice from './reducers/app';
import cachedNewsSlice from './reducers/cachedNews';
import contactInformationSlice from './reducers/contactInformation';
import finanzierungsRechnerSlice from './reducers/finanzierungRechner';
import formValidatorsSlice from './reducers/formValidators';
import kaufAnbotSlice from './reducers/kaufAnbote';
import loginFormSlice from './reducers/loginForm';
import marktinfoLightSlice from './reducers/marktinfoLight';
import objectRequestSlice from './reducers/objectRequest';
import registerFormSlice from './reducers/registerForm';
import satisfactionFormSlice from './reducers/satisfactionForm';
import searchFormSlice from './reducers/searchForm';
import searchProfileFormSlice from './reducers/searchProfileForm';
import userSlice from './reducers/user';
import wohnwertLightSlice from './reducers/wohnwertLight';
import notificationsSlice from './reducers/notifications';
import registerFormChangePhoneNumberSlice from './reducers/registerFormChangePhoneNumber';

const reducers = {
  app: appSlice,
  user: userSlice,
  loginForm: loginFormSlice,
  registerForm: registerFormSlice,
  registerFormChangePhoneNumber: registerFormChangePhoneNumberSlice,
  contactInformation: contactInformationSlice,
  formValidators: formValidatorsSlice,
  marktinfoLight: marktinfoLightSlice,
  finanzierungsRechner: finanzierungsRechnerSlice,
  wohnwertLight: wohnwertLightSlice,
  searchForm: searchFormSlice,
  searchProfileForm: searchProfileFormSlice,
  kaufAnbote: kaufAnbotSlice,
  objectRequest: objectRequestSlice,
  cachedNews: cachedNewsSlice,
  satisfactionForm: satisfactionFormSlice,
  notifications: notificationsSlice,
};

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

sagaMiddleware.run(mySaga);

root.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
);
