import { useDispatch, useSelector } from 'react-redux';
import { getMySearchProfiles, getSearchProfilesResult } from '../../api/JustImmo';
import { 
  SEARCHPROFILE_STATE_INACTIVE,
  JUSTIMMO_TYPE_POLITICAL_DISTRICT_LABEL,
  JUSTIMMO_TYPE_POLITICAL_DISTRICT_ID,
  JUSTIMMO_TYPE_CITY_LABEL,
  JUSTIMMO_TYPE_CITY_ID,
  JUSTIMMO_TYPE_MUNICIPALITY_LABEL,
  JUSTIMMO_TYPE_MUNICIPALITY_ID,
  JUSTIMMO_TYPE_ZIP_LABEL,
  JUSTIMMO_TYPE_ZIP_ID,
  JUSTIMMO_TYPE_CITYZIP_LABEL,
  JUSTIMMO_TYPE_CITYZIP_ID,
  JUSTIMMO_TYPE_FEDERAL_STATE_ID, 
  JUSTIMMO_TYPE_FEDERAL_STATE_LABEL } from '../../constants';
import { setUserSearchProfiles } from '../../reducers/user';
import { parseImmo } from '../../util/immoObjectParser';


export const useFetchSearchprofiles = () => {
  const user = useSelector((state) => state.user);
  const kaufanbote = useSelector((state) => state.kaufAnbote);
  const dispatch = useDispatch();

  const getAndSetSearchprofiles = async (loadWithHits = true) => {
    try {
      const sp = await getMySearchProfiles();
      if (sp) {
        let spWithName = sp.map((item, index) => {
          return {
            ...item,
            name: findNameToSearchprofile(item.id, index),
          };
        });

        if (loadWithHits) {
          const searchProfileResults = await getAllSearchProfileHits();
          spWithName = spWithName.map((sp, index) => {
            return {
              ...sp,
              immos: searchProfileResults
                .filter((res) => res.searchProfileId === sp.id)
                .map((res) => parseImmo(res.realty)),
            };
          });

          dispatch(setUserSearchProfiles(spWithName));
        } else {
          dispatch(setUserSearchProfiles(spWithName));
        }
      }
    } catch (e) {
      // TODO handle error
      console.log('e', e);
    }
  };

  /**
   *
   * @param {integer} id
   * @returns {String} name
   */
  const findNameToSearchprofile = (id, index) => {
    let name = 'Suchprofil ' + index;

    if (user.userObject?.results?.length > 0) {
      const userSearchprofiles = user.userObject.results.filter((res) => res.type === 'SearchProfile');
      if (userSearchprofiles) {
        const foundUserSearchprofile = userSearchprofiles.find((sp) => (sp && sp.metaJson && sp.metaJson?.id === id));
        if(foundUserSearchprofile) {
          name = foundUserSearchprofile.metaJson?.name;
        }
      }
    }

    return name;
  };

  /**
   * get searchprofiles by state active or inactive
   * @param {String} state
   * @returns
   */
  const getSearchprofilesByState = (state) => {
    return user.searchProfiles.filter((sp) => sp.state === state);
  };

  const getAllSearchProfileHits = async () => {
    try {
      return await getSearchProfilesResult();
    } catch (error) {
      console.log('error', error);
    }
  };

  /** 
   * sort an array of searchprofile hits 
   * sort by last expose date (= searchprfile hit was created)
   */
  const sortSearchprofileHits = (immoArray)  => {
    if(!immoArray || immoArray.length === 0) return [];
    let immos = [...immoArray];
    return immos.sort(function (a, b) {
      let exposeA = a.documentsPrivate?.find(doc => doc.isExpose);
      let exposeB = b.documentsPrivate?.find(doc => doc.isExpose);
      if(exposeA?.originalDate && exposeB?.originalDate) {    
        return new Date(exposeB.originalDate) - new Date(exposeA.originalDate);
      }
      else return 0;
    });
  }
  /**
   *
   * @returns build menu for searchprofiles
   */
  const getSearchprofilesMenu = () => {
    let menu = user.searchProfiles
      .filter((sp) => sp.state !== SEARCHPROFILE_STATE_INACTIVE)
      .map((sp, index) => {
        return {
          key: index + 1,
          label: sp.name,
          immos: sortSearchprofileHits(sp.immos)
        };
      });

    let spImmos = menu
      .filter((m) => m.immos && m.immos.length > 0)
      .map((m) => m.immos)
      .flat();

    let weitereImmos = [];
    if(kaufanbote.kaufanboteList?.length > 0) {
      // remove duplicates in weitereImmos that are already in searchProfiles
      let allSpImmosIds = spImmos.map((immo) => immo.id );
      weitereImmos = kaufanbote.kaufanboteList.filter((immo) => !allSpImmosIds.includes(immo.id));
      weitereImmos = sortSearchprofileHits(weitereImmos);
    }

    let alleImmos = [...spImmos, ...weitereImmos];
    alleImmos = sortSearchprofileHits(alleImmos);

    menu.unshift({
      key: 0,
      label: 'Alle',
      immos: alleImmos,
    });

    menu.push({
      key: menu.length,
      label: 'Weitere Immobilien',
      immos: weitereImmos
    });
    return menu;
  };

  /**
   * setup location_ids to send to Justimmo endpoints
   * @param {Array} location_ids 
   * @returns 
   */
  const getLocationIds = (location_ids) => {
    location_ids = location_ids.filter((location) => Object.keys(location).length !== 0).map((location, index) => {
      let type = location.type;
      if(!type && index === 0) {
        type = 0;
      }
      return {
        type: type,
        id: location.id,
        text: location.text
      };
    })
    
    // if districts are sent - remove federalstate form locations
    let locationDistricts = location_ids.filter((loc) => loc.type !== JUSTIMMO_TYPE_FEDERAL_STATE_ID);
    if(locationDistricts.length > 0) {
      location_ids = locationDistricts;
    }

    return location_ids;
  }

  const getCriteriaFromRequestbody = (requestBody) => {
    if(!requestBody) return {};

    
    let loc_ids = getLocationIds(requestBody.location.location_ids);
    let criteria = {
      ...requestBody,
      type_ids: requestBody.type_ids.map((type) => type.id),
      furnishing_ids: requestBody.furnishing_ids.map((f) => f.id),
      price: {
        from: requestBody.price.from || null,
        to: requestBody.price.to || null,
      },
      living_area: {
        from: requestBody.living_area.from || null,
        to: requestBody.living_area.to || null,
      },
      site_area: {
        from: requestBody.site_area.from || null,
        to: requestBody.site_area.to || null,
      },
      rooms: {
        from: requestBody.rooms.from || null,
        to: null,
      },
      location: {
        location_ids: loc_ids
      },
      balcony: {
        enabled: requestBody.balcony.enabled,
      },
      covered_balcony:{
        enabled: requestBody.covered_balcony.enabled,
      },
    };

    if(criteria.balcony.enabled){
      criteria.covered_balcony.enabled = true;
    }
    if(criteria.covered_balcony.enabled){
      criteria.balcony.enabled = true;
    }
    
    
    return criteria;
  }

  const getFederalStateFromRequestBody = (location_ids) => {
    if(!location_ids) return null;
    return location_ids.find((loc) => loc.type === JUSTIMMO_TYPE_FEDERAL_STATE_LABEL);
  }

  const getDistrictsFromRequestBody = (location_ids) => {
    if(!location_ids) return null;
    return location_ids.filter((loc) => loc.type !== JUSTIMMO_TYPE_FEDERAL_STATE_LABEL);
  }

  const getLocationTypeIdByLabel = (label) => {
    let type = null;
    switch (label) {
      case JUSTIMMO_TYPE_POLITICAL_DISTRICT_LABEL:
        type = JUSTIMMO_TYPE_POLITICAL_DISTRICT_ID;
        break;
      case JUSTIMMO_TYPE_CITY_LABEL:
        type = JUSTIMMO_TYPE_CITY_ID;
        break;
      case JUSTIMMO_TYPE_MUNICIPALITY_LABEL:
        type = JUSTIMMO_TYPE_MUNICIPALITY_ID;
        break;
      case JUSTIMMO_TYPE_ZIP_LABEL:
        type = JUSTIMMO_TYPE_ZIP_ID;
        break;
      case JUSTIMMO_TYPE_CITYZIP_LABEL:
        type = JUSTIMMO_TYPE_CITYZIP_ID;
        break;
      default:
        break;
    }

    return type;
  }

  return {
    getAndSetSearchprofiles,
    getSearchprofilesByState,
    getSearchprofilesMenu,
    getCriteriaFromRequestbody,
    getFederalStateFromRequestBody,
    getDistrictsFromRequestBody,
    getLocationTypeIdByLabel
  };
};
