import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import '../styles/impressum-datenschutz.scss';

// ICONS
import IconDocument from '../components/Icons/IconDocument';
import MetaTags from '../components/MetaTags';

function ImpressumTirol() {
  return (
    <>
      <MetaTags title={'Impressum Tirol | Die Online-Plattform für Ihre Immobilie'} description={'my-sreal.at ist eine Plattform der s REAL Immobilienvermittlung GmbH'} />
      <HeaderSmallWithIcon icon={<IconDocument />} headlineDesktop="Impressum für Tirol" iconActive={true} />

      <MainContent cssClasses="w-desktop--center impressum">
        <h1 className="headline--mobile">Impressum für Tirol</h1>
        <h2 className="mb-20 font-120">my-sreal.at ist eine Plattform der s REAL Immobilienvermittlung GmbH</h2>
        <div>
          <p>
            <span className="font-weight-700">Firma: </span> Sparkassen Real Service Tirol Realitätenvermittlungs-Ges.m.b.H.
          </p>
          <p>
            <span className="font-weight-700">Straße: </span>Sparkassenplatz 5
          </p>
          <p>
            <span className="font-weight-700">PLZ/Ort: </span>A-6020 Innsbruck
          </p>
          <p>
            <span className="font-weight-700">Tel.: </span>05 0100 - 26350
          </p>
          <p>
            <span className="font-weight-700">E-Mail: </span>
            <a href="mailto:info.tirol@sreal.at">info.tirol@sreal.at</a>
          </p>
          <p>
            <span className="font-weight-700">Firmenbuchnummer: </span>48095d
          </p>
          <p>
            <span className="font-weight-700">Gerichtsstand: </span>
            Innsbruck
          </p>
          <p>
            <span className="font-weight-700">Umsatzsteuer-Identifikationsnummer (UID): </span>
            ATU38567500
          </p>
          <p>
            <span className="font-weight-700">Berufsbezeichnung: </span>
            Beteiligungsverwaltung und Beratung
          </p>
        </div>
        <div className="mt-30">
          <p className="font-weight-700">Kammer/Berufsverband:</p>
          <p>Wirtschaftskammer Österreich, Sektion Information und Consulting Fachgruppe der Immobilien- und Vermögenstreuhänder</p>
          <p>1040 Wien, Wiedner Hauptstraße 63</p>
          <a href="http://www.wko.at" target="_blank">
            http://www.wko.at
          </a>
        </div>
        <div className="mt-30">
          <p className="font-weight-700">Aufsichtsbehörde:</p>
          <p>Stadtmagistrat Innsbruck</p>
        </div>
        <div className="mt-30">
          <p>Anwendbare Rechtsvorschriften sind insbesondere das Maklergesetz (MaklerG) sowie die Immobilienmaklerverordnung (IMV) aus 1996</p>
          <a href="http://www.ris.bka.gv.at" target="_blank">
            http://www.ris.bka.gv.at
          </a>
        </div>
        <div className="mt-30">
          <p>Plattform der EU-Kommission zur Online-Streitbeilegung</p>
          <a href="www.ec.europa.eu/consumers/odr" target="_blank">
            www.ec.europa.eu/consumers/odr
          </a>
        </div>
      </MainContent>
    </>
  );
}

export default ImpressumTirol;
