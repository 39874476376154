import parseCurrency from '../util/currencyParser';
import { parseDate } from './datetimeParser';
import { isJson, parseBytes } from './generalUtils';
import moment from 'moment';

import {
  ACTIVITY_ABGEBERBRIEF_ID,
  ACTIVITY_ABLAUF_ALLEINVERMITTLUNG_ID,
  ACTIVITY_ABLAUF_VERMITTLUNG_ID,
  ACTIVITY_ABSAGE_ANFRAGE_ID,
  ACTIVITY_ANBOT_BRIEF_ID,
  ACTIVITY_ANBOT_PERSOENLICH_ID,
  ACTIVITY_ANBOT_SCHRIFTLICH,
  ACTIVITY_ANBOT_TELEFON_ID,
  ACTIVITY_ANFRAGE_ID,
  ACTIVITY_ANGEBOTSENTFERNUNG_ID,
  ACTIVITY_ANGEBOTSPROZESS_BEENDET_ID,
  ACTIVITY_ANGEBOTSPROZESS_GESTARTET_ID,
  ACTIVITY_ANGEBOTSPROZESS_VERLÄNGERT_ID,
  ACTIVITY_AUSHANG_ID,
  ACTIVITY_ABGEBERBRIEF_ID_5,
  ACTIVITY_RUECKGABEBRIEF_ID_1,
  ACTIVITY_RUECKGABEBRIEF_ID_2,
  ACTIVITY_RUECKGABEBRIEF_ID_3,
  ACTIVITY_RUECKGABEBRIEF_ID_4,
  ACTIVITY_RUECKGABEBRIEF_ID_5,
  ACTIVITY_RUECKGABEBRIEF_ID_6,
  ACTIVITY_ABGEBERBRIEF_ID_6,
  ACTIVITY_ABGEBERBRIEF_ID_2,
  ACTIVITY_ABGEBERBRIEF_ID_1,
  ACTIVITY_ABGEBERBRIEF_ID_4,
  ACTIVITY_ABGEBERBRIEF_ID_3,
  ACTIVITY_BEHOERDENWEG_ID,
  ACTIVITY_BESICHTIGUGN_TUERSYSTEM_ID,
  ACTIVITY_BESICHTIGUNG_ID,
  ACTIVITY_BESICHTUNGSWUNSCH_ID,
  ACTIVITY_BESPRECHUNG_ID,
  ACTIVITY_BEWERTUNG_ID,
  ACTIVITY_EINGANGSTUERE_MITARBEITER_AUF_ID,
  ACTIVITY_EINGANGSTUERE_MITARBEITER_ZU_ID,
  ACTIVITY_EINGANGSTUER_INTERESSENT_AUF_ID,
  ACTIVITY_EINGANGSTUER_INTERESSENT_ZU_ID,
  ACTIVITY_EMAIL_ANBOT_ID,
  ACTIVITY_EMAIL_ID,
  ACTIVITY_EXPOSE_ID,
  ACTIVITY_FACEBOOK_ID,
  ACTIVITY_FINANZIERUNGSBERATUNG_ID,
  ACTIVITY_FOLDER_ID,
  ACTIVITY_GEBAEUDETUER_INTERESSENT_AUF_ID,
  ACTIVITY_GEBAEUDETUER_INTERESSENT_ZU_ID,
  ACTIVITY_GEBAUDETUER_MITARBEITER_AUF_ID,
  ACTIVITY_GEBAUDETUER_MITARBEITER_ZU_ID,
  ACTIVITY_GEGENANBOT_ID,
  ACTIVITY_INSERAT_ID,
  ACTIVITY_INTERESSENTENABSAGE_ID,
  ACTIVITY_INTERESSENT_ANGEBOT_ABGEGEBEN_ID,
  ACTIVITY_INTERESSENT_EINGELADEN_ID,
  ACTIVITY_INTERNETINSERTION_ID,
  ACTIVITY_IWB_INSERAT_ID,
  ACTIVITY_KAUFANBOT_ID,
  ACTIVITY_LINKEDIN_ID,
  ACTIVITY_MIETANBOT_ID,
  ACTIVITY_NACHFRAGE_EMAIL_ID,
  ACTIVITY_NACHFRAGE_SCHRIFTLICH_ID,
  ACTIVITY_NACHTELEFONAT_ID,
  ACTIVITY_NEUER_HOECHSTBIETER_ID,
  ACTIVITY_NEWSLETTER_ID,
  ACTIVITY_NOTARIATSTERMIN_ID,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_RESERVIERT_ID,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID,
  ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID,
  ACTIVITY_OBJEKTUEBERGABE_ID,
  ACTIVITY_ONLINE_BESICHTIGUNG_ID,
  ACTIVITY_PREISÄNDERUNG_ID,
  ACTIVITY_RESERVIERUNG_AUFHEBEN_ID,
  ACTIVITY_RUECKGABEBRIEF_ID,
  ACTIVITY_SCHRIFTVERKEHR_ID,
  ACTIVITY_SOFORTKAUFANFRAGE_ABGELEHNT_ID,
  ACTIVITY_SOFORTKAUFANFRAGE_BESTAETIGT_ID,
  ACTIVITY_SOFORTKAUFANFRAGE_INTERESSENT_ID,
  ACTIVITY_SONSTIGE_ID,
  ACTIVITY_SREAL_MAGAZIN_INSERAT_ID,
  ACTIVITY_TELEFONAT_ID,
  ACTIVITY_TERMINEINLADUNG_ID,
  ACTIVITY_TERMINERINNERUNG_ID,
  ACTIVITY_TERMINWUNSCH_ID,
  ACTIVITY_TERMIN_NICHT_STATTGEFUNDEN_ID,
  ACTIVITY_VERKAUFSTAFEL_ID,
  ACTIVITY_VERTRAGSABSCHLUSS_ID,
  IMMO_STATE_AKTIV,
  IS_ACTIVITY_ABGEBERBRIEF,
  ACTIVITY_EMAIL_ANBOT_JUSTIMMO_ID,
  JUSTIMMO_REALTY_SYSTEMTYPE_REALTY,
  IMMO_DRIVE_GROUP_ID_INTERESSENT,
} from '../constants';

import { teaserTextParser } from './teaserTextParser';

function getDefaultImmoObject() {
  return {
    id: -1,
    catalogue_number: '',
    title: '',
    url: '',
    tenant_id: -1,
    status: IMMO_STATE_AKTIV,
    description: '',
    shortDescription: '',
    onlineViewing: [],
    immolive: {},
    activities: [],
    areas: {
      living_area: 0,
      site_area: 0,
      floor_area: 0,
      floor_area_to: 0,
    },
    imagesPublic: [],
    imagesPrivate: [],
    preview_image: null,
    preview_plan: null,
    documentsPublic: [],
    documentsPrivate: [],
    rooms: 0,
    rooms_to: 0,
    address: {
      country: '',
      city: '',
      zip: '',
      street: '',
      number: '',
      entrance: '',
      door: '',
      geo_coordinates: {},
      floor: '',
    },
    prices: [],
    agent: {
      id: 0,
      gender: '',
      first_name: '',
      last_name: '',
      email: '',
      prefix: '',
      phone: '',
      profile_picture: '',
      suffix: '',
      role: '',
    },
    featureList: [],
    areaList: [],
    infrastructureList: [],
    updated_at: null,
    system_type: JUSTIMMO_REALTY_SYSTEMTYPE_REALTY,
    subunits_available: 0,
    parent_id: null,
  };
}

const filetypeLabels = (fileType) => {
  if (!fileType) return null;
  switch (fileType) {
    case 'application/pdf':
      return 'PDF';
    case 'image/jpeg':
    case 'image/jpg':
      return 'JPG';
    case 'image/png':
      return 'PNG';
    case 'application/vnd.ms-outlook':
      return 'MS-OUTLOOK';
    default:
      return fileType.toUpperCase();
  }
};

const priceLabels = (key) => {
  switch (key) {
    case 'purchase_price':
    case 'purchase':
    case 'residential_aggregation_data':
      return 'Kaufpreis';
    case 'operating':
    case 'operating_costs':
      return 'Betriebskosten';
    case 'maintenance_funds':
    case 'monthly_costs':
      return 'Hausverwaltungskosten';
    case 'heating':
      return 'Heizkosten';
    case 'rent':
      return 'Miete';
    case 'furniture_hire':
      return 'Möbelmiete';
    case 'parking':
      return 'Parkplatz';
    case 'garage':
      return 'Garage';
    case 'hot_water':
      return 'Warmwasser';
    case 'cooling':
      return 'Klimaanlage';
    case 'elevator':
      return 'Aufzug';
    case 'others':
      return 'Sonstige Kosten';
    case 'commission':
      return 'Provision';
    case 'other_costs':
      return 'Kommission';
    case 'from':
      return 'von';
    case 'to':
      return 'bis';
    default:
      break;
  }
};

const roomLabels = (room) => {
  switch (room) {
    case 'rooms':
      return 'Zimmer';
    case 'toilets':
      return 'WCs';
    case 'bathrooms':
      return 'Bäder';
    case 'storerooms':
      return 'Abstellräume';
    default:
      return false;
  }
};

const areaLabels = (area) => {
  switch (area) {
    case 'balcony_area':
      return 'Balkon';
    case 'basement_area':
      return 'Keller';
    case 'terrace_area':
      return 'Terrasse';
    case 'covered_balcony_area':
      return 'Loggias';
    case 'garden_area':
      return 'Gärten';
    case 'parking_space_area':
      return 'Parkplätze';
    case 'sales_area':
      return 'Verkaufsfläche';
    case 'office_area':
      return 'Bürofläche';
    case 'living_area':
      return 'Wohnfläche';
    case 'garage_area':
      return 'Garage';
    case 'storage_area':
      return 'Lagerfläche';
    case 'undeveloped_attic':
      return 'Rohdachbodenfläche';
    default:
      return false;
  }
};

const openSpaceLabels = ['Balkon', 'Keller', 'Terrasse', 'Loggias', 'Gärten', 'Parkplätze'];

const getOpenSpaceSize = (immo) => {
  let num = 0;
  if (immo.featureList) {
    const openSpaceAreas = immo.featureList.filter((a) => openSpaceLabels.includes(a.label));

    openSpaceAreas.forEach((a) => {
      if (openSpaceLabels.includes(a.label)) {
        num += a.num;
      }
    });
  }

  return num;
};

const getWohneinheitOpenSpaceSize = (immo) => {
  const openSpaceAreas = ['balcony_area', 'terrace_area', 'covered_balcony_area', 'garden_area'];

  let num = 0;
  openSpaceAreas.forEach((a) => {
    if (immo.areas?.[a]) {
      num += immo.areas?.[a].area;
    }
  });

  return Number(num.toFixed(2));
};

const energyLabels = (item) => {
  switch (item) {
    case 'heating_demand':
      return 'Heizwärmebedarf';
    case 'energy_performance':
      return 'fGEE';
    case 'heating_demand_class':
      return 'Heizwärmeklasse';
    case 'energy_performance_class':
      return 'fGEE Klasse';
    default:
      return false;
  }
};

const activityLabels = (id) => {
  switch (id) {
    case ACTIVITY_ANBOT_TELEFON_ID:
      return 'Immobilie über Telefon angeboten';
    case ACTIVITY_ANBOT_BRIEF_ID:
      return 'Immobilienangebot brieflich versendet';
    case ACTIVITY_ANBOT_SCHRIFTLICH:
      return 'Immobilie schriftlich versendet';
    case ACTIVITY_AUSHANG_ID:
      return 'Immobilienangebot im Aushang';
    case ACTIVITY_BESICHTIGUNG_ID:
      return 'Besichtigung der Immobilie';
    case ACTIVITY_INSERAT_ID:
      return 'Inseratschaltung der Immobilie';
    case ACTIVITY_NOTARIATSTERMIN_ID:
      return 'Notariatstermin zur Immobilie';
    case ACTIVITY_OBJEKTSTATUSAENDERUNG_RESERVIERT_ID:
      return 'Immobilie ist reserviert';
    case ACTIVITY_RESERVIERUNG_AUFHEBEN_ID:
      return 'Immobilienreservierung aufgehoben';
    case ACTIVITY_VERTRAGSABSCHLUSS_ID:
      return 'Vertragsabschluss';
    case ACTIVITY_PREISÄNDERUNG_ID:
      return 'Preisänderung';
    case ACTIVITY_NACHTELEFONAT_ID:
      return 'Nachtelefonat zur Immobilie';
    case ACTIVITY_INTERESSENTENABSAGE_ID:
      return 'Interessentenabsage zur Immobilie';
    case ACTIVITY_SONSTIGE_ID:
      return 'Sonstiges zur Immobilie';
    case ACTIVITY_SCHRIFTVERKEHR_ID:
      return 'Schriftverkehr zur Immobilie';
    case ACTIVITY_ABLAUF_ALLEINVERMITTLUNG_ID:
      return 'Ablauf des Alleinvermittlungsauftrages';
    case ACTIVITY_ABLAUF_VERMITTLUNG_ID:
      return 'Ablauf des Vermittlungsauftrages';
    case ACTIVITY_KAUFANBOT_ID:
      return 'Kaufanbot zur Immobilie';
    case ACTIVITY_MIETANBOT_ID:
      return 'Mietanbot zur Immobilie';
    case ACTIVITY_INTERNETINSERTION_ID:
      return 'Immobilienfreischaltung';
    case ACTIVITY_VERKAUFSTAFEL_ID:
      return 'Eine Verkaufstafel';
    case ACTIVITY_OBJEKTUEBERGABE_ID:
      return 'Die Objektübergabe';
    case ACTIVITY_TELEFONAT_ID:
      return 'Ein Telefonat';
    case ACTIVITY_NACHFRAGE_SCHRIFTLICH_ID:
      return 'Nachfrage schriftlich zur Immobilie';
    case ACTIVITY_NACHFRAGE_EMAIL_ID:
      return 'Nachfrage über E-Mail zur Immobilie';
    case ACTIVITY_BESPRECHUNG_ID:
      return 'Eine Besprechung';
    case ACTIVITY_FINANZIERUNGSBERATUNG_ID:
      return 'Finanzierungsberatung';
    case ACTIVITY_ANFRAGE_ID:
      return 'Eine Anfrage zur Immobilie';
    case ACTIVITY_EMAIL_ANBOT_ID:
      return 'Immobilie per E-Mail angeboten';
    case ACTIVITY_BEHOERDENWEG_ID:
      return 'Behördenweg';
    case ACTIVITY_GEGENANBOT_ID:
      return 'Gegenanbot zur Immobilie';
    case ACTIVITY_FOLDER_ID:
      return 'Immobilienfolder';
    case ACTIVITY_NEWSLETTER_ID:
      return 'Newsletter';
    case ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID:
      return 'Immobilie wurde verkauft';
    case ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID:
      return 'Immobilie wurde vermietet';
    case ACTIVITY_RUECKGABEBRIEF_ID_1:
      return 'Rückgabebrief wurde verschickt';
    case ACTIVITY_RUECKGABEBRIEF_ID_2:
      return 'Rückgabebrief wurde verschickt';
    case ACTIVITY_RUECKGABEBRIEF_ID_3:
      return 'Rückgabebrief wurde verschickt';
    case ACTIVITY_RUECKGABEBRIEF_ID_4:
      return 'Rückgabebrief wurde verschickt';
    case ACTIVITY_RUECKGABEBRIEF_ID_5:
      return 'Rückgabebrief wurde verschickt';
    case ACTIVITY_RUECKGABEBRIEF_ID_6:
      return 'Rückgabebrief wurde verschickt';
    case ACTIVITY_IWB_INSERAT_ID:
      return 'Immobilieninserat';
    case ACTIVITY_SREAL_MAGAZIN_INSERAT_ID:
      return 'Immobilie im s REAL Magazin';
    case ACTIVITY_ABSAGE_ANFRAGE_ID:
      return 'Interessent:in wurde Absage übermittelt';
    case ACTIVITY_ABGEBERBRIEF_ID_1:
      return 'Abgeberbrief mit Immobilienexposé versendet';
    case ACTIVITY_ABGEBERBRIEF_ID_2:
      return 'Abgeberbrief mit Immobilienexposé versendet';
    case ACTIVITY_ABGEBERBRIEF_ID_3:
      return 'Abgeberbrief mit Immobilienexposé versendet';
    case ACTIVITY_ABGEBERBRIEF_ID_4:
      return 'Abgeberbrief mit Immobilienexposé versendet';
    case ACTIVITY_ABGEBERBRIEF_ID_5:
      return 'Abgeberbrief mit Immobilienexposé versendet';
    case ACTIVITY_ABGEBERBRIEF_ID_6:
      return 'Abgeberbrief mit Immobilienexposé versendet';
    case ACTIVITY_BEWERTUNG_ID:
      return 'Bewertung';
    case ACTIVITY_FACEBOOK_ID:
      return 'Immobilie auf Facebook geteilt';
    case ACTIVITY_LINKEDIN_ID:
      return 'Immobilie auf LinkedIn geteilt';
    case ACTIVITY_ANBOT_PERSOENLICH_ID:
      return 'Immobilie wurde persönlich angeboten';
    case ACTIVITY_BESICHTUNGSWUNSCH_ID:
      return 'Besichtigungswunsch';
    case ACTIVITY_EXPOSE_ID:
      return 'Immobilienexposé erstellt';
    case ACTIVITY_EMAIL_ID:
      return 'Ein E-Mail';
    case ACTIVITY_TERMINEINLADUNG_ID:
      return 'Termineinladung wurde versendet';
    case ACTIVITY_TERMINERINNERUNG_ID:
      return 'Terminerinnerung wurde versendet';
    case ACTIVITY_ANGEBOTSPROZESS_GESTARTET_ID:
      return 'Angebotsprozess in immo-live gestartet';
    case ACTIVITY_ANGEBOTSPROZESS_VERLÄNGERT_ID:
      return 'Angebotsprozess in immo-live verlängert';
    case ACTIVITY_ANGEBOTSPROZESS_BEENDET_ID:
      return 'Angebotsprozess in immo-live beendet';
    case ACTIVITY_INTERESSENT_EINGELADEN_ID:
      return 'Interessent in immo-live eingeladen';
    case ACTIVITY_INTERESSENT_ANGEBOT_ABGEGEBEN_ID:
      return 'Interessent hat Angebot abgegeben in immo-live';
    case ACTIVITY_SOFORTKAUFANFRAGE_INTERESSENT_ID:
      return 'Sofortkaufanfrage durch Interessent in immo-live';
    case ACTIVITY_SOFORTKAUFANFRAGE_ABGELEHNT_ID:
      return 'Sofortkaufanfrage von Makler abgelehnt in immo-live';
    case ACTIVITY_SOFORTKAUFANFRAGE_BESTAETIGT_ID:
      return 'Sofortkaufanfrage von Makler bestätigt in immo-live';
    case ACTIVITY_ANGEBOTSENTFERNUNG_ID:
      return 'Angebotsentfernung durch Makler in immo-live';
    case ACTIVITY_NEUER_HOECHSTBIETER_ID:
      return 'Neuer Höchstbieter in immo-live';
    case ACTIVITY_ONLINE_BESICHTIGUNG_ID:
      return 'Onlinebesichtigung';
    case ACTIVITY_TERMIN_NICHT_STATTGEFUNDEN_ID:
      return 'Termin hat nicht stattgefunden';
    case ACTIVITY_TERMINWUNSCH_ID:
      return 'Terminwunsch';
    case ACTIVITY_BESICHTIGUGN_TUERSYSTEM_ID:
      return 'Besichtigung über Türöffnungssystem';
    case ACTIVITY_GEBAUDETUER_MITARBEITER_AUF_ID:
      return 'Gebäudetüre durch Makler:in aufgesperrt';
    case ACTIVITY_GEBAUDETUER_MITARBEITER_ZU_ID:
      return 'Gebäudetüre durch Makler:in zugesperrt';
    case ACTIVITY_EINGANGSTUERE_MITARBEITER_AUF_ID:
      return 'Eingangstüre durch Makler:in aufgesperrt';
    case ACTIVITY_EINGANGSTUERE_MITARBEITER_ZU_ID:
      return 'Eingangstüre durch Makler:in zugesperrt';
    case ACTIVITY_GEBAEUDETUER_INTERESSENT_AUF_ID:
      return 'Gebäudetüre durch Interessent:in aufgesperrt';
    case ACTIVITY_GEBAEUDETUER_INTERESSENT_ZU_ID:
      return 'Gebäudetüre durch Interessent:in zugesperrt';
    case ACTIVITY_EINGANGSTUER_INTERESSENT_AUF_ID:
      return 'Eingangstüre durch Interessent:in aufgesperrt';
    case ACTIVITY_EINGANGSTUER_INTERESSENT_ZU_ID:
      return 'Eingangstüre durch Interessent:in zugesperrt';
    default:
      return false;
  }
};

// TODO
const energyClassesMapping = (energyClass) => {
  switch (energyClass) {
    case 0:
      return 'A++';
    case 1:
      return 'A+';
    case 2:
      return 'A';
    case 3:
      return 'B';
    case 4:
      return 'C';
    case 5:
      return 'D';
    case 6:
      return 'E';
    case 7:
      return 'F';
    case 8:
      return 'G';
    default:
      return '';
  }
};

const parseDistance = (distance) => {
  // parse distance in m to km
  if (distance > 1000) {
    return (distance / 1000).toFixed(2) + ' km';
  } else {
    return distance + ' m';
  }
};
const architectureMapping = (architecture) => {
  switch (architecture) {
    case 0:
      return 'Neubau';
    case 1:
      return 'Altbau';
    default:
      return false;
  }
};

const condition_assessmentMapping = (condition) => {
  switch (condition) {
    case 0:
      return 'sehr gut';
    case 1:
      return 'gut';
    case 2:
      return 'mittelmäßig';
    case 3:
      return 'schlecht';
    default:
      return false;
  }
};
const infrastructureMapping = (infrastructure) => {
  switch (infrastructure) {
    case 0:
      return 'unerschlossen';
    case 1:
      return 'teilerschlossen';
    case 2:
      return 'vollerschlossen';
    default:
      return false;
  }
};

const getThumbnail = (image, th_name) => {
  if (!image) return '';

  let thumbnail = image.url;
  if (image.thumbnails) {
    let th = image.thumbnails.find((th) => th.name === th_name);
    if (th && th.url && th.url !== '') {
      thumbnail = th.url;
    }
  }
  return thumbnail;
};

const getMainArea = (immo, returnLabel) => {
  let area = false;
  let areaLabel = false;

  if (!immo.areas) {
    return null;
  }

  if (parseInt(immo.areas.living_area) > 0) {
    area = immo.areas.living_area.toLocaleString('de-DE');
    areaLabel = 'Wohnfläche';
  } else if (parseInt(immo.areas.site_area) > 0) {
    area = immo.areas.site_area.toLocaleString('de-DE');
    areaLabel = 'Grundfläche';
  } else if (parseInt(immo.areas.floor_area) > 0) {
    area = immo.areas.floor_area.toLocaleString('de-DE');
    areaLabel = 'Nutzfläche';
    if (parseInt(immo.areas.floor_area_to) > 0 && immo.areas.floor_area_to !== immo.areas.floor_area_from) {
      area = 'ab ' + area;
    }
  }

  if (returnLabel) {
    return areaLabel;
  } else if (area) {
    return area + ' m²';
  } else {
    return null;
  }
};

const getKaufpreis = (immo) => {
  let kp = 'auf Anfrage';

  if (immo.prices) {
    let purchasePrice = immo.prices.find((p) => p.key === 'purchase_price');
    let residential_aggregation_data = immo.prices.find((p) => p.key === 'residential_aggregation_data');
    let ab = immo.prices.find((p) => p.key === 'from');

    if (purchasePrice && purchasePrice.value) {
      kp = purchasePrice.value;
    } else if (residential_aggregation_data && residential_aggregation_data.value) {
      kp = 'ab ' + residential_aggregation_data.value;
    } else if (ab && ab.value) {
      kp = 'ab ' + ab.value;
    }
  }

  return kp;
};

const getKaufpreisNum = (immo) => {
  let kp = 0;

  if (immo.prices) {
    let purchasePrice = immo.prices.find((p) => p.key === 'purchase_price');
    let residential_aggregation_data = immo.prices.find((p) => p.key === 'residential_aggregation_data');
    let ab = immo.prices.find((p) => p.key === 'from');

    if (purchasePrice && purchasePrice.value) {
      kp = purchasePrice.num;
    } else if (residential_aggregation_data && residential_aggregation_data.value) {
      kp = residential_aggregation_data.num;
    } else if (ab && ab.value) {
      kp = ab.num;
    }
  }

  return kp;
};

const parseInfrastructureList = (pois, split = true) => {
  if (!pois) return;
  let infrastructureList = pois.groups.map((POI) => {
    return POI.categories.map((category) => {
      return {
        label: category.name,
        value: parseDistance(category.distance),
      };
    });
  });

  infrastructureList = infrastructureList.flat();
  infrastructureList = infrastructureList.sort((a, b) => a.label.localeCompare(b.label));

  if (split) {
    infrastructureList = infrastructureList.reduce((acc, val, i) => (acc[i % 2].push(val), acc), [[], []]);
  }

  pois.groups = pois.groups.map((poi) => {
    return {
      ...poi,
      label: poi.name,
    };
  });

  return pois;
};

const renderImmoFileHeadline = (title, original_filename) => {
  let headline = 'Datei';
  if (title !== '') {
    headline = title;
  } else if (original_filename !== '') {
    headline = original_filename;

    // remove type from name
    let fileNameIndexOf = headline.indexOf('.');
    if (fileNameIndexOf > -1) {
      headline = headline.substring(0, fileNameIndexOf);
    }
  }
  return headline;
};

const renderImmoFileText = (mime_type, original_filesize) => {
  let text = '';
  if (mime_type && original_filesize) {
    text = filetypeLabels(mime_type) + ', ' + parseBytes(original_filesize, 1);
  }

  return text;
};

const parseImmo = (data, updated_at) => {
  let immoObject = getDefaultImmoObject();
  if (!data) return immoObject;

  immoObject.id = data.id;

  if (updated_at) {
    immoObject.updated_at = updated_at;
  }

  // CATALOGUE_NUMBER
  if (data.catalogue_number?.display) {
    immoObject.catalogue_number = data.catalogue_number.display;
  }

  // TENANT_ID
  if (data.tenant_id) {
    immoObject.tenant_id = data.tenant_id;
  }

  // STATUS
  if (data.status?.name) {
    immoObject.status = data.status.name;
  }

  // TITLE
  if (data.texts?.title?.[0]?.value) {
    immoObject.title = data.texts.title[0].value;
  } else {
    immoObject.title = 'Immobilie ' + immoObject.catalogue_number;
  }

  // DESCRIPTION & SHORT_DESCRIPTION
  if (data.texts?.description?.[0]?.value) {
    immoObject.description = data.texts.description[0].value;
    immoObject.shortDescription = teaserTextParser(data.texts.description[0].value, 50);
  }

  // AREA
  if (data.areas?.living_area?.area) {
    immoObject.areas.living_area = data.areas.living_area.area.toLocaleString('de-DE');
  }
  if (data.areas?.total_area?.area) {
    immoObject.areas.total_area = data.areas.total_area.area.toLocaleString('de-DE');
  }
  if (data.areas?.site_area?.area) {
    immoObject.areas.site_area = data.areas.site_area.area.toLocaleString('de-DE');
  }
  if (data.areas?.floor_area?.area) {
    immoObject.areas.floor_area = data.areas.floor_area.area.toLocaleString('de-DE');
  }

  // ADDRESSE
  Object.keys(immoObject.address).map((key) => {
    if (data.address?.[key]) {
      immoObject.address[key] = data.address[key];
    } else if (data.public_address?.[key]) {
      immoObject.address[key] = data.public_address[key];
    } else {
      immoObject.address[key] = '';
    }
  });

  // ROOMS
  if (data.rooms?.rooms > 0) {
    immoObject.rooms = data.rooms.rooms;
  }

  //RESIDENTIAL_AGGREGATION_DATA
  if (data.residential_aggregation_data?.rooms?.from > 0) {
    if (immoObject.rooms === 0) {
      immoObject.rooms = data.residential_aggregation_data.rooms.from;
      immoObject.rooms_to = data.residential_aggregation_data.rooms.to;
    }
  }

  //FILES
  if (data.files) {
    data.files.map((file) => {
      let headline = renderImmoFileHeadline(file.title, file.original_filename);
      let text = renderImmoFileText(file.mime_type, file.original_filesize);
      let f = {
        ...file,
        headline: headline,
        text: text,
      };

      if (f.mime_type.includes('image')) {
        if (f.visibility === 1) {
          immoObject.imagesPrivate.push(f);
        } else {
          immoObject.imagesPublic.push(f);
        }
      } else {
        if (f.visibility !== 1 || f.group?.id === IMMO_DRIVE_GROUP_ID_INTERESSENT) {
          immoObject.documentsPublic.push(f);
        } else {
          immoObject.documentsPrivate.push(f);
        }
      }
    });

    // sort images
    if (immoObject.imagesPublic.length > 0) {
      immoObject.imagesPublic = immoObject.imagesPublic.sort((a, b) => {
        if (a.sort_order === b.sort_order) {
          // If sort_order is the same, further sort by uploaded_at
          return new Date(a.uploaded_at) - new Date(b.uploaded_at);
        } else {
          // Otherwise, sort by sort_order
          return a.sort_order - b.sort_order;
        }
      });

      // sort plans to the end
      immoObject.imagesPublic = immoObject.imagesPublic.sort((a, b) => {
        if (a.group.id !== 1 && b.group.id !== 1) {
          // If both items have group.id !== 1, no change in order
          return 0;
        } else if (a.group.id !== 1) {
          // If a.group.id !== 1, move a to the end
          return 1;
        } else if (b.group.id !== 1) {
          // If b.group.id !== 1, move b to the end
          return -1;
        } else {
          // If both have group.id === 1, no change in order
          return 0;
        }
      });
    }
  }

  immoObject.preview_image = data.preview_image;
  immoObject.preview_plan = data.preview_plan;

  //POINTS OF INTEREST
  if (data.points_of_interest) {
    immoObject.infrastructureList = data.points_of_interest.map((POI) => {
      return POI.categories.map((category) => {
        return {
          label: category.name,
          value: parseDistance(category.distance),
        };
      });
    });

    immoObject.infrastructureList = immoObject.infrastructureList.flat();
    immoObject.infrastructureList = immoObject.infrastructureList.sort((a, b) => a.label.localeCompare(b.label));

    immoObject.infrastructureList = immoObject.infrastructureList.reduce((acc, val, i) => (acc[i % 2].push(val), acc), [[], []]);
  }

  // PRICES
  if (data.prices) {
    Object.entries(data.prices).map(([key, val]) => {
      if (val?.gross > 0) {
        immoObject.prices.push({
          key: key,
          label: priceLabels(key),
          value: parseCurrency(val.gross, true),
          num: val.gross,
        });
      } else if (val?.net > 0) {
        immoObject.prices.push({
          key: key,
          label: priceLabels(key),
          value: parseCurrency(val.net, true),
          num: val.net,
        });
      } else if (parseInt(val) > 0) {
        immoObject.prices.push({
          key: key,
          label: priceLabels(key),
          value: parseCurrency(val, true),
          num: val,
        });
      }
    });

    //Provision
    if (data.commission?.prospect?.text_value) {
      let key = 'commission';
      immoObject.prices.push({
        label: priceLabels(key),
        value: data.commission.prospect.text_value,
        num: data.commission.prospect.text_value,
      });
    }
  }

  //RESIDENTIAL_AGGREGATION_DATA
  if (data.residential_aggregation_data?.price?.from > 0) {
    immoObject.prices.push({
      key: 'residential_aggregation_data',
      label: priceLabels('residential_aggregation_data'),
      value: parseCurrency(data.residential_aggregation_data.price.from, true),
      num: data.residential_aggregation_data.price.from,
    });
  }

  if (data.residential_aggregation_data?.area?.from) {
    if (immoObject.areas.floor_area === 0) {
      immoObject.areas.floor_area = data.residential_aggregation_data.area.from;
      immoObject.areas.floor_area_to = data.residential_aggregation_data.area.to;
    }
  }

  //AGENT
  if (data.agent) {
    Object.keys(data.agent).map((key) => {
      if (key === 'profile_picture') {
        immoObject.agent.profile_picture = data.agent[key];
      } else if (key === 'gender') {
        immoObject.agent[key] = data.agent[key] === 0 ? 'Herr' : 'Frau';
      } else if (data.agent?.[key]) {
        immoObject.agent[key] = data.agent[key];
      } else {
        immoObject.agent[key] = '';
      }
    });
  }

  //FEATURES
  let featureList = [];
  if (data.features && data.features.length > 0) {
    featureList = data.features.map((feature) => {
      let itemstring = '';
      feature.items.map((item) => {
        itemstring = itemstring + item.label + ', ';
      });

      // remove last 2 characters
      itemstring = itemstring.substring(0, itemstring.length - 2);

      return {
        category: 'condition',
        label: feature.group_label,
        value: itemstring,
      };
    });
  }

  let areaList = [];
  // Feature Rooms
  if (data.rooms && typeof data.rooms === 'object') {
    const order = ['rooms', 'bathrooms', 'toilets', 'storerooms'];

    Object.entries(data.rooms)
      .sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
      .filter((val) => {
        let room = val[0];
        let num = val[1];
        if (num <= 0) return false;
        else if (!roomLabels(room)) return false;
        else return true;
      })
      .map((val) => {
        let room = val[0];
        let num = val[1];

        let item = {
          category: 'areas',
          label: roomLabels(room),
          value: num.toLocaleString('de-DE'),
        };

        areaList.push(item);
        featureList.push(item);
      });
  }
  // Feature Areas
  if (data.areas && typeof data.areas === 'object') {
    const order = [
      'living_area',
      'sales_area',
      'office_area',
      'basement_area',
      'covered_balcony_area',
      'balcony_area',
      'terrace_area',
      'garden_area',
      'garage_area',
      'parking_space_area',
      'storage_area',
      'undeveloped_attic',
    ];

    Object.entries(data.areas)
      .sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
      .filter((val) => {
        let area = val[0];
        let v = 0;

        if (val[1]) {
          if (val[1].area) {
            v = val[1].area;
          } else if (val[1].count) {
            v = val[1].count;
          }
        }
        if (v <= 0) return false;
        else if (!areaLabels(area)) return false;
        else return true;
      })
      .map((val) => {
        let area = val[0];
        let v = 0;
        let num = 0;
        if (val[1].area) {
          v = val[1].area.toLocaleString('de-DE') + ' m²';
          num = val[1].area;
        } else if (val[1].count) {
          v = val[1].count;
          num = val[1].count;
        }

        let item = {
          category: 'areas',
          label: areaLabels(area),
          value: v,
          num: num,
        };
        areaList.push(item);
        featureList.push(item);
      });
  }

  immoObject.areaList = areaList;

  // Feature verfügbar ab
  if (data.obtainable_from) {
    if (data.obtainable_from.text) {
      let item = {
        category: 'condition',
        label: 'Beziehbar',
        value: data.obtainable_from.text,
      };

      featureList.push(item);
    } else {
      let item = {
        category: 'condition',
        label: 'Beziehbar',
        value: parseDate(data.obtainable_from.date),
      };
      if (item.value !== '01.01.0001') {
        featureList.push(item);
      }
    }
  }

  //Bauart
  if (parseInt(data.architecture) > -1) {
    let item;
    if (typeof data.architecture === 'number') {
      item = {
        category: 'condition',
        label: 'Bauart',
        value: architectureMapping(data.architecture),
      };
    } else {
      item = {
        category: 'condition',
        label: 'Bauart',
        value: data.architecture,
      };
    }
    featureList.push(item);
  }
  //Zustandbewertung
  if (parseInt(data.condition_assessment) > -1) {
    let item;
    if (typeof data.condition_assessment === 'number') {
      item = {
        category: 'condition',
        label: 'Zustandsbewertung',
        value: condition_assessmentMapping(data.condition_assessment),
      };
    } else {
      item = {
        category: 'condition',
        label: 'Zustandsbewertung',
        value: data.condition_assessment,
      };
    }
    featureList.push(item);
  }
  //Barrierefreiheit
  if (data.accessible) {
    let item;
    item = {
      category: 'condition',
      label: 'Barrierefreiheit',
      value: 'Ja',
    };
    featureList.push(item);
  }
  //Erschliessung
  if (parseInt(data.infrastructure_provision) > -1) {
    let item;
    if (typeof data.infrastructure_provision === 'number') {
      item = {
        category: 'condition',
        label: 'Erschließung',
        value: infrastructureMapping(data.infrastructure_provision),
      };
    } else {
      item = {
        category: 'condition',
        label: 'Erschließung',
        value: data.infrastructure_provision,
      };
    }
    featureList.push(item);
  }

  // Features Energy
  if (data.energy_performance_certification) {
    Object.entries(data.energy_performance_certification)
      .filter((val) => {
        let label = val[0];
        let num = 0;
        if (val[1] && val[1].value) {
          num = val[1].value;
        }
        if (num <= 0) return false;
        else if (!energyLabels(label)) return false;
        else return true;
      })
      .map((val) => {
        let label = val[0];
        let num = val[1].value;
        if (label === 'heating_demand') {
          num += ' kWh/m²a';
        }

        let item = {
          category: 'energy',
          label: energyLabels(label),
          value: num,
        };

        featureList.push(item);

        // Energie-Klassen suchen
        let energyClass = false;
        if (parseInt(val[1].class) > -1) {
          energyClass = val[1].class;

          if (energyClassesMapping(energyClass)) {
            let item = {
              category: 'energy',
              label: energyLabels(label + '_class'),
              value: energyClassesMapping(energyClass),
            };
            featureList.push(item);
          }
        }
      });
  }

  immoObject.featureList = featureList.sort((a, b) => a.label.localeCompare(b.label));

  if (data.features) {
    const order = ['Fenster', 'Ausblick', 'Boden', 'Balkon', 'Küche', 'Bad', 'WCs', 'Fahrstuhl', 'Beleuchtung', 'Fernsehen', 'Stellplatzart', 'Gastronomie', 'Serviceleistungen', 'Extras'];

    let ausstattung = order
      .map((label) => {
        return data.features
          .filter((feature) => feature.group_label == label)
          .map((feature) => feature.items.map((item) => item.label))
          .flat();
      })
      .flat();

    featureList.push({
      category: 'furnishing',
      label: 'Ausstattung',
      value: ausstattung,
    });
  }

  //VIDEO
  if (data.links && data.links.length > 0) {
    immoObject.onlineViewing = data.links;
  }

  immoObject.url = parseImmoUrl(immoObject);

  let activityFiles = [];

  if (data.additionalProperties) {
    let activityEmailIsset = false;
    const activitiesList = Object.keys(data.additionalProperties)
      .sort((a, b) => {
        if (a && isJson(a) && b && isJson(b)) {
          let aJson = JSON.parse(a);
          let bJson = JSON.parse(b);
          return moment(aJson.Created_at) - moment(bJson.Created_at);
        } else {
          return -1;
        }
      })
      .map((key) => {
        let item = data.additionalProperties[key];

        // Immolive doesnt have id - check by key
        switch (key) {
          case 'ImmoLiveStart':
            immoObject.immolive.immoliveStart = item;
            break;
          case 'ImmoLiveEnd':
            immoObject.immolive.immoliveEnd = item;
            break;
          case 'ImmoLiveHighestBid':
            immoObject.immolive.immoLiveHighestBid = item;
            break;
          case 'ImmoLiveUrl':
            immoObject.immolive.ImmoLiveUrl = item;
            break;
          default:
            if (item && isJson(item)) {
              let itemVal = JSON.parse(item);

              const defaultDate = '0001-01-01 00:00:00Z';
              let dateFrom = itemVal.From;
              let dateCreated = itemVal.Created_at;
              let dateTo = itemVal.To;

              if (dateFrom === defaultDate) {
                if (dateCreated && dateCreated !== defaultDate) {
                  dateFrom = dateCreated;
                } else {
                  dateFrom = null;
                }
              }

              if (dateTo === '0001-01-01 00:00:00Z') {
                dateTo = null;
              }
              // save files from email activity in documentsPrivate
							if ((itemVal.ActivityTypeId === ACTIVITY_EMAIL_ANBOT_JUSTIMMO_ID || itemVal.ActivityTypeId === ACTIVITY_SCHRIFTVERKEHR_ID) && itemVal.Files?.length > 0 && !activityEmailIsset) {
                // activityEmailIsset = true;

                activityFiles = activityFiles.concat(
                  itemVal.Files.map((file) => {
                    let headline = renderImmoFileHeadline(file.Title, file.Original_filename);
                    let text = renderImmoFileText(file.Mime_type, file.Original_filesize);

                    let docDate = dateFrom;
                    if (dateFrom !== null) {
                      docDate = parseDate(dateFrom);
                    }

                    const storageKey = file.Storage_key;
                    const fileExists = activityFiles.find((f) => f.storage_key === storageKey || f.headline === headline);
										const exposeExists = activityFiles.find((f) => f.headline.includes('Expos'));
										const isExpose = headline.includes('Expos');
                    if (fileExists || (isExpose && exposeExists)) {
                      // if current file is older then already existing file - dont add it
                      //if (dateFrom == null || moment(dateFrom).isBefore(fileExists.originalDate)) {
                      return null;
                      //}
                    }

                    return {
                      ...file,
                      url: file.Url,
                      visibility: file.Visibility,
                      headline: headline,
                      text: text,
                      storage_key: storageKey,
                      original_filename: file.Original_filename,
                      date: docDate,
                      originalDate: dateFrom,
                      isExpose: isExpose,
                      isActivityFile: true,
                    };
                  }).filter((a) => a !== undefined && a !== null),
                );
              }

              const id = itemVal.ActivityTypeId;
              const label = activityLabels(id);

              if (id && label) {
                return {
                  id: id,
                  key: key,
                  label: label,
                  dateFrom: dateFrom,
                  dateTo: dateTo,
                  value: itemVal,
                };
              }
            }
            break;
        }
      })
      .filter((a) => a !== undefined && a !== null)
      .sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.dateFrom) - new Date(a.dateFrom);
      });

    immoObject.activities = activitiesList;
  }

  if (activityFiles.length > 0) {
    immoObject.documentsPrivate = [...immoObject.documentsPrivate, ...activityFiles];
  }

  immoObject.system_type = data.system_type;
  immoObject.parent_id = data.parent_id;

  if (data.residential_aggregation_data?.subunits_available > 0) {
    immoObject.subunits_available = data.residential_aggregation_data.subunits_available;
  }

  return immoObject;
};

const parseImmoSreal = (data) => {
  let immoObject = getDefaultImmoObject();

  if (data) {
    immoObject.id = data.crm_id;

    if (data.tenant_id) {
      immoObject.tenant_id = data.tenant_id;
    }

    // IMAGE
    if (data.cover_image) {
      immoObject.imagesPublic[0] = data.cover_image;
    }

    if (data.number) {
      immoObject.catalogue_number = data.number;
    }

    // TITLE
    if (data.title && data.title.de !== '') {
      immoObject.title = data.title.de;
    } else {
      immoObject.title = 'Immobilie ' + immoObject.catalogue_number;
    }

    //ADDRESSE
    if (data.address) {
      if (data.address.country !== '' && data.address.country === 'AUT') {
        immoObject.address.country = 'Österreich';
      }
      if (data.address.city !== '') {
        immoObject.address.city = data.address.city;
      }
      if (data.address.zip !== '') {
        immoObject.address.zip = data.address.zip;
      }
      if (data.address.street !== '') {
        immoObject.address.street = data.address.street;
      }
      if (data.address.house_number > 0) {
        immoObject.address.number = data.address.house_number;
      }
      if (data.address.door_number > 0) {
        immoObject.address.door = data.address.door_number;
      }
      if (data.address.floor > 0) {
        immoObject.address.floor = data.address.floor;
      }
    }

    // AREA
    if (data.area) {
      if (data.area.living > 0) {
        immoObject.areas.living_area = data.area.living;
      }
      if (data.area.surface > 0) {
        immoObject.areas.site_area = data.area.surface;
      }
      if (data.area.floor > 0) {
        immoObject.areas.floor_area = data.area.floor;
      }
      if (data.area.from > 0) {
        immoObject.areas.floor_area = data.area.from;
      }
      if (data.area.to > 0 && data.area.to !== data.area.from) {
        immoObject.areas.floor_area_to = data.area.to;
      }
    }

    // ROOMS
    if (data.rooms) {
      immoObject.rooms = data.rooms;
    }

    // PRICES
    if (data.price) {
      immoObject.prices = [];
      if (Object.keys(data.price).length > 0) {
        Object.entries(data.price).map((p) => {
          let key = p[0];
          let val = p[1];
          if (val) {
            if (val.gross && val.gross > 0) {
              immoObject.prices.push({
                key: key,
                label: priceLabels(key),
                value: parseCurrency(val.gross, true),
              });
            } else if (val && val.net && val.net > 0) {
              immoObject.prices.push({
                key: key,
                label: priceLabels(key),
                value: parseCurrency(val.net, true),
              });
            } else if (parseInt(val) > 0) {
              immoObject.prices.push({
                key: key,
                label: priceLabels(key),
                value: parseCurrency(val, true),
              });
            }
          }
        });
      }
    }
  }

  immoObject.url = parseImmoUrl(immoObject);
  return immoObject;
};

function parseImmoUrl(immo, useDisplay = false) {
  let url = '';
  if (immo.catalogue_number) {
    if (useDisplay) {
      if (immo.catalogue_number.display.indexOf('/') > 0) {
        url += immo.catalogue_number.display.replace('/', '-');
      } else {
        url += immo.tenant_id + '-' + immo.catalogue_number;
      }
    } else {
      if (immo.catalogue_number.indexOf('/') > 0) {
        url += immo.catalogue_number.replace('/', '-');
      } else {
        url += immo.tenant_id + '-' + immo.catalogue_number;
      }
    }

    if (immo.title) {
      let urlTitle = immo.title?.toLowerCase();

      urlTitle = urlTitle
        .replaceAll(/\u00e4/g, 'ae')
        .replaceAll(/\u00fc/g, 'ue')
        .replaceAll(/\u00f6/g, 'oe')
        .replaceAll('ß', 'ss')
        .replaceAll('!', '')
        .replaceAll('?', '')
        .replace(/[^\w\s!?]/g, '')
        .replaceAll(/ /g, '-');
      url += '/' + urlTitle;
    } else {
      url += '/immobilie';
    }

    if (immo.id > 0) {
      url += '/' + immo.id;
    }
  }
  return url;
}

export {
  parseImmo,
  getDefaultImmoObject,
  parseImmoSreal,
  getThumbnail,
  getMainArea,
  parseInfrastructureList,
  getKaufpreis,
  getKaufpreisNum,
  openSpaceLabels,
  getOpenSpaceSize,
  getWohneinheitOpenSpaceSize,
  parseImmoUrl,
};
