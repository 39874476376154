//*React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//*Components
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormSelectPhoneNumber from '../../FormFields/customFields/FormSelectPhoneNumber';
import LoadingSpinner from '../../LoadingSpinner';

//*Constants
import { REGFORM_SITEINDEX_INFO_MOBILNUMMER, REGFORM_SITEINDEX_START, REGFORM_SITEINDEX_SUCCESSTOKEN, REGFORM_SITEINDEX_USEREXISTS, REGFORM_SITEINDEX_LINKEXPIRED } from '../../../constants';
import { confirm, registerChangePhonenumber } from '../../../api/Onboarding';
import { setParsedPhoneNumberRegisterForm, setPhoneNumberPrefixRegisterForm, setPhoneNumberRegisterForm, setSiteIndexRegisterForm } from '../../../reducers/registerForm';
import InfoDialogPhoneNumber from '../../Dialogs/InfoDialogPhoneNumber';
import { validateFields } from '../../FormFields/util/validateFields';
import { combineReduxPhoneParts } from '../../../util/phoneNumberParser';
import { resetInitial } from '../../../reducers/registerFormChangePhoneNumber';
import { setFormIsLoading } from '../../../reducers/app';

function RegisterFormChangePhonenumber() {
  let registerForm = useSelector((state) => state.registerForm);
  let registerFormChangePhoneNumber = useSelector((state) => state.registerFormChangePhoneNumber);
  let app = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const [openInfoDialogMobilnummer, setOpenInfoDialogMobilnummer] = useState(false);
  const [oldPhoneNumber, setOldPhoneNumber] = useState('');
  const [error, setError] = useState('');

  let phoneNumber = registerForm?.requestBody?.phoneNumber;

  useEffect(() => {
    if (phoneNumber) {
      setOldPhoneNumber(phoneNumber);
    }
  }, []);

  const forwardAction = async () => {
    setError('');
    if (app.formIsLoading) {
      return;
    }

    const validatePhoneInputsCheck = validateFields({
      phone: `${registerFormChangePhoneNumber.phoneNumberPrefix.text} ${registerFormChangePhoneNumber.parsedPhoneNumber}`,
      phoneCheck: [
        `${registerFormChangePhoneNumber.phoneNumberPrefix.text} ${registerFormChangePhoneNumber.parsedPhoneNumber}`,
        `${registerFormChangePhoneNumber.phoneNumberPrefixCheck.text} ${registerFormChangePhoneNumber.parsedPhoneNumberCheck}`,
      ],
    }, dispatch);

    if (validatePhoneInputsCheck) {
      dispatch(setFormIsLoading(true));

      try {
        const combinedString = combineReduxPhoneParts(registerFormChangePhoneNumber.phoneNumberPrefix, registerFormChangePhoneNumber.parsedPhoneNumber);
        await registerChangePhonenumber(encodeURIComponent(combinedString));

        dispatch(setParsedPhoneNumberRegisterForm(combinedString));
        dispatch(setPhoneNumberPrefixRegisterForm(registerFormChangePhoneNumber.phoneNumberPrefix));
        dispatch(setPhoneNumberRegisterForm(registerFormChangePhoneNumber.parsedPhoneNumber.replace(/\s/g, '')));

        try {
		  const phoneNumber = await confirm({
            userId: registerForm.userId,
            code: registerForm.code,
          });

          localStorage.removeItem('tokenTimerStart');
          dispatch(resetInitial());
          dispatch(setFormIsLoading(false));
		  dispatch(setParsedPhoneNumberRegisterForm(phoneNumber));
          dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_SUCCESSTOKEN));
        } catch (e) {
          let errDetail = e.response?.data?.detail;
          dispatch(setFormIsLoading(false));
          if (errDetail === 'user-already-registered') {
            dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_USEREXISTS));
          } else if (errDetail === 'SMS_EXCEEDED') {
            dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_SUCCESSTOKEN));
          } else {
            dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_LINKEXPIRED));
          }
        }

        dispatch(setFormIsLoading(false));
        dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_SUCCESSTOKEN));
      } catch (e) {
        dispatch(setFormIsLoading(false));
        console.error(e);
      }
      return;
    } else {
      console.log('Validation failed');
      return;
    }
  };

  const handleSetOpenInfoDialogMobilnummer = () => {
    let val = !openInfoDialogMobilnummer;
    if (val) {
      window.location.hash = REGFORM_SITEINDEX_INFO_MOBILNUMMER;
    } else {
      window.location.hash = REGFORM_SITEINDEX_START;
    }
    setOpenInfoDialogMobilnummer(val);
  };

  return (
    <>
      <h2>Mobilnummer ändern</h2>
      <p className="pt-200rem pb-200rem">Sie haben Ihre Mobilnummer falsch eingegeben? Ändern Sie hier Ihre Nummer und fordern Sie den SMS-Code erneut an.</p>

      {phoneNumber && (
        <>
          <label htmlFor="mobilnummer">Aktuelle Mobilnummer</label>
          <br />
          <span className="d-flex font-120 font-weight-600 lh-200 mb-200rem">{oldPhoneNumber}</span>
        </>
      )}
      <FormSelectPhoneNumber formType={'registerFormChangePhoneNumber'} form={registerFormChangePhoneNumber} />

      <span className="textlink font-80 d-flex mb-150rem" onClick={handleSetOpenInfoDialogMobilnummer}>
        Deshalb benötigen wir Ihre Mobilnummer
      </span>
      <FormSelectPhoneNumber formType={'registerFormChangePhoneNumber'} form={registerFormChangePhoneNumber} hasCheck={true} />

      <LoadingSpinner />
      <div className="button-panel pt-100rem">
        <ButtonForwardAction formType={'registerFormChangePhoneNumber'} buttonText="Neue Mobilnummer bestätigen" forwardAction={forwardAction} />
      </div>

      {error && <p className="form__fielderror">{error}</p>}

      <InfoDialogPhoneNumber open={openInfoDialogMobilnummer} handleClose={handleSetOpenInfoDialogMobilnummer} />
    </>
  );
}

export default RegisterFormChangePhonenumber;
