import { useState, useEffect } from 'react';

function HeaderCtaButtons({ children }) {
  const [opacity, setOpacity] = useState(1);
  const defaultDisplay = 'inline-block';
  const [display, setDisplay] = useState(defaultDisplay);

	// INFO: auskommentiert weil Scrollhandler aktuell  nicht genutzt wird!!!
  // const handleCTAScroll = (event) => {
  //   let scrollY = window.scrollY;
  //   if (scrollY > 490) {
  //     let newOpacity = opacity - (scrollY - 10) / 800;
  //     if (newOpacity >= 0 && newOpacity <= 100) {
  //       setOpacity(newOpacity);
  //       setDisplay(defaultDisplay);
  //     } else if (newOpacity <= 0) {
  //       setDisplay('none');
  //     }
  //   } else if (scrollY > 100) {
  //     setOpacity(1);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleCTAScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleCTAScroll);
  //   };
  // }, []);

  return (
    <div
      className="header__ctabuttons"
      key="headerCtabuttons"
      style={{ opacity: opacity, display: display }}
    >
      {children}
    </div>
  );
}
export default HeaderCtaButtons;
