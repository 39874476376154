import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ContactFormCard from '../components/Cards/ContactFormCard';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

// ICONS
import IconPlusCircle2 from '../components/Icons/IconPlusCircle2';
import IconQuestionBubble from '../components/Icons/IconQuestionBubble';

import { useSelector } from 'react-redux';
import Card from '../components/Cards/Card';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';

function FaqItem({ question, children }) {
  return (
    <Accordion className="accordion accordion-faq">
      <AccordionSummary expandIcon={<IconPlusCircle2 color="#2870ED" />}>
        <h2>{question}</h2>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

function FAQ() {
  const app = useSelector((state) => state.app);

  const { isMobile } = useHandleScreenResize();

  return (
    <>
      <MetaTags title={'FAQs | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon icon={<IconQuestionBubble />} iconActive={true} headlineDesktop="FAQs" />
      <MainContent cssClasses="w-desktop--center faq">
        <h1 className="headline--mobile">FAQs</h1>

        <FaqItem question="Warum ist eine 2-Faktor-Authentifizierung für den Login notwendig?">
          <p>
            Bei my-sreal.at nehmen wir den Schutz unserer Kund:innen sehr ernst, insbesondere wenn es um den Vermittlungsprozess von Immobilien und den Austausch von sensiblen Daten geht. Um
            sicherzustellen, dass Ihre Daten bei uns sicher aufbewahrt sind, verwenden wir eine 2-Faktor-Authentifizierung für den Login. Dies dient dazu, potenzielle Angriffsflächen für Cyberangriffe
            zu minimieren und Ihre Privatsphäre zu schützen.
          </p>
        </FaqItem>

        <FaqItem question="Kann ich mich darauf verlassen, dass meine Daten sicher in der immo-drive Datenablage aufbewahrt sind?">
          <p>
            Absolut! Wir bei my-sreal.at legen großen Wert auf die Sicherheit Ihrer Daten und haben deshalb Maßnahmen ergriffen, um sicherzustellen, dass Ihre Dokumente bei uns in guten Händen sind.
            Dazu gehören unter anderem Server im Inland und eine 2-Faktor-Authentifizierung beim Login. Wir möchten, dass Sie sich auf unsere Datensicherheit verlassen können und versichern Ihnen,
            dass wir vertrauensvoll mit Ihren Dokumenten umgehen.
          </p>
        </FaqItem>

        <FaqItem question="Wird meine gesendete Nachricht über die Nachrichtenfunktion sofort von der Makler:in gesehen?">
          <p>
            Unsere Makler:innen haben die Möglichkeit, Ihre gesendeten Nachrichten am Computer abzurufen und direkt darauf zu reagieren. Bitte haben Sie jedoch Verständnis dafür, dass sich unsere
            Makler:innen auch auf Kundenterminen befinden und daher nicht immer sofort auf Ihre Nachricht antworten können. Wir möchten jedoch betonen, dass wir uns bemühen, so schnell wie möglich auf
            alle Anliegen unserer Kund:innen zu reagieren. Wenn Sie also eine dringende Frage oder ein Anliegen haben, zögern Sie bitte nicht, uns zu kontaktieren. Wir sind für Sie da!
          </p>
        </FaqItem>

        <FaqItem question="Warum bekomme ich keine Antwort auf meine Nachricht von der Makler:in?">
          <p>
            Es kann verschiedene Gründe dafür geben, warum Sie momentan keine Antwort von Ihrer Makler:in erhalten. Vielleicht befindet sie sich gerade in einem Kundengespräch oder ist mit einer
            Interessent:in für Ihre Immobilie beschäftigt. Es kann aber auch sein, dass sie gerade keine Zeit hat, weil sie, wie wir alle, ein Privatleben hat. Wir möchten Ihnen versichern, dass
            unsere Makler:innen stets bemüht sind, auf alle Kundenanfragen so schnell wie möglich zu reagieren. Bitte haben Sie noch etwas Geduld und seien Sie versichert, dass Sie bald eine
            Rückmeldung erhalten werden. Wir danken Ihnen für Ihr Verständnis!
          </p>
        </FaqItem>
        <FaqItem question="Wer kann mein Dashboard der Vermarktungsaktivitäten einsehen?">
          <p>
            Ihr Verkäuferdashboard ist nur für Sie und Ihre Makler:in zugänglich. Dies bedeutet, dass Interessent:innen nicht in der Lage sind, die Nachfragesituation für Ihre Immobilie abzulesen. Wir
            möchten sicherstellen, dass Ihre Privatsphäre und die Vertraulichkeit Ihrer Verkaufsaktivitäten geschützt werden. Wenn Sie Fragen zu Ihrem Verkäuferdashboard haben oder Unterstützung bei
            der Nutzung benötigen, zögern Sie bitte nicht, uns zu kontaktieren. Wir helfen Ihnen gerne weiter!
          </p>
        </FaqItem>
        <FaqItem question="Welche Immobilien bekomme ich bei einem Suchprofil angeboten?">
          <p>
            Die Auswahl der Immobilien, die Sie bei Ihrem Suchprofil angeboten bekommen, hängt von Ihren individuellen Suchkriterien ab. Sobald Sie Ihre Wünsche und Anforderungen angegeben haben,
            durchsucht unser System automatisch unser Portfolio und sendet Ihnen passende Angebote zu. Unsere langjährige Erfahrung hat gezeigt, dass manchmal auch Immobilien, die nicht alle
            Suchkriterien zu 100% erfüllen, dennoch für Sie interessant sein können. Aus diesem Grund senden wir Ihnen auch Angebote, von denen wir glauben, dass sie für Sie relevant sein könnten.
            Wenn Sie weitere Fragen zu Ihrem Suchprofil oder den von uns angebotenen Immobilien haben, stehen wir Ihnen gerne zur Verfügung.
          </p>
        </FaqItem>

        <FaqItem question="Ist es möglich, meine Immobilie auch ohne my-sreal.at zu vermarkten?">
          <p>
            my-sreal.at ist ein optionales digitales Serviceangebot, das unseren Kund:innen zusätzlich zur Verfügung steht. Wir verstehen, dass einige Menschen die herkömmliche Art der
            Immobilienvermittlung bevorzugen und deshalb die Online-Plattform nicht nutzen möchten. Keine Sorge, Sie können immer noch alle notwendigen Informationen und Unterstützung von Ihrer
            Makler:in erhalten, ohne my-sreal.at nutzen zu müssen. Wenn Sie jedoch zusätzliche Vorteile durch unsere Online-Plattform genießen möchten, helfen wir Ihnen gerne weiter und beantworten
            Ihnen gerne alle Fragen. Zögern Sie nicht, uns zu kontaktieren!
          </p>
        </FaqItem>

        <div>
          <h2 className="mt-300rem desktop-hidden">Sie haben eine allgemeine Frage?</h2>
          <ContactFormCard isFAQ={true} cssClasses="p-150rem" />
        </div>
      </MainContent>
    </>
  );
}

export default FAQ;
