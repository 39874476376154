import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import headerImage from '../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.1.jpg';

import CardGridPlaceholderElement from '../components/Cards/CardGridPlaceholderElement';
import ImmoCard from '../components/Cards/ImmoCard';
import PageDialogSearchProfileForm from '../components/Dialogs/PageDialogSearchProfileForm';
import Header from '../components/Header/Header';
import SearchProfilCreateLinkitem from '../components/Linkitems/SearchProfilCreateLinkitem';
import LoadingSpinner from '../components/LoadingSpinner';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import { neubauprojekteSearchNoResultsText } from '../components/FormFields/util/formTexts';
import { parseImmoSreal } from '../util/immoObjectParser';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchImmoSearch } from '../hooks/immo/useFetchImmoSearch';
import { setCachedNeubauList } from '../reducers/searchForm';
import SearchProfilFixedBar from '../components/fixedbar/SearchProfilFixedBar';

function Immosuche() {
  let currentSearchForm = useSelector((state) => state.searchForm);
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { setResidentialProjectsList, handleShowMoreImmos, error } = useFetchImmoSearch();

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    const getNeubauProjekte = async () => {
      await setResidentialProjectsList(true);
    };

    // first time visiting (no cached realties) - do search
    getNeubauProjekte();
  }, []);

  return (
    <>
      <MetaTags
        title={'Immobilien suchen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={
          'Sie sind auf der Suche, denn Sie wollen eine Wohnung kaufen oder ein Haus kaufen? my-sreal.at ist die Online-Plattform für Ihren Immobilienkauf. Einfach, transparent und digital suchen, finden und kaufen.'
        }
        imageName={'mysreal_hero_immosuche.jpg'}
      />

      {!isMobile ? (
        <Header cssClasses="header--default header--withimage mb-300rem">
          <img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
          <div className="header__content-wrapper header__content-wrapper--top">
            <h1 className="text-white mt-500rem mb-0">Neubauprojekte</h1>
          </div>
        </Header>
      ) : (
        <Header cssClasses="header--immosuche header--immosuche-mobil">
          <div className="header__content-wrapper header__content-wrapper--top">
            <h1>Neubauprojekte</h1>
          </div>
        </Header>
      )}
      <MainContent cssClasses="main-content--immosuche">
        <div className="w-desktop--center-residential">
					<SearchProfilFixedBar />

          <div className="pb-100rem" id="immoresult-list">
            {error ? (
              <>
                <h2 className="mb-200rem">Ergebnisse (0)</h2>
                <p>{error}</p>
              </>
            ) : (
              <>
								<h2>Ergebnisse ({currentSearchForm.cachedNeubauListCount})</h2>
								
                {currentSearchForm?.cachedNeubauList?.length > 0 ? (
                  <>
                    <div className="cardgrid mt-300rem">
                      {currentSearchForm.cachedNeubauList.map((immo) => {
                          return <ImmoCard wohneinheiten={immo.wohneinheiten} retailObject={parseImmoSreal(immo)} key={immo.id} />;
                        })
											}

                      <CardGridPlaceholderElement array={currentSearchForm.cachedNeubauList} />
                    </div>

                    {currentSearchForm.cachedNeubauListCount > currentSearchForm.cachedNeubauList.length && (
                      <div className="d-flex justify-content-center">
                        {app.formIsLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <span className="textlink" onClick={handleShowMoreImmos}>
                            Weitere Ergebnisse laden
                          </span>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <p>{neubauprojekteSearchNoResultsText}</p>
                )}
              </>
            )}
          </div>
        </div>
      </MainContent>
      <PageDialogSearchProfileForm></PageDialogSearchProfileForm>
    </>
  );
}

export default Immosuche;
