import React, { useEffect, useState, useMemo } from 'react';
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

import moment from 'moment';
import '../../styles/statistics.scss';

const CustomizedAxisTick = (props) => {
  const { x, y, payload, dashboardVersion } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill={!dashboardVersion ? '#666' : '#fff'} transform="rotate(-45)">
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ dashboardVersion, active, payload, label }) => {
  if (active && payload && payload.length) {
    const item = payload[0].payload;
    const currentDate = moment(item.fullDate);
    const formattedDate = currentDate.format('DD.MM.YYYY');
    const clickText = item.Klicks === 1 ? 'Klick' : 'Klicks';
    return !dashboardVersion ? (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          border: '1px solid #000',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <p className="label">{`${formattedDate} : ${item.Klicks} ${clickText}`}</p>
      </div>
    ) : (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          border: '1px solid #000',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <p className="label" style={{ color: '#1e3163' }}>{`${formattedDate} : ${item.Klicks} ${clickText}`}</p>
      </div>
    );
  }
  return null;
};

const LineChartNew = ({ chartData, dataError, dashboardVersion = false }) => {
  const [chartState, setChartState] = useState({
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    top: 'dataMax',
    animation: true,
  });
  const { left, right, top } = chartState;
  const [currentlyHovered, setCurrentlyHovered] = useState(null);
  const memoizedData = useMemo(() => chartData, [chartData]);
  const { isMobile } = useHandleScreenResize();

  const handleOnMouseMove = (o) => {
    if (o.activePayload) {
      setCurrentlyHovered({
        Klicks: o.activePayload[0].value,
        fullDate: o.activePayload[0].payload.fullDate,
      });
    }
  };

  const handleOnMouseLeave = (o) => {
    setCurrentlyHovered(null);
  };

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      setChartState((prevState) => ({
        ...prevState,
      }));
    }
  }, [chartData]);

  if (dataError) {
    return <p className='m-0'>Die Daten konnten nicht geladen werden.</p>;
  }

  if (!chartData || chartData.length === 0) {
    return <p className='m-0'>Daten werden geladen...</p>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between pr-150rem pl-150rem chartinfo-container">
        {isMobile && (
          <span className="date-length-label text-bold">
            {moment(chartData[0].fullDate).format('LL')} - {moment(chartData[chartData.length - 1].fullDate).format('ll')}
          </span>
        )}
        <div className="h-2rem ">
          {currentlyHovered && (
            <div className={`${dashboardVersion && 'chartinfo__inverted'}`}>
              Klicks: {currentlyHovered.Klicks}
              <br />
              {moment(currentlyHovered.fullDate).format('LL')}
            </div>
          )}
        </div>
        {!isMobile && (
          <span className="date-length-label text-bold">
            {moment(chartData[0].fullDate).format('LL')} - {moment(chartData[chartData.length - 1].fullDate).format('LL')}
          </span>
        )}
      </div>

      <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%' }}>
        <ResponsiveContainer width="100%" height={!isMobile && !dashboardVersion ? 400 : 250}>
          <AreaChart
            height={!dashboardVersion ? 400 : 250}
            margin={{ left: !isMobile ? 50 : 30, bottom: 10, right: -20 }}
            data={memoizedData}
            onMouseMove={handleOnMouseMove}
            onMouseLeave={handleOnMouseLeave}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              allowDataOverflow
              dataKey="name"
              domain={[left, right]}
              type="category"
              interval={'equidistantPreserveStart'}
              minTickGap={40}
              height={60}
              tick={<CustomizedAxisTick dashboardVersion={dashboardVersion} />}
            />
            <YAxis
              allowDataOverflow
              domain={[0, top]}
              orientation="right"
              type="number"
              yAxisId="1"
              tickCount={8}
              tick={{ fill: !dashboardVersion ? '#666' : '#fff' }}
              tickLine={{ stroke: !dashboardVersion ? '#666' : '#fff' }}
            />
            <Tooltip content={<CustomTooltip dashboardVersion={dashboardVersion} />} />
            <Area yAxisId="1" dot={false} fillOpacity={0.25} dataKey="Klicks" stroke={!dashboardVersion ? '#2870ED' : '#fff'} animationDuration={300} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default LineChartNew;
