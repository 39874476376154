import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// FORM FIELDS
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import InfoDialogZustandImmo from '../../Dialogs/InfoDialogZustandImmo';
import FormInput from '../../FormFields/FormInput';
import FormSelect from '../../FormFields/FormSelect';
import SatisfactionScale from '../../FormFields/SatisfactionScale';
import InfoMark from '../../InfoMark';

import { immobilienartOptions } from '../../FormFields/util/formOptions';

// STORE
import { setConditionContactForm, setSiteIndexContactForm } from '../../../reducers/contactInformation';

import {
  CONDITION_NEW_OR_RECENTLY_RENOVATED,
  CONTACTFORM_SITEINDEX_IHREIMMOBILIE,
  CONDITION_RENOVATION_NEEDED,
  CONDITION_WELL_MAINTAINED,
  CONTACTFORM_SITEINDEX_INFO_BUNDESLAND,
  CONTACTFORM_SITEINDEX_KONTAKTIEREN,
  CONTACTFORM_SITEINDEX_TERMINWUNSCH,
} from '../../../constants';
import InfoDialogBundesland from '../../Dialogs/InfoDialogBundesland';
import statesWithZips from '../../FormFields/util/statesWithZips';
import { validateFields } from '../../FormFields/util/validateFields';

function ContactIhreImmobilie() {
  let currentContactInformation = useSelector((state) => state.contactInformation);
  let currentFormValidators = useSelector((state) => state.formValidators);
  const user = useSelector((state) => state.user);

  const [openInfoDialogZustandImmo, setOpenInfoDialogZustandImmo] = useState(false);
  const [openInfoDialogBundesland, setOpenInfoDialogBundesland] = useState(false);

  const dispatch = useDispatch();

  const handleSetOpenInfoDialogZustandImmo = () => {
    setOpenInfoDialogZustandImmo(!openInfoDialogZustandImmo);
  };

  const handleSetOpenInfoDialogBundesland = () => {
    let val = !openInfoDialogBundesland;
    if (val) {
      window.location.hash = CONTACTFORM_SITEINDEX_INFO_BUNDESLAND;
    } else {
      window.location.hash = CONTACTFORM_SITEINDEX_IHREIMMOBILIE;
    }
    setOpenInfoDialogBundesland(val);
  };

  const forwardAction = () => {
    let conditionVal = '';
    switch (currentContactInformation.requestBody.condition) {
      case 1:
        conditionVal = CONDITION_RENOVATION_NEEDED;
        break;
      case 2:
        conditionVal = CONDITION_WELL_MAINTAINED;
        break;
      case 3:
        conditionVal = CONDITION_NEW_OR_RECENTLY_RENOVATED;
        break;
      default:
        break;
    }

    if (conditionVal !== '') {
      dispatch(setConditionContactForm(conditionVal));
    }

    let validationResult = validateFields({ federalState: currentContactInformation.stateObject.id }, dispatch);
    if (validationResult) {
      dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_KONTAKTIEREN));
    }
  };

  const skipAction = () => {
    let validationResult = validateFields({ federalState: currentContactInformation.stateObject.id }, dispatch);
    if (validationResult) {
      dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_KONTAKTIEREN));
    }
  };

  const backAction = (e) => {
    e.preventDefault();
    dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_TERMINWUNSCH));
  };

  const formType = 'contactInformation';

  const federalStateOptions = statesWithZips.map((state) => {
    return {
      id: state.justImmoId,
      text: state.label,
    };
  });

  return (
    <>
      <h3 className="form__title">Ihre Immobilie (optional)</h3>
      <FormSelect
        label={'Bundesland'}
        placeholder="Bitte wählen"
        value={currentContactInformation.stateObject}
        error={currentFormValidators.federalStateError}
        formType={formType}
        required={true}
        id={'inputState'}
        options={federalStateOptions}
      />
      <span className="textlink font-80 mb-20" onClick={handleSetOpenInfoDialogBundesland}>
        Welches Bundesland wird benötigt?
      </span>

      <div>
        <FormInput
          id={'inputPostalCode'}
          label={'Postleitzahl'}
          hasList={true}
          value={currentContactInformation.displayedDistrictName}
          formType={formType}
          disabled={currentContactInformation.requestBody.state ? false : true}
          hasLockIcon={currentContactInformation.requestBody.state ? false : true}
          placeholder={currentContactInformation.requestBody.state ? 'Postleitzahl' : 'Bitte erst Bundesland auswählen'}
          error={currentFormValidators.districtError}
        />
      </div>

      <FormSelect label={'Immobilienart'} placeholder="Immobilienart" value={currentContactInformation.typeObject} formType={formType} id={'inputType'} options={immobilienartOptions} />

      <FormInput
        type={'number'}
        minValue={0}
        id={'inputArea'}
        label={'Fläche in m²'}
        value={currentContactInformation.requestBody.area}
        placeholder={'Wert eingeben'}
        required={false}
        formType={formType}
      />

      <div className="mt-200rem mb-200rem d-flex flex-row align-items-center">
        <span className="pr-50rem">Zustand der Immobilie</span>
        <InfoMark handleClick={handleSetOpenInfoDialogZustandImmo} character={'?'} />
      </div>

      <SatisfactionScale detailsColor="#fff" formType={formType} selectedValue={currentContactInformation.requestBody.condition} />

      <div className="button-panel align-items-center">
        <button className="button button--gray-outline" onClick={backAction}>
          Zurück
        </button>
        <ButtonForwardAction formType={formType} forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
      </div>

      <InfoDialogZustandImmo handleClose={handleSetOpenInfoDialogZustandImmo} open={openInfoDialogZustandImmo} />

      <InfoDialogBundesland open={openInfoDialogBundesland} handleClose={handleSetOpenInfoDialogBundesland} />
    </>
  );
}

export default ContactIhreImmobilie;
