import React from 'react';
import ImmoFeatures from '../components/Immo/ImmoFeatures';
import Table from '../components/Table';
import ContactAgentCard from '../components/Cards/ContactAgentCard';

const ImmoDetailPrint = React.forwardRef((props, ref) => {
  const { immo } = props;
  const parseHTML = require('html-react-parser');
  
  return (
    <div id="immodetailprint" className='printview' ref={ref}> 
      <div className="immodetail__headline">
        <h1 className="mb-0">{immo.title}</h1>

        <ul>
          <li>{immo.catalogue_number}</li>
          <li>{immo.address.zip} {immo.address.city}</li>
        </ul>
      </div>

      {immo.imagesPublic.length > 0 && <img className='immodetail__cover' src={immo.imagesPublic[0].url} alt={immo.title} />}

      <ImmoFeatures immo={immo} showKaufpreis={true} />

      {immo.prices && immo.prices.length > 0 && (
        <div>
          <h2>Preise</h2>
          <Table list={immo.prices} />
        </div>
      )}

      {immo.featureList[0] && immo.featureList[0].length > 0 ? (
        <>
          <h2>Merkmale</h2>
          <div className="immodetail__table-wrapper">
            <div className="immodetail__table">
              <Table list={immo.featureList[0]} htmlParser={true} />
            </div>

            {immo.featureList[1] && immo.featureList[1].length > 0 && (
              <div className="immodetail__table">
                <Table list={immo.featureList[1]} htmlParser={true} />
              </div>
            )}
          </div>
        </>
      ) : null}

      
      <div className="print--spacer"></div>
      <ContactAgentCard agent={immo.agent}  />

      <div className="immodetail__desc">
        <h2 className='pt-200rem'>Objektbeschreibung</h2>
        <strong className='pb-50rem'>{immo.catalogue_number}</strong>
        <div>{parseHTML(immo.description)}</div>


        <h2 className='pt-200rem'>Lagebeschreibung</h2>
        <p>{immo.address.zip} {immo.address.city}</p>
      </div>
    </div>

  );
});


export default ImmoDetailPrint;