import { put, takeEvery } from 'redux-saga/effects';
import { increment, incrementByAmount } from './reducers/dummy';

// could also be some function that fetches things and calls put on success...
// don't do multiple puts in a loop; this is a bad example
function* storePlusTen() {
  for (let i = 0; i < 10; i++) {
    yield put({ type: increment });
  }
}

function* storePlusHundred() {
  yield put({ type: incrementByAmount, payload: 100 });
}

// can have multiple yields/functions
function* mySaga() {
  yield takeEvery('STORE_PLUS_TEN', storePlusTen);
  yield takeEvery('STORE_PLUS_HUNDRED', storePlusHundred);
}

export default mySaga;
