function IconCalculator({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="51.049"
      height="64.486"
      viewBox="0 0 51.049 64.486"
    >
      <g data-name="Gruppe 9599" transform="translate(0 0.373)">
        <g data-name="Gruppe 10110" transform="translate(0)">
          <path
            data-name="Pfad 7498"
            d="M42.988,0H8.06A8.068,8.068,0,0,0,0,8.06V21.5H51.049V8.06A8.068,8.068,0,0,0,42.988,0m2.687,13.719a3.752,3.752,0,0,1-3.748,3.745H9.122a3.751,3.751,0,0,1-3.748-3.745V7.773A3.75,3.75,0,0,1,9.122,4.03H41.927a3.752,3.752,0,0,1,3.748,3.743Z"
            transform="translate(0 -0.374)"
            fill={color}
          />
          <path
            data-name="Pfad 7499"
            d="M24.181,9H0V25.122H24.181Zm-5.374,9.4h-4.03v4.03H12.09V18.4H8.06V15.718h4.03v-4.03h2.687v4.03h4.03Z"
            transform="translate(0 14.809)"
            fill={color}
          />
          <path
            data-name="Pfad 7500"
            d="M26.868,36.152H24.181V16H0V29.435A8.07,8.07,0,0,0,8.06,37.5H42.988a8.07,8.07,0,0,0,8.06-8.06V16H26.868Zm-9.194-6.717-.954.946-3.272-3.3-3.3,3.31-1.9-1.9,3.313-3.313L8.238,21.821l1.908-1.889,3.31,3.337L16.725,20l.948.948.951.946-3.278,3.281,3.281,3.31Zm14.568-8.06H42.988v2.687H32.241Zm0,5.374H42.988v2.687H32.241Z"
            transform="translate(0 26.616)"
            fill={color}
          />
          <path
            data-name="Pfad 7501"
            d="M10,25.122H34.181V9H10Zm5.374-8.06H26.121v2.687H15.374Z"
            transform="translate(16.868 14.809)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconCalculator;
