function IconSocialYT({ color, cssClasses }) {
  return (
    <svg
      data-name="Ebene 2"
      xmlns="http://www.w3.org/2000/svg"
      width="25.76"
      height="18.15"
      viewBox="0 0 25.76 18.15"
    >
      <defs>
        <style></style>
      </defs>
      <g data-name="OBJECTS">
        <path
          className="d"
          fill={color}
          d="M25.23,2.83c-.3-1.12-1.17-1.99-2.28-2.29-2.01-.54-10.07-.54-10.07-.54,0,0-8.06,0-10.07,.54C1.71,.84,.83,1.72,.54,2.83c-.54,2.02-.54,6.24-.54,6.24,0,0,0,4.22,.54,6.24,.3,1.12,1.17,1.99,2.28,2.29,2.01,.54,10.07,.54,10.07,.54,0,0,8.06,0,10.07-.54,1.11-.3,1.98-1.18,2.28-2.29,.54-2.02,.54-6.24,.54-6.24,0,0,0-4.22-.54-6.24ZM10.25,12.91V5.25l6.73,3.83-6.73,3.83Z"
        />
      </g>
    </svg>
  );
}

export default IconSocialYT;
