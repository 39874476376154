import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import InfoDialog2FAuth from '../../Dialogs/InfoDialog2FAuth';
import FormInput from '../../FormFields/FormInput';
import LoadingSpinner from '../../LoadingSpinner';

import {
  DIREKTREGFORM_SITEINDEX_INFO_TOKEN,
  DIREKTREGFORM_SITEINDEX_LOGINSUCCESS,
  DIREKTREGFORM_SITEINDEX_PW,
  DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN,
  REGFORM_SITEINDEX_CONFIRMERROR,
  REGFORM_SITEINDEX_EMAIL,
  REGFORM_SITEINDEX_INFO_PWFORGETTOKEN,
  REGFORM_SITEINDEX_INFO_TOKEN,
  REGFORM_SITEINDEX_PW,
  REGFORM_SITEINDEX_PWSUCCESS,
  REGFORM_SITEINDEX_START,
} from '../../../constants';
import { directRegPending, fetchErrorText, registerUsernameAlreadyExists } from '../../FormFields/util/formTexts';
import { confirmPassword, validateFields } from '../../FormFields/util/validateFields';

// STORE
import { setFormIsLoading } from '../../../reducers/app';
import { setSiteIndexRegisterForm } from '../../../reducers/registerForm';

// API
import { forgot_password, register, directRegisterConfirm } from '../../../api/Onboarding';

function FormSetPassword({ handleClose }) {
  let registerForm = useSelector((state) => state.registerForm);
  let currentFormValidators = useSelector((state) => state.formValidators);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [error, setError] = useState('');
  const [userId, setUserId] = useState('');
  const [code, setCode] = useState('');

  const formType = 'registerForm';

  const [isDirektReg, setIsDirektReg] = useState(false);

  useEffect(() => {
    let userId = searchParams.get('UserId');
    let code = searchParams.get('Code');

    if (registerForm.userId !== '') {
      userId = registerForm.userId;
    }
    if (registerForm.code !== '') {
      code = registerForm.code;
    }

    setUserId(userId);
    setCode(code);

    if (location.pathname.indexOf('/direct-registration-confirm') >= 0 && userId && code) {
      setIsDirektReg(true);
    }

    if (location.pathname.indexOf('/set_password') >= 0) {
      searchParams.delete('UserId');
      searchParams.delete('Code');
      setSearchParams(searchParams);
    }
  }, []);

  const [openInfoDialog2FAuth, setOpenInfoDialog2FAuth] = useState(false);

  const handleSetOpenInfoDialog2FAuth = () => {
    let val = !openInfoDialog2FAuth;

    if (location.pathname.indexOf('/direct-registration-confirm') >= 0) {
      if (val) {
        window.location.hash = DIREKTREGFORM_SITEINDEX_INFO_TOKEN;
      } else {
        window.location.hash = DIREKTREGFORM_SITEINDEX_PW;
      }
    } else {
      if (val) {
        window.location.hash = REGFORM_SITEINDEX_INFO_TOKEN;
      } else {
        window.location.hash = REGFORM_SITEINDEX_PW;
      }
    }
    setOpenInfoDialog2FAuth(val);
  };

  const backAction = () => {
    dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_START));
  };

  const forwardAction = () => {
    setError('');

    if (app.formIsLoading) {
      return;
    }

    // password
    let validationResult = validateFields(
      {
        password: registerForm.requestBody.password,
        passwordConfirm: registerForm.passwordConfirm,
      },
      dispatch,
    );

    if (validationResult) {
      validationResult = confirmPassword(registerForm.requestBody.password, registerForm.passwordConfirm, dispatch);
    }

    if (validationResult) {
      dispatch(setFormIsLoading(true));

      if (location.pathname.indexOf('set_password') >= 0) {
        if (userId && code) {
          forwardActionForgotPassword(userId, code, registerForm.requestBody.password);
        } else {
          dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_CONFIRMERROR));
        }
      } else if (location.pathname.indexOf('/direct-registration-confirm') >= 0) {
        if (userId && code) {
          directRegisterConfirmCall(userId, code);
        } else {
          dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_CONFIRMERROR));
        }
      } else {
        forwardActionRegister();
      }
    }
  };

  const forwardActionForgotPassword = async (userId, code, password) => {
    try {
      await forgot_password({
        password: password,
        userId: userId,
        hash: code,
      });
      dispatch(setFormIsLoading(false));
      navigate('../' + app.root + '/');
      dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_PWSUCCESS));
    } catch (e) {
      dispatch(setFormIsLoading(false));
      setError(fetchErrorText);
    }
  };

  const directRegisterConfirmCall = async (userId, code) => {
    try {
      await directRegisterConfirm({
        userId: userId,
        code: code,
        mode: 0,
        registerDto: registerForm.requestBody,
      });

      dispatch(setFormIsLoading(false));
      navigate('../' + app.root + '/');
      dispatch(setSiteIndexRegisterForm(DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN));
    } catch (e) {
      dispatch(setFormIsLoading(false));
      setError(fetchErrorText);
    }
  };

  const forwardActionRegister = async () => {
    try {
      await register(registerForm.requestBody);
      dispatch(setFormIsLoading(false));
      dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_EMAIL));
      localStorage.removeItem('tokenTimerStart');
    } catch (e) {
      dispatch(setFormIsLoading(false));
      let errDetail = e.response?.data?.detail;

      if (errDetail === 'DuplicateUserName') {
        setError(registerUsernameAlreadyExists);
      } else if (errDetail === 'direct_registration_pending') {
        setError(directRegPending);
      } else {
        setError(fetchErrorText);
      }
    }
  };

  return (
    <>
      {location.pathname.indexOf('set_password') >= 0 ? <h2 className="mb-200rem">Legen Sie ein neues Passwort fest</h2> : <h2 className="mb-200rem">Legen Sie Ihr persönliches Passwort fest</h2>}

      <FormInput type={'password'} id={'inputPassword'} label={'Passwort'} value={registerForm.requestBody.password} error={currentFormValidators.passwordError} required={true} formType={formType} />

      <span className="form--info">Mindestens 8 Zeichen, 1 Klein- und Großbuchstabe, 1 Ziffer und 1 Sonderzeichen</span>

      <FormInput
        type={'password'}
        id={'inputPasswordConfirm'}
        label={'Passwort wiederholen'}
        value={registerForm.passwordConfirm}
        error={currentFormValidators.passwordConfirmError}
        required={true}
        formType={formType}
      />

      <p className="mt-200rem form--info mb-0">
        Im nächsten Schritt bestätigen Sie Ihr neues Passwort mit der 2-Faktor-Authentifizierung.
        <span className="textlink w-100 d-flex mt-50rem" onClick={handleSetOpenInfoDialog2FAuth}>
          Wozu dient die 2-Faktor-Authentifizierung?
        </span>
      </p>

      <LoadingSpinner />

      <div className="button-panel pt-100rem">
        {location.pathname.indexOf('set_password') >= 0 ? (
          <ButtonForwardAction formType={formType} buttonText="Passwort zurücksetzen" forwardAction={forwardAction} />
        ) : (
          <>
            <button className="button button--gray-outline" onClick={backAction}>
              Zurück
            </button>
            <ButtonForwardAction formType={formType} buttonText={isDirektReg ? 'SMS-Code anfordern' : 'E-Mail anfordern'} forwardAction={forwardAction} />
          </>
        )}
      </div>

      {error && <p className="form__fielderror">{error}</p>}

      <InfoDialog2FAuth open={openInfoDialog2FAuth} handleClose={handleSetOpenInfoDialog2FAuth} />
    </>
  );
}

export default FormSetPassword;
