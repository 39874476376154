import MainContent from '../MainContent';
import Header from '../Header/Header';

// REACT LOADING SKELETON
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function NewsDetailSkeleton() {
  const skeletonColor = '#e4e4e4';
  return (
    <>
      <Header />
      <div className="mobile-hidden">
        <div className="mt-30">
          <Skeleton baseColor={skeletonColor} height={430} />
        </div>

        <MainContent cssClasses="w-desktop--center newsdetail">
          <div className="header--news__links">
            <span>
              <Skeleton baseColor={skeletonColor} width={80} height={20} />
            </span>
            <span>
              <Skeleton baseColor={skeletonColor} width={150} height={20} />
            </span>
          </div>

          <h1 className="mb-100rem">
            <Skeleton baseColor={skeletonColor} width={'80%'} height={30} />
          </h1>
          <Skeleton baseColor={skeletonColor} width={150} height={15} />
          <Skeleton baseColor={skeletonColor} width={'50%'} height={15} />

          <p className="lh-140 mt-200rem">
            <Skeleton count={5} baseColor={skeletonColor} height={20} />
          </p>
        </MainContent>
      </div>

      <div className="mt-20">
        <Skeleton baseColor={skeletonColor} height={510} />
      </div>

      <MainContent cssClasses="w-desktop--center newsdetail">
        <h1 className="mb-100rem">
          <Skeleton count={2} baseColor={skeletonColor} width={'85%'} height={30} />
        </h1>
        <Skeleton baseColor={skeletonColor} width={150} height={15} />
        <Skeleton baseColor={skeletonColor} width={'75%'} height={15} />

        <p className="lh-140 mt-200rem">
          <Skeleton count={5} baseColor={skeletonColor} height={20} />
        </p>
      </MainContent>
    </>
  );
}

export default NewsDetailSkeleton;
