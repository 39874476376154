import IconEmailSent from '../../Icons/IconEmailSent';
import { motion } from 'framer-motion';
import '../../../styles/animations.scss';

function FormEmailSent({ handleClose }) {
  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <svg xmlns="http://www.w3.org/2000/svg" width="120.792" height="161" viewBox="0 0 120.792 161">
          <g data-name="Gruppe 16020" transform="translate(0 31)">
            <motion.path
              id="Pfad_10377"
              data-name="Pfad 10377"
              d="M36.518,63.646c.271.271.542.542.813.542a.265.265,0,0,1,.271.271c.271,0,.271.271.542.271s.271,0,.542.271h2.167c.271,0,.271,0,.542-.271.271,0,.271-.271.542-.271a.265.265,0,0,0,.271-.271c.271-.271.542-.271.542-.542L66.039,40.354a4.6,4.6,0,0,0-6.5-6.5L44.372,49.021V4.6a4.6,4.6,0,1,0-9.208,0V49.021L20,33.854a4.423,4.423,0,0,0-6.5,0,4.423,4.423,0,0,0,0,6.5Z"
              transform="translate(20.628 -31)"
              fill="#0b1f42"
              initial={{ x: [21] }}
              animate={{ y: [-30, 0, -30, 0, -30, 0, -30] }}
              transition={{ repeat: Infinity, duration: 4 }}
            />
            <path
              data-name="Pfad 10378"
              d="M120.792,29.95V27.512A13.871,13.871,0,0,0,106.979,13.7a4.746,4.746,0,0,0-4.6,4.6,4.746,4.746,0,0,0,4.6,4.6,4.746,4.746,0,0,1,4.6,4.6L60.4,63.8,9.208,27.783a4.746,4.746,0,0,1,4.6-4.6,4.746,4.746,0,0,0,4.6-4.6,4.746,4.746,0,0,0-4.6-4.6A13.871,13.871,0,0,0,0,27.783v65A13.93,13.93,0,0,0,14.083,106.6h92.9a13.871,13.871,0,0,0,13.813-13.813ZM106.708,97.387H14.083a4.746,4.746,0,0,1-4.6-4.6V39.158L57.687,73.283a4.92,4.92,0,0,0,5.417,0l48.479-34.125V92.783a4.8,4.8,0,0,1-4.875,4.6"
              transform="translate(0 23.404)"
              fill="#0b1f42"
            />
          </g>
        </svg>
      </div>

      <h2 className="mb-200rem">Sie haben eine E-Mail erhalten</h2>
      <p className="lh-140">Bitte klicken Sie auf den Bestätigungslink in der E-Mail, um den Registrierungsprozess abzuschließen. Bitte überprüfen Sie auch Ihren Spam-Ordner.</p>

      <div className="button-panel pt-100rem">
        <button className="button button--gray-outline" onClick={handleClose}>
          Zurück
        </button>
      </div>
    </>
  );
}

export default FormEmailSent;
