function IconSearch({ color, cssClasses }) {
  return (
    <svg
      data-name="Gruppe 11336"
      xmlns="http://www.w3.org/2000/svg"
      width="19.492"
      height="19.493"
      viewBox="0 0 19.492 19.493"
      className={cssClasses}
    >
      <path
        data-name="Pfad 6609"
        d="M7.31,14.619A7.273,7.273,0,0,0,11.885,13l6.22,6.252a.813.813,0,0,0,1.152-1.147l-6.228-6.259A7.3,7.3,0,1,0,7.31,14.619m0-12.995A5.685,5.685,0,1,1,1.624,7.31,5.692,5.692,0,0,1,7.31,1.624"
        fill={color}
      />
    </svg>
  );
}

export default IconSearch;
