import React from 'react';
import Header from '../Header/Header';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import headerImageMobile from '../../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0002_s-REAL-Sujets-2023-Webhero-16zu9-1.14.jpg';
import headerImage from '../../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.4.jpg';
import IconDocument from '../Icons/IconDocument';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function DashboardHeaderDocuments({ files }) {
  const app = useSelector((state) => state.app);
  const { isMobile } = useHandleScreenResize();
  const navigate = useNavigate();

  return (
    <Header cssClasses="header--stats header--fitcontent">
      <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />

      <div className="documentsheader-container w-desktop--center">
        <h1>Dashboard</h1>
        <div className="documents-card">
          <p>immo-drive</p>
          <div className="documents-card--content">
            <IconDocument color={'#fff'} scale={3} />
            <span>Sie haben aktuell {files.length} Dokumente in Ihrem immo-drive</span>
            <button
              className="button button--glass mb-10"
              onClick={() => {
                navigate(app.root + '/immo-drive');
              }}
            >
              immo-drive aufrufen
            </button>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default DashboardHeaderDocuments;
