import { useState } from 'react';

export const useHandleVisibleElements = () => {
    const [visibleElements, setVisibleElements] = useState(new Set());

    const updateVisibleElements = (entries) => {
        const visibleElementsNew = new Set(visibleElements);
        entries
            .filter((entry) => entry.isIntersecting)
            .forEach((entry) => {
                visibleElementsNew.add(entry.target);
            });
        setVisibleElements(visibleElementsNew);
    };

    // JavaScript function to detect visible elements inside a div container
    function getVisibleElements(containerSelector, elementSelector) {
        const container = document.querySelector(containerSelector);
        if (container === null) return new Set();
        const elements = container.querySelectorAll(elementSelector);
    
        // Intersection Observer configuration
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        };
        
        // Intersection Observer callback function
        const observerCallback = (entries, observer) => {
            entries.forEach(entry => {
                // Check if the entry's intersectionRatio is greater than 0
                // This means it's intersecting with the viewport
                if (entry.intersectionRatio > 0) {
                    updateVisibleElements(entries);
                }
            });
        };
        
        // Create the Intersection Observer
        const observer = new IntersectionObserver(observerCallback, observerOptions);
    
        // Observe all elements inside the container
        elements.forEach(element => {
            observer.observe(element);
        });
    
        // Return the initial visible elements
        return visibleElements;
    }

    return { getVisibleElements, visibleElements };
}
