import httpClient from './index';

const END_POINT = '/News';

//TODO Alte Version der Schnittstelle, beim Deployment mit Wolfgang abstimmen und entfernen
const getNews = async () => {
  try {
    const resp = await httpClient.get(`${END_POINT}`);
    return resp.data.items;
  } catch (e) {
    throw e;
  }
};

//TODO Alte Version der Schnittstelle, beim Deployment mit Wolfgang abstimmen und entfernen
const getNewsDetail = async (slug) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/Detail`, {
      params: { slug: slug },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getNewsNew = async (page = 1) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/ListNew?page=${page}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getNewsDetailNew = async (slug) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/DetailNew`, {
      params: { slug: slug },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export { getNews, getNewsDetail, getNewsNew, getNewsDetailNew };
