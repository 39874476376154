function IconCalendarHouse({ color, cssClasses }) {
  return (
    <svg className={cssClasses} id="Gruppe_15973" data-name="Gruppe 15973" xmlns="http://www.w3.org/2000/svg" width="40.769" height="40.758" viewBox="0 0 40.769 40.758">
      <g id="Gruppe_15975" data-name="Gruppe 15975">
        <path
          id="Pfad_10363"
          data-name="Pfad 10363"
          d="M39.567,5.616A4.318,4.318,0,0,0,36.5,4.34h-4.34V1.447A1.636,1.636,0,0,0,30.632,0a1.491,1.491,0,0,0-1.447,1.447v8.764a1.447,1.447,0,0,0,2.893,0V7.318h4.34a1.409,1.409,0,0,1,1.021.425,1.57,1.57,0,0,1,.425,1.021V36.5a1.409,1.409,0,0,1-.425,1.021,1.57,1.57,0,0,1-1.021.425H4.34a1.409,1.409,0,0,1-1.021-.425A1.57,1.57,0,0,1,2.893,36.5V8.764a1.409,1.409,0,0,1,.425-1.021A1.57,1.57,0,0,1,4.34,7.318,1.491,1.491,0,0,0,5.786,5.871,1.507,1.507,0,0,0,4.34,4.34,4.045,4.045,0,0,0,1.276,5.616,4.318,4.318,0,0,0,0,8.679V36.418a4.045,4.045,0,0,0,1.276,3.063A4.318,4.318,0,0,0,4.34,40.758H36.418a4.045,4.045,0,0,0,3.063-1.276,4.318,4.318,0,0,0,1.276-3.063V8.764a3.979,3.979,0,0,0-1.191-3.148"
          fill={color}
        />
        <path
          id="Pfad_10364"
          data-name="Pfad 10364"
          d="M11.747,11.657a1.491,1.491,0,0,0,1.447-1.447V7.318H26.3a1.491,1.491,0,0,0,1.447-1.447A1.491,1.491,0,0,0,26.3,4.425h-13.1V1.447a1.447,1.447,0,1,0-2.893,0v8.764a1.491,1.491,0,0,0,1.447,1.447m4.34,20.421H27.743a1.491,1.491,0,0,0,1.447-1.447V23.91l.425.425a1.438,1.438,0,0,0,2.042,0,1.39,1.39,0,0,0,0-2.042L28.764,19.4l-5.871-5.871a1.39,1.39,0,0,0-2.042,0l-8.679,8.764a1.444,1.444,0,0,0,2.042,2.042l.425-.425v6.722a1.491,1.491,0,0,0,1.447,1.447m5.871-15.4,4.34,4.34v8.169H17.618V21.017Z"
          transform="translate(-1.536)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconCalendarHouse;
