import React from 'react';

function IconFreizeit({ cssClasses, color }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15829" xmlns="http://www.w3.org/2000/svg" width="23.402" height="27.311" viewBox="0 0 23.402 27.311">
      <g data-name="Gruppe 15830" transform="translate(0 0)">
        <path
          data-name="Pfad 10326"
          d="M21.33,9.962C17.576,4.672,9.044,1.543,4.266.178a2.358,2.358,0,0,0-.91-.171A3.1,3.1,0,0,0,1.138.747,3.29,3.29,0,0,0,.171,2.795c-.4,4.949-.4,13.993,3.413,19.34,2.9,4.266,7.565,5.176,11.092,5.176a25.888,25.888,0,0,0,3.527-.284,3.432,3.432,0,0,0,1.081-.341c.114,0,.171-.057.284-.114a.568.568,0,0,0,.284-.228,3.73,3.73,0,0,0,1.024-1.081c1.479-2.617,4.607-9.385.455-15.3M19.283,24.068A100.482,100.482,0,0,0,8.134,8.085a.954.954,0,0,0-1.422-.114A.983.983,0,0,0,6.6,9.336,98.057,98.057,0,0,1,17.576,25.149c-2.787.455-9.044.8-12.457-4.1C1.536,15.991,1.82,6.72,2.1,2.965a.917.917,0,0,1,.341-.739.942.942,0,0,1,.739-.284h.057A.721.721,0,0,1,3.583,2H3.64c3.64,1.081,12.514,4.039,16.1,9.044,3.413,4.892.91,10.637-.455,13.026"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconFreizeit;
