function IconBarchartWithRoof({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="46.3" height="47.998" viewBox="0 0 46.3 47.998">
      <path data-name="Pfad 8569" d="M3.4,24.1a.952.952,0,0,0,.7-.2L39.7,7.8l-1.3,3.5a1.7,1.7,0,0,0,1,2.2,1.268,1.268,0,0,0,.6.1,1.714,1.714,0,0,0,1.6-1.1l2.7-7.4c0-.1.1-.2.1-.3V4.1c0-.1-.1-.2-.1-.3V3.7c0-.1-.1-.2-.2-.3L44,3.3l-.1-.1-.1-.1L43.7,3c-.1,0-.1-.1-.2-.1h-.1L36,.1a1.709,1.709,0,1,0-1.2,3.2l3.5,1.3L2.6,20.9a1.808,1.808,0,0,0-.9,2.3,1.965,1.965,0,0,0,1.7.9m39.4-5.2H37.7a3.372,3.372,0,0,0-3.4,3.4v24A1.752,1.752,0,0,0,36,48h8.6a1.752,1.752,0,0,0,1.7-1.7v-24a3.523,3.523,0,0,0-3.5-3.4m0,25.7H37.7V22.3h5.1ZM25.7,24H20.6a3.372,3.372,0,0,0-3.4,3.4V46.3A1.752,1.752,0,0,0,18.9,48h8.6a1.752,1.752,0,0,0,1.7-1.7V27.5A3.612,3.612,0,0,0,25.7,24m0,20.6H20.6V27.5h5.1ZM8.5,29.2H3.4A3.372,3.372,0,0,0,0,32.6V46.3A1.752,1.752,0,0,0,1.7,48h8.6A1.752,1.752,0,0,0,12,46.3V32.6a3.458,3.458,0,0,0-3.5-3.4m0,15.4H3.4v-12H8.5Z" transform="translate(0 -0.001)" fill="#fff" />
    </svg>

  );
}

export default IconBarchartWithRoof;
