function IconExternalLink({ color, cssClasses }) {
  return (
    <svg
      xmlns="
    http://www.w3.org/2000/svg"
      className={cssClasses}
      width="18.098"
      height="18.03"
      viewBox="0 0 18.098 18.03"
    >
      <g id="Gruppe_17264" data-name="Gruppe 17264" transform="translate(7034.872 -16935.15)">
        <path
          id="Pfad_10508"
          fill={color}
          data-name="Pfad 10508"
          d="M125.084,1.093A3.145,3.145,0,0,0,122.858.15a3.232,3.232,0,0,0-2.263.943L119.5,2.187a.616.616,0,0,0,0,.905.616.616,0,0,0,.905,0L121.538,2a1.921,1.921,0,0,1,1.32-.566,1.77,1.77,0,0,1,1.32.566,1.89,1.89,0,0,1,0,2.641l-1.094,1.132a.616.616,0,0,0,0,.905.638.638,0,0,0,.905,0l1.094-1.094a3.165,3.165,0,0,0,0-4.489"
          transform="translate(-7142.792 16935)"
        />
        <path
          id="Pfad_10509"
          fill={color}
          data-name="Pfad 10509"
          d="M119.254,13.067a.7.7,0,0,0,.453-.189l2.15-2.15a.64.64,0,1,0-.905-.905l-2.15,2.15a.616.616,0,0,0,0,.905.508.508,0,0,0,.453.189"
          transform="translate(-7142.356 16929.1)"
        />
        <path
          id="Pfad_10510"
          fill={color}
          data-name="Pfad 10510"
          d="M112.412,17.011a3.08,3.08,0,0,0,2.226-.943l1.094-1.094a.64.64,0,0,0-.905-.905l-1.094,1.094a1.921,1.921,0,0,1-1.32.566,1.77,1.77,0,0,1-1.32-.566,1.89,1.89,0,0,1,0-2.641l1.094-1.094a.64.64,0,0,0-.905-.905l-1.094,1.094a3.165,3.165,0,0,0-.013,4.476l.013.013a3.107,3.107,0,0,0,2.226.905"
          transform="translate(-7136.533 16928.664)"
        />
        <path
          id="Pfad_10511"
          fill={color}
          data-name="Pfad 10511"
          d="M104.066,15.552a.661.661,0,0,0-.641.641v2.98a1.755,1.755,0,0,1-1.735,1.735H92.146a1.755,1.755,0,0,1-1.735-1.735V9.668a1.731,1.731,0,0,1,1.735-1.735h2.98a.641.641,0,1,0,0-1.283h-2.98a3.027,3.027,0,0,0-3.018,3.018v9.544a3.027,3.027,0,0,0,3.018,3.018h9.544a3.027,3.027,0,0,0,3.018-3.018v-2.98a.645.645,0,0,0-.641-.679"
          transform="translate(-7124 16930.951)"
        />
      </g>
    </svg>
  );
}

export default IconExternalLink;
