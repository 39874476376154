import React from 'react';
import InfoDialog from './InfoDialog';

function InfoDialogPhoneNumber({handleClose, open}) {
  return (
    <InfoDialog handleClose={handleClose} headline='Mobiltelefonnummer' open={open}>
      <p className="mb-200rem">
        Mit der 2-Faktor-Authentifizierung erfolgt der Zugang zu Ihrem Konto über zwei Stufen. Neben dem Passwort wird ein zweiter, maximal drei Minuten gültiger Code generiert, den Sie für den Login
        benötigen. <span className="font-weight-600">Da dieser Code via SMS verschickt wird, benötigen wir dafür Ihre Mobilnummer.</span>
      </p>
      <p className="mb-200rem">
        Um Ihnen eine SMS zusenden zu können, benötigen wir eine empfangsfähige Mobilnummer. Sollten Sie eine Alias-Rufnummer verwenden, verwenden Sie bitte Ihre Mobilnummer. Zugelassene Vorwahlen für
        Österreich:
      </p>
      <ul className="mb-200rem vorwahl-list">
        <li>+43 644</li>
        <li>+43 650</li>
        <li>+43 660</li>
        <li>+43 663</li>
        <li>+43 664</li>
        <li>+43 665</li>
        <li>+43 667</li>
        <li>+43 670</li>
        <li>+43 676</li>
        <li>+43 677</li>
        <li>+43 678</li>
        <li>+43 680</li>
        <li>+43 681</li>
        <li>+43 686</li>
        <li>+43 688</li>
        <li>+43 690</li>
        <li>+43 699</li>
      </ul>
    </InfoDialog>
  );
}

export default InfoDialogPhoneNumber;
