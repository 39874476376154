import { Autocomplete, Chip, ListSubheader, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAddressObjectSearchForm } from '../../reducers/searchForm';
import { addDistrictSPForm, removeDistrictSPForm } from '../../reducers/searchProfileForm';
import IconClose from '../Icons/IconClose';
import { useHandleAutocomplete } from './hooks/useHandleAutocomplete';

function FormInputMultiselect({ label, id, placeholder, disabled, hasLockIcon, formType, required, error, cssClasses, labelName = 'label', valueName = 'value' }) {
  if (!cssClasses) cssClasses = '';
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let currentSearchForm = useSelector((state) => state.searchForm);
  const currentSearchProfileForm = useSelector((state) => state.searchProfileForm);
  let app = useSelector((state) => state.app);

  const { handleAutocomplete, filteredOptions, selectedOptions, setSelectedOptions, setFilteredOptions } = useHandleAutocomplete(formType);

  const [showResetIcon, setShowResetIcon] = useState(false);

  useEffect(() => {
    if (formType === 'searchForm') {
      setSelectedOptions(currentSearchForm.requestBody.addressObject);
    } else if (formType === 'searchProfileForm') {
      setSelectedOptions(currentSearchProfileForm.requestBody.location.location_ids.filter((_, index) => index > 0));
    }

    if (!hasLockIcon && !disabled && Object.keys(selectedOptions).length > 0) {
      setShowResetIcon(true);
    }
  }, []);

  // Fill input with cached values
  useEffect(() => {
    if (formType === 'searchProfileForm') {
      setSelectedOptions(currentSearchProfileForm.requestBody.location.location_ids.filter((_, index) => index > 0));
    }
  }, [currentSearchProfileForm.requestBody.location.location_ids]);

  // Fill input with cached values
  useEffect(() => {
    if (formType === 'searchForm') {
      setSelectedOptions(currentSearchForm.requestBody.addressObject);
    }
  }, [currentSearchForm.requestBody.addressObject]);

  useEffect(() => {
    if (Object.keys(selectedOptions).length > 0) {
      setShowResetIcon(true);
    } else {
      setShowResetIcon(false);
    }
  }, [selectedOptions]);

  const handleDelete = (val) => {
    const filteredValue = selectedOptions.filter((v) => v[valueName] !== val[valueName]);
    setSelectedOptions(filteredValue);
    switch (formType) {
      case 'searchForm':
        dispatch(setAddressObjectSearchForm(filteredValue));
        break;
      case 'searchProfileForm':
        dispatch(removeDistrictSPForm(val));
        break;
      default:
        break;
    }
  };

  const handleReset = () => {
    setSelectedOptions([]);
    switch (formType) {
      case 'searchForm':
        dispatch(setAddressObjectSearchForm([]));
        break;
      case 'searchProfileForm':
        selectedOptions.forEach((v) => {
          dispatch(removeDistrictSPForm(v));
        });
        break;
      default:
        break;
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length === 0) {
      handleClose();
    } else {
      handleOpen();
      handleAutocomplete(newInputValue);
    }
  };

  const handleChange = async (event, newValue) => {
    if (formType === 'searchForm') {
      switch (id) {
        case 'inputAddress':
          // Wenn properties "crm_id" und "tenant_id" vorhanden sind, dann wird auf die Detailseite des Objekts navigiert
          const objectNumberValue = newValue.find((v) => v?.crm_id && v?.tenant_id);
          // Wenn sofort navigiert wird, dann wird der Wert nicht in den Redux-Store geschrieben
          if (objectNumberValue) {
            navigate(`${app.root}${objectNumberValue.value.substring(5)}/${objectNumberValue.crm_id}`);
          } else {
            setSelectedOptions(newValue);
            dispatch(setAddressObjectSearchForm(newValue));
            setFilteredOptions([]);
          }
          break;
        default:
          break;
      }
    } else if (formType === 'searchProfileForm') {
      switch (id) {
        case 'inputDistrict':
          const parsedValue = newValue.map((v) => {
            return {
              text: v.label || v.text,
              id: v.value || v.id,
              type: v.type || 5,
            };
          });
          setSelectedOptions(parsedValue);
          dispatch(addDistrictSPForm(parsedValue));
          setFilteredOptions([]);
          break;
        default:
          break;
      }
    }
  };

  const getValueForAutoComplete = (formType) => {
    switch (formType) {
      case 'searchForm':
        return currentSearchForm.requestBody.addressObject;
      case 'searchProfileForm':
        return currentSearchProfileForm.requestBody.location.location_ids.filter((_, index) => index > 0);
      default:
        break;
    }
  };

  return (
    <div className={`${cssClasses} form__field`}>
      {label && (
        <InputLabel htmlFor={id}>
          {label} {!!required ? '*' : ''}
        </InputLabel>
      )}

      <div
        className={`select-wrapper 
        ${hasLockIcon && disabled ? 'locked' : ''}
        ${showResetIcon ? 'formfield--withreseticon' : ''}`}
      >
        <Autocomplete
          key={selectedOptions.length}
          className={`select autocomplete select-multiple ${selectedOptions.length > 0 && 'autocomplete--withChips'} ${inputValue.length > 0 && 'autocomplete--withInput'}`}
          multiple
          options={filteredOptions}
          filterOptions={(options) => options}
          getOptionDisabled={(option) => option.disabled}
          filterSelectedOptions
          freeSolo
          fullWidth
          disabled={disabled}
          onChange={handleChange}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onBlur={() => {
            setInputValue('');
            handleClose();
            setFilteredOptions([]);
          }}
          value={getValueForAutoComplete(formType)}
          renderTags={() => {
            return selectedOptions?.map((option, index) => {
              return (
                <Chip
                  key={index}
                  label={option[labelName]}
                  variant="outlined"
                  deleteIcon={
                    <span onMouseDown={(event) => event.stopPropagation()} className="autocomplete__deleteicon">
                      <IconClose color="#3679ec" />
                    </span>
                  }
                  onDelete={(e) => handleDelete(option)}
                />
              );
            });
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={props['data-option-index']}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              className="autocomplete__input"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                },
              }}
              disabled={disabled}
              variant="standard"
              placeholder={selectedOptions.length > 0 ? '' : placeholder}
            />
          )}
          clearIcon={null}
        />

        {showResetIcon ? (
          <span className="form__field--reset form__field--reset-autocomplete" onClick={handleReset}>
            <IconClose color={'#313a45'} />
          </span>
        ) : null}
      </div>

      {error && <p className="form__fielderror">{error}</p>}
    </div>
  );
}

export default FormInputMultiselect;
