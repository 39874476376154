import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageDialog from './PageDialog';
import ContactForm from '../Forms/ContactForm';

import {
  setSiteIndexContactForm,
  setPageDialogOpenContactForm,
  setResetFields
} from '../../reducers/contactInformation';
import { setResetFormValidator } from '../../reducers/formValidators';
import { setFormIsLoading } from '../../reducers/app';

import {
  CONTACTFORM_SITEINDEX_MAIN,
  CONTACTFORM_SITEINDEX_IHREIMMOBILIE,
  CONTACTFORM_SITEINDEX_KONTAKTIEREN,
  CONTACTFORM_SITEINDEX_TERMINWUNSCH,
  CONTACTFORM_SITEINDEX_EMAILSENT,
  CONTACTFORM_ANLIEGEN_SUPPORT
} from '../../constants';

function PageDialogContactForm() {
  let currentContactInformation = useSelector((state) => state.contactInformation);
  let currentWohnwertLight = useSelector((state) => state.wohnwertLight);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const location = useLocation();

  const handleClose = () => {
    if (app.formIsLoading) {
      return;
    }

    localStorage.removeItem('profildeaktivieren-clicked');

    dispatch(setPageDialogOpenContactForm(false));
    dispatch(setFormIsLoading(false));
    dispatch(setResetFields());
    dispatch(setResetFormValidator());
  };

  const handleBack = () => {
    if (currentContactInformation.siteIndex === CONTACTFORM_SITEINDEX_MAIN) {
      handleClose();
    }
    else if(currentContactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_SUPPORT) {
      dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_MAIN));
    } else {
      let newSiteIndex = '';
      switch (currentContactInformation.siteIndex) {
        case CONTACTFORM_SITEINDEX_TERMINWUNSCH:
          newSiteIndex = CONTACTFORM_SITEINDEX_MAIN;
          break;
        case CONTACTFORM_SITEINDEX_IHREIMMOBILIE:
          newSiteIndex = CONTACTFORM_SITEINDEX_TERMINWUNSCH;
          break;
        case CONTACTFORM_SITEINDEX_KONTAKTIEREN:
          newSiteIndex = CONTACTFORM_SITEINDEX_IHREIMMOBILIE;
          break;
        default:
          break;
      }

      if (newSiteIndex !== '') {
        dispatch(setSiteIndexContactForm(newSiteIndex));
      }
    }
  };

  useEffect(() => {
    if (location.hash && location.hash !== '') {
      let hash = location.hash.replace('#', '');

      // check hash
      if (hash !== '') {
        if (hash.indexOf(CONTACTFORM_SITEINDEX_MAIN) === 0) {
          dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_MAIN));
          dispatch(setPageDialogOpenContactForm(true));
        }
      }
    }
  }, []);

  return (
    <PageDialog
      open={currentContactInformation.pageDialogOpen}
      handleClose={handleClose}
      handleBack={handleBack}
      headline={currentContactInformation.headline}
      showBackArrow={
        (currentContactInformation.siteIndex !== CONTACTFORM_SITEINDEX_MAIN &&
          currentContactInformation.siteIndex !== CONTACTFORM_SITEINDEX_EMAILSENT) ||
        currentWohnwertLight.pageDialogOpen
          ? true
          : false
      }
    >
      <ContactForm handleClose={handleClose} />
    </PageDialog>
  );
}

export default PageDialogContactForm;
