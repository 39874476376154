import React from 'react';
import { useDispatch } from 'react-redux';
import Card from './Card';
import { setPageDialogOpenContactForm } from '../../reducers/contactInformation';

import '../../styles/card.scss';

// Icons
import IconPhone from '../Icons/IconPhone';
import IconMailOutlined from '../Icons/IconMailOutlined';
import IconContact2 from '../Icons/IconContact2';
import IconChat from '../Icons/IconChat3';

function ContactAgentCard({ cssClasses, agent, urlBtnAnfrage, objectNumber }) {
  const { gender, first_name, prefix, last_name, role, phone, email, profile_picture, suffix } = agent;

  if (!cssClasses) cssClasses = 'contactagent-card';
  else cssClasses += ' contactagent-card';

  let telLink = '';
  if (phone) {
    telLink = 'tel:' + phone.replace(/ /g, '').replace(/-/g, '');
  }

  const dispatch = useDispatch();

  return (
    <>
      <Card
        cssClasses={'contact-card cardagent-card ' + cssClasses}
        cardStyle="customContent"
        //icon={<IconChat cssClasses={cardStyle == 'topImage2Cols' ? 'scale-1-7' : ''} color="#fff" />}
        //dropdownHeadline="Hotline & Kontakt"
        //dropdownChildren={contactInformation}
        //handleInfoModalDesktop={handleInfoModalDesktop}
      >
        <div className="d-flex">
          {/*{profile_picture && profile_picture.storage_key !== '' ? (*/}
          {profile_picture ? (
            <>
              <img className="contactagent-card__image mt-100rem" src={`https://storage.justimmo.at/file/${profile_picture.storage_key}`} alt={`${gender} ${prefix} ${first_name} ${last_name}`} />
            </>
          ) : (
            <>
              <div className="contactagent-card__image mt-100rem">
                <IconChat cssClasses="w-100" color="#fff" />
              </div>
            </>
          )}
          <div className="mt-200rem ml-100rem mb-100rem desktop-hidden">
            <h4 className="text-white">
              {gender} {prefix} {first_name} {last_name}
              {suffix !== '' ? `, ${suffix}` : ''}
            </h4>
            <p className="mb-100rem mt-0 text-white">Immobilienmakler:in</p>
          </div>
        </div>
        <div className="card__content text-center pt-0">
          <div>
            <h1 className="mb-100rem mt-0 text-white mobile-hidden">Kontaktieren Sie Ihre Immobilienmakler:in</h1>
            <div className="text-center">
              {/*{role && <p>{role}</p>}*/}

              <ul className="list-style-none mb-20 icon-list">
                <li>
                  {gender} {prefix} {first_name} {last_name}
                  {suffix !== '' ? `, ${suffix}` : ''}
                </li>
                {phone && (
                  <li className="font-weight-400 text-white">
                    <IconPhone color="#fff" />{' '}
                    <a className="text-white" href={telLink} title={`${first_name} ${last_name} anrufen`}>
                      {phone}
                    </a>
                  </li>
                )}
                {email && (
                  <li className="font-weight-400 text-white">
                    <IconMailOutlined cssClasses="h-1rem w-fit-content" color="#fff" />{' '}
                    <a className="text-white" href={'mailto:' + email} title={`E-Mail an ${first_name} ${last_name}`}>
                      {email}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="mb-100rem contact-card__buttons mt-100rem">
            <a href={telLink} className="button button--white-outline mr-50rem desktop-hidden">
              Anrufen
            </a>
            <a className="button button--white no-wrap" href={'mailto:' + email + '?subject=Kontaktaufnahme%20zu%20Objekt%20Nr.%20' + objectNumber}>
              Nachricht senden
            </a>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ContactAgentCard;
