import React from 'react';
import '../../styles/checklisttable.scss';
import ChecklistTable from './ChecklistTable';

import { useState } from 'react';

function ChecklistTableSingle({ label, childList, id }) {
  const [checkedList, setCheckedList] = useState(childList);

  const handleCheckItem = (label, index) => {
    const updatedCheckboxList = checkedList.map((entry) => {
      if (checkedList.indexOf(entry) === index) {
        entry.checked = !entry.checked;
      }
      return entry;
    });

    setCheckedList(updatedCheckboxList);
  };

  const handleCheckAllInCategory = () => {
    if (!checkIfAllChecked()) {
      const updatedCheckboxList = checkedList.map((entry) => {
        return {
          label: entry.label,
          checked: true
        };
      });
      setCheckedList(updatedCheckboxList);
    } else {
      const updatedCheckboxList = checkedList.map((entry) => {
        return {
          label: entry.label,
          checked: false
        };
      });
      setCheckedList(updatedCheckboxList);
    }
  };

  const checkIfAllChecked = () => {
    return checkedList.every((entry) => entry.checked);
  };

  return (
    <div className="checklistTableSingle">
      <ChecklistTable
        id={id}
        parentLabel={label}
        childList={checkedList}
        handleCheckItem={handleCheckItem}
        handleCheckAllInCategory={handleCheckAllInCategory}
      />
    </div>
  );
}

export default ChecklistTableSingle;
