const parseCurrency = (amount, removeDecimals = false) => {
  if (!amount) {
    return '';
  }

  // check if number has decimals
  if (amount % 1 !== 0) {
		if(removeDecimals === true) {
			amount = Math.round(amount);
		}
		else {
			amount = +parseFloat(amount).toFixed(2);
		}
  }

  amount = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  }).format(amount);

  return amount;
};

export default parseCurrency;
