function IconPaperOutlined({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="199.24" height="193.439" viewBox="0 0 199.24 193.439">
      <g id="Gruppe_15270" data-name="Gruppe 15270" transform="translate(-5 38.075)">
        <g id="Gruppe_15271" data-name="Gruppe 15271" transform="translate(5)">
          <path
            id="Pfad_8766"
            data-name="Pfad 8766"
            d="M109.078,62.841H71.856a15.119,15.119,0,0,0,.971-5.5,17.686,17.686,0,0,0-5.179-12.3L54.7,32.092A27.915,27.915,0,0,0,34.957,24H5.5A5.672,5.672,0,0,0,0,29.5,5.672,5.672,0,0,0,5.5,35H35.28A15.387,15.387,0,0,1,46.933,39.86L59.88,52.807a6.258,6.258,0,0,1,0,9.063,6.468,6.468,0,0,1-8.739,0l-8.416-8.416a5.493,5.493,0,1,0-7.768,7.768l8.416,8.416a17.686,17.686,0,0,0,12.3,5.179,21.857,21.857,0,0,0,4.531-.647h49.2a12.985,12.985,0,0,1,12.947,12.947,3.592,3.592,0,0,1-3.56,3.56H46.933a16.089,16.089,0,0,1-10.681-3.884L9.063,64.136a5.591,5.591,0,0,0-7.768.647,5.591,5.591,0,0,0,.647,7.768L29.131,95.208a27.38,27.38,0,0,0,17.8,6.473h71.532a15.06,15.06,0,0,0,14.889-14.889,24.386,24.386,0,0,0-24.276-23.952"
            transform="translate(0 53.682)"
            fill={color}
          />
          <g id="Gruppe_15272" data-name="Gruppe 15272" transform="translate(82.682 -38.075)">
            <g id="Gruppe_15274" data-name="Gruppe 15274" transform="translate(0 0)">
              <path
                id="Pfad_8767"
                data-name="Pfad 8767"
                d="M115.351,49.882,66.6,1.417A4.694,4.694,0,0,0,63.2,0H14.738A14.578,14.578,0,0,0,0,14.454V121.3a14.515,14.515,0,0,0,14.454,14.454h87.576A14.515,14.515,0,0,0,116.485,121.3V53.283c.283-1.134-.283-2.267-1.134-3.4m-8.5,71.7a4.967,4.967,0,0,1-4.818,4.818H14.738a4.967,4.967,0,0,1-4.818-4.818V14.454a4.967,4.967,0,0,1,4.818-4.818H61.218l45.63,45.63Z"
                transform="translate(0 0)"
                fill={color}
              />
              <path
                id="Pfad_8768"
                data-name="Pfad 8768"
                d="M15.118,23.336H34.674a4.967,4.967,0,0,0,4.818-4.818A4.967,4.967,0,0,0,34.674,13.7H15.118A4.967,4.967,0,0,0,10.3,18.518a4.967,4.967,0,0,0,4.818,4.818M10.3,47.71a4.967,4.967,0,0,0,4.818,4.818H63.583a4.818,4.818,0,1,0,0-9.636H15.118A4.967,4.967,0,0,0,10.3,47.71M63.866,72.084H15.118a4.818,4.818,0,0,0,0,9.636H63.583A4.967,4.967,0,0,0,68.4,76.9a4.723,4.723,0,0,0-4.535-4.818"
                transform="translate(18.892 25.128)"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconPaperOutlined;
