import InfoDialog from './InfoDialog';

function InfoDialogKaufvertrag({ open, handleClose }) {
  return (
    <InfoDialog open={open} headline={'Der Kaufvertrag'} handleClose={handleClose}>
      <h3>Kaufanbotsannahme:</h3>
      <p>
        Unterschreibt die Verkäuferpartei das verbindliche Kaufanbot, haben sich beide Parteien zum
        Geschäft verpflichtet. Die Käuferpartei kann eine Rechtsanwält:in und Treuhänder:in für die
        Kaufabwicklung beauftragen.
      </p>
      <h3>Kaufanbotsannahme:</h3>
      <p>
        Sind alle kaufrelevanten Unterlagen – auch für etwaige Lastenfreistellungen - vorhanden und
        der Kaufpreis inklusive Nebenkosten auf dem Treuhandkonto eingegangen, kann der
        Kaufvertragstermin stattfinden. Anwesend sind die Vertragsparteien, eine Rechtsanwält:in und
        eine Notar:in. Der Inhalt des Kaufvertrags wird noch einmal besprochen, bevor der Vertrag
        unterschrieben und notariell beglaubigt wird.
      </p>
      <h3>Übergabe:</h3>
      <p>
        Nach Eingang des Kaufpreises auf dem Treuhandkonto und der Kaufvertragsunterzeichnung kann
        die Immobilienübergabe erfolgen, wenn beide Parteien dies wünschen. Ab Übergabe gehen alle
        Rechte und Pflichten der Immobilie auf die Käuferpartei über. Sie tragen ab sofort alle
        laufenden Nebenkosten und die Verantwortung für Mängel und Schäden.
      </p>
      <h3>Grundbucheintragung & Auszahlung:</h3>
      <p>
        Der Treuhänder überweist die Grunderwerbssteuer an das Finanzamt und erhält eine
        Unbedenklichkeitsbescheinigung, die für die Grundbuchseintragung erforderlich ist. Sobald
        die Eigentümerpartei im Grundbuch eingetragen ist, kann der Kaufpreis vom Treuhandkonto an
        die Verkäuferpartei überwiesen werden.
      </p>
    </InfoDialog>
  );
}

export default InfoDialogKaufvertrag;
