import React from 'react';
import '../../styles/card.scss';
import Card from './Card';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { parseTime, parseDate } from '../../util/datetimeParser';
import IconAnnouncmentCircle from '../Icons/IconAnnouncmentCircle';
import DotUnread from '../DotUnread';

function WeitereAktivitaetenCard({ cssClasses, icon, headline, dateFrom, dateTo, handleLink, linkTarget, unread, keyFigures, comment, imagePath, imageTitle, id }) {
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);

  const [date, setDate] = useState(null);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (dateFrom) {
      setDate(parseDate(dateFrom));

      let timeFromParsed = parseTime(dateFrom);
      if (timeFromParsed !== '00:00') {
        setTimeFrom(timeFromParsed);
      }
    }

    if (dateTo) {
      let timeToParsed = parseTime(dateTo);
      if (timeToParsed !== '00:00') {
        setTimeTo(timeToParsed);
      }
    }
  }, [user.userRealtyActive]);

  if (!cssClasses) cssClasses = 'marketing-card';
  else cssClasses += ' marketing-card';

  let clonedIcon = '';
  if (icon) {
    clonedIcon = React.cloneElement(icon, { color: '#1E3163' }); // $erste-darkblue
  } else {
    clonedIcon = <IconAnnouncmentCircle />;
  }

  return (
    <Card cardStyle="customContent" cssClasses={cssClasses}>
      <span className="js-dataid is-hidden" data-id={id}></span>
      <div className="card__content">
        <div className="d-flex flex-row justify-content-between align-items-center mb-20 mt-100rem">
          <div className="marketing-card__title">
            {clonedIcon}
            <h3 className="font-100 font-weight-700">{headline}</h3>
          </div>
          <div className="marketing-card__date">{date}</div>
        </div>

        {keyFigures && (
          <div className="mb-20">
            <h4 className="font-weight-700">Eckdaten:</h4>
            <span>{keyFigures}</span>
          </div>
        )}

        {timeFrom && timeFrom !== '00:00' && (
          <ul className="list-style-none lh-150 mb-20">
            <li>
              <span className="font-weight-600">Uhrzeit:</span> {timeFrom}
              {timeTo && <> - {timeTo}</>} Uhr
            </li>
          </ul>
        )}

        {comment && (
          <div className="mb-20">
            <h4 className="mt-0 font-weight-700">Kommentar:</h4>
            <div>{comment}</div>
          </div>
        )}

        {handleLink && (
          <a href={handleLink} target={linkTarget === 'new' ? '_blank' : '_self'} rel="noreferrer" className="textlink">
            Hier können Sie das Inserat sehen
          </a>
        )}

        {imagePath && imageTitle && <img className="marketing-card__image" src={imagePath} alt={imageTitle} />}

        {unread !== false && <DotUnread unreadAmount={unread} />}
      </div>
    </Card>
  );
}

export default WeitereAktivitaetenCard;
