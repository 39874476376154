function IconLine({ cssClasses, color }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="950" height="10" viewBox="0 0 950 10">
      <g data-name="Gruppe 17240" transform="translate(18998 -6027)">
        <g data-name="Rechteck 5483" transform="translate(-18847 6027)" fill="#fff" stroke="#fff" strokeWidth="1">
          <rect width="799" height="10" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="798" height="9" rx="4.5" fill="none" />
        </g>
        <g data-name="Rechteck 5484" transform="translate(-18945 6027)" fill="#fff" stroke="#fff" strokeWidth="1">
          <rect width="12" height="10" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="11" height="9" rx="4.5" fill="none" />
        </g>
        <g data-name="Rechteck 5485" transform="translate(-18925 6027)" fill="#fff" stroke="#fff" strokeWidth="1">
          <rect width="12" height="10" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="11" height="9" rx="4.5" fill="none" />
        </g>
        <g data-name="Rechteck 5486" transform="translate(-18886 6027)" fill="#fff" stroke="#fff" strokeWidth="1">
          <rect width="12" height="10" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="11" height="9" rx="4.5" fill="none" />
        </g>
        <g data-name="Rechteck 5488" transform="translate(-18906 6027)" fill="#fff" stroke="#fff" strokeWidth="1">
          <rect width="12" height="10" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="11" height="9" rx="4.5" fill="none" />
        </g>
        <g data-name="Rechteck 5487" transform="translate(-18867 6027)" fill="#fff" stroke="#fff" strokeWidth="1">
          <rect width="12" height="10" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="11" height="9" rx="4.5" fill="none" />
        </g>
        <rect data-name="Rechteck 5707" width="950" height="10" rx="5" transform="translate(-18998 6027)" fill="none" />
      </g>
    </svg>
  );
}
export default IconLine;
