import { useSelector } from 'react-redux';

// STEPS
import ContactMain from './Contact/ContactMain';
import ContactEmailSent from './Contact/ContactEmailSent';
import ContactTerminwunsch from './Contact/ContactTerminwunsch';
import ContactIhreImmobilie from './Contact/ContactIhreImmobilie';
import ContactIhreNachricht from './Contact/ContactIhreNachricht';

import {
  CONTACTFORM_SITEINDEX_MAIN,
  CONTACTFORM_SITEINDEX_TERMINWUNSCH,
  CONTACTFORM_SITEINDEX_IHREIMMOBILIE,
  CONTACTFORM_SITEINDEX_KONTAKTIEREN,
  CONTACTFORM_SITEINDEX_EMAILSENT,
} from '../../constants';

function ContactForm({ handleClose }) {
  let currentContactInformation = useSelector(
    (state) => state.contactInformation,
  );

  return (
    <>
      <div className="contactForm p-20">
        {currentContactInformation.siteIndex === CONTACTFORM_SITEINDEX_MAIN ? (
          <ContactMain handleClose={handleClose} />
        ) : currentContactInformation.siteIndex === CONTACTFORM_SITEINDEX_TERMINWUNSCH ? (
          <ContactTerminwunsch />
        ) : currentContactInformation.siteIndex === CONTACTFORM_SITEINDEX_IHREIMMOBILIE ? (
          <ContactIhreImmobilie />
        ) : currentContactInformation.siteIndex === CONTACTFORM_SITEINDEX_KONTAKTIEREN ? (
          <ContactIhreNachricht />
        ) : currentContactInformation.siteIndex === CONTACTFORM_SITEINDEX_EMAILSENT ? (
          <ContactEmailSent handleClose={handleClose} />
        ) : null}
      </div>
    </>
  );
}

export default ContactForm;
