import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InfoDialog from '../Dialogs/InfoDialog';
import PageDialogKaufanbot from '../Dialogs/PageDialogKaufanbot';
import FormSelect from '../FormFields/FormSelect';
import Card from './Card';

// ICONS
import IconPaperOutlined from '../Icons/IconPaperOutlined';

// STORE
import { setPageDialogOpenKaufanbotForm } from '../../reducers/kaufAnbote';

// API
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useHandleFavorite } from '../../hooks/immo/useHandleFavorite';
import { useFetchKaufanbote } from '../../hooks/kaufanbote/useFetchKaufanbote';
import { useFetchSearchprofiles } from '../../hooks/searchprofiles/useFetchSearchprofiles';
import parseCurrency from '../../util/currencyParser';
import { parseDate } from '../../util/datetimeParser';
import { teaserTextParser } from '../../util/teaserTextParser';

function AbgegebeneAngeboteList({ wunschImmosList }) {
  const { getSentOffersList } = useFetchKaufanbote();
  const { isMobile } = useHandleScreenResize();
  let sentOffersList = getSentOffersList(wunschImmosList);

  return (
    <>
      {sentOffersList.length > 0 && (
        <ul className="list-style-none">
          {sentOffersList.map((anbotImmo, index) => (
            <li className="pb-50rem font-90" key={index}>
              <Link className="textlink" to={`../immobilie/${anbotImmo.url}`} title={anbotImmo.title}>
                Objekt {anbotImmo.catalogue_number} {anbotImmo.title}
              </Link>
              <span className="d-flex w-100 pt-50rem">Verschickt am: {parseDate(anbotImmo.offerCreatedAt)}</span>
              <span className="d-flex w-100">Angebotener Preis: {parseCurrency(anbotImmo.offeredPrice)}</span>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

function KaufanbotCard({ cssClasses }) {
  cssClasses += ' kaufanbot-card';

  const dispatch = useDispatch();
  const { isMobile } = useHandleScreenResize();

  let currentKaufanbote = useSelector((state) => state.kaufAnbote);
  let user = useSelector((state) => state.user);
  const { sentOffersListHeadline, showSentOffersList } = useFetchKaufanbote();
  const { reloadFavsWithRealtyDetails } = useHandleFavorite();

  const [infoDialogAnfragenOpen, setInfoDialogAnfragenOpen] = useState(false);
  const [wunschImmosList, setWunschImmosList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [suchprofileMenu, setSuchprofileMenu] = useState(null);
  const { getSearchprofilesMenu } = useFetchSearchprofiles();

  const handleInfoDialogAnfragenOpen = () => {
    setInfoDialogAnfragenOpen(!infoDialogAnfragenOpen);
  };

  useEffect(() => {
    setSuchprofileMenuState();
  }, []);

  useEffect(() => {
    if (suchprofileMenu?.length > 0) {
      getAndSetWunschImmosList();
    }
  }, [suchprofileMenu]);

  const setSuchprofileMenuState = async () => {
    let menu = getSearchprofilesMenu();
    menu = menu?.filter((m) => m.label !== 'Alle' && m.immos.length > 0);
    setSuchprofileMenu(menu);
  };

  const getAndSetWunschImmosList = async () => {
    try {
      // get favourites
      await reloadFavsWithRealtyDetails();

      let options = [];
      if (user.favourites && user.favourites.length > 0) {
        options.push({
          optgroup: true,
          text: 'Favoriten',
        });

        user.favourites.map((f) => {
          options.push({
            text: teaserTextParser('Objekt ' + f.catalogue_number + ' - ' + f.title),
            id: f.id,
            immo: f,
          });
        });
      }

      suchprofileMenu
        .map((m) => {
          let text = m.label;
          if (text !== 'Weitere Immobilien') {
            text = 'Suchprofil ' + m.label;
          }
          options.push({
            optgroup: true,
            text: text,
          });

          m.immos.map((immo) => {
            options.push({
              text: teaserTextParser('Objekt ' + immo.catalogue_number + ' - ' + immo.title),
              id: immo.id,
              immo: immo,
            });
          });
        })
        .filter((opt) => opt !== undefined);

      setWunschImmosList(options);
      setLoading(false);
    } catch (e) {
      // handle getRealtyDetails Promise all error
      setLoading(false);
      console.log('e', e);
    }
  };

  const defaultWunschImmoObject = {
    id: -1,
    addressLine: 'PLZ Ort, Wohnfläche, Zimmeranzahl',
    kaufpreis: '-,- €',
    url: '',
    title: '',
  };
  const [wunschImmo, setWunschImmo] = useState(defaultWunschImmoObject);

  useEffect(() => {
    if (currentKaufanbote.wunschimmobilie.wunschImmo) {
      setWunschImmo(currentKaufanbote.wunschimmobilie.wunschImmo);
    } else {
      setWunschImmo(defaultWunschImmoObject);
    }
  }, [currentKaufanbote.wunschimmobilie]);

  const setKaufanbot = () => {
    dispatch(setPageDialogOpenKaufanbotForm(true));
  };

  if (suchprofileMenu?.length > 0) {
    return (
      <>
        <Card
          cardStyle={isMobile ? 'topImage' : 'topImage2ColsRight'}
          cssClasses={`${cssClasses}`}
          icon={<IconPaperOutlined color="#fff" cssClasses={isMobile ? 'scale-0-5' : ''} />}
          dropdownHeadline={showSentOffersList ? sentOffersListHeadline : null}
          dropdownChildren={showSentOffersList ? <AbgegebeneAngeboteList wunschImmosList={wunschImmosList} /> : null}
          handleInfoModalDesktop={handleInfoDialogAnfragenOpen}
        >
          <div className={!isMobile ? 'pl-100rem' : ''}>
            <h2 className={!isMobile ? 'font-220' : ''}>Anfrage Kaufanbot abgeben</h2>

            {!loading && wunschImmosList.length === 0 ? (
              <p>Leider keine Wunschimmobilien zur Auswahl.</p>
            ) : (
              <FormSelect
                label={'Wunschimmobilie wählen'}
                placeholder={loading ? 'Wird geladen...' : 'Bitte wählen'}
                formType={'wunschimmobilie'}
                value={currentKaufanbote.wunschimmobilie.id ? currentKaufanbote.wunschimmobilie : ''}
                hasLockIcon={loading}
                disabled={loading}
                id={'inputWunschimmobilie'}
                options={wunschImmosList}
                cssClasses={isMobile ? '' : 'mt-300rem'}
              />
            )}

            <div className="d-flex flex-col mt-300rem">
              <div className="elements2cols">
                <div className="elements2cols__col mb-100rem">
                  <span className="d-flex w-100 pb-50rem font-weight-600">Infos zum Objekt</span>
                  <span className="font-weight-600 font-140 font-weight-800">{wunschImmo.addressLine}</span>
                </div>

                <div className="elements2cols__col mb-100rem">
                  <span className="d-flex w-100 pb-50rem font-weight-600">Verkaufspreis</span>
                  <span className="font-weight-700 font-140">{wunschImmo.kaufpreis}</span>
                </div>
              </div>

              <div>
                <div className="button-panel align-items-center">
                  {wunschImmo.id > 0 ? (
                    <>
                      <button className="button button--blue mb-0" onClick={() => setKaufanbot()}>
                        Wunsch-Kaufpreis festlegen
                      </button>
                      <Link className={`textlink ${isMobile ? 'mt-100rem' : ''}`} to={`../immobilie/${wunschImmo.url}`} title={wunschImmo.title}>
                        Zum Objekt
                      </Link>
                    </>
                  ) : (
                    <button className="button mb-0 mt-0" disabled={true}>
                      Wunsch-Kaufpreis festlegen
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>

        <PageDialogKaufanbot />

        <InfoDialog headline={sentOffersListHeadline} open={infoDialogAnfragenOpen} handleClose={handleInfoDialogAnfragenOpen}>
          <AbgegebeneAngeboteList wunschImmosList={wunschImmosList} />
        </InfoDialog>
      </>
    );
  } else {
    return null;
  }
}

export default KaufanbotCard;
