import { useEffect, useState } from 'react';
import NewsCards from '../components/Cards/NewsCards';
import NewsCardsHighlight from '../components/Cards/NewsCardsHighlight';
import FilterMenu from '../components/FilterMenu';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsCardsSkeleton from '../components/Skeletons/NewsCardsSkeleton';
import IconNews from '../components/Icons/IconNews';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchNews } from '../hooks/news/useFetchNews';

function News() {
  const { selectedFilter, news, newsError, didNewsLoad, showMoreEntries, shownEntries, currentPage, maxPages, handleSetActiveFilterIndex, sortbyPublishedOn } = useFetchNews();
  const { isMobile, isTablet } = useHandleScreenResize();
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [filteredNewsWithoutHighlights, setFilteredNewsWithoutHighlights] = useState([]);

  useEffect(() => {
    if (didNewsLoad && news.length > 0) {
      let topics = news.flatMap((item) => item.topics).filter((value, index, self) => index === self.findIndex((t) => t.code === value.code));

      if (topics.length > 0) {
        topics = topics.map((t) => ({ label: t.name, code: t.code }));
        topics.unshift({ label: 'Alle', code: 'alle' });
        setFilterOptions(topics);
      }
    }
  }, [didNewsLoad, news]);

  useEffect(() => {
    if (filterOptions.length > 0) {
      let filtered = news.filter((item) => {
        if (selectedFilter === 0) return true;
        const filterCode = filterOptions[selectedFilter]?.code;
        const itemTopicsCodes = item.topics.map((t) => t.code);
        return itemTopicsCodes.includes(filterCode);
      });
			filtered = sortbyPublishedOn(filtered);
      setFilteredNews(filtered);

      const { _, newsWithoutHighlights } = removeHighlightsFromNews(news);
      const filteredWithoutHighlights = newsWithoutHighlights.filter((item) => {
        if (selectedFilter === 0) return true;
        const filterCode = filterOptions[selectedFilter]?.code;
        const itemTopicsCodes = item.topics.map((t) => t.code);
        return itemTopicsCodes.includes(filterCode);
      });
      setFilteredNewsWithoutHighlights(filteredWithoutHighlights);
    }
  }, [selectedFilter, news, filterOptions]);

  const removeHighlightsFromNews = (news) => {
    const highlightedNews = filterHighlightedNews(news);
    const highlightedIds = new Set(highlightedNews.map((article) => article.slug));

    return {
      highlightedNews,
      newsWithoutHighlights: news.filter((article) => !highlightedIds.has(article.slug)),
    };
  };

  const filterHighlightedNews = (news) => {
    let sortedNews = news.sort((a, b) => {
      if (a.hero_rank && b.hero_rank) {
        if (a.hero_rank === b.hero_rank) {
          return new Date(b.published_on) - new Date(a.published_on);
        }
        return a.hero_rank - b.hero_rank;
      } else if (a.hero_rank) {
        return -1;
      } else if (b.hero_rank) {
        return 1;
      }
    });

		sortedNews = sortbyPublishedOn(sortedNews);

    const highlightedNews = [];
    const usedRanks = new Set();

    for (const article of sortedNews) {
      if (article.hero_rank && !usedRanks.has(article.hero_rank) && article.hero_rank <= 5) {
        highlightedNews.push(article);
        usedRanks.add(article.hero_rank);
      }

      if (highlightedNews.length === 5) break;
    }

    if (highlightedNews.length < 5) {
      const remainingSlots = 5 - highlightedNews.length;
      const nonHighlightedNews = sortedNews.filter((article) => !article.hero_rank);
      highlightedNews.push(...nonHighlightedNews.slice(0, remainingSlots));
    }

    return highlightedNews;
  };

  if (!didNewsLoad) {
    return <NewsCardsSkeleton />;
  } else {
    return (
      <>
        <MetaTags
          title={'my-sreal News | Nachrichten, Einblicke und Hintergründe aus der Immobilienwelt'}
          description={'my-sreal News bietet Nachrichten, Einblicke und Hintergründe aus der Immobilienwelt. Erhalten Sie mehr Einblick auf my-sreal.at – die Online-Plattform für Ihre Immobilie.'}
          imageName={'mysreal_hero_news.jpg'}
        />
        <HeaderSmallWithIcon icon={<IconNews />} headlineDesktop="News" cssClasses="header--news" />

        <MainContent cssClasses="news">
          {news?.length > 0 && !newsError ? (
            <>
              <h1 className="headline--mobile w-desktop--center mb-0">News</h1>
              <div className="background-stone mobile-hidden tablet-hidden news__highlight-wrapper">
                <div className="d-flex justify-content-between w-desktop--center h-36rem">
                  <NewsCardsHighlight news={filterHighlightedNews(news)} topic={true} cssClasses="news__highlight" />
                </div>
              </div>

              <div className="w-desktop--center">
                <h2 className="mobile-hidden tablet-hidden mt-200rem mb-100rem">Alle Artikel</h2>
                {filterOptions.length > 0 && <FilterMenu cssClasses="mb-200rem" activeIndex={selectedFilter} handleClick={handleSetActiveFilterIndex} categories={filterOptions} cutside="right" />}

                <NewsCards
                  cssClasses={`news__other ${isTablet ? 'mt-200rem' : ''}`}
                  news={isMobile ? filteredNews.slice(0, shownEntries) : filteredNewsWithoutHighlights.slice(0, shownEntries)}
                  selectedFilter={selectedFilter}
                  filterOptions={filterOptions}
                />

								{isMobile && filteredNews.length > shownEntries ? (
									<span onClick={() => showMoreEntries(false)} className="textlink w-100 d-flex flex-col align-items-center">
										Weitere laden
									</span>
								) : filteredNewsWithoutHighlights.length > shownEntries ? (
									<span onClick={() => showMoreEntries(false)} className="textlink w-100 d-flex flex-col align-items-center">
										Weitere laden
									</span>
								) : currentPage < maxPages ? (
									<span onClick={() => showMoreEntries(true)} className="textlink w-100 d-flex flex-col align-items-center">
										Weitere laden
									</span>
								) : null}
              </div>
            </>
          ) : (
            <div className="background-stone">
              <p className="w-desktop--center page__emptymessage text-white">Es konnten leider keine News geladen werden. Bitte Seite aktualisieren, um News neu zu laden.</p>
            </div>
          )}
        </MainContent>
      </>
    );
  }
}

export default News;
