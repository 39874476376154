function IconIndeterminate({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <rect data-name="Rechteck 33113" width="26" height="26" rx="5" fill={color} />
      <line
        data-name="Linie 4467"
        x2="13"
        transform="translate(6.5 13)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="3"
      />
    </svg>
  );
}

export default IconIndeterminate;
