import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

//Page Dialogs
import PageDialogContactForm from './Dialogs/PageDialogContactForm';
import PageDialogCookie from './Dialogs/PageDialogCookie';
import PageDialogLogin from './Dialogs/PageDialogLogin';
import PageDialogObjectRequestForm from './Dialogs/PageDialogObjectRequestForm';
import PageDialogRegister from './Dialogs/PageDialogRegister';
import PageDialogSatisfaction from './Dialogs/PageDialogSatisfaction';
import PageDialogSearchProfileForm from './Dialogs/PageDialogSearchProfileForm';
import PageDialogSearchprofilesList from './Dialogs/PageDialogSearchprofilesList';

function SiteWrapper() {
  let activeMobileMenu = useSelector((state) => state.app.mobileMenuOpen);

  return (
    <div id="sreal-kundenportal-root" className={activeMobileMenu ? 'no-scroll is-blurred' : ''}>
      <PageDialogLogin />
      <PageDialogRegister />
      <PageDialogContactForm />
      <PageDialogSearchProfileForm />
      <PageDialogObjectRequestForm />
      <PageDialogCookie />
      <PageDialogSatisfaction />
      <PageDialogSearchprofilesList />
      <Outlet />
      <Footer />
    </div>
  );
}

export default SiteWrapper;
