import React from 'react';

function IconGesundheit({ cssClasses, color }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15827" xmlns="http://www.w3.org/2000/svg" width="31.822" height="27.311" viewBox="0 0 31.822 27.311">
      <g data-name="Gruppe 15249" transform="translate(0 0)">
        <path
          data-name="Pfad 8755"
          d="M31.528,6.549a9.008,9.008,0,0,0-6.5-6.368c-1.791-.4-5.24-.464-9.021,3.316C12.093-.349,8.512-.216,6.655.315A9.01,9.01,0,0,0,.287,7.279a1.127,1.127,0,0,0,.862,1.327,1.127,1.127,0,0,0,1.327-.862A6.808,6.808,0,0,1,7.185,2.5c2.587-.663,5.373.531,7.959,3.449a1.133,1.133,0,0,0,1.725,0C19.323,3.1,22.043,1.84,24.5,2.371a6.882,6.882,0,0,1,4.842,4.776,6.43,6.43,0,0,1-1.592,6.566L16.007,24.591l-6.566-6.1a1.127,1.127,0,1,0-1.526,1.658l7.362,6.832a1.1,1.1,0,0,0,.8.332,1.378,1.378,0,0,0,.8-.332l12.6-11.607a9.068,9.068,0,0,0,2.056-8.822"
          transform="translate(-0.088 0)"
          fill={color}
        />
        <path
          data-name="Pfad 8756"
          d="M7.76,18.342l2.388-3.184,3.714,6.434a1.29,1.29,0,0,0,.929.6h.066a1.394,1.394,0,0,0,.929-.464l3.051-4.112h2.852a1.128,1.128,0,1,0,0-2.255H18.307a1.394,1.394,0,0,0-.929.464L14.924,19l-3.714-6.434a1.29,1.29,0,0,0-.929-.6,1.193,1.193,0,0,0-.995.464L6.235,16.551H1.128a1.128,1.128,0,1,0,0,2.255h5.7a1.158,1.158,0,0,0,.929-.464"
          transform="translate(0 -4.032)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconGesundheit;
