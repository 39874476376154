import '../styles/iconbox.scss';

function IconBox({ headline, icon, color, text }) {
  return (
    <>
      <div className="icon-box">
        <div className={'icon-box__icon ' + color}>{icon}</div>
        {headline && <h5>{headline}</h5>}
        {text && <p>{text}</p>}
      </div>
    </>
  );
}

export default IconBox;
