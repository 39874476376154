import IconBox from '../IconBox';
import Card from './Card';

function VorteilsweltCards({ text, headline, icon, color, children }) {
  return (
    <Card cardStyle="topImage" cssClasses={'card--vorteile bg-white with-shadow text-center'}>
      <>
        <IconBox color={color} icon={icon} />
        <div className="card__content__text">
          <h4 className="font-110 font-weight-600 lh-130">{headline}</h4>
          <p className="cardText">{text}</p>
        </div>
        {children}
      </>
    </Card>
  );
}

export default VorteilsweltCards;
