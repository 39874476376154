import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from './Header/Logo';
import IconSocialFB from './Icons/IconSocialFB';
import IconSocialIG from './Icons/IconSocialIG';
import IconSocialIN from './Icons/IconSociaIN';
import IconSocialYT from './Icons/IconSocialYT';
import IconArrowDown from './Icons/IconArrowDown';

import { setPageDialogOpenContactForm } from '../reducers/contactInformation';
import { VERSION_MAJOR, VERSION_MINOR, VERSION_PATCH } from '../constants';
import '../styles/footer.scss';

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

function Footer() {
  const dispatch = useDispatch();

  let app = useSelector((state) => state.app);

  const [env, setEnv] = useState('f');
  useEffect(() => {
    if (window.location.host.includes('my-sreal.at')) {
      setEnv('p');
    }
  }, []);

  return (
    <footer className="sreal-footer">
      <div className="sreal-footer__inner w-desktop--center">
        <div className="sreal-footer__main-block">
          <div className="sreal-footer__to-top-wrapper">
            <button id="btn--scrolltop" className="button button--glass button--withicon button--nohover" onClick={scrollTop}>
              <span className="btn-icon"></span>
              <span>nach oben</span>
            </button>
          </div>
          <div className="sreal-footer__upper-menu">
            <Link className="sreal-footer__upper-menu-block" to={app.root + '/verkaufen'} title="Immobilie verkaufen">
              Immobilie verkaufen
              <IconArrowDown cssClasses="header__nav__arrow" color="#fff" />
            </Link>
            <Link className="sreal-footer__upper-menu-block" to={app.root + '/kaufen'} title="Immobilie kaufen">
              Immobilie kaufen
              <IconArrowDown cssClasses="header__nav__arrow" color="#fff" />
            </Link>
            <Link className="sreal-footer__upper-menu-block" to={app.root + '/immobiliensuche'} title="Immobiliensuche">
              Immobiliensuche <IconArrowDown cssClasses="header__nav__arrow" color="#fff" />
            </Link>
            <Link className="sreal-footer__upper-menu-block" to={app.root + '/news'} title="News">
              News <IconArrowDown cssClasses="header__nav__arrow" color="#fff" />
            </Link>
            {/*  BITTE NICHT LÖSCHEN
          <div className="sreal-footer__upper-menu-block" onClick={() => navigate("../faqs")}>
              FAQ
          </div>
          */}
          </div>
        </div>

        <div className="sreal-footer__social d-flex justify-content-center align-items-center pt-200rem">
          <a href="https://www.youtube.com/user/sREALImmobilien" target="_blank" rel="noreferrer" title={app.companyName + ' auf Youtube'}>
            <IconSocialYT color="#fff" />
          </a>
          <a href="https://at.linkedin.com/company/s-real-immobilienvermittlung-gmbh" target="_blank" rel="noreferrer" title={app.companyName + ' auf LinkedIn'}>
            <IconSocialIN color="#fff" />
          </a>
          <a href="https://www.instagram.com/sreal.immobilien/" target="_blank" rel="noreferrer" title={app.companyName + ' auf Instagram'}>
            <IconSocialIG color="#fff" />
          </a>
          <a href="https://www.facebook.com/sREAL.Immobilien" target="_blank" rel="noreferrer" title={app.companyName + ' auf Facebook'}>
            <IconSocialFB color="#fff" />
          </a>
        </div>

        <div className="sreal-footer__blue-area">
          <Logo showBothLogos={true} />
        </div>
        <div className="footer-blue-link-wrapper">
          <Link to={app.root + '/impressum'} title="Impressum" className="footer-blue-link">
            Impressum
          </Link>
          <Link to={app.root + '/datenschutz'} title="Datenschutz" className="footer-blue-link">
            Datenschutz
          </Link>
          <span onClick={() => dispatch(setPageDialogOpenContactForm(true))} className="footer-blue-link">
            Kontakt
          </span>
        </div>
        <div className="sreal-footer__copyright">
          © 2023 s REAL Immobilienvermittlung GmbH | v.{VERSION_MAJOR}.{VERSION_MINOR}.{VERSION_PATCH}-{env}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
