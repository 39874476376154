function IconProfile({ color, cssClasses }) {
  return (
    <svg className={cssClasses} width='30' height='30' viewBox='0 0 30 30'>
      <g>
        <path
          d="M19.991,21.082A6.291,6.291,0,1,0,13.7,14.791a6.282,6.282,0,0,0,6.291,6.291m0-10.445a4.215,4.215,0,1,1-4.215,4.215,4.223,4.223,0,0,1,4.215-4.215"
          transform="translate(-5.332 -3.308)"
          fill={color}
        />
        <path
          d="M29.318,14.659A14.659,14.659,0,1,0,4.947,25.654c.061.061.122.122.183.122a14.585,14.585,0,0,0,19.057,0c.061-.061.122-.061.122-.122a14.486,14.486,0,0,0,5.009-10.994m-27.242,0A12.582,12.582,0,1,1,23.76,23.333a10.322,10.322,0,0,0-3.115-2.749A11.6,11.6,0,0,0,5.558,23.333a12.61,12.61,0,0,1-3.482-8.673m5.07,10.017a9.351,9.351,0,0,1,12.4-2.382,9.8,9.8,0,0,1,2.626,2.382,12.348,12.348,0,0,1-7.513,2.5,11.971,11.971,0,0,1-7.513-2.5"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconProfile;
