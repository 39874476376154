import { useSelector, useDispatch } from 'react-redux';
import statesWithZips from '../../components/FormFields/util/statesWithZips';
import {
  CONDITION_RENOVATION_NEEDED,
  CONDITION_WELL_MAINTAINED,
  CONDITION_NEW_OR_RECENTLY_RENOVATED,
} from '../../constants';
import { isJson } from '../../util/generalUtils';
import { setWohnwertResultsActive } from '../../reducers/user';
import {
  setAreaContactForm,
  setConditionContactForm,
  setDisplayedDistrictNameContactForm,
  setPostalCodeContactForm,
  setStateContactForm,
  setTypeContactForm,
} from '../../reducers/contactInformation';
import { immobilienartOptions } from '../../components/FormFields/util/formOptions';

export const useHandleWohnwert = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  /**
   * check results for marktinfoLight form and update store
   * @param {Object} currentUser
   * @returns
   */
  const getWohnwertResults = (userObject) => {
    let results = [];
    let userWohnwertResults = userObject.results.filter((calc) => calc.type === 'ValuationLight');

    if (!userWohnwertResults) return user.wohnwertLastResults;

    if (userWohnwertResults.length >= user.wohnwertLastResults.length) {
      results = userWohnwertResults.map((calc) => {
        let id = calc.id;
        let federalState = '';
        let district = '';
        let type = '';
        let size = '';
        let condition = '';

        let request = calc.request;
        if (request && isJson(request)) {
          request = JSON.parse(request);

          if (request.propertyType) {
            switch (request.propertyType) {
              case 'house':
                type = 'Haus';
                break;
              case 'apartment':
                type = 'Wohnung';
                break;
              default:
                break;
            }
          }

          if (request.livingArea) {
            size = request.livingArea;
          }

          if (request.divisionLevel4) {
            let fed = statesWithZips.find((state) => state.priceHubbleId == request.divisionLevel4);
            if (fed && fed.label) {
              federalState = fed.label;
            }
          }

          if (request.postCode) {
            district = request.postCode;
          }

          if (request.condition) {
            switch (request.condition) {
              case CONDITION_RENOVATION_NEEDED:
                condition = 0;
                break;
              case CONDITION_WELL_MAINTAINED:
                condition = 1;
                break;
              case CONDITION_NEW_OR_RECENTLY_RENOVATED:
                condition = 3;
                break;
              default:
                condition = 3;
            }
          }
        }

        let von = '';
        let bis = '';
        let response = calc.response;
        if (response && isJson(response)) {
          response = JSON.parse(response);
          if (response.valueRange) {
            von = response.valueRange.lower;
            bis = response.valueRange.upper;
          }
        }

        return {
          id: id,
          von: von,
          bis: bis,
          federalState: federalState,
          district: district,
          estateType: type,
          estateSize: size,
          estateCondition: condition,
        };
      });
    }

    return results;
  };

  /**
   * find if user has marktbericht (file in user-realty)
   * @returns Array
   */
  const getWohnwertGutachten = () => {
    let gutachten = undefined;

    if (user.userRealtyActive?.documentsPrivate) {
      gutachten = user.userRealtyActive.documentsPrivate.find((file) => file.headline.indexOf('gutachten-wohnwert_') > -1);
    }

    return gutachten;
  };

  /**
   * build label for FormSelect of previous calculations
   * @param {Array} wohnwertResults
   */
  const getAndSetWohnwertResultActive = (wohnwertResults, index) => {
    const id = wohnwertResults[index].id;
    const label = getWohnwertLabel(wohnwertResults, index);
    dispatch(setWohnwertResultsActive({ id: id, text: label }));
  };

  /**
   * get label to identify wohnwert-calculation
   * @param {Array} wohnwertResults
   * @param {Integer} index
   * @returns
   */
  const getWohnwertLabel = (wohnwertResults, index) => {
    let label = '';
    if (wohnwertResults[index]) {
      const option = wohnwertResults[index];
      label = option.federalState + ', ' + option.district + ', ' + option.estateType + ', ' + option.estateSize + 'm';
    }

    return label;
  };

  // check if wohnwert cals exist and set data in contactform
  const handleSetImmoForContactForm = () => {
    const federalStateOptions = statesWithZips.map((state) => {
      return {
        id: state.justImmoId,
        text: state.label,
      };
    });

    const districtOptions = statesWithZips
      .filter((state) => state.label !== 'TESTACCOUNT')
      .map((state) => state.zipCodes)
      .flat();

    if (user.wohnwertLastResults.length > 0) {
      let index = user.wohnwertLastResults.length - 1;
      if (user.wohnwertResultsActive?.id > -1) {
        index = user.wohnwertResultsActive.id;
      }

      const { federalState, district, estateType, estateSize, estateCondition } = user.wohnwertLastResults[index];

      if (federalState) {
        const federalStateInOption = federalStateOptions.find((o) => o.text === federalState);
        if (federalStateInOption) {
          dispatch(setStateContactForm(federalStateInOption));
        }
      }

      if (district) {
        const districtInOptions = districtOptions.find((o) => o === district);
        if (districtInOptions) {
          dispatch(setPostalCodeContactForm(districtInOptions));
          dispatch(setDisplayedDistrictNameContactForm(districtInOptions));
        }
      }

      if (estateType) {
        const estateTypeInOptions = immobilienartOptions.find((o) => o.text === estateType);
        if (estateTypeInOptions) {
          dispatch(setTypeContactForm(estateTypeInOptions));
        }
      }

      if (estateSize) {
        dispatch(setAreaContactForm(estateSize));
      }

      if (parseInt(estateCondition) > -1) {
        dispatch(setConditionContactForm(estateCondition));
      }
    }
  };

  return {
    getWohnwertResults,
    getWohnwertGutachten,
    getAndSetWohnwertResultActive,
    getWohnwertLabel,
    handleSetImmoForContactForm,
  };
};
