import { useSelector } from 'react-redux';

import FormSelect from '../FormSelect';
import FormInput from '../FormInput';

import { phoneNumberPrefixOptions } from '../../FormFields/util/formOptions';

function FormSelectPhoneNumber({ formType, form, disabled = false, hasCheck = false }) {
  let currentFormValidators = useSelector((state) => state.formValidators);

  return (
    <div className="form__row form__row--double form__row--double--phone">
      <FormSelect
        id={!hasCheck ? 'selectPhoneNumberPrefix' : 'selectPhoneNumberPrefixCheck'}
        label={'Vorwahl'}
        value={!hasCheck ? form.phoneNumberPrefix : form.phoneNumberPrefixCheck}
        formType={formType}
        options={phoneNumberPrefixOptions}
        disabled={disabled}
      />

      <FormInput
        type={'text'}
        id={!hasCheck ? 'inputPhoneNumber' : 'inputPhoneNumberCheck'}
        label={!hasCheck ? 'Mobilnummer' : 'Mobilnummer wiederholen'}
        value={!hasCheck ? form.parsedPhoneNumber : form.parsedPhoneNumberCheck}
        error={!hasCheck ? currentFormValidators.phoneError : currentFormValidators.phoneCheckError}
        required={true}
        formType={formType}
        disabled={disabled}
      />
    </div>
  );
}

export default FormSelectPhoneNumber;
