import { createSlice } from '@reduxjs/toolkit';
import { findAndParsePhoneNumber, removeHashFromUrl, setPhoneNumber } from '../util/generalUtils';

import {
  CONTACTFORM_ANLIEGEN_ALLG,
  CONTACTFORM_SITEINDEX_MAIN,
  CONTACTFORM_SITEINDEX_TERMINWUNSCH,
  CONTACTFORM_SITEINDEX_IHREIMMOBILIE,
  CONTACTFORM_SITEINDEX_KONTAKTIEREN,
  CONTACTFORM_SITEINDEX_EMAILSENT,
  CONTACTFORM_ANLIEGEN_SUPPORT,
} from '../constants';

import { anliegenOptions, bitteWaehlenOption } from '../components/FormFields/util/formOptions';

const initialState = {
  siteIndex: CONTACTFORM_SITEINDEX_MAIN,
  headline: 'Kontaktieren & beauftragen',
  pageDialogOpen: false,
  requestBody: {
    register: {
      gender: '',
      titleBefore: '',
      titleAfter: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      privacyAggrement: false,
    },
    reason: anliegenOptions[CONTACTFORM_ANLIEGEN_ALLG].text,
    message: '',
    appointment: null,
    appointment2: null,
    state: '',
    postalCode: '',
    area: 0,
    condition: '',
    type: '',
    createUser: false,
    createJustImmoContact: false,
  },
  passwordConfirm: '',
  reasonObject: anliegenOptions[0],
  stateObject: bitteWaehlenOption,
  typeObject: bitteWaehlenOption,
  appointmentDate: '',
  appointmentTime: '',
  appointment2Date: '',
  appointment2Time: '',
  parsedPhoneNumber: '',
  phoneNumberPrefix: { id: 43, text: '+43 (Österreich)' },
  displayedDistrictName: '',
};

export const contactInformationSlice = createSlice({
  name: 'contactInformation',
  initialState,
  reducers: {
    setSiteIndexContactForm: (state, action) => {
      let reasonClean = state.requestBody.reason.toLowerCase();
      reasonClean = reasonClean.replace(' ', '-').replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue');
      window.location.hash = action.payload + '-' + reasonClean;
      state.siteIndex = action.payload;

      switch (state.siteIndex) {
        case CONTACTFORM_SITEINDEX_TERMINWUNSCH:
          state.headline = 'Terminwunsch';
          break;
        case CONTACTFORM_SITEINDEX_IHREIMMOBILIE:
          state.headline = 'Immobilie';
          break;
        case CONTACTFORM_SITEINDEX_KONTAKTIEREN:
        case CONTACTFORM_SITEINDEX_EMAILSENT:
          if (state.reasonObject.id === CONTACTFORM_ANLIEGEN_SUPPORT) {
            state.headline = 'Bundesland';
          } else {
            state.headline = 'Nachricht';
          }
          break;
        default:
          state.headline = initialState.headline;
          break;
      }
    },
    setPageDialogOpenContactForm: (state, action) => {
      // check hash
      if (action.payload === true) {
        let reasonClean = state.requestBody.reason.toLowerCase();
        reasonClean = reasonClean.replace(' ', '-').replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue');
        window.location.hash = state.siteIndex + '-' + reasonClean;
      } else {
        removeHashFromUrl();
      }

      state.pageDialogOpen = action.payload;
    },
    setGenderContactForm: (state, action) => {
      state.requestBody.register.gender = action.payload;
    },
    setTitleBeforeContactForm: (state, action) => {
      state.requestBody.register.titleBefore = action.payload;
    },
    setTitleAfterContactForm: (state, action) => {
      state.requestBody.register.titleAfter = action.payload;
    },
    setFirstNameContactForm: (state, action) => {
      state.requestBody.register.firstName = action.payload;
    },
    setLastNameContactForm: (state, action) => {
      state.requestBody.register.lastName = action.payload;
    },
    setEmailContactForm: (state, action) => {
      state.requestBody.register.email = action.payload;
    },
    setPhoneNumberPrefixContactForm: (state, action) => {
      state.phoneNumberPrefix = action.payload;
    },
    setPhoneNumberContactForm: (state, action) => {
      setPhoneNumber(state.requestBody.register, 'phoneNumber', state, action.payload);
    },
    setPhoneToUserPhoneContactForm: (state, action) => {
      state.requestBody.register.phoneNumber = action.payload;
    },
    setParsedPhoneNumberContactForm: (state, action) => {
      findAndParsePhoneNumber(state, 'parsedPhoneNumber', action.payload);
    },
    setPasswordContactForm: (state, action) => {
      state.requestBody.register.password = action.payload;
    },
    setPrivacyAggrementContactForm: (state, action) => {
      state.requestBody.register.privacyAggrement = action.payload;
    },
    setReasonContactForm: (state, action) => {
      let reasonObject = action.payload;
      state.reasonObject = reasonObject;
      if (reasonObject.id > -1) {
        state.requestBody.reason = reasonObject.text;

        let reasonClean = reasonObject.text.toLowerCase();
        reasonClean = reasonClean.replace(' ', '-').replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue');
        window.location.hash = state.siteIndex + '-' + reasonClean;
      } else {
        state.requestBody.reason = '';
      }
    },
    setMessageContactForm: (state, action) => {
      state.requestBody.message = action.payload;
    },
    setAppointmentContactForm: (state, action) => {
      state.requestBody.appointment = action.payload;
    },
    setAppointment2ContactForm: (state, action) => {
      state.requestBody.appointment2 = action.payload;
    },
    setAppointmentDateContactForm: (state, action) => {
      state.appointmentDate = action.payload;
    },
    setAppointmentTimeContactForm: (state, action) => {
      state.appointmentTime = action.payload;
    },
    setAppointment2DateContactForm: (state, action) => {
      state.appointment2Date = action.payload;
    },
    setAppointment2TimeContactForm: (state, action) => {
      state.appointment2Time = action.payload;
    },
    setStateContactForm: (state, action) => {
      let stateObject = action.payload;
      state.stateObject = stateObject;
      if (stateObject.id > -1) {
        state.requestBody.state = stateObject.text;
      } else {
        state.requestBody.state = '';
      }
    },
    setPostalCodeContactForm: (state, action) => {
      state.requestBody.postalCode = action.payload;
    },
    setAreaContactForm: (state, action) => {
      state.requestBody.area = action.payload;
    },
    setConditionContactForm: (state, action) => {
      state.requestBody.condition = action.payload;
    },
    setTypeContactForm: (state, action) => {
      let typeObject = action.payload;
      state.typeObject = typeObject;
      if (typeObject.id > -1) {
        state.requestBody.type = typeObject.text;
      } else {
        state.requestBody.type = '';
      }
    },
    setCreateUserContactForm: (state, action) => {
      state.requestBody.createUser = action.payload;
    },
    setCreateJustImmoContactContactForm: (state, action) => {
      state.requestBody.createJustImmoContact = action.payload;
    },
    setPasswordConfirmContactForm: (state, action) => {
      state.passwordConfirm = action.payload;
    },
    setDisplayedDistrictNameContactForm: (state, action) => {
      state.displayedDistrictName = action.payload;
    },
    setResetFields: (state) => {
      return initialState;
    },
  },
});

export const {
  setSiteIndexContactForm,
  setPageDialogOpenContactForm,
  setGenderContactForm,
  setTitleBeforeContactForm,
  setTitleAfterContactForm,
  setFirstNameContactForm,
  setLastNameContactForm,
  setEmailContactForm,
  setPhoneNumberContactForm,
  setPasswordContactForm,
  setPrivacyAggrementContactForm,
  setReasonContactForm,
  setAppointmentContactForm,
  setAppointment2ContactForm,
  setAppointmentDateContactForm,
  setAppointmentTimeContactForm,
  setAppointment2DateContactForm,
  setAppointment2TimeContactForm,
  setStateContactForm,
  setPostalCodeContactForm,
  setAreaContactForm,
  setConditionContactForm,
  setTypeContactForm,
  setMessageContactForm,
  setCreateUserContactForm,
  setCreateJustImmoContactContactForm,
  setPasswordConfirmContactForm,
  setResetFields,
  setDisplayedDistrictNameContactForm,
  setParsedPhoneNumberContactForm,
  setPhoneNumberPrefixContactForm,
  setPhoneToUserPhoneContactForm,
} = contactInformationSlice.actions;

export default contactInformationSlice.reducer;
