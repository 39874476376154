import React from 'react';
import { setGenderContactForm } from '../../reducers/contactInformation';
import { setGenderRegisterForm } from '../../reducers/registerForm';
import { setGenderSPForm } from '../../reducers/searchProfileForm';
import { setGenderObjectRequest } from '../../reducers/objectRequest';

import { useDispatch } from 'react-redux';
import { setEmailFrequency, setEmailTimeOfDay, setWantsToReceiveMails } from '../../reducers/user';

function FormRadio({ options, id, selectedValue, required, error, formType, callback, disabled }) {
  const dispatch = useDispatch();

  const handleRadioChange = (value) => {
    if (callback) {
      callback(value);
    } else {
      if (formType === 'contactInformation') {
        switch (id) {
          case 'inputGender':
            dispatch(setGenderContactForm(value));
            break;
          default:
            break;
        }
      } else if (formType === 'registerForm') {
        switch (id) {
          case 'inputGender':
            dispatch(setGenderRegisterForm(value));
            break;
          default:
            break;
        }
      } else if (formType === 'searchProfileForm') {
        switch (id) {
          case 'gender':
            dispatch(setGenderSPForm(value));
            break;
          default:
            break;
        }
      } else if (formType === 'objectRequestForm') {
        switch (id) {
          case 'gender':
            dispatch(setGenderObjectRequest(value));
            break;
          default:
            break;
        }
      } else if (formType === 'profileEmailSettingsForm') {
        switch (id) {
          case 'emailSettingsWantsToReceiveMails':
            dispatch(setWantsToReceiveMails(value));
            break;
          case 'emailSettingsFrequency':
            dispatch(setEmailFrequency(+value));
            break;
          case 'emailSettingsTimeOfDay':
            dispatch(setEmailTimeOfDay(+value));
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <div className="form__row justify-content-start">
      {options.map((option, index) => {
        return (
          <div className={`form__field ${!disabled ? 'form__field--radio' : 'form__field--radio-disabled'}`} key={option.value}>
            <input type="radio" name={id} id={option.value} value={option.value} checked={selectedValue === option.value ? true : false} onChange={(e) => handleRadioChange(e.target.value)} />
            <label htmlFor={id}>
              {option.label} {!!required ? '*' : ''}
            </label>
          </div>
        );
      })}
      {error && <p className="form__fielderror">{error}</p>}
    </div>
  );
}

export default FormRadio;
