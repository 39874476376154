function IconSocialIG({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      data-name="Ebene 2"
      xmlns="http://www.w3.org/2000/svg"
      width="22.59"
      height="22.59"
      viewBox="0 0 22.59 22.59"
    >
      <g data-name="OBJECTS">
        <g>
          <path
            className="d"
            fill={color}
            d="M11.29,2.03c3.02,0,3.37,.01,4.56,.07,1.1,.05,1.7,.23,2.1,.39,.53,.2,.9,.45,1.3,.84,.4,.4,.64,.77,.84,1.3,.15,.4,.34,1,.39,2.1,.05,1.19,.07,1.55,.07,4.56s-.01,3.37-.07,4.56c-.05,1.1-.23,1.7-.39,2.1-.2,.53-.45,.9-.84,1.3s-.77,.64-1.3,.84c-.4,.15-1,.34-2.1,.39-1.19,.05-1.55,.07-4.56,.07s-3.37-.01-4.56-.07c-1.1-.05-1.7-.23-2.1-.39-.53-.2-.9-.45-1.3-.84-.4-.4-.64-.77-.84-1.3-.15-.4-.34-1-.39-2.1-.05-1.19-.07-1.55-.07-4.56s.01-3.37,.07-4.56c.05-1.1,.23-1.7,.39-2.1,.2-.53,.45-.9,.84-1.3,.4-.4,.77-.64,1.3-.84,.4-.15,1-.34,2.1-.39,1.19-.05,1.55-.07,4.56-.07m0-2.03c-3.07,0-3.45,.01-4.66,.07-1.2,.05-2.02,.25-2.74,.53-.74,.29-1.37,.67-2,1.3-.63,.63-1.01,1.26-1.3,2-.28,.72-.47,1.54-.52,2.74-.06,1.2-.07,1.59-.07,4.66s.01,3.45,.07,4.66c.05,1.2,.25,2.02,.52,2.74,.29,.74,.67,1.37,1.3,2s1.26,1.01,2,1.3c.72,.28,1.54,.47,2.74,.53,1.2,.05,1.59,.07,4.66,.07s3.45-.01,4.66-.07c1.2-.05,2.02-.25,2.74-.53,.74-.29,1.37-.67,2-1.3,.63-.63,1.01-1.26,1.3-2,.28-.72,.47-1.54,.52-2.74,.05-1.2,.07-1.59,.07-4.66s-.01-3.45-.07-4.66c-.05-1.2-.25-2.02-.52-2.74-.29-.74-.67-1.37-1.3-2-.63-.63-1.26-1.01-2-1.3-.72-.28-1.54-.47-2.74-.53-1.2-.05-1.59-.07-4.66-.07"
          />
          <path
            className="d"
            fill={color}
            d="M11.29,5.49c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8,5.8-2.6,5.8-5.8-2.6-5.8-5.8-5.8m0,9.56c-2.08,0-3.76-1.69-3.76-3.76s1.69-3.76,3.76-3.76,3.76,1.69,3.76,3.76-1.69,3.76-3.76,3.76"
          />
          <path
            className="d"
            fill={color}
            d="M18.68,5.27c0,.75-.61,1.36-1.36,1.36s-1.36-.61-1.36-1.36,.61-1.36,1.36-1.36,1.36,.61,1.36,1.36"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconSocialIG;
