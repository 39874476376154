import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageDialog from './PageDialog';

import { useSelector } from 'react-redux';
import '../../styles/cookies.scss';
import SmallCookieBanner from '../SmallCookieBanner';

import { isJson } from '../../util/generalUtils';

function CookieSettingBox({headline, text, cookie, cookieFunc}) {
  return (
    <div className='cookiesetting'>
      <div className="cookiesetting__text">
        <h3>{headline}</h3>
        <p>{text}</p>
      </div>
      <div className="cookiesetting__btn">
        <button className={cookie === true ? 'button active' : 'button'} onClick={() => cookieFunc(true)}>Ja</button>
        <button className={cookie === false ? 'button active' : 'button'} onClick={() => cookieFunc(false)}>Nein</button>
      </div>
    </div>
  )
}

function PageDialogCookie() {
  const location = useLocation();
  const app = useSelector((state) => state.app);
  const [firstCookieDialogShown, setFirstCookieDialogShown] = useState(true);

  const handleSetFirstCookieDialogShown = () => {
    setFirstCookieDialogShown(!firstCookieDialogShown);
  };

  const handleClose = () => {
    setRequiredCookiesAccepted(true);
  };

  const [requiredCookiesAccepted, setRequiredCookiesAccepted] = useState(true);

  useEffect(() => {
    // check if cookie is set in localStorage
    let privacySettings = localStorage.getItem('privacySettings');
    if(privacySettings && isJson(privacySettings)) {
      let required = JSON.parse(privacySettings).required;
      if(required) {
        handleClose();
      }
      else {
        setRequiredCookiesAccepted(false);
        setFirstCookieDialogShown(true);
      }
    }
    else {
      setRequiredCookiesAccepted(false);
      setFirstCookieDialogShown(true);
    }
  }, [])
  

  let classNameDialog = 'pagedialog--cookie';
  if (!firstCookieDialogShown) classNameDialog += ' pagedialog-cookie2';

  //adCookie
  const [adCookie, setAdCookie] = useState(false);
  const handleSetAdCookie = (value) => {
    setAdCookie(value);
  };

  //statCookie
  const [statCookie, setStatCookie] = useState(false);
  const handleSetStatCookie = (value) => {
    setStatCookie(value);
  };

  //bankCookie
  const [bankCookie, setBankCookie] = useState(false);
  const handleSetBankCookie = (value) => {
    setBankCookie(value);
  };

  const handleCookiesLog = () => {
    localStorage.setItem('privacySettings',JSON.stringify({
        marketing: true,
        statistics: true,
        erstebank: true,
        required: true
      })
    );
    handleClose();
  };

  const handleCookiesLogLog = () => {
    localStorage.setItem('privacySettings', JSON.stringify({
        marketing: adCookie,
        statistics: statCookie,
        erstebank: bankCookie,
        required: true
      })
    );
    handleClose();
  };

  const handleNoCookiesLog = () => {
    localStorage.setItem('privacySettings', JSON.stringify({
        marketing: false,
        statistics: false,
        erstebank: false,
        required: true
      })
    );
    handleClose();
  };

  return location.pathname !== `${app.root}/datenschutz` ? (
    <PageDialog
      open={!requiredCookiesAccepted}
      handleClose={handleClose}
      cookieDialog={true}
      cssClasses={classNameDialog}
    >
      {firstCookieDialogShown === true ? (
        <>
          <h2 className="mt-0 pt-0">Ihre Einstellungen zu Cookies &#127850;</h2>
          <p className="lh-140">
            Um Ihnen eine benutzerfreundliche Webseite zu bieten, verwenden wir Cookies. Wenn Sie
            über 16 Jahre sind, klicken Sie auf „Ich bin einverstanden“,{' '}
            <Link to={app.root + '/datenschutz'} title='Datenschutzinformation für KundInnen' className='textlink'>um allen Verarbeitungszwecken</Link>{' '}
            zuzustimmen. Einzelne Kategorien von Verarbeitungszwecken (Cookies) können Sie auch
            ablehnen. Ihre{' '}
            <span className="textlink" onClick={handleSetFirstCookieDialogShown}>
              Cookie Einstellungen
            </span>{' '}
            können Sie jederzeit ändern.
          </p>
          <p className="pt-200rem pb-200rem lh-140">
            Einige unserer Partnerdienste befinden sich in den USA. Nach Rechtsprechung des
            Europäischen Gerichtshofs existiert derzeit in den USA kein angemessener Datenschutz. Es
            besteht das Risiko, dass Ihre Daten durch US-Behörden kontrolliert und überwacht werden.
            Dagegen können Sie keine wirksamen Rechtsmittel vorbringen. Weitere Informationen zum
            Datenschutz finden Sie{' '}
            <Link to={app.root + '/datenschutz'} title='Datenschutzinformation für KundInnen' className='textlink'>hier</Link>.
          </p>
          <div className="d-flex justify-content-center flex-wrap">
            <button onClick={handleCookiesLog} className="button button--orange m-10">
              Ich bin einverstanden
            </button>
            <button className="button button--white-outline m-10" onClick={handleNoCookiesLog}>
              Ablehnen
            </button>
          </div>
        </>
      ) : (
        <>
          <h2 className="mt-300rem pt-0">Ihre Einstellungen zu Cookies &#127850;</h2>  
          <CookieSettingBox
            headline={"Personalisierte Werbung & Statistik"}
            text={"Erlaubt uns personalisierte Inhalte und Werbung anzuzeigen, sowie diese zu analysieren."}
            cookie={adCookie}
            cookieFunc={handleSetAdCookie}
          />
          <CookieSettingBox
            headline={"Statistik"}
            text={"Erlaubt die pseudonyme Analyse der Nutzung und Erfolgsmessung der Website und dient der stetigen Weiterentwicklung."}
            cookie={statCookie}
            cookieFunc={handleSetStatCookie}
          />
          <CookieSettingBox
            headline={"Datenweitergabe an Erste Bank und Sparkassen"}
            text={"Erlaubt uns die Datenweitergabe an die Erste Bank und Sparkassen, um personalisierte Inhalte und Werbung anzuzeigen."}
            cookie={bankCookie}
            cookieFunc={handleSetBankCookie}
          />
          <div className='cookiesetting'>
            <div className="cookiesetting__text">
              <h3>Wesentlich</h3>
              <p>Ausschließlich Cookies, die wesentlich für die Nutzung der Seite sind.</p>
            </div>
            <div className="cookiesetting__btn cookiesetting__btn--required">
              <button className="button active">Ja</button>
            </div>
          </div>        
        
          <button
            className="button button--orange mt-300rem button--fitcontent mb-300rem"
            onClick={handleCookiesLogLog}
          >
            Einstellungen speichern
          </button>
        </>
      )}
    </PageDialog>
  ) : !requiredCookiesAccepted ? (
    <SmallCookieBanner acceptHandler={handleCookiesLog} declineHandler={handleNoCookiesLog} />
  ) : null;
}

export default PageDialogCookie;
