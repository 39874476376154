function IconSonstiges({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 14402" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g data-name="Gruppe 14403">
        <path
          data-name="Pfad 8742"
          d="M15.4,0H5.1A5.121,5.121,0,0,0,0,5.1V15.4a5.121,5.121,0,0,0,5.1,5.1H15.4a5.121,5.121,0,0,0,5.1-5.1V5.1A5,5,0,0,0,15.4,0m1.7,15.4a1.752,1.752,0,0,1-1.7,1.7H5.1a1.752,1.752,0,0,1-1.7-1.7V5.1A1.752,1.752,0,0,1,5.1,3.4H15.4a1.752,1.752,0,0,1,1.7,1.7ZM42.9,0H32.6a5.121,5.121,0,0,0-5.1,5.1V15.4a5.121,5.121,0,0,0,5.1,5.1H42.9A5.121,5.121,0,0,0,48,15.4V5.1A5.121,5.121,0,0,0,42.9,0m1.7,15.4a1.752,1.752,0,0,1-1.7,1.7H32.6a1.752,1.752,0,0,1-1.7-1.7V5.1a1.752,1.752,0,0,1,1.7-1.7H42.9a1.752,1.752,0,0,1,1.7,1.7Zm-29.2,12H5.1A5.121,5.121,0,0,0,0,32.5V42.8A5.143,5.143,0,0,0,5.1,48H15.4a5.121,5.121,0,0,0,5.1-5.1V32.6a5.02,5.02,0,0,0-5.1-5.2m1.7,15.5a1.752,1.752,0,0,1-1.7,1.7H5.1a1.752,1.752,0,0,1-1.7-1.7V32.6a1.752,1.752,0,0,1,1.7-1.7H15.4a1.752,1.752,0,0,1,1.7,1.7ZM42.9,27.4H32.6a5.121,5.121,0,0,0-5.1,5.1V42.8a5.121,5.121,0,0,0,5.1,5.1H42.9A5.121,5.121,0,0,0,48,42.8V32.6a5.143,5.143,0,0,0-5.1-5.2m1.7,15.5a1.752,1.752,0,0,1-1.7,1.7H32.6a1.752,1.752,0,0,1-1.7-1.7V32.6a1.752,1.752,0,0,1,1.7-1.7H42.9a1.752,1.752,0,0,1,1.7,1.7Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconSonstiges;
