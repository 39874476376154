import React from 'react';

function IconHouse({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="24.663" height="21.236" viewBox="0 0 24.663 21.236">
      <path
        data-name="Pfad 10476"
        d="M23.9,11.481,20.434,8.01,12.688.264a.821.821,0,0,0-1.207,0L3.735,8.06.264,11.531a.821.821,0,0,0,0,1.207.928.928,0,0,0,.6.251.928.928,0,0,0,.6-.251l1.962-1.962v9.1a.881.881,0,0,0,.855.855H19.83a.881.881,0,0,0,.855-.855V10.726l1.962,1.962a.821.821,0,0,0,1.207,0,.785.785,0,0,0,.05-1.207m-4.929,7.495H12.939V14.65a.855.855,0,1,0-1.71,0v4.326H5.193V9.016l6.891-6.891,6.891,6.891Z"
        transform="translate(0.25 0.25)"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default IconHouse;
