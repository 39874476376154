import { useEffect, useState } from 'react';

import InfoDialogSuchprofilDeactivate from '../Dialogs/InfoDialogSuchprofilDeactivate';
import { immobilienartOptionsJustImmo } from '../FormFields/util/formOptions';
import Card from './Card';

import '../../styles/card.scss';

// ICONS
import { useDispatch } from 'react-redux';

import { SEARCHPROFILE_SITEINDEX_EDIT, SEARCHPROFILE_STATE_ACTIVE, JUSTIMMO_TYPE_FEDERAL_STATE_ID } from '../../constants';

import {
  setBalconyEnabledSPForm,
  setCoveredBalconyEnabledSPForm,
  setDistrictSPForm,
  setEstateTypeSPForm,
  setFederalStateSPForm,
  setLivingAreaFromSPForm,
  setLivingAreaToSPForm,
  setGardenEnabledSPForm,
  setNameSPForm,
  setPageDialogOpenSPEdit,
  setPriceFromSPForm,
  setPriceToSPForm,
  setRoomsFromSPForm,
  setSiteAreaFromSPForm,
  setSiteAreaToSPForm,
  setSiteIndexSPForm,
  setTerraceEnabledSPForm,
} from '../../reducers/searchProfileForm';
import parseCurrency from '../../util/currencyParser';
import PageDialogSuchprofilEdit from '../Dialogs/PageDialogSuchprofilEdit';
import IconMagnifyingGlassWithHouse from '../Icons/IconMagnifyingGlassWithHouse';
import { useFetchSearchprofiles } from '../../hooks/searchprofiles/useFetchSearchprofiles';
import statesWithZips from '../FormFields/util/statesWithZips';

function SuchprofilCard({ suchProfilData, cssClasses = '' }) {
  if (!cssClasses) cssClasses = 'suchprofil-card';
  else cssClasses += ' suchprofil-card';
  const dispatch = useDispatch();

  const [openInfoDialogSuchprofilDeactivate, setOpenInfoDialogSuchprofilDeactivate] = useState(false);
  const [suchprofilEditId, setSuchprofilEditId] = useState(null);

  const handleSetOpenInfoDialogSuchprofilDeactivate = () => {
    setOpenInfoDialogSuchprofilDeactivate(!openInfoDialogSuchprofilDeactivate);
  };

  const { getFederalStateFromRequestBody, getDistrictsFromRequestBody, getLocationTypeIdByLabel } = useFetchSearchprofiles();

  /**
   * set data for searchprofile form and open pagedialog for editing
   */
  const handleOpenPageDialogSearchProfileEdit = () => {
    setSuchprofilEditId(suchProfilData.id);

    dispatch(setNameSPForm(suchProfilData.name));

    let federalState = getFederalStateFromRequestBody(suchProfilData.criteria.location.location_ids);
    let districts = getDistrictsFromRequestBody(suchProfilData.criteria.location.location_ids);

    if (federalState) {
      dispatch(setFederalStateSPForm(federalState));
    } else if (districts) {
      // get federalState from district
      let districtName = districts[0].text;

      federalState = statesWithZips
        .filter((state) => state.zipCodes?.length > 0)
        .map((state) => {
          let hasDistrictId = state.zipCodes.find((zip) => districtName.includes(zip));

          if (hasDistrictId) {
            return state;
          }
        })
        .filter((state) => state !== undefined);

      if (federalState && federalState[0] && federalState[0].justImmoId && federalState[0].label) {
        let federalStateObject = {
          type: JUSTIMMO_TYPE_FEDERAL_STATE_ID,
          id: federalState[0].justImmoId,
          text: federalState[0].label,
        };

        dispatch(setFederalStateSPForm(federalStateObject));
      }
    }

    dispatch(setFederalStateSPForm({ type: 0, id: 1, text: 'Niederösterreich' }));

    if (districts?.length > 0) {
      districts = districts.map((item) => {
        return { id: item.id?.toString(), text: item.text, type: getLocationTypeIdByLabel(item.type) };
      });

      dispatch(setDistrictSPForm(districts));
    }

    // get correct data for estateType
    let estateTypeVal = null;
    if (suchProfilData.criteria?.types[0]) {
      let estateId = suchProfilData.criteria.types[0].id;
      let estateName = suchProfilData.criteria.types[0].name || suchProfilData.criteria.types[0].text;
      if (estateId && estateName) {
        estateTypeVal = { id: estateId, text: estateName };
      }
    }

    dispatch(setEstateTypeSPForm(estateTypeVal));
    dispatch(setPriceFromSPForm(suchProfilData.criteria?.price?.from));

    dispatch(setPriceToSPForm(suchProfilData.criteria?.price?.to));
    dispatch(setPriceToSPForm(suchProfilData.criteria?.price?.to));

    if (suchProfilData.criteria?.living_area && Object.values(suchProfilData.criteria.living_area).some((item) => item !== null)) {
      dispatch(setLivingAreaFromSPForm(suchProfilData.criteria?.living_area?.from));
      dispatch(setLivingAreaToSPForm(suchProfilData.criteria?.living_area?.to));
    }

    if (suchProfilData.criteria?.site_area && Object.values(suchProfilData.criteria.site_area).some((item) => item !== null)) {
      dispatch(setSiteAreaFromSPForm(suchProfilData.criteria?.site_area?.from));
      dispatch(setSiteAreaToSPForm(suchProfilData.criteria?.site_area?.to));
    }

    dispatch(setRoomsFromSPForm(suchProfilData.criteria?.rooms?.from));

    if (suchProfilData.criteria?.balcony.enabled) {
      dispatch(setBalconyEnabledSPForm(true));
    }

    if (suchProfilData.criteria?.terrace.enabled) {
      dispatch(setTerraceEnabledSPForm(true));
    }

    if (suchProfilData.criteria?.covered_balcony.enabled) {
      dispatch(setCoveredBalconyEnabledSPForm(true));
    }

    if (suchProfilData.criteria?.garden.enabled) {
      dispatch(setGardenEnabledSPForm(true));
    }

    dispatch(setSiteIndexSPForm(SEARCHPROFILE_SITEINDEX_EDIT));
    dispatch(setPageDialogOpenSPEdit(true));
  };

  /**
   * get Paramete
   * @param {String} category
   * @returns
   */
  const getParameter = (category) => {
    if (!category) return null;

    if (category === 'exteriorSurface') {
      let außenflächen = [];
      Object.entries(suchProfilData.criteria).forEach(([key, val]) => {
        if (key === 'covered_balcony' && val.enabled) {
          außenflächen.push('Loggia');
        }
        if (key === 'balcony' && val.enabled) {
          außenflächen.push('Balkon');
        }
        if (key === 'terrace' && val.enabled) {
          außenflächen.push('Terrasse');
        }
        if (key === 'garden' && val.enabled) {
          außenflächen.push('Garten');
        }
      });

      if (außenflächen.length > 0) {
        return <li key={category}>Außenflächen: {außenflächen.join(', ')}</li>;
      }
    }

    let value = suchProfilData.criteria[category];
    if (!value) return null;

    switch (category) {
      case 'types':
        let typeId = -1;
        if (value[0] && value[0].id) {
          typeId = value[0].id;
        }

        let type = immobilienartOptionsJustImmo.find((option) => option.id === typeId);
        if (type && type.text) {
          return <li key={category}>Immobilientyp: {type.text}</li>;
        }
        break;
      case 'location':
        if (value.location_ids) {
          let locations = value.location_ids.map((v) => v.text);
          if (locations.length > 0) {
            return <li key={category}>Lage: {locations.join(', ')}</li>;
          }
        }
        break;
      case 'price':
        if (value.from && value.to) {
          return <li key={category}>Preis: {parseCurrency(value.from) + ' - ' + parseCurrency(value.to)}</li>;
        } else if (value.from && !value.to) {
          return <li key={category}>Mindestpreis: {parseCurrency(value.from)}</li>;
        } else if (!value.from && value.to) {
          return <li key={category}>Maximalpreis: {parseCurrency(value.to)}</li>;
        }
        break;
      case 'living_area':
      case 'site_area':
        if (value.from && value.to) {
          return <li key={category}>Fläche: {parseInt(value.from) + 'm² - ' + parseInt(value.to) + 'm²'}</li>;
        } else if (value.from && !value.to) {
          return <li key={category}>Mindestfläche: {parseInt(value.from) + 'm²'}</li>;
        } else if (!value.from && value.to) {
          return <li key={category}>Maximalfläche: {parseInt(value.to) + 'm²'}</li>;
        }
        break;
      case 'rooms':
        if (value.from) {
          return <li key={category}>Zimmer: Mind. {value.from}</li>;
        }
        break;
      case 'furnishings':
        // TBA: Ausstattung temporär entfernt
        let furnishings = value.map((v) => v.name);
        if (furnishings.length > 0) {
          return <li key={category}>Ausstattung: {furnishings.join(', ')}</li>;
        }
        break;
      default:
        break;
    }
  };

  const categoryArray = ['types', 'location', 'living_area', 'site_area', 'price', 'rooms', 'exteriorSurface'];

  return (
    <>
      <Card cardStyle="customContent" cssClasses={cssClasses}>
        <div className={suchProfilData.state === SEARCHPROFILE_STATE_ACTIVE ? 'card__content' : 'card__content card__content--deaktiv'}>
          <div className="d-flex flex-row align-items-center">
            <IconMagnifyingGlassWithHouse color="#0B1F42" />
          </div>

          <h4>{suchProfilData.name}</h4>

          <div className="suchprofil-card__data">
            <h4>Parameter:</h4>

            {categoryArray && categoryArray.length > 0 && (
              <ul className="list-style-none">
                {categoryArray.map((cat) => {
                  return getParameter(cat);
                })}
              </ul>
            )}
          </div>

          <div className="button-panel align-items-center">
            <button className="button button--blue" onClick={handleSetOpenInfoDialogSuchprofilDeactivate}>
              {suchProfilData.state === SEARCHPROFILE_STATE_ACTIVE ? <>Deaktivieren</> : <>Aktivieren</>}
            </button>

            {suchProfilData.state === SEARCHPROFILE_STATE_ACTIVE && (
              <button className="button button--gray-outline" onClick={handleOpenPageDialogSearchProfileEdit}>
                Bearbeiten
              </button>
            )}
          </div>
        </div>
      </Card>

      <InfoDialogSuchprofilDeactivate open={openInfoDialogSuchprofilDeactivate} handleClose={handleSetOpenInfoDialogSuchprofilDeactivate} suchprofil={suchProfilData} />

      {suchprofilEditId && <PageDialogSuchprofilEdit suchprofilId={suchprofilEditId} />}
    </>
  );
}

export default SuchprofilCard;
