import { createSlice } from '@reduxjs/toolkit';
import { MARKTINFO_SITEINDEX_FORM } from '../constants';

const initialState = {
  siteIndex: MARKTINFO_SITEINDEX_FORM,
  pageDialogOpen: false,
  street: '',
  town: {},
  zip: {},
  typedZip: '',
};

export const marktinfoLightSlice = createSlice({
  name: 'marktinfoLight',
  initialState,
  reducers: {
    setSiteIndexMarktinfoForm: (state, action) => {
      // window.location.hash = action.payload;
      state.siteIndex = action.payload;
    },
    setPageDialogOpenMarktinfoForm: (state, action) => {
      // check hash
      // const url = new URL(window.location);
      // if(action.payload === true) {
      //   window.history.pushState({}, '', url.href + "/marktanalyse#" + state.siteIndex);
      // }
      // else {
      //   removeHashFromUrl();
      //   removeLastDirectoryFromUrl("marktanalyse");
      // }

      state.pageDialogOpen = action.payload;
    },
    setMarktinfoLightStreet: (state, action) => {
      state.street = action.payload;
    },
    setMarktinfoLightTown: (state, action) => {
      state.town = action.payload;
    },
    setMarktinfoLightZip: (state, action) => {
      state.zip = action.payload;
    },
    setMarktinfoLightTypedZip: (state, action) => {
      state.typedZip = action.payload;
    },

    setResetFieldsMarktinfoLightForm: (state) => {
      return initialState;
    },
  },
});

export const {
  setSiteIndexMarktinfoForm,
  setMarktinfoLightTown,
  setPageDialogOpenMarktinfoForm,
  setMarktinfoLightStreet,
  setMarktinfoLightZip,
  setMarktinfoLightTypedZip,
  setResetFieldsMarktinfoLightForm,
} = marktinfoLightSlice.actions;

export default marktinfoLightSlice.reducer;
