function IconMessageSent1({ cssClasses, color }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="104"
      height="166.6"
      viewBox="0 0 104 166.6"
    >
      <defs>
        <clipPath id="clip-path-messagesent-1">
          <rect data-name="Rechteck 4015" width="104" height="104" fill="none" />
        </clipPath>
      </defs>
      <path
        data-name="Pfad 7965"
        d="M30.519,52.382a2.824,2.824,0,0,0,.913.6,2.78,2.78,0,0,0,2.134,0,2.824,2.824,0,0,0,.913-.6l25.2-25.2a2.8,2.8,0,1,0-3.959-3.959L35.3,43.641V2.8a2.8,2.8,0,1,0-5.6,0V43.641L9.278,23.223a2.8,2.8,0,1,0-3.959,3.959Z"
        transform="translate(17.502)"
        fill={color}
      />
      <g data-name="Gruppe 11778" transform="translate(0 62.6)">
        <g id="-2" data-name="Gruppe 11778" clipPath="url(#clip-path-messagesent-1)">
          <path
            data-name="Pfad 8131"
            d="M75.833,43.333a32.292,32.292,0,0,1,19.5,6.561V2.167A2.167,2.167,0,0,0,93.167,0h-91A2.167,2.167,0,0,0,0,2.167V62.833A2.167,2.167,0,0,0,2.167,65H17.333V80.167a2.167,2.167,0,0,0,1.339,2,2.219,2.219,0,0,0,.828.165,2.183,2.183,0,0,0,1.534-.633L37.73,65h7.5a32.526,32.526,0,0,1,30.6-21.667"
            fill={color}
          />
          <path
            data-name="Pfad 8132"
            d="M39.167,11A28.167,28.167,0,1,0,67.333,39.167,28.2,28.2,0,0,0,39.167,11M50,41.333H41.333V50A2.167,2.167,0,0,1,37,50V41.333H28.333a2.167,2.167,0,0,1,0-4.333H37V28.333a2.167,2.167,0,1,1,4.333,0V37H50a2.167,2.167,0,0,1,0,4.333"
            transform="translate(36.667 36.667)"
            fill={color}
          />
        </g>
        <ellipse
          data-name="Ellipse 1592"
          cx="21.5"
          cy="22"
          rx="21.5"
          ry="22"
          transform="translate(55 53)"
          fill={color}
        />
        <path
          data-name="Pfad 8133"
          d="M12.873,34.938h-4.1V18.474q0-2.948.14-4.674-.4.419-.985.924T4.013,17.933l-2.058-2.6,7.5-5.9h3.418Z"
          transform="translate(66.627 54)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default IconMessageSent1;
