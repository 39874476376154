
import Skeleton from "react-loading-skeleton";

function ImmoWohneinheitenSkeleton () {
    const skeletonColor = '#e4e4e4';

    return (
			<>
				<div className="subunits">
					<Skeleton baseColor={skeletonColor} height={150} />
				</div>
			</>
    )
}

export default ImmoWohneinheitenSkeleton;