import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
import PageDialogBesichtigungen from '../Dialogs/PageDialogBesichtigungen';
import PageDialogWeitere from '../Dialogs/PageDialogWeitere';
import MenuCount from './MenuCount';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMobileMenu } from '../../reducers/app';
import { useHandleUserRealtyActive } from '../../hooks/user/useHandleUserRealtyActive';
import { setUserRealtyActive } from '../../reducers/user';

function Submenu({ submenu }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);

  const { getPageDialogSetting, getLinkCssClass, getLinkName } = useHandleMenu();
  const { getBesichtigungenUnreadCount, getWeitereUnreadCount } = useHandleUserRealtyActive();

  const [openBesichtigungen, setOpenBesichtigungen] = useState(false);
  const [openWeitere, setOpenWeitere] = useState(false);

  const changeRealtyActive = (sublink) => {
    const sublinkParts = sublink.split('/');
    const currentImmo = user.userRealties.find((immo) => immo.catalogue_number.replace('/', '-') === sublinkParts[3]);
    dispatch(setUserRealtyActive(currentImmo));
  };

  const handleSetOpenPageDialog = (sub) => {
    if (!sub) {
      setOpenBesichtigungen(false);
      setOpenWeitere(false);

      // update unread counts on notifications
      const lsArray = localStorage.getItem('activityIdsRead') ? localStorage.getItem('activityIdsRead').split(';') : [];
      if (lsArray.length > 0) {
        getBesichtigungenUnreadCount(lsArray);
        getWeitereUnreadCount(lsArray);
      }
    } else {
      let linkname = getLinkName(sub);
      switch (linkname) {
        case 'besichtigungen':
          setOpenBesichtigungen(true);
          break;
        case 'aktivitaeten':
          setOpenWeitere(true);
          break;
        default:
          break;
      }

      if (app.mobileMenuOpen) {
        dispatch(toggleMobileMenu());
      }
    }
  };

  return (
    <>
      <ul className="header__nav__sub">
        {submenu.map((sub) => {
          const openAsPageDialog = getPageDialogSetting(sub);
          const key = sub.name.toLowerCase().replace(/[^A-Z0-9]/gi, '');
          return (
            <li key={key} className={getLinkCssClass(sub)} data-id={`linkitem--${key}`} onClick={sub.link.includes('immobilie') ? () => changeRealtyActive(sub.link) : () => {}}>
              {sub.link && !openAsPageDialog ? (
                <Link to={sub.link} title={sub.name} className="header__nav__link">
                  <span className="header__nav__span">
                    {sub.name}
                    <MenuCount li={sub} />
                  </span>
                </Link>
              ) : (
                <span className="header__nav__span" onClick={() => handleSetOpenPageDialog(sub)}>
                  {sub.name}
                  <MenuCount li={sub} />
                </span>
              )}
            </li>
          );
        })}
      </ul>

      <PageDialogBesichtigungen open={openBesichtigungen} handleClose={() => handleSetOpenPageDialog(false)} />
      <PageDialogWeitere open={openWeitere} handleClose={() => handleSetOpenPageDialog(false)} />
    </>
  );
}

export default Submenu;
