function IconThumbUpDown({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="112.319" height="46.998" viewBox="0 0 112.319 46.998">
      <g id="Gruppe_15963" data-name="Gruppe 15963" transform="translate(-15852.19 -9003.008)">
        <g id="Gruppe_15957" data-name="Gruppe 15957" transform="translate(15964.51 9050.006) rotate(180)">
          <g id="Gruppe_15959" data-name="Gruppe 15959" transform="translate(0 0)">
            <path
              id="Pfad_10362"
              data-name="Pfad 10362"
              d="M46.559,15.118H31.879V5.806a6.005,6.005,0,0,0-1.972-4.163A6.337,6.337,0,0,0,25.635,0a6.116,6.116,0,0,0-5.149,2.848L11.5,17.2H3.725A3.694,3.694,0,0,0,0,20.924V40.972A3.694,3.694,0,0,0,3.725,44.7H12.05l2.52,1.315A9.3,9.3,0,0,0,18.733,47H43.382a6.24,6.24,0,0,0,6.135-5.368l2.958-19.39c0-.219.11-.438.11-.767a6.148,6.148,0,0,0-6.025-6.354M3.725,21.034h7.011V40.972H3.725Zm45.134.657L45.9,41.081a2.381,2.381,0,0,1-2.41,2.081H18.733a6.5,6.5,0,0,1-2.52-.548l-1.753-.876V19.609L23.772,4.82a2.448,2.448,0,0,1,1.972-1.1,2.415,2.415,0,0,1,1.643.657,2.879,2.879,0,0,1,.767,1.643V16.98a1.92,1.92,0,0,0,1.862,1.862H46.449a2.529,2.529,0,0,1,2.41,2.52Z"
              transform="translate(0 0)"
              fill={color}
            />
          </g>
        </g>
        <g id="Gruppe_15958" data-name="Gruppe 15958" transform="translate(15852.19 9003.008)">
          <g id="Gruppe_15960" data-name="Gruppe 15960" transform="translate(0 0)">
            <path
              id="Pfad_10362-2"
              data-name="Pfad 10362"
              d="M46.559,15.118H31.879V5.806a6.005,6.005,0,0,0-1.972-4.163A6.337,6.337,0,0,0,25.635,0a6.116,6.116,0,0,0-5.149,2.848L11.5,17.2H3.725A3.694,3.694,0,0,0,0,20.924V40.972A3.694,3.694,0,0,0,3.725,44.7H12.05l2.52,1.315A9.3,9.3,0,0,0,18.733,47H43.382a6.24,6.24,0,0,0,6.135-5.368l2.958-19.39c0-.219.11-.438.11-.767a6.148,6.148,0,0,0-6.025-6.354M3.725,21.034h7.011V40.972H3.725Zm45.134.657L45.9,41.081a2.381,2.381,0,0,1-2.41,2.081H18.733a6.5,6.5,0,0,1-2.52-.548l-1.753-.876V19.609L23.772,4.82a2.448,2.448,0,0,1,1.972-1.1,2.415,2.415,0,0,1,1.643.657,2.879,2.879,0,0,1,.767,1.643V16.98a1.92,1.92,0,0,0,1.862,1.862H46.449a2.529,2.529,0,0,1,2.41,2.52Z"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconThumbUpDown;
