import mysrealLogo from '../../assets/images/Logo.svg';
import mysrealLogo_blue from '../../assets/images/Logo_blue.svg';
import srealLogo from '../../assets/images/Logos_sreal.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Logo({ cssClasses, showBothLogos, logoColor = 'white' }) {
  const app = useSelector((state) => state.app);
	const user = useSelector((state) => state.user);

  return (
    <>
      {showBothLogos ? (
        <div className="footer-bothlogos">
          <a href="https://www.sreal.at" title="sreal.at" target="_blank" rel="noreferrer">
            <img src={srealLogo} alt={app.companyName} className={cssClasses} />
          </a>

          <Link to={user.isLoggedIn ? app.root + '/dashboard' : app.root + '/'} title={app.companyName}>
            <img src={mysrealLogo} alt={app.companyName} className={cssClasses} />
          </Link>
        </div>
      ) : (
        <Link to={user.isLoggedIn ? app.root + '/dashboard' : app.root + '/'} title={app.companyName}>
          <img src={(app.mobileMenuOpen && logoColor === 'blue') ? mysrealLogo_blue : mysrealLogo} alt={app.companyName} className={cssClasses} />
        </Link>
      )}
    </>
  );
}

export default Logo;
