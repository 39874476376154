import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from './Card';

// ICONS
import IconGutachten from '../Icons/IconGutachten';
import IconGutachtenHakerl from '../Icons/IconGutachtenHakerl';
import IconGutachtenOutlined from '../Icons/IconGutachtenOutlined';

// STORE
import { setPageDialogOpenContactForm, setReasonContactForm } from '../../reducers/contactInformation';

import { CONTACTFORM_ANLIEGEN_IMMOGUTACHTEN, CONTACTFORM_ANLIEGEN_BERATUNG, CONTACTFORM_ANLIEGEN_VERKAUF } from '../../constants';
import '../../styles/wohnwert.scss';

import { getRealtyFile } from '../../api/JustImmo';
import { useHandleWohnwert } from '../../hooks/user/useHandleWohnwert';
import { anliegenOptions } from '../FormFields/util/formOptions';

function WohnwertProCard({ cssClasses }) {
  if (!cssClasses) cssClasses = 'wohnwertpro-card';
  else cssClasses += ' wohnwertpro-card';

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { getWohnwertGutachten } = useHandleWohnwert();

  const handleGutachten = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_VERKAUF]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  const [isDesktop, setIsDesktop] = useState(false);
  const [gutachten, setGutachten] = useState(null);
  useEffect(() => {
    if (isDesktop) {
      setIsDesktop(true);
    }

    setGutachten(getWohnwertGutachten());
  }, []);

  const downloadDocJustImmo = async (file) => {
    try {
      const realtyFile = await getRealtyFile(user.userRealtyActive.id, user.userRealtyActive.tenant_id, file.storage_key);
      const url = window.URL.createObjectURL(realtyFile);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.original_filename;
      a.click();
    } catch (e) {
      //handle realty file error
      console.error('e', e);
    }
  };

  return (
    <Card cssClasses={cssClasses + ' background-blue text-white'} cardStyle={'customContent'}>
      <div className="card__content card__content--wohnwert">
        <IconGutachtenHakerl color="#fff" cssClasses="mobile-hidden mb-20" />
        <h2 className="mb-100rem text-white">Wohnwert Pro</h2>

        {/**gutachten ? (
          <div className="wohnwertpro__content--withGutachten">
            <p className="font-90">Hier finden Sie das persönliche Gutachten zu Ihrer Immobilie.</p>

            <div className="wohnwertpro__content__item" onClick={() => downloadDocJustImmo(gutachten)}>
              <div className="wohnwertpro__content__item__icon">
                <IconGutachtenHakerl color="#fff" />
              </div>
              <span className={isDesktop ? 'textlink mt-20' : 'textlink'}>Ihr Gutachten</span>
            </div>
          </div>
        ) : (**/}
        <>
          <p className="font-90 h-100">Eine s REAL Immobilienexpert:in schaut sich Ihre Immobilie für eine unverbindliche Verkehrswertschätzung an. Wir freuen uns, Sie kennenzulernen!</p>

          <div className="d-flex d-flex--btnandicon mt-auto">
            <div>
              <button className="button button--white-outline mt-100rem" onClick={handleGutachten}>
                Anfrage senden
              </button>
            </div>
          </div>
        </>
      </div>
    </Card>
  );
}

export default WohnwertProCard;
