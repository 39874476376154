import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../../styles/kennzahlen.scss';
import FilterMenu from '../FilterMenu';
import KennzahlBars from './KennzahlBars';
import KennzahlCircleBar from './KennzahlCircleBar';
import KennzahlList from './KennzahlList';
import KennzahlPieChart from './KennzahlPieChart';

import {
  KENNZAHL_LABEL_AGEGROUPS,
  KENNZAHL_LABEL_CATERING,
  KENNZAHL_LABEL_DAYSONMARKET,
  KENNZAHL_LABEL_FAMILY,
  KENNZAHL_LABEL_HEALTH,
  KENNZAHL_LABEL_HOUSEHOLDCHILDREN,
  KENNZAHL_LABEL_HOUSINGTYPE,
  KENNZAHL_LABEL_LEISURE,
  KENNZAHL_LABEL_SHOPPING,
  KENNZAHL_LABEL_VALUATION,
} from '../../constants';

function Kennzahlen({ filtermenu, kennzahlen, hasButton, handleButtonClick, children, cssClasses = '' }) {
  const [kennzahlIndex, setKennzahlIndex] = useState(0);

  const marktinfoLight = useSelector((state) => state.marktinfoLight);
  let user = useSelector((state) => state.user);

  const handleSetKennzahlenIndex = (event) => {
    let index = event.target.dataset.index;
    setKennzahlIndex(parseInt(index));
  };

  useEffect(() => {
    resetKennzahlIndex();
  }, [user.marktinfoResultActive, user.marktinfoLastResults]);

  const resetKennzahlIndex = () => {
    setKennzahlIndex(0);
  };

  return (
    <div className={`kennzahlen ${cssClasses}`}>
      <div className="d-flex justify-content-between align-items-end flex-wrap">
        <p className="marktinfolight-card__marktanalys__txt-desktop pr-200rem font-weight-600">Das Ergebnis Ihrer Marktanalyse:</p>
        <FilterMenu
          categories={filtermenu}
          activeIndex={kennzahlIndex}
          handleClick={handleSetKennzahlenIndex}
          cssClasses={marktinfoLight.pageDialogOpen ? 'filtermenu--inverted grey' : ''}
          cutside={marktinfoLight.pageDialogOpen ? 'right' : 'left'}
        />
      </div>
      {kennzahlen.map((k, index) => {
        let currentCategory = '';
        if (filtermenu[index] && filtermenu[index].label) {
          currentCategory = filtermenu[index].label;
        }

        return (
          <div key={index} className={kennzahlIndex !== index ? 'kennzahlen-wrapper is-hidden' : 'kennzahlen-wrapper'}>
            {currentCategory === KENNZAHL_LABEL_LEISURE ||
            currentCategory === KENNZAHL_LABEL_HEALTH ||
            currentCategory === KENNZAHL_LABEL_SHOPPING ||
            currentCategory === KENNZAHL_LABEL_CATERING ||
            currentCategory === KENNZAHL_LABEL_FAMILY ? (
              <KennzahlCircleBar key={index} kennzahl={k} category={currentCategory} hasButton={hasButton} handleButtonClick={handleButtonClick} />
            ) : currentCategory === KENNZAHL_LABEL_HOUSEHOLDCHILDREN ? (
              <KennzahlList kennzahl={k.value} category={currentCategory} hasButton={hasButton} handleButtonClick={handleButtonClick} />
            ) : currentCategory === KENNZAHL_LABEL_AGEGROUPS ? (
              <KennzahlPieChart kennzahl={k} category={currentCategory} hasButton={hasButton} handleButtonClick={handleButtonClick} />
            ) : currentCategory === KENNZAHL_LABEL_HOUSINGTYPE ? (
              <KennzahlBars kennzahl={k.value} category={currentCategory} hasButton={hasButton} handleButtonClick={handleButtonClick} />
            ) : currentCategory === KENNZAHL_LABEL_DAYSONMARKET || currentCategory === KENNZAHL_LABEL_VALUATION ? (
              <KennzahlList kennzahl={k} category={currentCategory} hasButton={hasButton} handleButtonClick={handleButtonClick} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default Kennzahlen;
