import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormRadio from '../../FormFields/FormRadio';
import FormInput from '../../FormFields/FormInput';
import Textarea from '../../FormFields/Textarea';
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormSelectPhoneNumber from '../../FormFields/customFields/FormSelectPhoneNumber';
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';

import { genderOptions } from '../../FormFields/util/formOptions';
import { findPhonePrefix } from '../../../util/generalUtils';

// STORE
import {
  setGenderObjectRequest,
  setTitleBeforeObjectRequest,
  setTitleAfterObjectRequest,
  setFirstNameObjectRequest,
  setLastNameObjectRequest,
  setEmailObjectRequest,
  setPhoneToUserPhoneObjectRequest,
  setPhoneNumberPrefixObjectRequest,
  setParsedPhoneNumberObjectRequest,
} from '../../../reducers/objectRequest';
import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';

function ObjectRequestMain({ forwardAction, error }) {
  let currentFormValidators = useSelector((state) => state.formValidators);
  const objectRequest = useSelector((state) => state.objectRequest);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const formType = 'objectRequestForm';

  useEffect(() => {
    if (user.isLoggedIn && Object.keys(user.userObject).length > 0) {
      const foundPrefix = findPhonePrefix(user.userObject.phoneNumber);
      // fill data
      dispatch(setGenderObjectRequest(user.userObject.gender));
      dispatch(setTitleBeforeObjectRequest(user.userObject.titleBefore));
      dispatch(setTitleAfterObjectRequest(user.userObject.titleAfter));
      dispatch(setFirstNameObjectRequest(user.userObject.firstName));
      dispatch(setLastNameObjectRequest(user.userObject.lastName));
      dispatch(setEmailObjectRequest(user.userObject.userName));
      dispatch(setPhoneNumberPrefixObjectRequest(foundPrefix));
      dispatch(setParsedPhoneNumberObjectRequest(user.userObject.phoneNumber.substring(foundPrefix.text.substring(0, foundPrefix.text.indexOf(' ')).length)));
      dispatch(setPhoneToUserPhoneObjectRequest(user.userObject.phoneNumber));
    }
  }, []);

  return (
    <>
      <h2 className="form__title">Persönliche Daten</h2>
      <p>
        Um Ihnen detaillierte Informationen zu unseren Immobilienangeboten übermitteln zu können,
        bedarf es eines Maklervertrages. Über diesen, unsere AGB und Ihre Rechte als Konsument:in
        möchten wir Sie gerne umfassend informieren. Bitte entnehmen Sie diese unserer Homepage und
        unmittelbar unter diesem Link:{' '}
        <a
          href="https://www.sreal.at/de/agb"
          target="_blank"
          rel="noopener noreferrer"
          title="s REAL AGB und Information zum Maklervertrag"
        >
          s REAL AGB und Information zum Maklervertrag.
        </a>
        <br />
        <br />
        Der Maklervertrag wird damit für Sie unkompliziert abgeschlossen, wenn Sie auf den Button
        „Absenden und Maklervertrag zustimmen“ klicken. Diese Informationen über unsere
        Immobilienangebote sind selbstverständlich kostenlos! Die Vermittlungsprovision fällt nur
        an, wenn Sie eine bestimmte Immobilie dann tatsächlich kaufen oder mieten, also wir für Sie
        erfolgreich tätig sein konnten.
      </p>

      <h3 className="form__title">Kontakt</h3>

      <FormRadio
        options={genderOptions}
        id={'gender'}
        error={currentFormValidators.genderError}
        formType={formType}
        selectedValue={objectRequest.requestBody.gender}
      />

      <div className="form__row form__row--double">
        <FormInput
          type={'text'}
          id={'inputTitlePre'}
          label={'Titel (vorgestellt)'}
          value={objectRequest.requestBody.titlePre}
          formType={formType}
        />

        <FormInput
          type={'text'}
          id={'inputTitlePost'}
          label={'Titel (nachgestellt)'}
          value={objectRequest.requestBody.titlePost}
          formType={formType}
        />
      </div>

      <FormInput
        type={'text'}
        id={'inputFirstName'}
        label={'Vorname'}
        value={objectRequest.requestBody.firstname}
        error={currentFormValidators.firstNameError}
        formType={formType}
        required={true}
      />
      <FormInput
        type={'text'}
        id={'inputLastName'}
        label={'Nachname'}
        value={objectRequest.requestBody.lastname}
        error={currentFormValidators.lastNameError}
        formType={formType}
        required={true}
      />

      <FormInput
        type={'email'}
        id={'inputEmail'}
        label={'E-Mail-Adresse'}
        value={objectRequest.requestBody.email}
        error={currentFormValidators.emailError}
        required={true}
        formType={formType}
      />

      <FormSelectPhoneNumber formType={formType} form={objectRequest} />

      <h3 className="form__title pt-200rem">Ihre Nachricht</h3>
      <Textarea formType={formType} value={objectRequest.requestBody.message} />

      <span className="form--info">
        Ihre angegebenen Daten werden zum Bearbeiten Ihrer Anfrage verwendet. Es gelten unsere{' '}
        <FormTextDsgvo objektanfrage={true} />
      </span>

      <FormCheckbox
        id="newsletter"
        label={
          'Ich bin damit einverstanden, dass ich zukünftig per E-Mail von s REAL Immobilien über Produkte, Services und Events informiert werde. Meine persönlichen Daten werden nur dafür genutzt. Diese Zustimmung kann ich jederzeit widerrufen.'
        }
        value={objectRequest.requestBody.newsletterConfirmed}
        error={currentFormValidators.privacyPolicyError}
        formType={formType}
      />

      <div className="button-panel pt-100rem">
        <ButtonForwardAction
          formType={formType}
          forwardAction={forwardAction}
          buttonText={'Absenden und Maklervertrag zustimmen'}
        />
      </div>
      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default ObjectRequestMain;
