function IconSearchBig({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 13721" xmlns="http://www.w3.org/2000/svg" width="44.395" height="44.371" viewBox="0 0 44.395 44.371">
      <g data-name="Gruppe 13723">
        <path data-name="Pfad 8574" d="M43.909,41.691,33.094,30.875a18.567,18.567,0,0,0,4.437-12.11,18.778,18.778,0,1,0-6.656,14.328L41.691,43.909a1.562,1.562,0,0,0,2.219,0,1.51,1.51,0,0,0,0-2.219M3.143,18.765a15.622,15.622,0,0,1,31.245,0,15.833,15.833,0,0,1-4.53,11,15.833,15.833,0,0,1-11,4.53A15.545,15.545,0,0,1,3.143,18.765" fill={color} />
      </g>
    </svg>

  );
}

export default IconSearchBig;
