import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import headerImageMobileSlide1 from '../assets/images/sREAL-2024-Webhero-Mobil-950x593px-2-0-Martin.jpg';
import headerImageMobileSlide2 from '../assets/images/sREAL-2024-Webhero-Mobil-950x593px-2-0-Mariam-gerade-2.jpg';
import headerImageMobileSlide3 from '../assets/images/sREAL-2024-Webhero-Mobil-950x593px-1-0-News.jpg';
import headerImageSlide1 from '../assets/images/sREAL-2024-Webhero-16zu9-1-5-Martin.jpg';
import headerImageSlide2 from '../assets/images/sREAL-2024-Webhero-16zu9-1-5-Mariam.jpg';
import headerImageSlide3 from '../assets/images/sREAL-2024-Webhero-16zu9-1-5-News.jpg';
import fullWidthContentImage from '../assets/images/fullwidthsection-img.png';
import ProductHighlightsImg1 from '../assets/images/producthighlights-img-1.png';
import ProductHighlightsImg1Mobile from '../assets/images/producthighlights-img-1-mobil.png';
import ProductHighlightsImg2 from '../assets/images/producthighlights-img-2.png';
import ProductHighlightsImg3 from '../assets/images/producthighlights-img-3.png';
import ContactFormCard from '../components/Cards/ContactFormCard';
import IconContentCard from '../components/Cards/IconContentCard';
import Header from '../components/Header/Header';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import ProductHighlights from '../components/ContentBlocks/ProductHighlights';
import Quicklinks from '../components/Quicklinks';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import FullWidthContent from '../components/ContentBlocks/FullWidthContent';
import SimpleSlider from '../components/Sliders/SimpleSlider';

//ICONS
import IconProfile from '../components/Icons/IconProfile';
import IconSearchBig from '../components/Icons/IconSearchBig';
import IconVerKaufen from '../components/Icons/IconVerKaufen';
import IconNachbetreuung from '../components/Icons/IconNachbetreuung';

//STORE
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../reducers/registerForm';

function Home() {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const { isMobile } = useHandleScreenResize();

  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  const goToDashboard = () => {
    navigate('../' + app.root + '/dashboard');
  };

  const headerCtabuttonsBewerten = (
    <HeaderCtaButtons>
      <>
        <Link className="button button--orange" to={app.root + '/verkaufen#marktanalyse'} title="Immobilie bewerten">
          Meine Immobilie bewerten
        </Link>
        <Link className="button button--white" to={app.root + '/immobilie-verkaufen'} title="Mehr Informationen zum Immobilienverkauf">
          Mehr Informationen
        </Link>
      </>
    </HeaderCtaButtons>
  );

  const headerCtabuttonsSuche = (
    <HeaderCtaButtons>
      <>
        <Link className="button button--orange" to={app.root + '/immobiliensuche'} title="Immobilie suchen">
          Immobilie suchen
        </Link>
        <Link className="button button--white" to={app.root + '/immobilie-kaufen'} title="Mehr Informationen zur Immobiliensuche">
          Mehr Informationen
        </Link>
      </>
    </HeaderCtaButtons>
  );

  const headerCtabuttonsNews = (
    <HeaderCtaButtons>
      <Link className="button button--orange" to={app.root + '/news'} title="Zu den News">
        News anzeigen
      </Link>
    </HeaderCtaButtons>
  );

  const sliderSettings = {
    appendDots: (dots) => <ul>{dots}</ul>,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
  };

  return (
    <>
      <MetaTags />

      <Header cssClasses="header--slider header--home header--withimage">
        <SimpleSlider settings={sliderSettings}>
          <div className="header--slider__item">
            <img src={!isMobile ? headerImageSlide1 : headerImageMobileSlide1} alt="Foto: Das moderne Kundenportal für Ihren Immobilienverkauf." className="header__image" />
            <div className="header__content-wrapper w-100">
              <div className="header__content">
                <h1 className="mt-0 mb-0 text-white">
                  Das moderne
                  <br />
                  Kundenportal für Ihren
                  <br />
                  Immobilienverkauf.
                </h1>
              </div>
              <div className="header__ctabuttons-wrapper">{headerCtabuttonsBewerten}</div>
            </div>
          </div>

          <div className="header--slider__item">
            <img src={!isMobile ? headerImageSlide2 : headerImageMobileSlide2} alt="Foto: Das moderne Kundenportal für Ihre Immobiliensuche." className="header__image" />
            <div className="header__content-wrapper w-100">
              <div className="header__content">
                <h1 className="mt-0 mb-0 text-white">
                  Das moderne
                  <br />
                  Kundenportal für Ihre
                  <br />
                  Immobiliensuche.
                </h1>
              </div>
              <div className="header__ctabuttons-wrapper">{headerCtabuttonsSuche}</div>
            </div>
          </div>

          <div className="header--slider__item">
            <img src={!isMobile ? headerImageSlide3 : headerImageMobileSlide3} alt="Foto: Alle Immobiliennews an einem Platz." className="header__image" />
            <div className="header__content-wrapper w-100">
              <div className="header__content">
                <h1 className="mt-0 mb-0 text-white">
                  Alle Immobiliennews
                  <br />
                  an einem Platz.
                </h1>
              </div>
              <div className="header__ctabuttons-wrapper">{headerCtabuttonsNews}</div>
            </div>
          </div>
        </SimpleSlider>
      </Header>

      {/* <Header cssClasses="header--default header--withimage header--home">
        <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        <div className="header__content-wrapper w-100">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">
              Das moderne
              <br />
              Kundenportal für Ihren
              <br />
              Immobilienverkauf.
            </h1>
          </div>
          <div className="header__ctabuttons-wrapper">{headerCtabuttons}</div>
        </div>
      </Header> */}

      <Quicklinks
        list={[
          { label: 'Jetzt loslegen!', id: 'loslegen' },
          { label: 'Alles auf einen Blick', id: 'alles' },
          { label: 'Von Anfang bis Ende bestens betreut', id: 'betreuung' },
          { label: 'Kontakt', id: 'kontakt' },
        ]}
      />

      <MainContent>
        <div className="w-desktop--center">
          <div id="loslegen">
            <ProductHighlights
              isMobile={isMobile}
              mainHeadline="Alles rund um Ihre Immobilie: einfach, transparent und digital"
              mainSubline="Suchen Sie Ihre Traumimmobilie wie ein Profi, behalten Sie den vollen Durchblick beim Immobilienverkauf und bleiben Sie auf dem neusten Stand mit aktuellen Immobiliennews. my-sreal ist der moderne Weg für Ihren Immo-Verkauf, Ihre Immo-Suche und Ihre Immo-Marktupdates.<br>Registrieren Sie sich jetzt kostenlos."
              imagePath1={isMobile ? ProductHighlightsImg1Mobile : ProductHighlightsImg1}
              imagePath2={ProductHighlightsImg2}
              imagePath3={ProductHighlightsImg3}
              color1="background-darkpink"
              color2="background-orange"
              color3="background-darkteal"
              headline1="Immobilienverkauf, so transparent wie noch nie"
              headline2="Immobilien suchen wie ein Profi!"
              headline3="Alle Immobiliennews an einem Platz"
              button1="Mehr anzeigen"
              button2="Mehr anzeigen"
              button3="Mehr anzeigen"
              buttonlink1="/verkaufen"
              buttonlink2="/immobiliensuche"
              buttonlink3="/news"
            />
          </div>
        </div>

        <div id="alles">
          <FullWidthContent imagePath={fullWidthContentImage} cssClasses="pl-100rem pr-100rem">
            <h2 className="text-white pt-80">Auf my-sreal haben Sie alles im Blick</h2>
            <p className="text-white pb-20">
              Als Nutzer:in von my-sreal haben Sie exklusiv Zugriff auf Ihr persönliches Dashboard. Unsere Makler:innen aus ganz Österreich versorgen Sie darauf mit passenden Angeboten, Infos zum
              Immobilienmarkt und Statistiken zu Ihrem Immobilienverkauf.{' '}
            </p>
            {!user.isLoggedIn ? (
              <button className="button button--white" onClick={handleOpenRegisterForm}>
                Jetzt registrieren
              </button>
            ) : (
              <button className="button button--white" onClick={goToDashboard}>
                Dashboard anzeigen
              </button>
            )}
          </FullWidthContent>
        </div>

        <div className="w-desktop--center">
          <div className="mt-400rem">
            <div id="betreuung">
              <IconContentCard
                iconBox={[
                  { color: 'background-blue', headline: 'Schritt 1', text: 'Kostenlos registrieren und unverbindlich beraten lassen.', icon: <IconProfile color="white" /> },
                  { color: 'background-apple', headline: 'Schritt 2', text: 'Immobilie mit Suchprofil finden oder Vermittlung einer Immobilie beauftragen.', icon: <IconSearchBig color="white" /> },
                  { color: 'background-teal', headline: 'Schritt 3', text: 'Ihre Makler:in kümmert sich um alles. Sie behalten den Überblick.', icon: <IconVerKaufen color="white" /> },
                  { color: 'background-darkteal', headline: 'Schritt 4', text: 'In Absprache mit Ihnen kommen wir ans Ziel.', icon: <IconNachbetreuung color="white" /> },
                ]}
              >
                <div className="mb-100rem">
                  {!user.isLoggedIn ? (
                    <button className="button button--orange" onClick={handleOpenRegisterForm}>
                      Jetzt kostenlos registrieren
                    </button>
                  ) : (
                    <button className="button button--orange" onClick={goToDashboard}>
                      Dashboard anzeigen
                    </button>
                  )}
                </div>
              </IconContentCard>
            </div>
          </div>
          <div className="mt-400rem">
            <div id="kontakt">
              <ContactFormCard />
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
}

export default Home;
