import React, { useEffect, useState, useCallback } from 'react';
import Slider from 'react-slick';

function CarouselNew({ children, cssClasses = '', isMobile = false, twoSlides }) {
  const settings = {
    className: 'center',
    speed: 500,
    slidesToShow: isMobile ? 1.1 : twoSlides ? 2 : 3,
    swipeToSlide: false,
    infinite: false,
    arrows: !isMobile ? true : false,
    dots: isMobile ? true : false,
    prevArrow: (
      <button className={`slick-prev slick-arrow`} aria-label="Previous" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="29.099" height="16.6" viewBox="0 0 29.099 16.6" style={{ transform: 'rotate(180deg)', marginLeft: '-12px', marginTop: '4px' }}>
          <path
            data-name="Pfad 8568"
            d="M28.127,7.305H3.584L9.112,1.777a.992.992,0,0,0,0-1.458.992.992,0,0,0-1.458,0L.364,7.609a.752.752,0,0,0-.182.122l-.061.061c0,.061-.061.061-.061.121s0,.061-.061.122v.547c0,.061,0,.061.061.121,0,.061.061.061.061.122a.059.059,0,0,0,.061.061c.061.061.061.122.121.122l7.29,7.29a1.027,1.027,0,0,0,1.458,0,.992.992,0,0,0,0-1.458L3.523,9.31H28.066A1.065,1.065,0,0,0,29.1,8.277a.96.96,0,0,0-.972-.972"
            transform="translate(29.099 16.6) rotate(180)"
            fill="#2970ed"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button className="slick-next slick-arrow" aria-label="Next" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="29.099" height="16.6" viewBox="0 0 29.099 16.6" style={{ marginLeft: '-12px', marginTop: '4px' }}>
          <path
            data-name="Pfad 8568"
            d="M28.127,7.305H3.584L9.112,1.777a.992.992,0,0,0,0-1.458.992.992,0,0,0-1.458,0L.364,7.609a.752.752,0,0,0-.182.122l-.061.061c0,.061-.061.061-.061.121s0,.061-.061.122v.547c0,.061,0,.061.061.121,0,.061.061.061.061.122a.059.059,0,0,0,.061.061c.061.061.061.122.121.122l7.29,7.29a1.027,1.027,0,0,0,1.458,0,.992.992,0,0,0,0-1.458L3.523,9.31H28.066A1.065,1.065,0,0,0,29.1,8.277a.96.96,0,0,0-.972-.972"
            transform="translate(29.099 16.6) rotate(180)"
            fill="#2970ed"
          />
        </svg>
      </button>
    ),
  };

  return (
    <div className={`carousel-wrapper ${cssClasses}`}>
      <div className="slider-container">
        <Slider {...settings}>{children}</Slider>
      </div>
    </div>
  );
}

export default CarouselNew;
