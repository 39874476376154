import React from 'react';
import '../styles/dotunread.scss';

function DotUnread({ cssClasses = '', unreadAmount }) {
  unreadAmount = parseInt(unreadAmount);

  return unreadAmount > 0 && unreadAmount <= 99 ? (
    <div className={`dot-unread dot-unread--withNumber ${cssClasses}`}>
      <span>{unreadAmount}</span>
    </div>
  ) : unreadAmount > 99 ? (
    <div className={`dot-unread dot-unread--withNumber ${cssClasses}`}>
      <span className="font-80">99+</span>
    </div>
  ) : (
    <div className={`dot-unread ${cssClasses}`}></div>
  );
}

export default DotUnread;
