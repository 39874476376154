import { useDispatch } from 'react-redux';
import { setPageDialogOpenSPForm } from '../../reducers/searchProfileForm';
import Linkitem from './Linkitem';
import IconHouseSearch from '../Icons/IconHouseSearch';

function SearchProfilCreateLinkitem({cssClasses = ''}) {
    const dispatch = useDispatch();

    const handleOpenSearchprofileDialog = () => {
        dispatch(setPageDialogOpenSPForm(true));
    };

    return (
        <Linkitem
            handleLink={handleOpenSearchprofileDialog}
            icon={<IconHouseSearch />}
            cssClasses={`linkitem--blue linkitem--einzeilig-desktop linkitem--IconHouseSearch ${cssClasses}`}
            headline="Suchprofil anlegen"
            text="Mit der s REAL Vormerkung finden Sie Ihre Wunschimmobilie"
          />
    )
}

export default SearchProfilCreateLinkitem;