function IconLoadingSpinner({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      id="b"
      data-name="Ebene 2"
      xmlns="http://www.w3.org/2000/svg"
      width="23.24"
      height="18"
      viewBox="0 0 23.24 18"
    >
      <defs></defs>
      <g id="c" data-name="Filled Icons">
        <g>
          <path
            fill={color}
            d="M23.07,10.97l-2.61-3.91c-.17-.26-.46-.43-.78-.44-.32-.01-.62,.12-.82,.36l-3.02,3.61c-.36,.42-.3,1.05,.12,1.41,.42,.36,1.05,.3,1.41-.12l1.17-1.39c-.6,2.55-2.63,4.72-5.31,5.35-2.36,.55-4.8-.14-6.53-1.85-.39-.39-1.03-.38-1.41,0-.39,.39-.38,1.03,0,1.41,1.71,1.7,3.98,2.61,6.32,2.61,.69,0,1.38-.08,2.08-.24,3.55-.83,6.15-3.65,6.85-7l.88,1.32c.19,.29,.51,.44,.83,.44,.19,0,.38-.05,.55-.17,.46-.31,.58-.93,.28-1.39Z"
          />
          <path
            fill={color}
            d="M7.28,6.97c-.42-.36-1.05-.3-1.41,.12l-1.43,1.7c.09-1.17,.46-2.31,1.09-3.34,1.02-1.66,2.66-2.85,4.48-3.28,2.52-.59,5.14,.25,6.85,2.19,.37,.41,1,.45,1.41,.09,.41-.37,.45-1,.09-1.41C16.17,.56,12.8-.52,9.57,.24,7.23,.79,5.14,2.31,3.84,4.41c-.68,1.09-1.11,2.29-1.31,3.52l-.7-1.05c-.31-.46-.93-.58-1.39-.28-.46,.31-.58,.93-.28,1.39l2.62,3.91c.18,.26,.46,.43,.78,.44,.02,0,.04,0,.05,0,.22,0,.44-.08,.61-.21,.1-.07,.19-.17,.26-.27l2.92-3.48c.35-.42,.3-1.05-.12-1.41Z"
          />
          <path fill={color} d="M11.62,11.89c3.8,0,3.79-5.76,0-5.76s-3.81,5.76,0,5.76Z" />
        </g>
      </g>
    </svg>
  );
}

export default IconLoadingSpinner;
