import '../../styles/kennzahlen.scss';
import SemiCircleProgressBarChart from '../Charts/SemiCircleProgressBarChart';

import { KENNZAHL_LABEL_CATERING, KENNZAHL_LABEL_FAMILY, KENNZAHL_LABEL_HEALTH, KENNZAHL_LABEL_LEISURE, KENNZAHL_LABEL_SHOPPING } from '../../constants';

// ICONS
//TODO:ICON FREIZEIT IST DAS NEUE DAMIT DIE NAMEN ZAMPASSEN   import IconLeaf from "../Icons/IconLeaf";
import IconBildung from '../Icons/IconBildung';
import IconEinkaufen from '../Icons/IconEinkaufen';
import IconFreizeit from '../Icons/IconFreizeit';
import IconGastronomie from '../Icons/IconGastronomie';
import IconGesundheit from '../Icons/IconGesundheit';

const scoreText = (score) => {
  if (score > 4 && score <= 5) {
    return 'sehr gut';
  } else if (score > 3 && score <= 4) {
    return 'gut';
  } else if (score > 2 && score <= 3) {
    return 'mittelmäßig';
  } else if (score > 1 && score <= 2) {
    return 'schlecht';
  } else if (score > 0 && score <= 1) {
    return 'sehr schlecht';
  }
};

const poisSubcategoryText = (subcategory) => {
  let text = subcategory;
  switch (subcategory) {
    case 'pharmacy':
      text = 'Apotheke';
      break;
    case 'doctor':
      text = 'Arzt';
      break;
    case 'nursing_home':
      text = 'Pflegeheim';
      break;
    case 'hospital':
      text = 'Krankenhaus';
      break;
    case 'dentist':
      text = 'Zahnarzt';
      break;
    case 'veterinary':
      text = 'Tierarzt';
      break;
    case 'clinic':
      text = 'Klinik';
      break;
    case 'park':
      text = 'Park';
      break;
    case 'sport':
      text = 'Sport';
      break;
    case 'playground':
      text = 'Spielplatz';
      break;
    case 'entertainment':
      text = 'Unterhaltung';
      break;
    case 'supermarket':
      text = 'Supermarkt';
      break;
    case 'convenience_store':
      text = 'Gemischtwarenladen';
      break;
    case 'restaurant':
      text = 'Restaurant';
      break;
    case 'bar':
      text = 'Bar';
      break;
    case 'cafe':
      text = 'Cafe';
      break;
    case 'kindergarten':
      text = 'Kindergarten';
      break;
    case 'school':
      text = 'Schule';
      break;
    case 'university':
      text = 'Universität';
      break;
    case 'primary_school':
      text = 'Grundschule';
      break;
    case 'secondary_school':
      text = 'Gymnasium';
      break;
    default:
      break;
  }

  return text;
};

const iconColor = '#7b91a9';

function KennzahlCircleBar({ kennzahl, cssClasses = '', category, hasButton, handleButtonClick }) {
  const { value, percentage, resultText, pointsOfInterest } = kennzahl;

  const margin = 150;
  let diameter = window.innerWidth - margin;

  const dialogMaxWidth = 420;
  const maxDiameter = dialogMaxWidth - margin;
  if (diameter > maxDiameter) {
    diameter = dialogMaxWidth - margin;
  }

  return (
    <>
      <h1 className="kennzahl__headline mobile-hidden">{category}</h1>
      <div className="kennzahlen-wrapper__circle-bar ">
        <div className="kennzahl__circlebar-wrapper d-flex justify-content-center align-items-center">
          <div className={`kennzahl__circlebar ${cssClasses}`} style={{ width: diameter }}>
            {category && (
              <div className="kennzahl__circlebar__icon flex-center">
                {category === KENNZAHL_LABEL_LEISURE && <IconFreizeit color={iconColor} />}
                {category === KENNZAHL_LABEL_HEALTH && <IconGesundheit color={iconColor} />}
                {category === KENNZAHL_LABEL_FAMILY && <IconBildung color={iconColor} />}
                {category === KENNZAHL_LABEL_CATERING && <IconGastronomie cssClasses="kennzahl__circlebar__foodicon" color={iconColor} />}
                {category === KENNZAHL_LABEL_SHOPPING && <IconEinkaufen color={iconColor} />}
              </div>
            )}

            <SemiCircleProgressBarChart percentage={percentage} diameter={diameter} strokeColor="#fff" backgroundColor="rgba(255,255,255,0.2)" />
            {value && (
              <>
                <div className="kennzahl__circlebar__value">{value}</div>

                <div className="kennzahl__circlebar__valuetext">
                  Diese Lage ist <span className="font-weight-700">{scoreText(value)}</span>
                </div>
              </>
            )}
          </div>
        </div>

        {hasButton && handleButtonClick && (
          <button className="button button--white-outline" onClick={handleButtonClick}>
            Details ansehen
          </button>
        )}

        <div className="kennzahlen__text">
          <span>{resultText}</span>

          {pointsOfInterest && pointsOfInterest.length > 0 && (
            <div className="kennzahl__pois mt-150rem">
              <h3>{pointsOfInterest.length} Nächstgelegene Points-of-Interest:</h3>

              {pointsOfInterest.map((poi, index) => {
                return (
                  <div key={index} className="mb-100rem">
                    {poisSubcategoryText(poi.subcategory)}, {poi.name}
                    <br />
                    {poi.hasOwnProperty('distance') ? <span className="font-weight-700">{poi.distance} m</span> : null}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default KennzahlCircleBar;
