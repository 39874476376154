import { useDispatch, useSelector } from 'react-redux';
//*Components
import IconLoadingSpinner from '../Icons/IconLoadingSpinner';

//*Util
import {
  AKTUELLES_FILTER_ALLE,
  AKTUELLES_FILTER_BESICHTIGUNGEN,
  AKTUELLES_FILTER_DOKUMENTE,
  AKTUELLES_FILTER_NACHRICHTEN,
  AKTUELLES_FILTER_WEITERE,
  MENU_VERKAUF,
  PHASE1_VERKAUF,
  PHASE3_VERKAUF,
  PHASE3_KAUF,
  PHASE4_VERKAUF,
  PHASE4_KAUF,
} from '../../constants';

import React, { useEffect, useState } from 'react';
import { useHandleUserRealtyActive } from '../../hooks/user/useHandleUserRealtyActive';

import { useNavigate } from 'react-router-dom';
import { getRealtyFile } from '../../api/JustImmo';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useHandleMessages } from '../../hooks/messages/useHandleMessages';
import { setAktuellesActiveFilter } from '../../reducers/app';
import { parseDate } from '../../util/datetimeParser';
import { teaserTextParser } from '../../util/teaserTextParser';
import PageDialogBesichtigungen from '../Dialogs/PageDialogBesichtigungen';
import PageDialogWeitere from '../Dialogs/PageDialogWeitere';
import FilterMenu from '../FilterMenu';
import IconAnnouncment from '../Icons/IconAnnouncment';
import IconChat2 from '../Icons/IconChat2';
import IconDocument from '../Icons/IconDocument';
import IconEye from '../Icons/IconEye';
import Linkitem from '../Linkitems/Linkitem';
import AktuellesNachrichten from './AktuellesNachrichten';
import { setUserObject } from '../../reducers/user';

function Aktuelles() {
  const app = useSelector((state) => state.app);
  const notifications = useSelector((state) => state.notifications);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // settings for load-more functionality
  const defaultShowEntriesDesktop = 9;
  const defaultShowEntriesMobile = 6;
  const entriesToAdd = 3;

  const { reloadData, besichtigungen, besichtigungenLoaded, weitere, weitereLoaded, files, filesLoaded, getBesichtigungenUnreadCount, getWeitereUnreadCount } = useHandleUserRealtyActive();
  const { threads, threadsLoading, loadMessages } = useHandleMessages();

  const [shownEntries, setShownEntries] = useState(defaultShowEntriesDesktop);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [messagesLoaded, setMessagesLoaded] = useState(false);

  const [openBesichtigungen, setOpenBesichtigungen] = useState(false);
  const [openWeitere, setOpenWeitere] = useState(false);

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    if (isMobile) {
      setShownEntries(defaultShowEntriesMobile);
    }
  }, []);

  const handleShowMoreEntries = () => {
    setShownEntries(shownEntries + entriesToAdd);
  };

  const [filterMenu, setFilterMenu] = useState([]);
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);

  // build items array
  useEffect(() => {
    if (besichtigungenLoaded && weitereLoaded && filesLoaded && messagesLoaded) {
      let menu = [];
      let allItems = [];
      setHasLoaded(true);

      if (besichtigungen?.length > 0) {
        const besUnread = getBesichtigungenUnreadCount();
        menu.push({ key: AKTUELLES_FILTER_BESICHTIGUNGEN, label: 'Besichtigungen', count: besUnread });
        allItems.push(...besichtigungen);
      }

      if (app.menuType === MENU_VERKAUF || app.menuId === PHASE3_KAUF || app.menuId === PHASE4_KAUF) {
        if (weitere?.length > 0) {
          const weitereUnread = getWeitereUnreadCount();
          menu.push({ key: AKTUELLES_FILTER_WEITERE, label: 'Weitere Aktivitäten', count: weitereUnread });
          allItems.push(...weitere);
        }

        if (files?.length > 0) {
          menu.push({ key: AKTUELLES_FILTER_DOKUMENTE, label: 'Dokumente' });
          allItems.push(...files);
        }

        if (threads?.length > 0) {
          menu.push({ key: AKTUELLES_FILTER_NACHRICHTEN, label: 'Nachrichten', count: app.menuId === PHASE1_VERKAUF ? 0 : notifications.unreadMessages });
          allItems.push(...threads);
        }
      }

      menu = menu.sort(function (a, b) {
        return a.key - b.key;
      });

      setFilterMenu(menu);

      allItems = allItems.sort(function (a, b) {
        return new Date(b.dateFrom) - new Date(a.dateFrom);
      });

      setAllItems(allItems);
    }
  }, [besichtigungenLoaded, weitereLoaded, filesLoaded, messagesLoaded]);

  // build filtermenu
  useEffect(() => {
    let findAlle = filterMenu.find((f) => f.key === AKTUELLES_FILTER_ALLE);
    if (filterMenu.length > 0 && !findAlle) {
      let filterMenuUpdated = [...filterMenu, { key: AKTUELLES_FILTER_ALLE, label: 'Alle' }];

      filterMenuUpdated = filterMenuUpdated.sort(function (a, b) {
        return a.key - b.key;
      });
      setFilterMenu(filterMenuUpdated);
    }
  }, [filterMenu]);

  // load messages only on MENU_VERKAUF
  useEffect(() => {
    if (app.menuType === MENU_VERKAUF || app.menuId === PHASE3_KAUF || app.menuId === PHASE4_KAUF) {
      loadMessages();
    } else {
      setMessagesLoaded(true);
    }
  }, []);

  useEffect(() => {
    setMessagesLoaded(!threadsLoading);
  }, [threadsLoading]);

  // check total count of (filteres) activities
  useEffect(() => {
    if (app.aktuellesActiveFilter == AKTUELLES_FILTER_ALLE) {
      setItems(allItems);
    } else {
      let itemsFiltered = allItems.filter((item) => item.filter_id == app.aktuellesActiveFilter);
      setItems(itemsFiltered);
    }
  }, [app.aktuellesActiveFilter, allItems]);

  useEffect(() => {
    reloadData();
  }, [user.userRealtyActive, app.menuType]);

  const handleSetActiveFilterIndex = (event) => {
    let key = event.target.dataset.key;
    dispatch(setAktuellesActiveFilter(key));
  };

  const downloadDocJustImmo = async (file) => {
    try {
      const realtyFile = await getRealtyFile(user.userRealtyActive.id, user.userRealtyActive.tenant_id, file.storage_key);

      const url = window.URL.createObjectURL(realtyFile);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.original_filename;
      a.click();
    } catch (e) {
      //handle realty file error
      console.error('e', e);
    }
  };

  const handleOpenBesichtigungen = (state) => {
    // on close
    if (state === false) {
      const lsArray = localStorage.getItem('activityIdsRead') ? localStorage.getItem('activityIdsRead').split(';') : [];
      if (lsArray.length > 0) {
        let newUserObject = { ...user.userObject };

        newUserObject.realtyActivitiesSeen = lsArray.join(';'); // string
        dispatch(setUserObject(newUserObject));
        updateFiltermenuCounts(lsArray);
      }

      setOpenBesichtigungen(state);
    } else {
      setOpenBesichtigungen(state);

      // open PageDialog or navigate to /besichtigungen on mobile
      // if(isMobile) {
      //   navigate(app.root + '/besichtigungen');
      // }
      // else {
      //   setOpenBesichtigungen(state);
      // }
    }
  };

  const handleOpenWeitere = (state) => {
    // on close
    if (state === false) {
      const lsArray = localStorage.getItem('activityIdsRead') ? localStorage.getItem('activityIdsRead').split(';') : [];
      if (lsArray.length > 0) {
        let newUserObject = { ...user.userObject };
        newUserObject.realtyActivitiesSeen = lsArray.join(';'); // string
        dispatch(setUserObject(newUserObject));
        updateFiltermenuCounts(lsArray);
      }

      setOpenWeitere(state);
    } else {
      setOpenWeitere(state);

      // open PageDialog or navigate to /aktivitaeten on mobile
      // if(isMobile) {
      //   navigate(app.root + '/aktivitaeten');
      // }
      // else {
      //   setOpenWeitere(state);
      // }
    }
  };

  /* check if activity id is unread */
  function checkIfUnread(id) {
    let isUnread = true;
    if (user.userObject?.realtyActivitiesSeen && user.userObject?.realtyActivitiesSeen !== '') {
      if (user.userObject.realtyActivitiesSeen.includes(String(id))) {
        isUnread = false;
      }
    }
    return isUnread;
  }

  function updateFiltermenuCounts(lsArray) {
    // update unread count
    const besUnread = getBesichtigungenUnreadCount(lsArray); // array
    const weitereUnread = getWeitereUnreadCount(lsArray); // array

    let filterMenuUpdated = filterMenu.map((menu) => {
      if (menu.label === 'Besichtigungen') {
        menu.count = besUnread;
      }
      if (menu.label === 'Weitere Aktivitäten') {
        menu.count = weitereUnread;
      }
      return {
        ...menu,
      };
    });

    setFilterMenu(filterMenuUpdated);
  }

  if (hasLoaded && allItems.length === 0) {
    return null;
  } else {
    return (
      <>
        <div className={`${isMobile ? 'pl-200rem pt-200rem' : 'w-desktop--center'}  headline--withfiltermenu`}>
          <h1 className="m-0 pr-200rem align-self-center">Aktuelles</h1>

          {filterMenu && filterMenu.length > 0 && (
            <FilterMenu categories={filterMenu} activeIndex={app.aktuellesActiveFilter} handleClick={handleSetActiveFilterIndex} cutside={'none'} hasPaddingRight={true} />
          )}
        </div>

        {hasLoaded ? (
          <>
            <div className={`w-desktop--center elements3cols elements3cols--aktuelles mb-100rem elements3cols--show${shownEntries}`}>
              {items.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.filter_id == AKTUELLES_FILTER_NACHRICHTEN ? (
                      <AktuellesNachrichten thread={item.chatList} cssClasses={'elements3cols__col linkitem-green'} reloadMessagesCallback={async () => loadMessages()} />
                    ) : item.filter_id == AKTUELLES_FILTER_DOKUMENTE ? (
                      <Linkitem
                        cssClasses={'elements3cols__col linkitem-newblue'}
                        handleLink={async () => {
                          await downloadDocJustImmo(item);
                        }}
                        icon={<IconDocument />}
                        headline={item.headline}
                        text={item.text}
                        showArrow={false}
                        key={item.headline}
                      />
                    ) : item.filter_id == AKTUELLES_FILTER_BESICHTIGUNGEN ? (
                      <Linkitem
                        cssClasses={'elements3cols__col linkitem-purple'}
                        handleLink={() => handleOpenBesichtigungen(true)}
                        icon={<IconEye />}
                        unread={checkIfUnread(item.key)}
                        headline={item.label}
                        text={teaserTextParser(item.text, 20)}
                        showArrow={false}
                        dateOrState={parseDate(item.dateFrom)}
                        key={item.id + '-' + item.value.Created_at}
                      />
                    ) : item.filter_id == AKTUELLES_FILTER_WEITERE ? (
                      <Linkitem
                        cssClasses={'elements3cols__col linkitem-turquoise'}
                        handleLink={() => handleOpenWeitere(true)}
                        icon={<IconAnnouncment />}
                        unread={checkIfUnread(item.key)}
                        headline={item.label}
                        text={teaserTextParser(item.text, 20)}
                        dateOrState={parseDate(item.dateFrom)}
                        showArrow={false}
                        key={item.id + '-' + item.value.Created_at + '-' + index}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}

              {/* linkitem to Detailspage */}
              {app.aktuellesActiveFilter == AKTUELLES_FILTER_NACHRICHTEN ? (
                <>
                  {items.length === 0 ? (
                    <p>Sie haben derzeit noch keine Nachrichten empfangen. Sobald eine Makler:in mit Ihnen in Kontakt ist, finden Sie hier Ihre Konversationen.</p>
                  ) : (
                    <Linkitem
                      handleLink={() => navigate(app.root + '/nachrichten')}
                      cssClasses={'elements3cols__col linkitem--blue'}
                      icon={<IconChat2 />}
                      headline="Zum Nachrichtencenter"
                      text="Im Nachrichtencenter schreiben und lesen Sie Nachrichten"
                      showArrow={false}
                    />
                  )}
                </>
              ) : app.aktuellesActiveFilter == AKTUELLES_FILTER_DOKUMENTE ? (
                <>
                  {items.length === 0 ? (
                    <p>Keine Dokumente vorhanden.</p>
                  ) : (
                    <Linkitem
                      handleLink={() => navigate(app.root + '/immo-drive')}
                      icon={<IconDocument />}
                      cssClasses={'elements3cols__col linkitem--blue'}
                      headline="Zu meinen Dokumenten "
                      text="Hier finden Sie alle Dokumente, die Ihre Objekte betreffen"
                      showArrow={false}
                    />
                  )}
                </>
              ) : app.aktuellesActiveFilter == AKTUELLES_FILTER_BESICHTIGUNGEN ? (
                <>
                  {items.length === 0 ? (
                    <p>Keine Besichtigungen vorhanden.</p>
                  ) : (
                    <Linkitem
                      handleLink={() => handleOpenBesichtigungen(true)}
                      cssClasses={'elements3cols__col linkitem--blue'}
                      icon={<IconEye />}
                      headline="Alle Besichtigungen"
                      text="Hier finden Sie alle aktuellen und vergangenen Besichtigungen"
                      showArrow={false}
                    />
                  )}
                </>
              ) : app.aktuellesActiveFilter == AKTUELLES_FILTER_WEITERE ? (
                <>
                  {items.length === 0 ? (
                    <p>Keine weiteren Aktivitäten vorhanden.</p>
                  ) : (
                    <Linkitem
                      handleLink={() => handleOpenWeitere(true)}
                      cssClasses={'elements3cols__col linkitem--blue'}
                      icon={<IconAnnouncment />}
                      headline="Alle Marketingaktivitäten"
                      text="Hier finden Sie alle aktuellen und vergangenen Aktivitäten"
                      showArrow={false}
                    />
                  )}
                </>
              ) : null}
            </div>

            {items.length > shownEntries && (
              <span onClick={() => handleShowMoreEntries()} className="textlink w-100 d-flex flex-col align-items-center pb-200rem">
                Weitere Aktivitäten laden
              </span>
            )}

            <PageDialogBesichtigungen open={openBesichtigungen} handleClose={() => handleOpenBesichtigungen(false)} />

            <PageDialogWeitere open={openWeitere} handleClose={() => handleOpenWeitere(false)} />
          </>
        ) : (
          <div className="w-desktop--center mb-200rem">
            <IconLoadingSpinner color={'#5d5d5d'} />
            <span className="ml-10">Aktivitäten werden geladen</span>
          </div>
        )}
      </>
    );
  }
}

export default Aktuelles;
