function IconCalendar({ cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 16067" xmlns="http://www.w3.org/2000/svg" width="25.085" height="25.078" viewBox="0 0 25.085 25.078">
      <g data-name="Gruppe 16067">
        <path
          data-name="Pfad 10391"
          d="M24.345,3.455A2.657,2.657,0,0,0,22.46,2.67H19.79V.89A1.007,1.007,0,0,0,18.848,0a.917.917,0,0,0-.89.89V6.283a.89.89,0,0,0,1.78,0V4.5h2.67a.867.867,0,0,1,.628.262.966.966,0,0,1,.262.628V22.46a.867.867,0,0,1-.262.628.966.966,0,0,1-.628.262H2.67a.867.867,0,0,1-.628-.262.966.966,0,0,1-.262-.628V5.393a.867.867,0,0,1,.262-.628A.966.966,0,0,1,2.67,4.5a.917.917,0,0,0,.89-.89.927.927,0,0,0-.89-.942,2.489,2.489,0,0,0-1.885.785A2.657,2.657,0,0,0,0,5.34V22.408a2.489,2.489,0,0,0,.785,1.885,2.657,2.657,0,0,0,1.885.785H22.408a2.489,2.489,0,0,0,1.885-.785,2.657,2.657,0,0,0,.785-1.885V5.393a2.448,2.448,0,0,0-.733-1.937"
          fill="#2870ed"
        />
        <path
          data-name="Pfad 10392"
          d="M11.19,7.173a.917.917,0,0,0,.89-.89V4.5h8.063a.917.917,0,0,0,.89-.89.917.917,0,0,0-.89-.89H12.08V.89a.89.89,0,0,0-1.78,0V6.283a.917.917,0,0,0,.89.89"
          transform="translate(-4.907)"
          fill="#2870ed"
        />
        <path data-name="Pfad 10393" d="M10.38,17.2A1.78,1.78,0,1,1,8.6,18.98a1.78,1.78,0,0,1,1.78-1.78" transform="translate(-4.097 -8.195)" fill="#2870ed" />
        <path
          data-name="Pfad 10394"
          d="M22.38,17.1a1.765,1.765,0,0,0-1.78,1.78,1.81,1.81,0,0,0,1.78,1.832,1.765,1.765,0,0,0,1.78-1.78A1.81,1.81,0,0,0,22.38,17.1"
          transform="translate(-9.815 -8.147)"
          fill="#2870ed"
        />
        <path data-name="Pfad 10395" d="M34.38,17.2a1.78,1.78,0,1,1-1.78,1.78,1.78,1.78,0,0,1,1.78-1.78" transform="translate(-15.532 -8.195)" fill="#2870ed" />
        <path
          data-name="Pfad 10396"
          d="M10.38,34.46A1.78,1.78,0,1,0,8.6,32.68a1.765,1.765,0,0,0,1.78,1.78m6.283-3.56a1.78,1.78,0,1,0,1.78,1.78,1.765,1.765,0,0,0-1.78-1.78"
          transform="translate(-4.097 -14.722)"
          fill="#2870ed"
        />
      </g>
    </svg>
  );
}

export default IconCalendar;
