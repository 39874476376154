import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FormInput from '../../FormFields/FormInput';
import LoadingSpinner from '../../LoadingSpinner';
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';

// STORE
import { setSiteIndexLoginForm } from '../../../reducers/loginForm';
import { setFormIsLoading } from '../../../reducers/app';

// API
import { forgot_password } from '../../../api/Onboarding';

import { LOGINFORM_SITEINDEX_START, LOGINFORM_SITEINDEX_PWFORGETEMAIL } from '../../../constants';
import { validateFields } from '../../FormFields/util/validateFields';
import { fetchErrorText } from '../../FormFields/util/formTexts';

function LoginFormPWForget() {
  let currentFormValidators = useSelector((state) => state.formValidators);
  let loginForm = useSelector((state) => state.loginForm);
  let app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const searchParams = useSearchParams();

  const [error, setError] = useState('');

  const forwardAction = async () => {
    setError('');

    if (app.formIsLoading) {
      return;
    }

    let validationResult = validateFields({ email: loginForm.requestBody.email }, dispatch);

    if (validationResult) {
      dispatch(setFormIsLoading(true));

      try {
        await forgot_password({ email: loginForm.requestBody.email });
        dispatch(setFormIsLoading(false));
        dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_PWFORGETEMAIL));
      } catch (e) {
        setError(fetchErrorText);
        dispatch(setFormIsLoading(false));
      }
    }
  };

  const backAction = () => {
    dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_START));
  };

  const formType = 'loginForm';

  return (
    <>
      <h2>Passwort vergessen?</h2>
      <p className="mb-200rem">Bitte geben Sie unten Ihre E-Mail-Adresse ein, die Sie für den Login bei my-sreal.at verwenden.</p>
      <p className="mb-200rem">Wenn ein Konto dafür bei uns hinterlegt ist, bekommen Sie eine Mail zugeschickt.</p>

      <FormInput type={'text'} id={'inputEmail'} label={'E-Mail-Adresse'} error={currentFormValidators.emailError} required={true} formType={formType} />

      <LoadingSpinner />

      <div className="button-panel pt-100rem">
        <button className="button button--gray-outline" onClick={backAction}>
          Zurück
        </button>
        <ButtonForwardAction formType={formType} buttonText="Passwort zurücksetzen" forwardAction={forwardAction} />
      </div>

      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default LoginFormPWForget;
