import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  genderError: '',
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  phoneError: '',
  phoneCheckError: '',
  privacyPolicyError: '',
  registerError: '',
  passwordError: '',
  passwordConfirmError: '',
  tokenError: '',
  federalStateError: '',
  districtError: '',
  estateTypeError: '',
  estateSizeError: '',
  estateConditionError: '',
  streetError: '',
  townError: '',
  zipError: '',
  zipShortVersionError: '',
  loanValueError: '',
  loanTimeError: '',
  roomsFromError: '',
  exteriorSurfaceError: '',
  searchProfileNameError: '',
  reasonError: '',
  messageError: '',
};

export const formValidatorsSlice = createSlice({
  name: 'formValidators',
  initialState,
  reducers: {
    setGenderError: (state, action) => {
      state.genderError = action.payload;
    },
    setFirstNameError: (state, action) => {
      state.firstNameError = action.payload;
    },
    setLastNameError: (state, action) => {
      state.lastNameError = action.payload;
    },
    setEmailError: (state, action) => {
      state.emailError = action.payload;
    },
    setPhoneError: (state, action) => {
      state.phoneError = action.payload;
    },
    setPhoneCheckError: (state, action) => {
      state.phoneCheckError = action.payload;
    },
    setPrivacyPolicyError: (state, action) => {
      state.privacyPolicyError = action.payload;
    },
    setRegisterError: (state, action) => {
      state.registerError = action.payload;
    },
    setPasswordError: (state, action) => {
      state.passwordError = action.payload;
    },
    setPasswordConfirmError: (state, action) => {
      state.passwordConfirmError = action.payload;
    },
    setTokenError: (state, action) => {
      state.tokenError = action.payload;
    },
    setResetFormValidator: (state) => {
      return initialState;
    },
    setFederalStateError: (state, action) => {
      state.federalStateError = action.payload;
    },
    setDistrictError: (state, action) => {
      state.districtError = action.payload;
    },
    setEstateTypeError: (state, action) => {
      state.estateTypeError = action.payload;
    },
    setEstateSizeError: (state, action) => {
      state.estateSizeError = action.payload;
    },
    setEstateConditionError: (state, action) => {
      state.estateConditionError = action.payload;
    },
    setStreetError: (state, action) => {
      state.streetError = action.payload;
    },
    setTownError: (state, action) => {
      state.townError = action.payload;
    },
    setZipError: (state, action) => {
      state.zipError = action.payload;
    },
    setZipShortVersionError: (state, action) => {
      state.zipShortVersionError = action.payload;
    },
    setLoanValueError: (state, action) => {
      state.loanValueError = action.payload;
    },
    setLoanTimeError: (state, action) => {
      state.loanTimeError = action.payload;
    },
    setRoomsFromError: (state, action) => {
      state.roomsFromError = action.payload;
    },
    setPriceFromError: (state, action) => {
      state.priceFromError = action.payload;
    },
    setPriceToError: (state, action) => {
      state.priceToError = action.payload;
    },
    setAreaFromError: (state, action) => {
      state.areaFromError = action.payload;
    },
    setAreaToError: (state, action) => {
      state.areaToError = action.payload;
    },
    setSearchProfileNameError: (state, action) => {
      state.searchProfileNameError = action.payload;
    },
    setReasonNameError: (state, action) => {
      state.reasonError = action.payload;
    },
    setMessageError: (state, action) => {
      state.messageError = action.payload;
    },
  },
});

export const {
  setGenderError,
  setFirstNameError,
  setLastNameError,
  setEmailError,
  setPhoneError,
  setPhoneCheckError,
  setPrivacyPolicyError,
  setRegisterError,
  setPasswordError,
  setPasswordConfirmError,
  setTokenError,
  setResetFormValidator,
  setFederalStateError,
  setDistrictError,
  setEstateTypeError,
  setEstateSizeError,
  setEstateConditionError,
  setStreetError,
  setTownError,
  setZipError,
  setZipShortVersionError,
  setSearchProfileNameError,
  setLoanValueError,
  setLoanTimeError,
  setPriceFromError,
  setPriceToError,
  setRoomsFromError,
  setAreaFromError,
  setAreaToError,
  setReasonNameError,
  setMessageError,
} = formValidatorsSlice.actions;

export default formValidatorsSlice.reducer;
