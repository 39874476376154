function IconLock({ color = '#1e3163', cssClasses }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.013"
      height="21.135"
      viewBox="0 0 16.013 21.135"
      className={cssClasses}
    >
      <g data-name="Gruppe 10293">
        <g data-name="Gruppe 10292" transform="translate(.323 .323)">
          <circle
            data-name="Ellipse 1465"
            cx="1.281"
            cy="1.281"
            r="1.281"
            transform="translate(6.402 11.524)"
            fill={color}
          />
          <path
            data-name="Linie 4409"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M7.683 13.232v2.561"
          />
          <path
            data-name="Rechteck 3667"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M.427 8.11H14.94v11.951H.427z"
          />
          <path
            data-name="Pfad 7617"
            d="M2.988 5.127a4.7 4.7 0 0 1 9.39 0V8.11h-9.39Z"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconLock;
