import { GoogleMap, Marker, useLoadScript, Polygon } from '@react-google-maps/api';
import { useMemo, memo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import IconLocation from '../Icons/IconLocation';
import markerImg from '../../assets/images/map-marker.png';
import postcodeData from '../../util/postcodeBoundaries.json';

function Map({ coordinates, postCode }) {
  const app = useSelector((state) => state.app);
  const [polygonCoords, setPolygonCoords] = useState([]);
  const [map, setMap] = useState(null);

  const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '12px',
  };

  let { isLoaded } = useLoadScript({ googleMapsApiKey: app.googleMapsKey });

  //const center = useMemo(() => ({ lat: coordinates.latitude, lng: coordinates.longitude }), []);
  const [center, setCenter] = useState(null);
  const onLoad = useCallback(
    (map) => {
      if (center) {
        if (polygonCoords.length > 0) {
          const bounds = new window.google.maps.LatLngBounds();
          polygonCoords.forEach((coord) => bounds.extend(coord));
          map.fitBounds(bounds);
        } else {
          map.setZoom(13);
        }
      }
      setMap(map);
    },
    [center, polygonCoords],
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (coordinates) {
        setCenter({ lat: coordinates.latitude, lng: coordinates.longitude });
      } else if (postCode) {
        const postcodeInfo = postcodeData.find((item) => item.officialId === postCode);
        if (postcodeInfo) {
          setCenter(postcodeInfo.center);
          setPolygonCoords(
            postcodeInfo.coordinates.map((coord) => ({
              lat: coord.lat,
              lng: coord.lng,
            })),
          );
        } else {
          console.error(`Postcode ${postCode} not found in the data`);
        }
      }
    };
    fetchData();
  }, [coordinates, postCode]);

  return (
    <div className="maps-container-wrapper">
      {isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12} onLoad={onLoad} onUnmount={onUnmount}>
          {postCode && polygonCoords.length > 0 ? (
            <Polygon
              paths={polygonCoords}
              options={{
                fillColor: '#2870ED',
                fillOpacity: 0.2,
                strokeColor: '#2870ED',
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          ) : coordinates ? (
            <Marker position={{ lat: coordinates.latitude, lng: coordinates.longitude }} icon={markerImg} />
          ) : null}
        </GoogleMap>
      ) : (
        <IconLocation color={'#3679ec'} cssClasses={'position-center'} />
      )}
    </div>
  );
}

export default memo(Map);
