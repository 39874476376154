import { createSlice } from '@reduxjs/toolkit';
import { findAndParsePhoneNumber, removeHashFromUrl, setPhoneNumber } from '../util/generalUtils';

import {
  REGFORM_SITEINDEX_START,
  REGFORM_SITEINDEX_PW,
  REGFORM_SITEINDEX_SUCCESSTOKEN,
  REGFORM_SITEINDEX_CHANGEPHONENUMBER,
  REGFORM_SITEINDEX_PWFORGETTOKEN,
  REGFORM_SITEINDEX_LOGINSUCCESS,
  DIREKTREGFORM_SITEINDEX_PW,
} from '../constants';

const initialState = {
  siteIndex: REGFORM_SITEINDEX_START,
  headline: 'Registrierung',
  pageDialogOpen: false,
  requestBody: {
    gender: '',
    titleBefore: '',
    titleAfter: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    privacyAggrement: false,
  },
  parsedPhoneNumber: '',
  phoneNumberPrefix: { id: 43, text: '+43 (Österreich)' },
  rememberMe: false,
  token: '',
  passwordConfirm: '',
  userId: '',
  code: '',
};

export const registerFormSlice = createSlice({
  name: 'registerForm',
  initialState,
  reducers: {
    setSiteIndexRegisterForm: (state, action) => {
      if (window.location.href.indexOf('set_password') > -1) {
        window.location.hash = REGFORM_SITEINDEX_PWFORGETTOKEN;
      } else {
        window.location.hash = action.payload;
      }

      state.siteIndex = action.payload;

      switch (state.siteIndex) {
        case REGFORM_SITEINDEX_PW:
        case DIREKTREGFORM_SITEINDEX_PW:
          state.headline = 'Passwortvergabe';
          break;
        case REGFORM_SITEINDEX_LOGINSUCCESS:
          state.headline = 'Erfolg';
          break;
        case REGFORM_SITEINDEX_SUCCESSTOKEN:
        case REGFORM_SITEINDEX_CHANGEPHONENUMBER:
          state.headline = 'SMS-Code';
          break;
        default:
          state.headline = 'Registrierung';
          break;
      }
    },
    setPageDialogOpenRegisterForm: (state, action) => {
      if (action.payload === true) {
        if (window.location.href.indexOf('set_password') > -1) {
          window.location.hash = REGFORM_SITEINDEX_PWFORGETTOKEN;
        } else {
          window.location.hash = state.siteIndex;
        }
      } else {
        removeHashFromUrl();
      }

      state.pageDialogOpen = action.payload;
    },
    setGenderRegisterForm: (state, action) => {
      state.requestBody.gender = action.payload;
    },
    setTitleBeforeRegisterForm: (state, action) => {
      state.requestBody.titleBefore = action.payload;
    },
    setTitleAfterRegisterForm: (state, action) => {
      state.requestBody.titleAfter = action.payload;
    },
    setFirstNameRegisterForm: (state, action) => {
      state.requestBody.firstName = action.payload;
    },
    setLastNameRegisterForm: (state, action) => {
      state.requestBody.lastName = action.payload;
    },
    setEmailRegisterForm: (state, action) => {
      state.requestBody.email = action.payload;
    },
    setPhoneNumberPrefixRegisterForm: (state, action) => {
      state.phoneNumberPrefix = action.payload;
    },
    setParsedPhoneNumberRegisterForm: (state, action) => {
      findAndParsePhoneNumber(state, 'parsedPhoneNumber', action.payload);
    },
    setPhoneNumberRegisterForm: (state, action) => {
      setPhoneNumber(state.requestBody, 'phoneNumber', state, action.payload);
    },
    setPrivacyAggrementRegisterForm: (state, action) => {
      state.requestBody.privacyAggrement = action.payload;
    },
    setPasswordRegisterForm: (state, action) => {
      state.requestBody.password = action.payload;
    },
    setRememberMeRegisterForm: (state, action) => {
      state.rememberMe = action.payload;
    },
    setPasswordConfirmRegisterForm: (state, action) => {
      state.passwordConfirm = action.payload;
    },
    setTokenRegisterForm: (state, action) => {
      state.token = action.payload;
    },
    setResetFieldsRegisterForm: (state) => {
      return initialState;
    },
    setUserIdRegisterForm: (state, action) => {
      state.userId = action.payload;
    },
    setCodeRegisterForm: (state, action) => {
      state.code = action.payload;
    },
  },
});

export const {
  setSiteIndexRegisterForm,
  setPageDialogOpenRegisterForm,
  setGenderRegisterForm,
  setTitleBeforeRegisterForm,
  setTitleAfterRegisterForm,
  setFirstNameRegisterForm,
  setLastNameRegisterForm,
  setEmailRegisterForm,
  setPhoneNumberRegisterForm,
  setResetFieldsRegisterForm,
  setPasswordRegisterForm,
  setPasswordConfirmRegisterForm,
  setTokenRegisterForm,
  setPrivacyAggrementRegisterForm,
  setRememberMeRegisterForm,
  setUserIdRegisterForm,
  setCodeRegisterForm,
  setParsedPhoneNumberRegisterForm,
  setPhoneNumberPrefixRegisterForm,
} = registerFormSlice.actions;

export default registerFormSlice.reducer;
