import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSearchParams, Navigate, Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

// PAGES
import SiteWrapper from './components/SiteWrapper';
import Dashboard from './routes/Dashboard';
import DashboardPhase3 from './routes/DashboardPhase3';
import DashboardPhase4 from './routes/DashboardPhase4';
import Home from './routes/Home';
import Immosuche from './routes/Immosuche';
import Neubauprojekte from './routes/Neubauprojekte';
import ImpressumKaernten from './routes/ImpressumKaernten';
import ImpressumOberoesterreich from './routes/ImpressumOberoesterreich';
import ImpressumSteiermark from './routes/ImpressumSteiermark';
import ImpressumTirol from './routes/ImpressumTirol';
import ImpressumVorarlberg from './routes/ImpressumVorarlberg';
import Kaeufer from './routes/Kaeufer';
import Verkaeufer from './routes/Verkaeufer';

import Datenschutz from './routes/Datenschutz';
import Favoriten from './routes/Favoriten';
import Impressum from './routes/Impressum';
import News from './routes/News';
import NewsDetail from './routes/NewsDetail';
import WeitereAktivitaeten from './routes/WeitereAktivitaeten';

import Besichtigungen from './routes/Besichtigungen';
import Immodrive from './routes/Immodrive';
import Nachrichten from './routes/Nachrichten';
import Profil from './routes/Profil';
//import FAQ from "./routes/FAQ";
import FAQ from './routes/FAQ';
import Statistiken from './routes/Statistiken';

import AlleAngebote from './routes/AlleAngebote';
import ImmoDetail from './routes/ImmoDetail';

import PageNotFound from './routes/PageNotFound';
import ImmoNotFound from './routes/ImmoNotFound';

// DUMMY

import './styles/main.scss';

// STORE
import { setFormIsLoading } from './reducers/app';
import { setPageDialogOpenLoginForm } from './reducers/loginForm';

import {
  setCodeRegisterForm,
  setEmailRegisterForm,
  setFirstNameRegisterForm,
  setGenderRegisterForm,
  setLastNameRegisterForm,
  setPageDialogOpenRegisterForm,
  setParsedPhoneNumberRegisterForm,
  setPhoneNumberPrefixRegisterForm,
  setPhoneNumberRegisterForm,
  setResetFieldsRegisterForm,
  setSiteIndexRegisterForm,
  setTitleAfterRegisterForm,
  setTitleBeforeRegisterForm,
  setUserIdRegisterForm,
} from './reducers/registerForm';

// API
import { current, logout } from './api/Onboarding';

import {
  DIREKTREGFORM_SITEINDEX_START,
  DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN,
  ERR_LOGIN_EXPIRED,
  LOGINFORM_SITEINDEX_LOGINSUCCESS,
  PHASE1_KAUF,
  PHASE1_VERKAUF,
  REGFORM_SITEINDEX_ALMOSTTHERE,
  REGFORM_SITEINDEX_PW,
  REGFORM_SITEINDEX_SUCCESSTOKEN,
  ROLE_ABGEBER_NAME,
  ROLE_INTERESSENT_NAME,
  VERSION_MAJOR,
  VERSION_MINOR,
  VERSION_PATCH,
} from './constants';

import { useHandleApp } from './hooks/app/useHandleApp';
import { useHandleUser } from './hooks/user/useHandleUser';
import { isJson, scrollToElement } from './util/generalUtils';

import KaufenLanding from './routes/KaufenLanding';
import VerkaufenLanding from './routes/VerkaufenLanding';
import StickyButton from './components/StickyButton';
import { setIsLoggedIn } from './reducers/user';
import { phoneNumberPrefixOptions } from './components/FormFields/util/formOptions';

function App() {
  const location = useLocation();
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const { setupUser } = useHandleUser();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { resetStore, closePageDialogs } = useHandleApp();

  const handleLogoutAndReset = async () => {
    if (user.isLoggedIn) {
      dispatch(setIsLoggedIn(false));
      await logout();
    }

    resetStore();

    checkIfConfirmUrl();
    checkIfSetPasswordUrl();
  };

  useEffect(() => {
    closePageDialogs();
  }, []);

  useEffect(() => {
    dispatch(setFormIsLoading(false));

    if (!app.versionMajor || !app.versionMinor || !app.versionPatch) {
      handleLogoutAndReset();
    } else if (VERSION_MAJOR > app.versionMajor || VERSION_MINOR > app.versionMinor || VERSION_PATCH > app.versionPatch) {
      handleLogoutAndReset();
    } else {
      let hash = window.location.hash;
      if (!hash) {
        window.scrollTo(0, 0);
      } else {
        scrollToElement(hash);
      }

      if (user.isLoggedIn || window.location.pathname.includes('/webangebot/')) {
        // do not reload userObject if user was on Login-Success page
        // reload already happened in FormToken.js
        if (hash !== '#' + LOGINFORM_SITEINDEX_LOGINSUCCESS && hash !== '#' + REGFORM_SITEINDEX_SUCCESSTOKEN && hash !== '#' + DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN) {
          getCurrentUserObject();
        }
      }

      checkIfConfirmUrl();
      checkIfSetPasswordUrl();

      // Url without app.root - redirect to correct pathname
      if (!location.pathname.includes(app.root)) {
        let newPath = app.root + location.pathname;
        if (location.pathname.endsWith('/')) {
          newPath = app.root + location.pathname.slice(0, -1);
        }

        navigate({
          pathname: newPath,
        });
      } else if (location.pathname.endsWith('/')) {
        // redirect from page-with-slash to page-without-slash
        navigate({
          pathname: location.pathname.slice(0, -1),
        });
      }

      // redirect from /de/home to /de/
      if (location.pathname === app.root + '/home') {
        navigate({
          pathname: app.root,
        });
      }

      // redirect from /projekt/ to /immobilie/
      if (location.pathname.includes('/projekt/')) {
        const subpathIndex = location.pathname.lastIndexOf('/projekt/');
        const subpath = location.pathname.substring(subpathIndex + 9);
        navigate({
          pathname: app.root + '/immobilie/' + subpath,
        });
      }
    }
  }, [location.pathname]);

  // check if url contains confirm or direct-registration-confirm and trigger registration process
  function checkIfConfirmUrl() {
    if (location.pathname.indexOf('/confirm') >= 0 || location.pathname.indexOf('/direct-registration-confirm') >= 0) {
      const userId = searchParams.get('UserId');
      const code = searchParams.get('Code');

      if (!!userId && !!code) {
        if (location.pathname.indexOf('/confirm') >= 0) {
          dispatch(setResetFieldsRegisterForm());
          dispatch(setUserIdRegisterForm(userId));
          dispatch(setCodeRegisterForm(code));

          const params = { UserId: userId, Code: code };

          navigate({
            pathname: app.root + '/confirm',
            search: `?${createSearchParams(params)}`,
          });

          dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_ALMOSTTHERE));
        } else if (location.pathname.indexOf('/direct-registration-confirm') >= 0) {
          dispatch(setResetFieldsRegisterForm());
          dispatch(setUserIdRegisterForm(userId));
          dispatch(setCodeRegisterForm(code));

          const hash = location.hash;
          if (hash && hash.indexOf('#direktregistrieren&Data=') === 0) {
            let data = hash.replace('#direktregistrieren&Data=', '');

            // decode base64 encoded
            const decodedData = window.atob(data);
            if (decodedData && isJson(decodedData)) {
              const userData = JSON.parse(decodedData);

              dispatch(setTitleBeforeRegisterForm(userData.TitleBefore));
              dispatch(setTitleAfterRegisterForm(userData.TitleAfter));
              dispatch(setFirstNameRegisterForm(userData.FirstName));
              dispatch(setLastNameRegisterForm(userData.LastName));
              dispatch(setEmailRegisterForm(userData.Email));

              let gender = userData.Gender === 'w' ? 'f' : 'm';
              dispatch(setGenderRegisterForm(gender));

              // set phonenumber prefix
              const prefix4Digits = userData.PhoneNumber.substring(1, 4); // first 4 digits without +
              const prefix3Digits = userData.PhoneNumber.substring(1, 3); // first 3 digits without +

              const findInOptions = phoneNumberPrefixOptions.find((o) => o.id == prefix4Digits || o.id == prefix3Digits);
              if (findInOptions) {
                dispatch(setPhoneNumberPrefixRegisterForm(findInOptions));

                // set parsed phonenumber (without prefix)
                const prefixLength = findInOptions.id.toString().length + 1; // add 1 for +
                const phonenumberWithoutPrefix = userData.PhoneNumber.substring(prefixLength);
                dispatch(setParsedPhoneNumberRegisterForm(phonenumberWithoutPrefix));
                dispatch(setPhoneNumberRegisterForm(phonenumberWithoutPrefix));
              }
            }
          }

          dispatch(setSiteIndexRegisterForm(DIREKTREGFORM_SITEINDEX_START));
        }

        dispatch(setPageDialogOpenLoginForm(false));
        dispatch(setPageDialogOpenRegisterForm(true));
      }
    }
  }

  // check if url contains set_password and trigger password-reset process
  function checkIfSetPasswordUrl() {
    // onboarding - set_password
    if (location.pathname.indexOf('set_password') >= 0) {
      const userId = searchParams.get('UserId');
      const code = searchParams.get('Code');
      if (!!userId && !!code) {
        dispatch(setUserIdRegisterForm(userId));
        dispatch(setCodeRegisterForm(code));

        const params = { UserId: userId, Code: code };
        navigate({
          pathname: app.root + '/set_password',
          search: `?${createSearchParams(params)}`,
        });

        dispatch(setPageDialogOpenRegisterForm(true));
        dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_PW));
      }
    }
  }

  const getCurrentUserObject = async () => {
    try {
      const currentUser = await current();
      setupUser(currentUser);
    } catch (e) {
      if (e.message === ERR_LOGIN_EXPIRED) {
        resetStore();
      }

      // Handle user error
      console.log(e);
    }
  };

  const navigateHome = <Navigate replace to={`${app.root}/`} />;
  const navigateDashboard = <Navigate replace to={`${app.root}/dashboard${location.hash}`} />;

  const CatchAllRoute = () => {
    window.location.replace(app.root + '/404');
    return <PageNotFound />;
  };

  return (
    <>
      {location.pathname.includes('/immobiliensuche') ? <StickyButton cssClasses="stickyButton--withFixedBar" /> : !location.pathname.includes('/immobilie/') && <StickyButton />}
      <Routes>
        <Route path="/" element={user.isLoggedIn ? navigateDashboard : <Home />} />

        <Route path={app.root} element={<SiteWrapper />}>
          <Route index element={<Home />} />
          <Route path="home" element={user.isLoggedIn ? navigateDashboard : <Home />} />
          <Route path="confirm" element={<Home />} />
          <Route path="direct-registration-confirm" element={<Home />} />
          <Route path="set_password" element={<Home />} />

          <Route path="profil" element={user.isLoggedIn ? <Profil /> : navigateHome} />
          <Route path="news" element={<News />} />
          <Route path="news/:slug" element={<NewsDetail />} />
          <Route path="impressum" element={<Impressum />} />
          <Route path="impressum-kaernten-und-osttirol" element={<ImpressumKaernten />} />
          <Route path="impressum-steiermark" element={<ImpressumSteiermark />} />
          <Route path="impressum-tirol" element={<ImpressumTirol />} />
          <Route path="impressum-oberoesterreich" element={<ImpressumOberoesterreich />} />
          <Route path="impressum-vorarlberg" element={<ImpressumVorarlberg />} />
          <Route path="*" element={<CatchAllRoute />} />

          <Route path="404" element={<PageNotFound />} />
          <Route path={`${app.root}/webangebot-404`} element={<ImmoNotFound />} />

          <Route path="immobilie/" element={<Immosuche />} />
          <Route path="immobilie/*" element={<ImmoDetail />} />
          <Route path="projekt/*" element={<ImmoDetail />} />

          <Route path="verkaufen" element={<Verkaeufer />} />

          <Route path="dashboard" element={user.isLoggedIn ? <Dashboard /> : navigateHome} />
          <Route path="immo-drive" element={user.isLoggedIn ? <Immodrive /> : navigateHome} />

          <Route path="nachrichten" element={user.isLoggedIn ? <Nachrichten /> : navigateHome} />
          <Route path="besichtigungen" element={user.isLoggedIn ? <Besichtigungen /> : navigateHome} />
          <Route path="aktivitaeten" element={user.isLoggedIn ? <WeitereAktivitaeten /> : navigateHome} />
          <Route path="statistik" element={user.isLoggedIn ? <Statistiken /> : navigateHome} />
          <Route path="angebote" element={user.isLoggedIn ? <AlleAngebote /> : navigateHome} />

          <Route path="kaufen" element={<Kaeufer />} />

          <Route path="favoriten" element={user.isLoggedIn ? <Favoriten /> : navigateHome} />

          <Route path="immobiliensuche" element={<Immosuche />} />
          <Route path="neubauprojekte" element={<Neubauprojekte />} />

          <Route path="datenschutz" element={<Datenschutz />} />

          {/* <Route path="kauf3" element={user.isLoggedIn ? <DashboardPhase3 /> : navigateHome} />
        <Route path="kauf4" element={user.isLoggedIn ? <DashboardPhase4 /> : navigateHome} /> */}

          <Route path="verkauf3" element={user.isLoggedIn ? <DashboardPhase3 /> : navigateHome} />
          <Route path="verkauf4" element={user.isLoggedIn ? <DashboardPhase4 /> : navigateHome} />

          <Route path="faqs" element={<FAQ />}>
            <Route path="kontakt" element={<FAQ />} />
          </Route>

          <Route path="immobilie-kaufen" element={<KaufenLanding />} />
          <Route path="immobilie-verkaufen" element={<VerkaufenLanding />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
