function CardGridPlaceholderElement({array, cssClasses = '', type = 'card'}) {
    return (
        <>
            {/* fill cardgrid with placeholder items for better grid-alignment */}
            {array.length % 3 === 2 && (
                <>
                    {type === 'card' ? (
                        <div className={`${cssClasses} card card--placeholder`}></div>
                    ) : type === 'linkitem' ? (
                        <div className={`${cssClasses} linkitem linkitem--placeholder`}></div>
                    ) : null }
                </>
            )}
        </>
    )
}


export default CardGridPlaceholderElement;