import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPageDialogOpenLoginForm, setSiteIndexLoginForm, setResetFieldsLoginForm } from '../../../reducers/loginForm';

import IconSuccess from '../../Icons/IconSuccess';

import { LOGINFORM_SITEINDEX_START } from '../../../constants';

function FormSetPasswordSuccess({ handleClose }) {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const app = useSelector((state) => state.app);

  const handleSwitchToLogin = () => {
    handleClose();
    dispatch(setResetFieldsLoginForm());
    dispatch(setPageDialogOpenLoginForm(true));
  };

  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconSuccess color="#1E3163" />
      </div>

      <h2 className="pt-200rem">Passwort erfolgreich zurückgesetzt</h2>
      <p className="pt-200rem pb-200rem lh-130">Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich jetzt einloggen.</p>

      <div className="button-panel">
        <button className="button button--gray-outline" onClick={handleSwitchToLogin}>
          Login aufrufen
        </button>
      </div>
    </>
  );
}

export default FormSetPasswordSuccess;
