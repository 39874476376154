import PageDialog from './PageDialog';
import WeitereAktivitaetenContent from '../ContentBlocks/WeitereAktivitaetenContent';

function PageDialogWeitere({ open, handleClose }) {
  const pageDialogId = 'PageDialogWeitere';
  return (
    <PageDialog
      id={pageDialogId}
      open={open}
      handleClose={handleClose}
      showBackArrow={false}
      headline={'Weitere Aktivitäten'}
    >
      <div className='p-100rem'>
        <WeitereAktivitaetenContent containerSelector={'#' + pageDialogId} />
      </div>
    </PageDialog>
  );
}

export default PageDialogWeitere;
