import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { MENU_KAUF, MENU_VERKAUF } from '../../constants';
import { setMenuType } from '../../reducers/app';
function MenuButtonSwitcher({ cssClasses = '' }) {
  const dispatch = useDispatch();

  let user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  cssClasses += ' buttonswitcher--wrapper';

  const handleKaufKlick = () => {
    dispatch(setMenuType(MENU_KAUF));
  };

  const handleVerkaufKlick = () => {
    dispatch(setMenuType(MENU_VERKAUF));
  };

  if (user.isLoggedIn) {
    return (
      <div className={cssClasses}>
        {app.menuType === MENU_VERKAUF ? (
          <>
            <Link to={`${app.root}/dashboard`} className="button active" onClick={() => handleVerkaufKlick()}>
              Verkaufen
            </Link>
            <Link to={`${app.root}/dashboard`} className="button" onClick={() => handleKaufKlick()}>
              Kaufen
            </Link>
          </>
        ) : app.menuType === MENU_KAUF ? (
          <>
            <Link to={`${app.root}/dashboard`} className="button" onClick={() => handleVerkaufKlick()}>
              Verkaufen
            </Link>

            <Link to={`${app.root}/dashboard`} className="button active" onClick={() => handleKaufKlick()}>
              Kaufen
            </Link>
          </>
        ) : null}
      </div>
    );
  } else return null;
}

export default MenuButtonSwitcher;
