const teaserTextParser = (text, charCount) => {
  if (text === '' || text === null) {
    return '';
  }

  if (!charCount) {
    charCount = 50;
  }

  if (text.length > charCount) {
    return text.substring(0, charCount) + '...';
  } else {
    return text;
  }
};

export { teaserTextParser };
