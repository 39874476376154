import { useSelector } from 'react-redux';
import Card from './Card';
import moment from 'moment';
import IconImmoLive from '../Icons/IconImmoLive';
import parseCurrency from '../../util/currencyParser';
import { parseDate } from '../../util/datetimeParser';

function ImmoLiveCard({ cssClasses, immoLiveData }) {
  cssClasses += ' immolive-card';

  const user = useSelector((state) => state.user);

  // TODO bei einem größeren Refactoring in eine hook auslagern
  const getDisplayedText = (immoLiveData) => {
    const now = moment().utc();
    const immoLiveStart = immoLiveData.immoliveStart ? moment(immoLiveData.immoliveStart).utc() : null;
    const immoLiveEnd = immoLiveData.immoliveEnd ? moment(immoLiveData.immoliveEnd).utc() : null;
    if (!immoLiveStart && !immoLiveEnd)
      return 'Für dieses Angebotsverfahren sind keine Daten verfügbar.';

    // If both dates are set
    if (immoLiveStart && immoLiveEnd) {
      if (moment(now).isBefore(immoLiveStart)) {
        return `Das digitale Angebotsverfahren beginnt am ${parseDate(immoLiveStart)}`;
      } else if (moment(now).isAfter(immoLiveEnd)) {
        return `Das digitale Angebotsverfahren wurde am ${parseDate(immoLiveEnd)} beendet.`;
      }
      return `Das digitale Angebotsverfahren läuft noch bis ${parseDate(immoLiveEnd)}.`;
    }
    // If only start date is set
    else if (immoLiveStart) {
      if (moment(now).isBefore(immoLiveStart)) {
        return `Das digitale Angebotsverfahren beginnt am ${parseDate(immoLiveStart)}.`;
      } else {
        return `Das digitale Angebotsverfahren läuft seit ${parseDate(immoLiveStart)}.`;
      }
    }
    // If only end date is set
    else if (immoLiveEnd) {
      if (moment(now).isAfter(immoLiveEnd)) {
        return `Das digitale Angebotsverfahren wurde am ${parseDate(immoLiveEnd)} beendet.`;
      } else {
        return `Das digitale Angebotsverfahren läuft noch bis ${parseDate(immoLiveEnd)}.`;
      }
    }
  };

  const getHighestBid = () => {
    if(immoLiveData.immoLiveHighestBid && parseInt(immoLiveData.immoLiveHighestBid) > 0) {
      return parseCurrency(immoLiveData.immoLiveHighestBid);
    }
    else {
      return '0 €';
    }
  }

  if(user.userRealtyActive?.immolive?.immoLiveHighestBid > 0) {
    return (
      <Card cardStyle="customContent" cssClasses={cssClasses}>
        <div className="card__content">
          <h2>Aktuelles Höchstgebot</h2>
          <p>{getDisplayedText(immoLiveData)}</p>

          <div className="immolive-card__content">
            <IconImmoLive color="#1E3163" />
            <span>{getHighestBid(immoLiveData)}</span>
          </div>
          { user.userRealtyActive.immolive?.ImmoLiveUrl && (
            <>
              <p className='pt-200rem'>Echtzeitangebote finden Sie direkt bei immo-live.<br/>
                <a href={user.userRealtyActive.immolive?.ImmoLiveUrl} title="immo-live aufrufen" target="_blank" rel="noreferrer">immo-live aufrufen</a>
              </p>
            </>
          )}
        </div>
      </Card>
    )
  }
  else return null;
}

export default ImmoLiveCard;
