import SemiCircleProgressBar from 'react-progressbar-semicircle';

function SemiCircleProgressBarChart({
  percentage,
  diameter,
  strokeColor,
  backgroundColor,
  strokeWidth = 25,
  showPercentValue = false
}) {
  return (
    <SemiCircleProgressBar
      percentage={percentage}
      strokeWidth={strokeWidth}
      diameter={diameter}
      stroke={strokeColor}
      showPercentValue={showPercentValue}
      background={backgroundColor}
    />
  );
}

export default SemiCircleProgressBarChart;
