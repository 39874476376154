//*React
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//*Components
import Aktuelles from '../components/Aktuelles/Aktuelles';
import ImmoDriveEmptyCard from '../components/Cards/ImmoDriveEmptyCard';
import ImmoDriveCard from '../components/Cards/ImmoDriveCard';
import MarktinfoLightCard from '../components/Cards/MarktinfoLightCard';
import WohnwertProCard from '../components/Cards/WohnwertProCard';
import Header from '../components/Header/Header';
import RecommendationLinkitem from '../components/Linkitems/RecommondationLinkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsTeaserCards from '../components/News/NewsTeaserCards';
//*Images
import IconArrowRight from '../components/Icons/IconArrowRight';

//*Utils
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
import DashboardHeaderDocuments from '../components/Dashboard/DashboardHeaderDocuments';

function VerkaeuferPhase2() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const { files } = useHandleUserRealtyActive();

  return (
    <>
      <MetaTags title={'Dashboard | Die Online-Plattform für Ihre Immobilie'} imageName={'mysreal_hero_dashboard-verkauf.jpg'} />
      <DashboardHeaderDocuments files={files} />

      <MainContent>
        <Aktuelles />

        <div className="w-desktop--center mt-300rem mb-300rem">
          <h2 className="mt-0">Wert ermitteln</h2>
          <WohnwertProCard cssClasses="w-50--desktop" />
        </div>

        <div className="w-desktop--center">
          <MarktinfoLightCard cssClasses={'bg-white'} />
          {/* <div className={'elements2cols mt-400rem'}>
              <MarktinfoProCard cssClasses={'elements2cols__col'} />
            </div> */}

          <RecommendationLinkitem cssClasses="mb-0 mt-300rem" />
        </div>

        <NewsTeaserCards cssClasses="mt-400rem w-desktop--slider" />
      </MainContent>
    </>
  );
}

export default VerkaeuferPhase2;
