function IconSocialFB({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      id="b"
      data-name="Ebene 2"
      xmlns="http://www.w3.org/2000/svg"
      width="11.49"
      height="24.9"
      viewBox="0 0 11.49 24.9"
    >
      <g id="c" data-name="OBJECTS">
        <path
          fill={color}
          d="M2.61,24.9H7.62V12.35h3.5l.37-4.2h-3.87v-2.39c0-.99,.2-1.38,1.16-1.38h2.71V0h-3.47C4.29,0,2.61,1.64,2.61,4.79v3.35H0v4.26H2.61v12.5Z"
        />
      </g>
    </svg>
  );
}

export default IconSocialFB;
