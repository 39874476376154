import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageDialog from './PageDialog';

// ICONS
import IconSend from '../Icons/IconSend';

// API
import { getSentMessages, sendMessage } from '../../api/Contact';

// STORE
import { setFormIsLoading } from '../../reducers/app';

import moment from 'moment';
import { parseDate, parseTime } from '../../util/datetimeParser';
import { fetchErrorText } from '../FormFields/util/formTexts';

import '../../styles/chat.scss';

import { useHandleMessages } from '../../hooks/messages/useHandleMessages';
import useOutsideAlerter from '../useOutsideAlerter';
import { WELCOME_MESSAGE_SUBJECT } from '../../constants';
import { Link, useNavigate } from 'react-router-dom';
import { setMenuType } from '../../reducers/app';
import { MENU_KAUF } from '../../constants';

function PageDialogChat({ open, handleClose, thread, reloadMessagesCallback }) {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setMessagesToRead } = useHandleMessages();

  var lastDate = '';

  const [error, setError] = useState('');
  const [chat, setChat] = useState([]);
  const [subject, setSubject] = useState('');
  const [textToSend, setTextToSend] = useState('');
  const [wrapperClass, setWrapperClass] = useState('');

  useEffect(() => {
    if (thread) {
      setChat(thread);
      setSubject(thread[0].subject);
      setError('');

      scrollToLastChat();

      const unreadMessages = thread.filter((item) => !item.isRead && item.id && item.author === 'sreal');
      setMessagesToRead(unreadMessages);
    }

    setTextToSend('');
  }, [thread]);

  const scrollToLastChat = () => {
    setTimeout(() => {
      // scroll to end of chatmessages
      const chatMessagesEl = document.querySelector('#chatmessages');
      if (chatMessagesEl) {
        chatMessagesEl.scrollTo({
          top: chatMessagesEl.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 500);
  };

  const handleTextareaChange = (value) => {
    setTextToSend(value);
  };

  const handleSendMessage = async () => {
    if (app.formIsLoading) {
      return;
    }

    if (textToSend === '') {
      setError('Bitte geben Sie Ihre Nachricht ein.');
    } else {
      dispatch(setFormIsLoading(true));

      try {
        // find last chatMessage from sreal
        let lastChatItemSreal = chat.findLast((c) => c.author === 'sreal');
        let sentAt = new Date();
        await sendMessage('', subject, textToSend, lastChatItemSreal.id);
        setTextToSend('');

        let sentMessageItem = {
          id: null,
          sentAt: sentAt,
          isRead: true,
          subject: subject,
          body: textToSend,
          replyToMessageId: lastChatItemSreal.id,
        };

        // add sent message to chat
        setChat([...chat, sentMessageItem]);

        if (reloadMessagesCallback) {
          reloadMessagesCallback();
        }

        scrollToLastChat();
        dispatch(setFormIsLoading(false));
      } catch (e) {
        // handle sendMessage error
        dispatch(setFormIsLoading(false));
        console.error('e', e);
        setError(fetchErrorText);
      }
    }
  };

  const handleOpenFlyout = () => {
    setWrapperClass('');
  };

  const respondarea = useRef(null);
  useOutsideAlerter(respondarea, handleOpenFlyout);

  const formatMessage = (message) => {
    let formattedMessage = message;
    const paragraphs = formattedMessage.split(/(?:\r\n|\r|\n)/g);

    const handleLinkClick = () => {
      dispatch(setMenuType(MENU_KAUF));

      setTimeout(() => {
        navigate(`${app.root}/dashboard`);
      }, 200);
    };

    formattedMessage = paragraphs.map((p) => {
      if (p && p.includes('[KAUFEN]')) {
        const pParts = p.split('[KAUFEN]');
        return (
          <>
            <p style={{ fontSize: '0.9rem' }}>
              {pParts[0]}
              <span className="textlink" onClick={handleLinkClick}>
                kaufen
              </span>
              {pParts[1]}
            </p>
            <br />
          </>
        );
      } else if (p) {
        return (
          <>
            <p style={{ fontSize: '0.9rem' }}>{p}</p>
            <br />
          </>
        );
      }
    });
    return formattedMessage;
  };

  if (!thread) {
    return null;
  } else {
    return (
      <PageDialog id="PageDialogChat" open={open} handleClose={handleClose} customHeaderBlue={subject}>
        <div className={`chat-wrapper ${wrapperClass}`}>
          <div className={'chatmessages'} id="chatmessages">
            {chat.map((item, index) => {
              let cssClassText = 'message--user';
              if (item.author === 'sreal') cssClassText = 'message--agent';

              // set date
              let date = parseDate(item.sentAt);
              let isToday = moment(item.sentAt).isSame(new Date(), 'day');
              if (isToday) {
                date = parseTime(item.sentAt) + ' Uhr';
              }

              // display date check
              let displayDate = true;
              if (lastDate === date) displayDate = false;
              lastDate = date;

              const kaufenLink = '<a href="' + window.location.origin + app.root + '/kaufen" title="Immobilie kaufen">Kaufen</a>';

              return (
                <div className={`message ${cssClassText}`} key={index}>
                  {displayDate && <div className="message__date">{date}</div>}
                  <div className="message__text">{formatMessage(item.body)}</div>
                  <div className="message__date--small">{parseTime(item.sentAt)}</div>
                </div>
              );
            })}
          </div>

          {subject !== WELCOME_MESSAGE_SUBJECT && (
            <div className="message__respondarea" id="respondarea" ref={respondarea}>
              {error && <p className="form__fielderror">{error}</p>}
              <div className={`message__respondarea-inner ${app.formIsLoading ? 'message__respondarea-inner--loading' : ''} `}>
                <textarea
                  type="text"
                  className="input"
                  placeholder="Zum Tippen klicken"
                  value={textToSend}
                  onChange={(e) => handleTextareaChange(e.target.value)}
                  onFocus={(e) => setWrapperClass('textarea-focus')}
                  resize="none"
                />

                <div className="image--round message__respondarea--btn" onClick={() => handleSendMessage()}>
                  <IconSend color="#fff" cssClasses="position-center" />
                </div>
              </div>
            </div>
          )}
        </div>
      </PageDialog>
    );
  }
}

export default PageDialogChat;
