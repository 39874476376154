import { createSlice } from '@reduxjs/toolkit';
import { findAndParsePhoneNumber } from '../util/generalUtils';

const initialState = {
  parsedPhoneNumber: '',
  parsedPhoneNumberCheck: '',
  phoneNumberPrefix: { id: 43, text: '+43 (Österreich)' },
  phoneNumberPrefixCheck: { id: 43, text: '+43 (Österreich)' },
};

export const registerFormChangePhoneNumberSlice = createSlice({
  name: 'registerFormChangePhoneNumber',
  initialState,
  reducers: {
    setChangedParsedPhoneNumberPrefix: (state, action) => {
      state.phoneNumberPrefix = action.payload;
    },
    setChangedParsedPhoneNumberPrefixCheck: (state, action) => {
      state.phoneNumberPrefixCheck = action.payload;
    },
    setChangedParsedPhoneNumber: (state, action) => {
      findAndParsePhoneNumber(state, 'parsedPhoneNumber', action.payload);
    },
    setChangedParsedPhoneNumberCheck: (state, action) => {
      findAndParsePhoneNumber(state, 'parsedPhoneNumberCheck', action.payload);
    },
    resetInitial: (state) => {
      state.parsedPhoneNumber = '';
      state.parsedPhoneNumberCheck = '';
      state.phoneNumberPrefix = { id: 43, text: '+43 (Österreich)' };
      state.phoneNumberPrefixCheck = { id: 43, text: '+43 (Österreich)' };
    },
  },
});

export const { 
  setChangedParsedPhoneNumberPrefix,
  setChangedParsedPhoneNumberPrefixCheck,
  setChangedParsedPhoneNumber, 
  setChangedParsedPhoneNumberCheck, 
  resetInitial
} = registerFormChangePhoneNumberSlice.actions;

export default registerFormChangePhoneNumberSlice.reducer;
