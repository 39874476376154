import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

// documentation
// https://react-chartjs-2.js.org/components/doughnut
// https://www.chartjs.org/docs/latest/charts/doughnut.html

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({ chartData, config }) {
  return <Doughnut data={chartData} options={config} />;
}

export default DoughnutChart;
