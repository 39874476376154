import { useSelector } from 'react-redux';

function FormTextDsgvo({ objektanfrage }) {
  let app = useSelector((state) => state.app);
  const handleDatenschutzbestimmungen = () => {
    window.open(`${app.root}/datenschutz`, '_blank');
  };
  return (
    <>
      {objektanfrage ? (
        <span className="textlink" onClick={handleDatenschutzbestimmungen}>
          Bestimmungen zum Datenschutz.
        </span>
      ) : (
        <span>
          Ich akzeptiere die{' '}
          <span className="textlink" onClick={handleDatenschutzbestimmungen}>
            Bestimmungen zum Datenschutz
          </span>
          *
        </span>
      )}
    </>
  );
}
export default FormTextDsgvo;
