function IconProcess({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 16633" xmlns="http://www.w3.org/2000/svg" width="17.977" height="17.448" viewBox="0 0 17.977 17.448">
      <g data-name="Gruppe 16632">
        <path
          data-name="Pfad 10463"
          d="M10.259,16.175H9.622V13.591a.656.656,0,0,0-.637-.637H1.91a.656.656,0,0,0-.637.637v2.583H.637a.637.637,0,1,0,0,1.273h9.622a.656.656,0,0,0,.637-.637.631.631,0,0,0-.637-.636M2.583,14.228H8.349v1.91H2.583Zm15.2-1.722L12.468,7.151,14.3,5.317a1.944,1.944,0,0,0,0-2.733L12.281.562a1.944,1.944,0,0,0-2.733,0L4.942,5.167a1.944,1.944,0,0,0,0,2.733L6.964,9.922a1.9,1.9,0,0,0,2.7,0L11.532,8.05l5.317,5.317a.633.633,0,0,0,.9,0,.546.546,0,0,0,.037-.861M8.8,9.023a.611.611,0,0,1-.9,0L5.878,7a.611.611,0,0,1,0-.9L10.446,1.46a.633.633,0,0,1,.9,0l2.022,2.022a.611.611,0,0,1,0,.9Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconProcess;
