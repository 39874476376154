import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function ButtonForwardAction({ formType, buttonText, forwardAction, buttonStyle = 'orange', preventEnter = false, cssClasses, disabled }) {
  const app = useSelector((state) => state.app);

  cssClasses += ' button';

  switch (buttonStyle) {
    case 'orange':
      cssClasses += ' button--orange';
      break;
    case 'blue':
      cssClasses += ' button--blue';
      break;
    case 'blue-outline':
      cssClasses += ' button--gray-outline';
      break;
    case 'white-outline':
      cssClasses += ' button--white-outline';
      break;
    case 'textlink':
      cssClasses = 'textlink';
    default:
      break;
  }

  if (app.formIsLoading) {
    cssClasses += ' button--loading';
  }

  //Submit forms by pressing Enter key
  const submitOnEnter = (event) => {
    if (preventEnter || document.activeElement.type === 'textarea') return;
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (formType === event.srcElement.dataset.formtype) {
        forwardAction();
      }
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', submitOnEnter);
    return () => window.removeEventListener('keydown', submitOnEnter);
  });

  return (
    <button className={cssClasses} onClick={forwardAction} disabled={disabled}>
      {buttonText}
    </button>
  );
}

export default ButtonForwardAction;
