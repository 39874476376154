import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { immoSearchErrorText, immoSearchNoResultsText, neubauprojekteSearchNoResultsText } from '../../components/FormFields/util/formTexts';


//STORE
import { setFormIsLoading } from '../../reducers/app';
import { setCachedImmoList, setCachedImmoListCount, setSearchFormExpanded, setResetFieldsSearchForm, setCachedNeubauList, setCachedNeubauListCount } from '../../reducers/searchForm';

// API
import { getRealtySearch } from '../../api/Sreal';

import { getRealtyList } from '../../api/JustImmo';
import { parseImmo  } from '../../util/immoObjectParser';

export const useFetchImmoSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
	const location = useLocation();

  const app = useSelector((state) => state.app);
  let currentSearchForm = useSelector((state) => state.searchForm);

  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page > 1) {
			
			if(location.pathname === app.root + '/immobiliensuche') {
				if (currentSearchForm.cachedImmoList.length <= currentSearchForm.cachedImmoListCount) {
					setRealtySearchList(false);
				}
			}
			else if(location.pathname === app.root + '/neubauprojekte') {
				if (currentSearchForm.cachedNeubauList.length <= currentSearchForm.cachedNeubauListCount) {
					setResidentialProjectsList(false);
				}
      }
    }
    // eslint-disable-next-line
  }, [page]);

  const handleShowMoreImmos = () => {
    setPage(page + 1);
    dispatch(setFormIsLoading(true));
  };

  const handleScrollToResults = () => {
    const resultListEl = document.querySelector('#immoresult-list');
    window.scrollTo({
      top: resultListEl.offsetTop,
      behavior: 'smooth',
    });
  };

  const clearSearch = () => {
    let expandedVal = currentSearchForm.searchFormExpanded;
    dispatch(setResetFieldsSearchForm());
    dispatch(setSearchFormExpanded(expandedVal));

    const formEl = document.querySelector('#searchForm');
    window.scrollTo({
      top: formEl.offsetTop,
      behavior: 'smooth',
    });

    setRealtySearchList(true, false, true);
  };

	async function getRealtiesWithWohneinheiten(list) {
		if(!list) return [];
		let residential_projects = list.filter((realty) => realty.sreal_type_id === 'residential_projects');

		// Fetch realty objects for each project and handle errors
		const realtiesWithObjects = await Promise.all(
			residential_projects.map(async (r) => {
				try {
					// const immo_wohneinheiten = await getRealtyList(13864141, 3255); //TESTING
					const immo_wohneinheiten = await getRealtyList(r.crm_id, r.tenant_id);
					const wohneinheiten = immo_wohneinheiten?.items?.map(parseImmo).filter(Boolean) || [];
					return { ...r, wohneinheiten };
				} catch (error) {
					console.error(`Error in getRealtyList:`, error);
					return r;
				}
			}),
		);

		return list.map((realty) => {
			let realtyWithObjects = realtiesWithObjects.find((realtyWithObjects) => realtyWithObjects.id === realty.id);
			return realtyWithObjects || realty;
		});
	}

  async function setRealtySearchList(resetPage = false, scrollToResultList = false, clearSearch = false, navigateToImmoSearch = false) {
    setError(false);

    if (resetPage) {
      setPage(1);
    }

    if (navigateToImmoSearch) {
      navigate(app.root + '/immobiliensuche');
    }

    let requestBody = currentSearchForm.requestBody;
    if (clearSearch) {
      requestBody = {};
    }

    let validationResult = true;
    if (validationResult) {
      const res = await getRealtySearch(requestBody, resetPage ? 1 : page);

      if (res.realties) {
        const updatedRealties = await getRealtiesWithWohneinheiten(res.realties);
				
				const immoList = resetPage ? updatedRealties : currentSearchForm.cachedImmoList.concat(updatedRealties);
				dispatch(setCachedImmoList(immoList));
        dispatch(setCachedImmoListCount(res.resultCount));
				
        dispatch(setFormIsLoading(false));

        if (res.resultCount === 0) {
          setError(immoSearchNoResultsText);
        }

        // scroll to resultslist
        if (scrollToResultList) {
          handleScrollToResults();
        }
      } else {
        dispatch(setCachedImmoList([]));
        dispatch(setCachedImmoListCount(0));
        dispatch(setFormIsLoading(false));
        setError(immoSearchErrorText);

        // scroll to resultslist
        if (scrollToResultList) {
          handleScrollToResults();
        }
      }
    } else {
      dispatch(setFormIsLoading(false));
    }
  }

  const setResidentialProjectsList = async (resetPage = false) => {
    if (resetPage) {
      setPage(1);
    }

		const requestBody = {
			estateType: [
        {
          id: 'residential_projects',
          text: 'Neubau Projekte',
        },
      ],
			sorting: {
        id: 'updated_desc',
        text: 'Aktualität',
      },
			projects_only: 1
		};

    const res = await getRealtySearch(requestBody, resetPage ? 1 : page);
    if (res.realties) {
			const updatedRealties = await getRealtiesWithWohneinheiten(res.realties);

			const immoList = resetPage ? updatedRealties : currentSearchForm.cachedNeubauList.concat(updatedRealties);
			dispatch(setCachedNeubauList(immoList));
      dispatch(setCachedNeubauListCount(res.resultCount));

      dispatch(setFormIsLoading(false));

			if (res.resultCount === 0) {
				setError(neubauprojekteSearchNoResultsText);
			}

    } else {
      dispatch(setCachedNeubauList([]));
      dispatch(setCachedNeubauListCount(0));
      dispatch(setFormIsLoading(false));
      setError(immoSearchErrorText);
    }
  };

  return {
    handleScrollToResults,
    clearSearch,
    setRealtySearchList,
    handleShowMoreImmos,
    setResidentialProjectsList,
    error,
  };
};
