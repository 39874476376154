import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconChat from '../Icons/IconChat3';
import IconSpeechBubbleStar from '../Icons/IconSpeechBubbleStar';
import Card from '../Cards/Card';
import PageDialogEmpfehlung from '../Dialogs/PageDialogEmpfehlung';

function RecommendationLinkitem({ cssClasses = '' }) {
  const [open, setOpen] = useState(false);
  const loginForm = useSelector((state) => state.loginForm);

  const handleSetOpen = () => {
    let val = !open;
    setOpen(val);

    // if pageDialog closed - set cookie
    if (val === false) {
      localStorage.setItem('mysreal-recommendation-already-opened', true);
    }
  };

  useEffect(() => {
    if (loginForm.pageDialogOpen === false) {
      if (!localStorage.getItem('mysreal-recommendation-already-opened') || localStorage.getItem('mysreal-recommendation-already-opened') === false) {
        setOpen(true);
      }
    }
  }, []);

  return (
    <>
      {(!localStorage.getItem('mysreal-recommendation-isset') || localStorage.getItem('mysreal-recommendation-isset' === false)) && (
        <Card cssClasses={cssClasses + ' text-center recommendation-card background-darkteal'} cardStyle={'customContent'}>
          <div className="card__icon">
            <IconSpeechBubbleStar color="#fff" />
          </div>

          <div className="card__content">
            <div>
              <h2 className="mb-100rem text-white">Haben Sie eine Minute?</h2>
              <p className="pb-100rem pt-50rem">Helfen Sie uns, unser Service weiter zu verbessern.</p>
            </div>
            <div className="mb-100rem">
              <button className="button button--white-outline" onClick={handleSetOpen}>
                Zwei Fragen beantworten
              </button>
            </div>
          </div>
        </Card>
      )}
      <PageDialogEmpfehlung open={open} handleClose={handleSetOpen} />
    </>
  );
}

export default RecommendationLinkitem;
