function IconGutachten({ color, cssClasses = '' }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 13714" xmlns="http://www.w3.org/2000/svg" width="37.6" height="47.9" viewBox="0 0 37.6 47.9">
      <g data-name="Gruppe 13716">
        <path data-name="Pfad 8572" d="M32.4,3.4H28.7A5.083,5.083,0,0,0,23.9,0H13.6A5.083,5.083,0,0,0,8.8,3.4H5.1A5.121,5.121,0,0,0,0,8.5V42.8a5.121,5.121,0,0,0,5.1,5.1H32.5a5.121,5.121,0,0,0,5.1-5.1V8.6a5.228,5.228,0,0,0-5.2-5.2m-18.8,0H23.9a1.752,1.752,0,0,1,1.7,1.7V6.8a1.752,1.752,0,0,1-1.7,1.7H13.6a1.752,1.752,0,0,1-1.7-1.7V5.1a1.626,1.626,0,0,1,1.7-1.7M34.1,42.9a1.752,1.752,0,0,1-1.7,1.7H5a1.752,1.752,0,0,1-1.7-1.7V8.6A1.752,1.752,0,0,1,5,6.9H8.4A5.121,5.121,0,0,0,13.5,12H23.8a5.121,5.121,0,0,0,5.1-5.1h3.4A1.752,1.752,0,0,1,34,8.6V42.9Z" fill={color} />
        <path data-name="Pfad 8573" d="M26.5,19.1a1.752,1.752,0,0,0-2.4.5L16.5,31.7l-5.4-4A1.71,1.71,0,1,0,9,30.4l6.9,5.1a1.929,1.929,0,0,0,1,.3h.3a1.548,1.548,0,0,0,1.1-.8l8.6-13.7a1.454,1.454,0,0,0-.4-2.2" fill={color} />
      </g>
    </svg>

  );
}

export default IconGutachten;

