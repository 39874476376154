import React from 'react';
import Header from './Header';

function HeaderSmallWithIcon({ icon, iconActive, iconDesktop, headlineDesktop, headlineDesktopRight, subheadlineDesktopRight, overlappedContent, cssClasses, children }) {
  let headerIcon = React.cloneElement(icon, {
    color: '#fff',
    cssClasses: 'header__iconbg',
    active: iconActive,
  });

  cssClasses += ' header--darkbluebg';
  if (overlappedContent) {
    cssClasses += ' header--overlapped';
  }

  return (
    <Header cssClasses={cssClasses}>
      <span className={'desktop-hidden'}>{headerIcon}</span>
      <div className={iconDesktop ? 'header__desktopheadlines' : 'header__desktopheadlines mb-20'}>
        {headlineDesktop && (
          <h1 className={iconDesktop ? 'header__desktopheadlines__iconDesktop text-white w-desktop--center mt-200rem' : 'text-white w-desktop--center mt-200rem mb-100'}>
            {iconDesktop && headerIcon}
            {headlineDesktop}
          </h1>
        )}
        <div className="mb-30">
          {headlineDesktopRight && <h2 className="text-white mb-0">{headlineDesktopRight}</h2>}
          {subheadlineDesktopRight && <p>{subheadlineDesktopRight}</p>}
        </div>
        {children}
      </div>
    </Header>
  );
}

export default HeaderSmallWithIcon;
