//*Components
import FinanzierungsCard from '../components/Cards/FinanzierungsCard';
import MarktinfoLightCard from '../components/Cards/MarktinfoLightCard';
import Header from '../components/Header/Header';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import SearchProfilCreateLinkitem from '../components/Linkitems/SearchProfilCreateLinkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsTeaserCards from '../components/News/NewsTeaserCards';
import headerImage from '../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.2.jpg';
import headerImageMobile from '../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0004_s-REAL-Sujets-2023-Webhero-16zu9-1.13.jpg';

//*Icons
//*Utils
import { useDispatch } from 'react-redux';
import { useFetchImmoSearch } from '../hooks/immo/useFetchImmoSearch';
import { setPageDialogOpenSPForm } from '../reducers/searchProfileForm';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import ContactFormCard from '../components/Cards/ContactFormCard';

function DashboardKPhase2InBearbeitung() {
  const { setRealtySearchList } = useFetchImmoSearch();
  const dispatch = useDispatch();

  const { isMobile } = useHandleScreenResize();

  const handleOpenSearchprofileDialog = () => {
    dispatch(setPageDialogOpenSPForm(true));
  };
  const headerCtabuttons = [
    <HeaderCtaButtons key="headerCtabuttons">
      <button className="button button--orange" onClick={handleOpenSearchprofileDialog}>
        Jetzt Suchprofil erstellen
      </button>
    </HeaderCtaButtons>,
  ];

  return (
    <>
      <MetaTags title={'Suchprofile in Bearbeitung | Die Online-Plattform für Ihre Immobilie'} imageName={'mysreal_hero_dashboard-kauf.jpg'} />
      <Header cssClasses="header--default header--fitcontent header-overflowhidden">
        <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />

        <div className="header__content-overlay"></div>
        <div className="header__content-wrapper">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">Hier finden Sie zukünftig Ihre Angebote und Treffer</h1>
          </div>
          <div className="header__ctabuttons-wrapper">{headerCtabuttons}</div>
        </div>

        {/* cta buttons mobile-version fixed */}
        {/* {headerCtabuttons} */}
      </Header>

      <MainContent>
        <div className="w-desktop--center">
          <FinanzierungsCard />
        </div>

        <div className="gray-background">
          <div className="w-desktop--center">
            <MarktinfoLightCard forKäufer={true} cssClasses={'bg-gray'} />
          </div>
        </div>

        <div className="w-desktop--center">
          <ContactFormCard />
        </div>

        <NewsTeaserCards cssClasses={isMobile ? 'w-desktop--slider' : 'w-desktop--slider mt-400rem'} />
      </MainContent>
    </>
  );
}

export default DashboardKPhase2InBearbeitung;
