import AktuellesNachrichten from '../components/Aktuelles/AktuellesNachrichten';
import Header from '../components/Header/Header';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

import { useEffect, useState } from 'react';
import CardGridPlaceholderElement from '../components/Cards/CardGridPlaceholderElement';
import IconLoadingSpinner from '../components/Icons/IconLoadingSpinner';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useHandleMessages } from '../hooks/messages/useHandleMessages';

function Nachrichten() {
  const { threads, threadsLoading, loadMessages } = useHandleMessages();
  const [isDesktop, setIsDesktop] = useState(false);

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    loadMessages();

    if (!isMobile) {
      setIsDesktop(true);
    }
  }, []);

  return (
    <>
      <MetaTags title={'Nachrichten | Die Online-Plattform für Ihre Immobilie'} />
      <Header cssClasses="header--darkbluebg header--darkblue-withouticon header--overlapped">
        <div className="header__desktopheadlines mb-20">
          <h1 className="text-white w-desktop--center mt-200rem mb-100">Nachrichten</h1>
        </div>
      </Header>
      <MainContent cssClasses="nachrichten">
        <div className="w-desktop--center elements3cols">
          {threadsLoading && threads.length === 0 ? (
            <p className={'page__loadingmessage'}>
              <IconLoadingSpinner color={isDesktop ? '#ffffff' : '#5d5d5d'} />
              <span className="ml-10">Ihre Nachrichten werden geladen.</span>
            </p>
          ) : (
            <>
              {threads?.length === 0 ? (
                <p className="page__emptymessage">Sie haben derzeit noch keine Nachrichten empfangen. Sobald eine Makler:in mit Ihnen in Kontakt ist, finden Sie hier Ihre Konversationen.</p>
              ) : (
                <>
                  {threads.map((item, index) => {
                    return <AktuellesNachrichten
								thread={item.chatList}
								cssClasses={'elements3cols__col linkitem-green '}
								reloadMessagesCallback={async () => loadMessages()}
								key={index + '_nacrichten'}
							/>;
                  })}

                  <CardGridPlaceholderElement array={threads} type="linkitem" cssClasses="elements3cols__col" />
                </>
              )}
            </>
          )}
        </div>
      </MainContent>
    </>
  );
}

export default Nachrichten;
