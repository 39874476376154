//*React
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
//*Components
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormInput from '../../FormFields/FormInput';
import LoadingSpinner from '../../LoadingSpinner';
//*Utils
import { validateFields } from '../../FormFields/util/validateFields';
//*Constants
import { REGFORM_SITEINDEX_START } from '../../../constants';
//*Reducers
import { setSiteIndexLoginForm } from '../../../reducers/loginForm';

function RegisterFormEmailConfirm({ resendConfirmationLink }) {
  let currentFormValidators = useSelector((state) => state.formValidators);
  let loginForm = useSelector((state) => state.loginForm);

  const dispatch = useDispatch();
  const location = useLocation();

  const backAction = () => {
    dispatch(setSiteIndexLoginForm(REGFORM_SITEINDEX_START));
  };

  const forwardAction = () => {
    let validationResult = validateFields({ email: loginForm.requestBody.email }, dispatch);

    if (validationResult) {
      resendConfirmationLink(loginForm.requestBody.email);
    }
  };

  const formType = 'loginForm';

  return (
    <>
      <h2>E-Mail-Adresse bestätigen</h2>

      {location.pathname.indexOf('set_password') >= 0 ? (
        <p className="mb-200rem">Geben Sie bitte Ihre E-Mail-Adresse in das unten stehende Feld ein.</p>
      ) : (
        <>
          <p className="mb-200rem">Sie haben die Registrierung gestartet aber nach Erhalt des Bestätigungslinks nicht abgeschlossen?</p>

          <p className="mb-200rem">Geben Sie bitte Ihre E-Mail-Adresse in das unten stehende Feld ein. Wenn eine unvollständige Registrierung vorliegt, erhalten Sie von uns eine E-Mail.</p>
        </>
      )}

      <FormInput type={'text'} id={'inputEmail'} label={'E-Mail-Adresse'} error={currentFormValidators.emailError} required={true} formType={formType} />

      <LoadingSpinner />

      <div className="button-panel pt-100rem">
        {location.pathname.indexOf('/confirm') < 0 && (
          <button className="button button--gray-outline" onClick={backAction}>
            Zurück
          </button>
        )}
        <ButtonForwardAction formType={formType} buttonText="Bestätigungslink anfordern" forwardAction={forwardAction} />
      </div>
    </>
  );
}

export default RegisterFormEmailConfirm;
