import MainContent from '../MainContent';

// REACT LOADING SKELETON
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Header from '../Header/Header';

function ImmoDetailSkeleton() {
  const skeletonColor = '#e4e4e4';

  return (
    <>
      <Header cssClasses="header--immodetail" />

      <MainContent id="immodetail">
        <div className="mobile-hidden">
          <div className="w-desktop--center">
            <h1 className="mt-60 mb-10">
              <Skeleton baseColor={skeletonColor} width={'70%'} height={30} />
            </h1>
            <p className="immodetail--immolive">
              <Skeleton baseColor={skeletonColor} width={80} height={20} />
            </p>
            <p className="immodetail__objectnumber">
              <Skeleton baseColor={skeletonColor} width={250} />
            </p>
            <Skeleton baseColor={skeletonColor} height={300} width={'120%'} className="mb-80" />

            <div className="immodetail__features mt-0 justify-content-around">
              {Array(3)
                .fill()
                .map((item, index) => (
                  <div className="immodetail__features-wrapper" key={index}>
                    <span className="immodetail__features--val">
                      <Skeleton baseColor={skeletonColor} width={200} height={60} />
                    </span>
                    <span className="immodetail__features--label">
                      <Skeleton baseColor={skeletonColor} width={70} height={15} />
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* MOBILE ONLY */}
        <div className="desktop-hidden">
          <Skeleton baseColor={skeletonColor} height={300} className="mb-10" />

          <div className="immodetail__headline">
            <div className="w-desktop--center">
              <h1 className="mt-10">
                <Skeleton baseColor={skeletonColor} width={'100%'} />
                <Skeleton count={2} baseColor={skeletonColor} width={'70%'} />
              </h1>
              <p className="immodetail__objectnumber mb-5">
                <Skeleton baseColor={skeletonColor} width={'70%'} />
              </p>
            </div>
            <div className="immodetail__features">
              {Array(3)
                .fill()
                .map((item, index) => (
                  <div className="immodetail__features-wrapper" key={index}>
                    <span className="immodetail__features--val">
                      <Skeleton baseColor={skeletonColor} width={120} />
                    </span>
                    <span className="immodetail__features--label">
                      <Skeleton baseColor={skeletonColor} width={70} height={15} />
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* IMMO DESCRIPTION */}
        <div className="immodetail__desc">
          <div className="w-desktop--center">
            <h2 className="mt-0 mb-10">
              <Skeleton baseColor={skeletonColor} width={'70%'} height={30} />
            </h2>

            <Skeleton baseColor={skeletonColor} width={'40%'} height={15} />
            <p>
              <Skeleton baseColor={skeletonColor} width={'60%'} height={15} />
            </p>

            <Skeleton baseColor={skeletonColor} height={300} className="mt-10" />
          </div>
        </div>
      </MainContent>
    </>
  );
}

export default ImmoDetailSkeleton;
