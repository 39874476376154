import React from 'react';

function IconTraffic({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 17238" xmlns="http://www.w3.org/2000/svg" width="41.197" height="46.1" viewBox="0 0 41.197 46.1">
      <g data-name="Gruppe 17239">
        <path
          data-name="Pfad 10491"
          d="M4.844,17.6v9.3a5.121,5.121,0,0,0,5.1,5.1h21.3a5.121,5.121,0,0,0,5.1-5.1V7.6a7.6,7.6,0,0,0-7.6-7.6h-16.3a7.6,7.6,0,0,0-7.6,7.6Zm28.1-7.6v6.2l-12.4,2.6-12.4-2.6V10Zm-1.7,18.7H9.944a1.752,1.752,0,0,1-1.7-1.7V19.7l12,2.5h.6l12-2.5v7.2a1.634,1.634,0,0,1-1.6,1.8M12.444,3.4h16.4a4.087,4.087,0,0,1,4,3.2H8.344a4.289,4.289,0,0,1,4.1-3.2m28.5,40.2-6-10.3a1.681,1.681,0,0,0-2.9,1.7l1.5,2.6H7.644l1.5-2.6a1.681,1.681,0,0,0-2.9-1.7l-6,10.3a1.64,1.64,0,0,0,.6,2.3,1.486,1.486,0,0,0,.9.2,1.65,1.65,0,0,0,1.5-.9l2.5-4.3h29.9l2.5,4.3a1.859,1.859,0,0,0,1.5.9,2.92,2.92,0,0,0,.9-.2,1.787,1.787,0,0,0,.4-2.3"
          fill={color}
        />
        <path
          data-name="Pfad 10492"
          d="M14.844,23.3h-2.7a1.7,1.7,0,0,0,0,3.4h2.7a1.7,1.7,0,1,0,0-3.4m14.1,0h-2.7a1.7,1.7,0,0,0,0,3.4h2.7a1.752,1.752,0,0,0,1.7-1.7,1.685,1.685,0,0,0-1.7-1.7"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconTraffic;
