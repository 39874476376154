import { useDispatch, useSelector } from 'react-redux';

import HeaderHamburger from './HeaderHamburger';
import Logo from './Logo';
import LogoutButton from './LogoutButton';
import Menu from './Menu';
import SelectIhreImmobilie from './SelectIhreImmobilie';

import { parseDate } from '../../util/datetimeParser';

import '../../styles/nav.scss';

// STORE
import { setPageDialogOpenLoginForm, setResetFieldsLoginForm } from '../../reducers/loginForm';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../../reducers/registerForm';
import MenuButtonSwitcher from './MenuButtonSwitcher';

function Nav({ activeMobileMenu }) {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user);

  // LOGIN + REGISTER
  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  const handleOpenLoginForm = () => {
    dispatch(setResetFieldsLoginForm());
    dispatch(setPageDialogOpenLoginForm(true));
  };

  return (
    <>
      {/*  mobile menu */}
      <nav className={activeMobileMenu ? 'is-visible header__nav--mobile' : 'header__nav--mobile'}>
        <div className="header__nav--mobiletop">
          <Logo logoColor="blue" />
          <HeaderHamburger />
        </div>

        {!user.isLoggedIn ? (
          <div className="button-panel">
            <button className="button button--blue" onClick={handleOpenLoginForm}>
              Login
            </button>
            <button className="button button--gray-outline" onClick={handleOpenRegisterForm}>
              Registrieren
            </button>
          </div>
        ) : (
          <>
            <div className="mb-100rem">
              <span className="font-weight-600">
                Hallo,
                <br />
                {user.userObject.firstName} {user.userObject.lastName}!
              </span>

              {user.lastLoggedIn !== '' && (
                <>
                  <br />
                  <span className="font-90">Letzter Login: {parseDate(user.lastLoggedIn, 'DD.MM.YYYY HH:mm')}</span>
                </>
              )}
            </div>

            <MenuButtonSwitcher cssClasses={'mt-10 mb-20'} />
          </>
        )}

        <SelectIhreImmobilie />

        <Menu />
        <LogoutButton />

        <a href="https://www.sreal.at" target="_blank" className="text-link mt-200rem" rel="noreferrer">
          sREAL.at aufrufen
        </a>
      </nav>

      {/*  desktop menu */}
      <Menu cssClasses="header__nav--desktop" />
    </>
  );
}

export default Nav;
