function IconClose({ color, cssClasses, width, height, handleClick }) {
  return (
    <svg
      className={cssClasses}
      onClick={handleClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.245 21.271"
    >
      <g data-name="Gruppe 9658" transform="translate(21636.912 16375.976)">
        <path
          data-name="Pfad 6496"
          d="M0,17.029,8.5,8.514,0,0"
          transform="translate(-21634.791 -16373.854)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          data-name="Pfad 6497"
          d="M0,0,8.5,8.514,0,17.028"
          transform="translate(-21617.789 -16356.826) rotate(180)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}

export default IconClose;
