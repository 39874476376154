function IconSpeechBubbleStar({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="48.02" height="47.925" viewBox="0 0 48.02 47.925">
      <g id="Gruppe_16274" data-name="Gruppe 16274" transform="translate(-1347 -2244.999)">
        <g id="Gruppe_16139" data-name="Gruppe 16139" transform="translate(1344 2348.999)">
          <g id="Gruppe_16140" data-name="Gruppe 16140" transform="translate(3 -104)">
            <path
              id="Pfad_10417"
              data-name="Pfad 10417"
              d="M34.6,2.4A24.508,24.508,0,0,0,24,0,24.071,24.071,0,0,0,0,24,23.819,23.819,0,0,0,3.5,36.5L.1,45.6a1.747,1.747,0,0,0,.3,1.7,1.658,1.658,0,0,0,1.6.6l12-2.2a23.456,23.456,0,0,0,10,2.2,23.221,23.221,0,0,0,7.8-1.3A23.741,23.741,0,0,0,45.6,34.4a24.012,24.012,0,0,0-11-32M42.5,33A20.663,20.663,0,0,1,15,42.5a1.884,1.884,0,0,0-.8-.2h-.3L4.4,44l2.7-7.1a1.839,1.839,0,0,0-.2-1.6A19.623,19.623,0,0,1,3.4,24a20.094,20.094,0,0,1,6-14.5A20.454,20.454,0,0,1,32.9,5.6,20.389,20.389,0,0,1,42.5,33"
              fill={color}
            />
          </g>
        </g>
        <path
          id="Pfad_10426"
          data-name="Pfad 10426"
          d="M35.4,18.9,29.2,18l-2.8-5.6a2.54,2.54,0,0,0-1.3-1.2,2.672,2.672,0,0,0-3.6,1.3L18.8,18l-6.2.9a2.628,2.628,0,0,0-1.5.7,2.732,2.732,0,0,0-.8,1.9,3.176,3.176,0,0,0,.8,2l4.5,4.3-1,6.2a3.079,3.079,0,0,0,.3,1.9,2.693,2.693,0,0,0,2.4,1.4,3.079,3.079,0,0,0,1.3-.3l5.5-2.9L29.5,37a3.194,3.194,0,0,0,1.9.3,2.591,2.591,0,0,0,1.7-1.2,2.815,2.815,0,0,0,.4-2l-.9-6.4,4.3-4.1a2.088,2.088,0,0,0,.8-1.6,2.739,2.739,0,0,0-2.3-3.1m-5.7,6.7c-.1.1-.2.3-.3.4a2.57,2.57,0,0,0-.1,2.1l.7,5-4.6-2.4h-.1a2.55,2.55,0,0,0-2.4,0h-.1l-4.6,2.4.8-5a2.678,2.678,0,0,0-.5-2.3c0-.1-.1-.1-.1-.2L14.6,22l5-.7a2.588,2.588,0,0,0,2.1-1.5L24,15.1l2.3,4.5a2.648,2.648,0,0,0,2.1,1.6l5.1.8Z"
          transform="translate(1347 2245)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconSpeechBubbleStar;
