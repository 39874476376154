import MainContent from '../MainContent';

// REACT LOADING SKELETON
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import HeaderSmallWithIcon from '../Header/HeaderSmallWithIcon';
import IconNews from '../Icons/IconNews';

function NewsCardsSkeleton() {
  const skeletonColor = '#e4e4e4';

  return (
    <>
      <HeaderSmallWithIcon icon={<IconNews />} headlineDesktop="News" cssClasses="header--news background-stone" />

      <MainContent cssClasses="news">
        <div className="mobile-hidden">
          <div className="background-stone">
            <div className="d-flex justify-content-between w-desktop--center h-36rem">
              <span className="card-link">
                <div className="card card--highlight-active">
                  <div>
                    <Skeleton baseColor={skeletonColor} width={'100%'} height={'17rem'} />
                  </div>
                  <div className="newsSkeletonCard p-100rem">
                    <h2 className="lh-130 mb-0">
                      <Skeleton baseColor={skeletonColor} width={250} height={25} />
                    </h2>
                    <p className="mt-10 mb-20">
                      <Skeleton baseColor={skeletonColor} width={'90%'} height={30} />
                      <Skeleton baseColor={skeletonColor} width={'70%'} height={30} />
                    </p>
                    <p>
                      <Skeleton baseColor={skeletonColor} width={'95%'} height={15} />
                      <Skeleton baseColor={skeletonColor} width={'35%'} height={15} />
                    </p>
                  </div>
                </div>
              </span>
              <div className="cardgrid news__highlight">
                <div className="news-card ">
                  <div className="card bg-white with-shadow">
                    <div className="d-flex">
                      <Skeleton className="mr-50rem" baseColor={skeletonColor} width={72} height={80} />
                      <div className="card__content__text">
                        <Skeleton baseColor={skeletonColor} width={80} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="news-card ">
                  <div className="card bg-white with-shadow">
                    <div className="d-flex">
                      <Skeleton className="mr-50rem" baseColor={skeletonColor} width={72} height={80} />
                      <div className="card__content__text">
                        <Skeleton baseColor={skeletonColor} width={80} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="news-card ">
                  <div className="card bg-white with-shadow">
                    <div className="d-flex">
                      <Skeleton className="mr-50rem" baseColor={skeletonColor} width={72} height={80} />
                      <div className="card__content__text">
                        <Skeleton baseColor={skeletonColor} width={80} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="news-card ">
                  <div className="card bg-white with-shadow">
                    <div className="d-flex">
                      <Skeleton className="mr-50rem" baseColor={skeletonColor} width={72} height={80} />
                      <div className="card__content__text">
                        <Skeleton baseColor={skeletonColor} width={80} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="news-card ">
                  <div className="card bg-white with-shadow">
                    <div className="d-flex">
                      <Skeleton className="mr-50rem" baseColor={skeletonColor} width={72} height={80} />
                      <div className="card__content__text">
                        <Skeleton baseColor={skeletonColor} width={80} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                        <Skeleton baseColor={skeletonColor} width={250} height={15} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* MOBILE */}
        <div className="desktop-hidden">
          <div className="w-desktop--center">
            <h1>
              <Skeleton baseColor={skeletonColor} width={90} height={30} />
            </h1>

            <p className="mt-60 mb-10">
              <Skeleton baseColor={skeletonColor} width={'100%'} height={30} />
            </p>

            <div className="cardgrid news__maincards">
              {Array(2)
                .fill()
                .map((item, index) => (
                  <div className="news-card mt-20" key={'newsskeleton-mobile-' + index}>
                    <div>
                      <Skeleton baseColor={skeletonColor} width={'100%'} height={200} />
                    </div>
                    <div className="newsSkeletonCard">
                      <div className="mt-40 mb-30 d-flex flex-wrap">
                        {Array(5)
                          .fill()
                          .map((item, index) => (
                            <div style={{ marginRight: '10px', marginBottom: '10px' }} key={'newsskeleton-mobilecard-' + index}>
                              <Skeleton baseColor={skeletonColor} width={110} height={30} />
                            </div>
                          ))}
                      </div>
                      <p className="mb-20">
                        <Skeleton baseColor={skeletonColor} width={250} height={30} />
                      </p>
                      <p>
                        <Skeleton baseColor={skeletonColor} width={'95%'} height={15} />
                        <Skeleton baseColor={skeletonColor} width={'35%'} height={15} />
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
}

export default NewsCardsSkeleton;
