import { useEffect } from 'react';

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      let target = event.target;
      if (ref.current && !ref.current.contains(target)) {
        if (!target.classList.contains('MuiMenuItem-root')) {
          callback();
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideAlerter;
